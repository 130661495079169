const state = {
  token: '',
  name: '',
  userInfo: {}, // 用户信息合并，后期统一用这个
}

const mutations = {
  // 更新用户信息
  updateUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  CLEAR: (state) => {
    state.name = ''
    state.token = ''
    state.userInfo = {}
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}

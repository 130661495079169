export default function PoiServer(ajax, config) {
  return {
    list: (opt) => ajax({ url: '/poiList', method: 'post', ...opt }),
    add: (opt) => ajax({ url: '/pois', method: 'post', ...opt }),
    // 查询行政区
    selDistricts: (opt) => ajax({ url: '/districts', method: 'get', ...opt }),
    info: (opt) => ajax({ url: `/pois/${opt.c_id}`, method: 'get', ...opt }),
    edit: (opt) => ajax({ url: `/pois`, method: 'put', ...opt }),
    // 枚举接口
    enum: {
      // 获取字典
      dicDict: (opt) => ajax({ url: `/dic/dict`, method: 'get', ...opt }),
      // 获取字典项
      dictItems: (opt) => ajax({ url: `/dic/dictItems`, method: 'get', ...opt }),
      // 获取字典及字典项
      dicts: (opt) => ajax({ url: `/dic/dicts`, method: 'get', ...opt }),
    },
    // 新的城市列表接口
    citys: (opt) => ajax({ url: `/citys`, method: 'get', ...opt }),
    // 根据PoiId分页查询图片信息
    picList: (opt) =>
      ajax({
        url: `/poi/${opt.c_id}/pic`,
        method: 'get',
        ...opt,
      }),
    // 根据PoiId数组分页查询图片信息
    getPoisPic: (opt) => ajax({ url: `/pois/pic`, method: 'post', ...opt }),
    // 获取带图片的poi列表
    getWithImgPoiList: (opt) => ajax({ url: `/pois-with-pic`, method: 'post', ...opt }),
  }
}

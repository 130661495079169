const state = {
  selectedOrders: [],
}

const getters = {
  selectedOrders: (state) => state.selectedOrders,
}

const mutations = {
  setSelectedOrders(state, data) {
    state.selectedOrders = data.map((item) => {
      return {
        ...item,
        channel:
          item.channel ||
          `${item.firstChannelName}-${item.secondChannelName}-${item.thirdChannelName}`,
        productClass:
          item.productClass || `${item.firstProductClassName}-${item.secondProductClassName}`,
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}

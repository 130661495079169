export default {
  Add: {
    '957132-0': '提交',
    '957132-1': '簽約方式',
    '957132-2': '請選擇',
    '957132-3': '簽約範本',
    '957132-4': '簽約時間',
    '957132-5': '簽約文件',
    '957132-6': '上傳文件',
    '957132-7': '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv',
    '957132-8': 'OA合約流程號',
    '957132-9': '請輸入',
    '957132-10': 'OA審批截圖',
    '957132-11': '請上傳簽約文件',
    '957132-12': '請上傳OA審批截圖文件',
    '957132-13': '添加簽約信息',
    '957132-14': '請選擇簽約方式',
    '957132-15': '請選擇簽約範本',
    '957132-16': '請選擇簽約時間',
    '957132-17': '更新簽約信息',
    '957132-18': '查看簽約信息',
    '957132-19': '{0}成功，您尚未添加收款信息，是否要添加收款？',
    '957132-20': '提示',
    '957132-21': '添加',
    '957132-22': '關閉',
    '957132-23': '添加收款成功，在分銷方訂單完成簽約收款後，此訂單可繼續往下一個環節流轉',
    '957132-24': '{0}成功，是否要完成簽約收款任務？',
    '957132-25': '完成簽約收款',
    '957132-26': '{0}不支持預訂，確認提交？',
    '957132-27': '{0}不支持預訂，請調整後重新操作',
    '957132-28': '{0}未填寫完整，確認提交？',
    '957132-29': '{0}未填寫完整，請完善後重新操作',
    '957132-30': '繼續',
    '957132-31': '取消',
  },
  List: {
    '848874-0': '添加簽約信息',
    '848874-1': '簽約方式',
    '848874-2': '簽約範本',
    '848874-3': '授權簽署人',
    '848874-4': '狀態',
    '848874-5': '簽署時間',
    '848874-6': '簽署開關',
    '848874-7': '操作',
    '848874-8': '查看',
    '848874-9': '更新簽約信息',
    '848874-10': '重新生成合約',
    '848874-11': '查看合約',
    '848874-12': '線上簽約',
    '848874-13': '線下簽約',
    '848874-14': '單項委託協定-機酒',
    '848874-15': '國家版境內範本',
    '848874-16': '單項委託協定-通用',
    '848874-17': '補充協議',
    '848874-18': '待簽署',
    '848874-19': '已生效',
    '848874-20': '已終止',
    '848874-21': '合約終止',
    '848874-22': '已作廢',
    '848874-23': '關',
    '848874-24': '開',
  },
}

import { BaseApi } from '../couplingAjax'
import config from '../config'

const ajax = (opt) =>
  BaseApi(opt, {
    prefix: config.Cfs,
    codeField: 'code',
    codeNum: 200,
    msgField: 'msg',
  })

export default function Cfs() {
  // 关于接口文档说明：http://wiki.cits.com.cn/pages/viewpage.action?pageId=11179237
  return {
    // 文件服务器的删除
    upload: config.domainName + config.gateway + '/cfs/upload',
    // 文件上传函数，成功逻辑为内部返回值的url有值则上传成功
    uploadFun: (opt) =>
      ajax({
        url: '/upload',
        method: 'POST',
        file: true, // 走文件模式，绕过统一封装
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...opt,
      }),
    del: (opt) =>
      ajax({
        url: '/delete',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...opt,
      }),
    // 相对路径删除文件
    relativeDel: (opt) =>
      ajax({
        url: '/delete/relativeUrl',
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data',
        },
        ...opt,
      }),
    // 带域名的全路径删除
    allRouteDel: (opt) =>
      ajax({
        url: '/delete',
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data',
        },
        ...opt,
      }),
    uploadVideo: (opt) =>
      ajax({
        url: '/video/upload',
        method: 'POST',
        timeout: 1000 * 60 * 30, // 視頻上传失败控制为30分钟
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...opt,
      }),
    // 删除单个视频
    delVideo: (opt) =>
      ajax({
        url: '/video/delete',
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        ...opt,
      }),
  }
}

// 不需要进行权限管控的页面
export default [
  '/login',
  '/404',
  '/403',
  '/',
  '/posterToIn/edit', // 海报对内地址
  '/grounding/resource-info', // 资源详情页面，无权限可访问
  '/grounding/product-info', // 产品详情
  '/order-sys/order-details', // 订单详情
  '/announcements',
  '/announcement-view',
  '/change-password',
  '/mbs-web/monitor/pvuv-activity-detail',
  '/mbs-web/activity-manage-list',
  '/mbs-web/add-activity-manage',
  '/mbs-web/member-list',
  '/mbs-web/member-detail',
  '/mbs-web/information-list',
  '/mbs-web/information-list-detail',
  // '/salesReport/detail',
  '/mbs-web/information-list',
  '/mbs-web/information-list-detail',
  '/product-sys/ticket-list',
  '/flow-chart',
  '/mbs-web/test',
  '/trip-web/trip/edit', // 行程编辑器地址
  '/product-sys/ticket-edit', // 门券产品
  '/mbs-web/add-maintain-product',
  // '/product-sys/hotel-set/info', // 酒店套餐详情
  // '/mbs-web/product/hotel-info', // 酒店产品
]

export default function BaseServer(ajax, config) {
  return {
    // 根据城市名称模糊搜索
    queryCityList: (opt) =>
      ajax({
        url: '/city/list',
        method: 'post',
        ...opt,
      }),

    // 查询商业区列表
    queryBusinessList: (opt) =>
      ajax({
        url: '/business/list',
        method: 'post',
        ...opt,
      }),

    // 查询行政区列表
    queryAreaList: (opt) =>
      ajax({
        url: '/area/list',
        method: 'post',
        ...opt,
      }),

    // 查询地铁站列表
    queryMetroList: (opt) =>
      ajax({
        url: '/metro/list',
        method: 'post',
        ...opt,
      }),

    // 常旅搜索接口 base-server/tourist/list  post
    // memberPhone	String	N	会员手机号	/  15888888888
    // memberId	String	Y	会员id	/    bd76275a685544fcafaf7b71f18ddba8
    // current	int	N	当前页	/
    // size	int	N	每页数	/
    queryFrequentlyTourists: (opt) =>
      ajax({
        url: '/tourist/list',
        method: 'post',
        ...opt,
      }),

    // 获取微信产品详情短链
    // queryProductUrlLikes: (opt) =>
    //   ajax({
    //     url: '/weixin/get-product-url-likes',
    //     method: 'post',
    //     ...opt,
    //   }),

    // 查询会员基本信息
    queryMemberBase: (opt) =>
      ajax({
        url: '/member/base',
        method: 'post',
        ...opt,
      }),

    // 查询字典信息
    queryDictionary: (opt) =>
      ajax({
        url: `/dictionary/getByDictionaryCode`,
        method: 'get',
        ...opt,
      }),
    // 生成短链
    generateShortLink: (opt) =>
      ajax({
        url: `/connect/get-short-chain`,
        method: 'post',
        ...opt,
      }),
    // 获取链接地址
    getBizCode: (opt) =>
      ajax({
        url: `/protocol/biz-code/${opt.code}`,
        method: 'get',
        ...opt,
      }),
    // 根据业务主键获取唯一数据
    getBizKeyBatch: (opt) =>
      ajax({
        url: `/tourist-limit-config/get-biz-key-batch`,
        method: 'post',
        ...opt,
      }),
  }
}

const state = {
  templateType: 'temp_1', //模板类型
  updateActive: false, //默认左侧logo和icon不选中
  hasTitle: true, //默认显示标题
  hasLabel: true, //默认显示主题标签
  hasLogo: true, //默认显示logo
  hasQrCode: false, //NBS云店专用-默认隐藏qrCode
  hasQrCodeImg: false, //NBS同业渠道-默认隐藏qrCode
  dZoom: 100, // 画布缩放百分比
  dScreen: {
    width: 0, // 记录编辑界面的宽度
    height: 0, // 记录编辑界面的高度
  },
  gridSize: {
    width: 0, // 网格小格子的宽度
    height: 0, // 网格小格子的高度
  },

  dActiveWidgetXY: {
    x: 0, // 选中组件的横向初始值
    y: 0, // 选中组件的纵向初始值
  },
  dMouseXY: {
    x: 0, // 鼠标按下时的横坐标
    y: 0, // 鼠标按下时的纵坐标
  },
  dMoving: false, // 是否正在移动组件
  dResizeing: false, // 是否正在调整组件宽高
  dShowRefLine: true, // 是否显示参考线

  dResizeWH: {
    // 初始化调整大小时组件的宽高
    width: 0,
    height: 0,
  },
  dActiveElement: {}, // 选中的组件或页面
  dCopyElement: [], // 复制的组件（可能是单个也可能是数组）
  dHoverUuid: '-1', // 鼠标在这个图层上
  dPage: {
    name: '背景页面',
    type: 'page',
    uuid: '-1',
    left: 0,
    top: 0,
    width: 1080, // 画布宽度
    height: 1920, // 画布高度
    backgroundColor: '#fff', // 画布背景颜色
    backgroundImage: '', // 画布背景图片
    opacity: 1, // 透明度
    tag: 0, // 强制刷新用
    setting: [
      {
        label: '背景颜色',
        parentKey: 'backgroundColor',
        value: false,
      },
    ],
    record: {},
  },
  dWidgets: [], // 已使用的组件
  dHistory: [], // 记录历史操作（直接保存整个画布的json数据）
  dActiveUuidHistory: [], // 记录历史操作对应的激活的组件的uuid
  dPageHistory: [], // 记录历史操作对应的page
  dHistoryParams: {
    index: -1,
    length: 0,
  },
  dColorHistory: [
    'rgba(255, 69, 0, 1)',
    'rgba(255, 140, 0, 1)',
    'rgba(255, 215, 0, 1)',
    'rgba(144, 238, 144, 1)',
    'rgba(0, 206, 209, 1)',
    'rgba(30, 144, 255, 1)',
    'rgba(199, 21, 133, 1)',
    'rgba(255, 69, 0, 0.68)',
    'rgba(255, 120, 0, 1)',
    'rgba(250, 212, 0, 1)',
    'rgba(144, 240, 144, 0.5)',
    'rgba(0, 186, 189, 1)',
    'rgba(31, 147, 255, 0.73)',
    'rgba(199, 21, 133, 0.46)',
  ], // 记录历史选择的颜色
  dAltDown: false, // 记录是否按下alt键
  dSelectWidgets: [], // 记录多选的组件
  dGroupJson: {}, // 组合的json数据
}

const getters = {
  templateType(state) {
    return state.templateType
  },
  updateActive(state) {
    return state.updateActive
  },
  hasTitle(state) {
    return state.hasTitle
  },
  hasLabel(state) {
    return state.hasLabel
  },
  hasLogo(state) {
    return state.hasLogo
  },
  hasQrCode(state) {
    return state.hasQrCode
  },
  hasQrCodeImg(state) {
    return state.hasQrCodeImg
  },
  dZoom(state) {
    return state.dZoom
  },
  dScreen(state) {
    return state.dScreen
  },
  gridSize(state) {
    return state.gridSize
  },
  dActiveWidgetXY(state) {
    return state.dActiveWidgetXY
  },
  dMouseXY(state) {
    return state.dMouseXY
  },
  dMoving(state) {
    return state.dMoving
  },
  dActiveElement(state) {
    return state.dActiveElement
  },
  dPage(state) {
    return state.dPage
  },
  dWidgets(state) {
    return state.dWidgets
  },
  dHistoryParams(state) {
    return state.dHistoryParams
  },
  dColorHistory(state) {
    return state.dColorHistory
  },
  dHoverUuid(state) {
    return state.dHoverUuid
  },
  dResizeing(state) {
    return state.dResizeing
  },
  dShowRefLine(state) {
    return state.dShowRefLine
  },
  dCopyElement(state) {
    return state.dCopyElement
  },
  dAltDown(state) {
    return state.dAltDown
  },
  dSelectWidgets(state) {
    return state.dSelectWidgets
  },
}

const actions = {
  updateHasTitle(store, value) {
    store.state.hasTitle = value
  },
  updateHasLabel(store, value) {
    store.state.hasLabel = value
  },
  updateHasLogo(store, value) {
    store.state.hasLogo = value
  },
  updateHasQrCode(store, value) {
    store.state.hasQrCode = value
  },
  updateHasQrCodeImg(store, value) {
    store.state.hasQrCodeImg = value
  },
  updateZoom(store, zoom) {
    store.state.dZoom = zoom
  },
  calcZoom(store) {
    const widthZoom = ((store.state.dScreen.width - 142) * 100) / store.state.dPage.width
    const heightZoom = ((store.state.dScreen.height - 122) * 100) / store.state.dPage.height

    store.state.dZoom = Math.min(widthZoom, heightZoom)
  },
  updateScreen(store, { width, height }) {
    store.state.dScreen.width = width
    store.state.dScreen.height = height
  },
  selectWidget(store, { uuid }) {
    const alt = store.state.dAltDown
    const selectWidgets = store.state.dSelectWidgets
    const widget = store.state.dWidgets.find((item) => item.uuid === uuid)
    if (alt) {
      if (uuid !== '-1' && widget.parent === '-1' && !widget.isContainer) {
        if (selectWidgets.length === 0) {
          if (store.state.dActiveElement.uuid !== '-1') {
            selectWidgets.push(store.state.dActiveElement)
          }
        }
        const index = selectWidgets.findIndex((item) => {
          return item.uuid === uuid
        })
        // 如果已经存在则取消选择，否则加入选取
        if (index !== -1) {
          selectWidgets.splice(index, 1)
          if (selectWidgets.length === 0) {
            store.state.dActiveElement = store.state.dPage
          }
        } else {
          selectWidgets.push(widget)
        }
        if (selectWidgets.length === 1) {
          store.state.dActiveElement = selectWidgets[0]
          store.state.dSelectWidgets = []
        }
      }
      return
    }
    store.state.dSelectWidgets = []
    if (uuid === '-1') {
      store.state.dActiveElement = store.state.dPage
      const pageHistory = store.state.dPageHistory
      if (pageHistory.length === 0) {
        pageHistory.push(JSON.stringify(store.state.dPage))
      }
    } else {
      store.state.dActiveElement = widget
    }
  },
  initGroupJson(store, json) {
    store.state.dGroupJson = json
  },
}

const mutations = {
  updateTemplateType: (state, value) => {
    state.templateType = value
  },
  updateActive: (state, value) => {
    state.updateActive = value
  },
  updateHasTitle: (state, value) => {
    state.hasTitle = value
  },
  updateHasLabel: (state, value) => {
    state.hasLabel = value
  },
  updateHasLogo: (state, value) => {
    state.hasLogo = value
  },
  updateHasQrCode: (state, value) => {
    state.hasQrCode = value
  },
  updateHasQrCodeImg: (state, value) => {
    state.hasQrCodeImg = value
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}

export default {
  productId: '產品碼',
  productName: '產品名稱',
  productCompany: '產品公司',
  Id: '產品編號',
  productStatus: '產品狀態',
  productCode: '產品碼',
  vendorId: '供應商',
  vendorProductCode: '供應商產品碼',
  ownerId: '產品負責人',
  depCode: '負責人部門',
  productCategory: '產品品類',
  destination: '目的地',
  teamType: '團類型',
  journeyDays: '天數',
  singleEntrustType: '代理業務類型',
  chargeUnit: '單位',
  productPoiInfos: '景點',
  terminals: '終端',
  exclusiveOutlet: '專售門市',
  ticketCollectionMethod: '取票方式',
  ticketCollectionDesc: '取票方式描述',
  ticketCollectionTime: '換票時間',
  ticketCollectionPlace: '換票地點',
  ticketExchangeRemark: '換票備註',
  branch: '門市取票',
  ticketExchangeFormat: '換票證格式',
  validityEnum: '有效期類型',
  containSeason: '場次',
  validityValue: '有效天數',
  validityDesc: '有效期描述',
  underwriteTicket: '包銷',
  minOrderQuantity: '每單最少預訂',
  maxOrderQuantity: '每單最多預訂',
  ticketBookingRequirement: '門票預訂要求',
  requireUsername: '姓名',
  requirePhoneNum: '手機號',
  bookExplain: '預訂須知',
  cancelPolicy: '取消政策',
  cancelPolicyDescription: '取消政策說明',
  receiptNote: '收據備註',
  productDesc: '產品銷售須知',
  bookNotice: '產品預訂須知',
  packageStatus: {
    0: '已下架',
    1: '已上架',
    2: '無貨',
    3: '審批中',
  },
  bigTraffic: {
    0: '否',
    1: '飛機',
    2: '火車',
    3: '汽車',
    4: '輪船',
  },
  routeType: {
    1: '單程',
    2: '往返',
    3: '多程',
  },
  flightTicketType: {
    1: '全部',
    2: '散客票',
    3: '團隊票',
  },
  arrivalDayOpt: {
    0: '當天（0）',
    1: '第二天（+1）',
    negativeOne: '前一天（-1）',
  },
  terminalType: {
    1: 'PC',
    3: 'M站',
    4: '中旅旅行微信小程式',
    5: 'NBS後台',
    6: '中旅雲店微信小程式',
    8: '中旅旅行雲閃付小程式',
    9: '中旅旅行抖音小程式',
    10: '中旅旅行翼支付H5',
  },
  terminalCategory: {
    1: '移動',
    2: 'PC',
  },
  source: {
    0: 'NBS',
    1: '資源',
    2: '直連',
    3: '供',
    4: '單團',
    5: '',
    6: '簽證',
    7: '認證',
    8: '門券',
  },
  addPrice: {
    zero: '金額',
    one: '比例',
  },
  saleType: {
    1: '直銷',
    2: '分銷',
  },
  entryTimes: {
    single: '單次',
    multiple: '多次',
    other: '其他',
  },
  durationOfStay: {
    day: '天',
    year: '年',
    other: '其他',
  },
  periodOfValidity: {
    day: '天',
    year: '年',
    other: '其他',
  },
  certType: {
    visa: '簽證',
    authentication: '認證',
  },
  salesMethod: {
    zero: '必選',
    one: '一項起選',
    two: '可選',
  },
  ticket: {
    ticketCollectionMethod: {
      direct: '直接使用',
      electronics: '列印【電子換票證】，憑證換領正式票券',
      selected: '到選定的中旅分社/取票點取票',
    },
    ticketExchangeFormat: {
      me: '我司提供',
      supplier: '由供應商提供',
    },
    validityEnum: {
      today: '選擇的使用日期當天有效',
      number: '預訂後【數字】日內有效',
      desc: '固定有效期截止前有效',
    },
    containSeason: {
      no: '無場次',
      yes: '有場次',
    },
    underwriteTicket: {
      no: '非包銷',
      yes: '包銷',
    },
    cancelPolicy: {
      yes: '可取消',
      no: '不可取消',
    },
  },
}

import { Message } from 'element-ui'

const customMessage = ({ msg = '', type = 'success', tipsCode = '', duration = 3000 }) => {
  if (!msg) return null
  if (!tipsCode) {
    Message({
      message: msg,
      type,
      showClose: true,
      duration,
    })
  }
  if (tipsCode) {
    Message({
      dangerouslyUseHTMLString: true,
      message: msg, //`${msg} <a href="/nbs-pc/wiki-search?name=${tipsCode}" style="color: var(--primary-color);" target="_blank">更多帮助</a>`,
      type,
      showClose: true,
      duration,
    })
  }
}

export default customMessage

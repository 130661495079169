import category from './category'
import category32 from './category.json'
import contentManage from './contentManage'
import departure from './departure'
import destinationGroup from './destinationGroup'
import supplier from './supplier'
export default {
  category: {
    ...category,
  },
  contentManage,
  departure,
  destinationGroup,
  supplier,
}

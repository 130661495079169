const gConfig = require('/config/config')
export const config = {
  name: gConfig.platform,
  description: '',
}

// 数据库表名称
export const dbTable = {
  twoTableCol: 'twoTableCol', // 主表名称
}

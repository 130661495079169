<script>
import TableShowField from './TableShowField.vue'
import { getCol, setCol } from '@/store/localforage/service/twoTableCol'
import { getCacheKey, getFreeLabels, getFiledLabels, setFiledLabels } from './tool'
import i18n from '@/i18n'
export default {
  name: 'TwoTable',
  components: {
    TableShowField,
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      },
    },
    border: {
      type: Boolean,
      default: true,
    },
    stripe: {
      type: Boolean,
      default: true,
    },
    headerCellStyle: {
      type: Object,
      default() {
        return {}
      },
    },
    emptyText: {
      type: String,
    },
    // 筛选表格列功能
    filterCol: {
      type: Boolean,
      default: false,
    },
    // 当一个页面存在多个表格的时候标识用哪个缓存
    cacheKey: String,
    // 控制最大展示列
    // maxCol: {
    //   type: Number,
    //   default: 10, // 最大展示列
    // },
  },
  computed: {
    headerCellStyleComputed() {
      return {
        background: '#EAEAEA',
        color: 'var(--text-color-primary)',
        // padding: '4px',
        'font-weight': 600,
        ...this.headerCellStyle,
      }
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.updateTimes()
        })
      },
    },
    $slots: {
      deep: true,
      immediate: true,
      handler(slot) {
        if (this.filterCol) {
          this.getColName(slot.default)
        }
      },
    },
  },
  data() {
    return {
      times: new Date().getTime(),
      filedLabels: [
        // {
        //   value: index,
        //   label: 'filed',
        //   show: true
        // }
      ],
      treeLabels: [], // 给子组件
    }
  },
  provide() {
    return {
      [getCacheKey]: () => this.cacheKey,
      [getFreeLabels]: () => this.treeLabels,
      [getFiledLabels]: () => this.filedLabels,
      [setFiledLabels]: (labels = []) => {
        this.filedLabels = labels
      },
    }
  },
  created() {
    // 表格子组件未定义在组件中的通过data.attrs中进行获取
    this.noProps = {
      'col-show': true, // 这个列默认是否展示
      'col-disabled': false, // 列是否禁用
    }
  },
  methods: {
    // 获取ref内容
    getRef() {
      return this.$refs.customTable
    },
    updateTimes() {
      // this.times = new Date().getTime()
      this.$refs.customTable.resizeListener()
    },
    toggleRowSelection(row, isSelect) {
      return this.$refs.customTable.toggleRowSelection(row, isSelect)
    },
    // 获取列名称形成控制列
    async getColName(col) {
      try {
        // console.log(col)
        const oldColLabels = await getCol(this.cacheKey)
        oldColLabels.map((li) => {
          if (!li.children) li.children = []
        })

        const showVal = (sourceLi, list = [], index, label) => {
          const item = list.find((li) => li.label === label)
          if (item) {
            // 优先读取缓存配置数据
            return {
              show: item.show,
              disabled: item.disabled,
            }
          } else {
            const attrs = { ...this.noProps, ...sourceLi.data?.attrs }
            // 自定义列控制的时候
            return {
              show: attrs['col-show'],
              disabled: attrs['col-disabled'],
            }
          }
        }
        // 最新的数组数据
        const filedLabels = []
        for (let i = 0; i < col.length; i++) {
          const li = col[i]
          const { label, prop } = li?.componentOptions?.propsData ?? {}
          const mainLabel = label ? label : prop

          const itemShow = showVal(li, oldColLabels, i, mainLabel)
          const itemLabel = {
            value: i.toString(),
            index: i,
            label: mainLabel,
            show: itemShow.show,
            disabled: itemShow.disabled,
            children: [],
          }
          const children = li?.componentOptions?.children
          // 子列表
          if (children) {
            children.forEach((ci, cindex) => {
              if (ci.componentOptions?.tag !== 'el-table-column') return null
              const tPropsData = ci.componentOptions?.propsData ?? {}
              const sonLabel = tPropsData.label ? tPropsData.label : tPropsData.prop
              const childShow = showVal(
                ci,
                oldColLabels[i] ? oldColLabels[i].children : [],
                cindex,
                sonLabel,
              )
              itemLabel.children.push({
                value: i + '-' + cindex,
                index: cindex,
                label: sonLabel,
                show: childShow.show,
                disabled: childShow.disabled,
                children: [],
              })
            })
          }
          filedLabels.push(itemLabel)
        }
        this.treeLabels = filedLabels
        this.filedLabels = filedLabels
        // 存入最新数据
        setCol(filedLabels, this.cacheKey)
      } catch (e) {
        console.log(e)
      }
    },
    finColShow() {
      const showCol = []
      this.filedLabels.forEach((li, oneI) => {
        const cItem = this.$slots.default[li.index] // 一定是获取原始数据
        const { componentOptions } = cItem ?? {}
        // 进行深拷贝处理，否则下面的子对象会继承地址数据
        const deepNode = {
          ...cItem,
          componentOptions: { ...componentOptions },
        }
        if (componentOptions) {
          const { children } = componentOptions
          if (children) {
            deepNode.componentOptions.children = [...children]
          }
        }

        if (li.show && deepNode) {
          // 标识key避免渲染错误和渲染闪烁问题
          deepNode.key = deepNode.key ?? oneI
          showCol.push(deepNode)
        }
        if (li.children.length) {
          const children = this.$slots.default[li.index].componentOptions.children // 一定是获取原始数据
          deepNode.componentOptions.children = []
          li.children.forEach((tl, twoI) => {
            if (tl.show && children[tl.index]) {
              const twoNode = {
                ...children[tl.index],
              }
              twoNode.key = twoNode.key ?? oneI + '-' + twoI

              deepNode.componentOptions.children.push(twoNode)
            }
          })
        }
      })

      // // showCol.forEach((li, oneIndex) => {
      // //   if (li) li.key = li.key ?? oneIndex
      // //   if (li.componentOptions?.children) {
      // //     li.componentOptions.children.map((cl, twoIndex) => {
      // //       if (cl) cl.key = cl.key ?? oneIndex + '-' + twoIndex
      // //     })
      // //   }
      // //
      // //   return li
      // // })
      return showCol
    },
  },
  render(createElement, context) {
    const {
      headerCellStyleComputed,
      border,
      stripe,
      times,
      emptyText,
      data,
      filterCol,
      $slots,
      $attrs,
      $listeners,
    } = this
    return (
      <div>
        {($slots.header || filterCol) && (
          <div class="two-table-header">
            {$slots.header && $slots.header}
            {filterCol ? <TableShowField /> : ''}
          </div>
        )}
        <el-table
          {...{ props: $attrs }}
          {...{ on: $listeners }}
          header-cell-style={headerCellStyleComputed}
          border={border}
          stripe={stripe}
          class="custom-table"
          key={times}
          ref="customTable"
          empty-text={emptyText ?? i18n.t('common.data.noData')}
          data={data}
        >
          {filterCol ? this.finColShow() : $slots.default}
        </el-table>
      </div>
    )
  },
}
</script>
<style>
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #ebf1fa !important;
}
</style>
<style lang="scss" scoped>
.custom-table {
  width: inherit;
  height: inherit;
  // 修复table列使用fixed属性导致可能出现多余线条的问题
  ::v-deep .el-table__fixed,
  ::v-deep .el-table__fixed-right {
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
  ::v-deep th.is-hidden,
  ::v-deep td.is-hidden {
    opacity: 0;
  }
}
.two-table-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 8px;
}
</style>

import common from './common.js'
import receiveOrder from './receiveOrder.js'
import orderDetail from './orderDetail.js'
import intention from './intention.js'
import constCommon from './const.js'

export default {
  common,
  receiveOrder,
  orderDetail,
  intention,
  constCommon,
}

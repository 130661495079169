export default {
  empty: '暫無數據',
  errorTotal: '異常總數',
  dayType: '按日',
  monthType: '按月',
  dayTotal: '當日新增異常',
  monthTotal: '當月新增異常',
  total: '異常累計總數',
  title: '系統異常TOP10',
}

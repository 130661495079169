export default [
  {
    path: '/salesReport/list',
    name: '/salesReport/list',
    component: () => import('@/views/salesReport/list/index'),
    meta: { title: '销售日结报表' },
  },
  {
    path: '/salesReport/detail',
    name: '/salesReport/detail',
    component: () => import('@/views/salesReport/detail'),
    meta: { title: '销售日结报表详情' },
  },
  {
    path: '/salesReport/approve',
    name: '/salesReport/approve',
    component: () => import('@/views/salesReport/detail'),
    meta: { title: '销售日结报表审核' },
  },
  {
    path: '/salesReport/approve/detail',
    name: '/salesReport/approve/detail',
    component: () => import('@/views/salesReport/detail'),
    meta: { title: '销售日结报表审核详情' },
  },
  {
    path: '/salesReport/approveList',
    name: '/salesReport/approveList',
    component: () => import('@/views/salesReport/approveList'),
    meta: { title: '审核列表' },
  },
]

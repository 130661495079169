export default function ResourceServer(ajax, config) {
  return {
    resource: {
      log2: (opt) => ajax({ url: '/resource/operation/logs', method: 'GET', ...opt }),
      // 日志
      log: (opt) => ajax({ url: '/resource/operation/logs', method: 'get', ...opt }),
      // 查看退回日志
      lookReturn: (opt) => ajax({ url: '/resource/audit/refuse-reason', method: 'get', ...opt }),
      // 资源基本信息保存接口
      base: (opt) => ajax({ url: '/resource/base', method: 'post', ...opt }),
      // 资源基本信息修改接口
      baseEdit: (opt) => ajax({ url: `/resource/base/${opt.cId}`, method: 'PATCH', ...opt }),
      // 资源推荐保存接口
      recommend: (opt) => ajax({ url: '/resource/recommend', method: 'post', ...opt }),
      // 资源费用说明保存接口
      cost: (opt) => ajax({ url: '/resource/cost', method: 'post', ...opt }),
      // 资源须知说明保存接口
      instructions: (opt) => ajax({ url: '/resource/instructions', method: 'post', ...opt }),
      // 资源动态打包配置保存接口
      packageConfig: (opt) => ajax({ url: '/resource/package-config', method: 'post', ...opt }),
      // 资源复制接口
      copy: (opt) => ajax({ url: '/resource/copy/' + opt.cId, method: 'post', ...opt }),
      // 资源基本信息查询接口
      summary: (opt) => ajax({ url: '/resource/summary/' + opt.cId, method: 'get', ...opt }),
      // 多资源基本信息查询接口
      allSummary: (opt) => ajax({ url: '/resource/summary', method: 'post', ...opt }),
      // 资源删除接口
      del: (opt) => ajax({ url: '/resource/' + opt.cId, method: 'DELETE', ...opt }),
      // 审核接口,包含提交审核，撤销审核，审核驳回,撤销审核
      audit: (opt) => ajax({ url: '/resource/audit', method: 'PATCH', ...opt }),
      // 跟团资源列表查询接口
      list: (opt) => ajax({ url: '/resource/resources', method: 'post', ...opt }),
      // 所有类型的资源
      allTypeList: (opt) => ajax({ url: '/resource/resources-all-type', method: 'post', ...opt }),
      // 资源详情查询接口-返回模板数据,已经拼接好的数据，详情页面用，只能查看审核通过数据
      infoTemplate: (opt) =>
        ajax({ url: `/resource/structural/${opt.resourceId}`, method: 'get', ...opt }),
      // 资源详情操作，
      detailTem: (opt) =>
        ajax({ url: `/resource/structural/detail/${opt.CId}`, method: 'get', ...opt }),
      // 资源详情查询接口，编辑的时候使用
      info: (opt) => ajax({ url: '/resource/detail/' + opt.cId, method: 'get', ...opt }),
      // 行程生成接口
      TravelGeneration: (opt) =>
        ajax({ url: `/resource/${opt.cId}/journey`, method: 'post', ...opt }),
      // 资源枚举值及固定文本查询接口
      enums: (opt) => ajax({ url: `/resource/template`, method: 'get', ...opt }),
      // 获取草稿id
      draft: (opt) => ajax({ url: `/resource/draft/${opt.cid}`, method: 'get', ...opt }),
      // 生成草稿
      generateDraft: (opt) => ajax({ url: `/resource/addDraft/${opt.cid}`, method: 'get', ...opt }),
      scheme: {
        query: (opt) => ajax({ url: `/scheme/${opt.resourceBaseId}`, method: 'get', ...opt }),
        edit: (opt) => ajax({ url: `/scheme/add`, method: 'POST', ...opt }),
        list: (opt) =>
          ajax({ url: `/scheme/list?resourceIdList=${opt.resourceBaseIds}`, method: 'GET' }),
      },
      // 获取资源页面全量配置表
      getConfig: (opt) => ajax({ url: `/resource/config`, method: 'get', ...opt }),
      // 查询场次信息
      seasonList: (opt) =>
        ajax({ url: `/resource/resource-session/query`, method: 'POST', ...opt }),
      // 跨组织资源复制，根据资源ID查询资源
      getResourceInfo: (opt) =>
        ajax({ url: `/resource/detail-for-cross-organ-copy/${opt.id}`, method: 'get' }),
      // 根据资源ids批量查询资源详情
      resourceDetailSimpleList: (opt) =>
        ajax({ url: `/resource/resource-detail/simple/list?resourceIds=${opt}`, method: 'get' }),
      // 获取资源参考样本
      getSampleInfo: (opt) =>
        ajax({
          url: `/resource/sample-info/${opt.resourceId}`,
          method: 'get',
          ...opt,
        }),
      // 下载签证材料
      downloadMaterial: (opt) =>
        ajax({
          url: `/resource/visa-generate-pdf-stream`,
          method: 'post',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
      // 获取用户是否与权限编辑资源
      listPermission: (opt) =>
        ajax({ url: '/resource/resources-with-permission', method: 'post', ...opt }),
    },
    // 机票资源
    ticket: {
      // 列表
      list: (opt) => ajax({ url: '/air-ticket-resource', method: 'get', ...opt }),
      info: (opt) => ajax({ url: `/air-ticket-resource/${opt.resourceId}`, method: 'get', ...opt }),
      add: (opt) => ajax({ url: `/air-ticket-resource`, method: 'post', ...opt }),
      del: (opt) => ajax({ url: `/air-ticket-resource/${opt.ID}`, method: 'get', ...opt }),
      // 机票资源-修改
      edit: (opt) => ajax({ url: `/air-ticket-resource/${opt.resourceId}`, method: 'put', ...opt }),
    },
    visa: {
      sendEmail: (opt) => ajax({ url: `/resource/visa-send-email`, method: 'post', ...opt }),
    },
    hotel: {
      list: (opt) => ajax({ url: `/hotel/hotel-detail-by-name`, method: 'POST', ...opt }),
    },
  }
}

export default {
  component: {
    DhtCalendarTwoPicker: {
      operateTips: '按住Ctrl，可快速選擇連續的日期區間，點擊選擇起-止日期即可',
      rise: '起',
      end: '止',
    },
  },
  viewCommon: {
    PreviewH5: {
      title: '預覽產品',
    },
    PublicOperationLog: {
      title: '操作記錄',
      serviceTypeName: '操作項',
      content: '備註',
      serviceId: '審核編號',
      createUserName: '操作賬號',
      updateUserName: '操作人',
      updateTime: '操作時間',
    },
    AllCategory: {
      placeholder: {
        one: '一級品類',
        two: '二級品類',
        three: '目的地大類',
        four: '一級目的地',
        five: '二級目的地',
        six: '目的地名稱',
      },
      PleaseSelectTheSuperiorCategoryFirst: '請先選擇上級品類',
      PleaseSelectTheParentDestinationFirst: '請先選擇上級目的地',
    },
    DepartmentUserList: {
      pleaseSelectDepartment: '請先選擇部門',
    },
  },
  upload: {
    maxSize: '最大{size}M',
  },
  business: {
    PoiList: {
      '865713-0': '共有',
      '865713-1': '條記錄，請輸入完整關鍵詞',
    },
  },
}

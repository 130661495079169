export default {
  systemName: '系統名稱',
  date: '日期範圍',
  startDate: '開始日期',
  endDate: '結束日期',
  errorCode: '錯誤碼',
  errorDesc: '錯誤標題',
  systemManager: '系統負責人',
  cnName: '所屬系統',
  createTime: '異常時間',
  more: '查看更多',
  errorStackTitle: '錯誤堆棧詳情',
  title: '錯誤標題詳情',
  to: '至',
  enter: '請輸入${text}',
  errorStack: '錯誤堆棧',
}

<template>
  <div class="row-popover-form">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RowPopoverForm',
  provide() {
    return {
      RowPopoverFormItemCheck: this.itemCheck,
      RowPopoverFormItemClear: this.clearItem,
    }
  },
  data() {
    return {
      customClass: false,
    }
  },
  created() {
    this.sonList = {}
  },
  beforeDestroy() {
    this.sonList = {}
  },
  methods: {
    itemCheck(fun, index, prop, itemSelf) {
      const arr = this.sonList[index]
      if (!Array.isArray(arr)) this.sonList[index] = []

      const newArr = this.sonList[index]
      // 避免重复函数进入
      const oneF = newArr.filter((li) => li.name === prop)
      !oneF[0] &&
        newArr.push({
          name: prop,
          fun: fun,
          itemSelf,
        })
    },
    // 清理缓存
    clearItem(index, prop) {
      if (JSON.toString(this.sonList) === '{}') return null
      if (!this.sonList[index]) return null
      // 单独清理销毁的函数
      const newFunS = this.sonList[index].filter((li) => li.name !== prop)

      this.sonList[index] = newFunS
    },
    async formCheck(index = 0) {
      const msgList = []
      console.log(this.sonList, index, 123123)
      const checkList = this.sonList[index]

      for (let i = 0; i < checkList.length; i++) {
        try {
          await checkList[i].fun()
        } catch (e) {
          msgList.push(e)
        }
      }
      if (msgList.length === 0) {
        return Promise.resolve()
      } else {
        return Promise.reject(msgList)
      }
    },
    // 校验单个字段校验
    /*this.currentRow = this.list[index]
    await this.$refs.popoverForm.validateField(field, index)*/
    async validateField(field, index) {
      try {
        const checkList = this.sonList[index]

        await checkList.filter((li) => li.name === field)[0].fun()

        return Promise.resolve('')
      } catch (e) {
        return Promise.reject(e)
      }
    },
    // 独立更新某个字段的popper位置
    updatePopper(field, index) {
      const checkList = this.sonList[index]
      const itemSelf = checkList.filter((li) => li.name === field)[0].itemSelf
      itemSelf.popperInstance && itemSelf.popperInstance.update()
    },
  },
}
</script>

<style scoped lang="scss">
.row-popover-form {
}
</style>

import Vue from 'vue'
import PreviewFile from './index.vue'

const PreviewFileConstructor = Vue.extend(PreviewFile)
const instance = new PreviewFileConstructor()
instance.$mount()

let isMount = false // 是否挂载过了
const PreviewFileInstance = {
  open({ fileUrl = '', fileName = '' } = {}) {
    if (!fileUrl) return ''
    instance.fileUrl = fileUrl
    instance.fileName = fileName

    if (!instance.show) instance.show = true
    if (!isMount) {
      isMount = true
      document.body.appendChild(instance.$el)
    }
  },
  // 卸载实例
  close() {
    isMount = false
    instance.onClose()
    instance.$destroy()
    instance.$el.parentNode.removeChild(instance.$el)
  },
}

export default PreviewFileInstance

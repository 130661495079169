const basePath = '/price-rule/'

const routers = [
  {
    path: 'group-flight-rule',
    name: 'group-flight-rule',
    component: () => import('@/views/priceRule/GroupFlightAddRule/index.vue'),
    meta: { title: '团队机票加价规则' },
  },
  {
    path: 'ig-aircraft-add-rule',
    name: 'ig-aircraft-add-rule',
    component: () => import('@/views/priceRule/IndividualGuestAircraftAddRule/index.vue'),
    meta: { title: '散客机票加价规则' },
  },
  {
    path: 'ig-hotel-add-rule',
    name: 'ig-hotel-add-rule',
    component: () => import('@/views/priceRule/IndividualGuestHotelAddRule/index.vue'),
    meta: { title: '散客酒店加价规则' },
  },
  {
    path: 'group-resources-add-rule',
    name: 'group-resources-add-rule',
    component: () => import('@/views/priceRule/GroupResourcesAddRule/index.vue'),
    meta: { title: '跟团资源加价规则' },
  },
]

routers.forEach((li) => {
  li.name = basePath + li.name
  li.path = basePath + li.path
})

export default routers

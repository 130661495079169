// 下单记录部分信息

const state = {
  bookingInfoList: [],
}

const getters = {
  bookingInfoList: (state) => state.bookingInfoList,
}

const mutations = {
  setBookingInfo(state, data) {
    const index = state.bookingInfoList.findIndex((item) => item.userId === data.userId)
    if (index !== -1) {
      state.bookingInfoList[index] = data
    } else {
      state.bookingInfoList.push(data)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}

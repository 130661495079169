export default {
  addApprovalRule: '新增審批規則',
  routeName: '規則名稱',
  processType: '審批流類型',
  processName: '審批流名稱',
  userTaskName: '審批節點',
  plsChooseFirst: '請先選擇',
  delRuleMsg: '是否確定删除該條審批規則？',
  delSuccess: '删除成功',
  addSuccess: '新增成功',
  baseInfo: '基本信息',
  ruleDetail: '審批規則明細',
  plsSaveFirst: '請先保存正在編輯的數據。',
  saveSuccess: '保存成功',
  paramType: '規則參數類型',
  formulaValue: '規則參數公式',
  detail: '明細',
  delRuleDetailMsg: '是否確定删除該條審批規則明細？',
  paramValue: '規則參數值',
  delParamMsg: '是否確定删除該條規則參數值？',
}

// 修改数据修改默认值处理
import { localStore } from '@/views/sysConfig/SysMenuSet/store'

export function handlerData(formData) {
  const { applyTo, desc, id, index, name, parentId, url, icon, isOpenPage, code } = formData
  // 解决传入空字符给后端
  return {
    applyTo: Array.isArray(applyTo) ? applyTo.toString() : null,
    desc: desc ? desc.trim() : desc,
    id,
    index,
    name: name ? name.trim() : name,
    parentId,
    icon: icon ? icon : 'el-icon-s-promotion',
    url: url ? url.replace(/\s/g, '') : '',
    isOpenPage,
    code: code ? code.replace(/\s/g, '') : '',
  }
}

// 列表菜单平行改树数据
export function FlatToTree(menuList = [], currentRoleType) {
  if (!menuList || menuList.length < 1) return []
  menuList = JSON.parse(JSON.stringify(menuList))

  const newTree = []
  const menuMap = new Map()
  menuList.forEach((li) => {
    li.children = [] // 先给空子级
    const { applyTo } = li
    li.applyTo = applyTo ? applyTo.split(',') : []
    li.disabled = (currentRoleType && !li.isChoose) || undefined
    menuMap.set(li.id, li)
  })

  menuList.forEach((li) => {
    const { parentId } = li
    const sonMenu = menuMap.get(parentId)
    sonMenu && sonMenu.children.push(li)
    if (parentId === 0) newTree.push(li)
  })
  // 排序处理
  const recursion = (son) => {
    const { children } = son
    if (children && children.length > 0) {
      son.children = children.sort((a, b) => {
        return a.index - b.index
      })
      children.forEach((li) => {
        recursion(li)
      })
    }
  }
  newTree.forEach((li) => {
    recursion(li)
  })

  return newTree.sort((a, b) => {
    return a.index - b.index
  })
}
// 树改平
export function treeLeveling(tree) {
  const list = []

  const recursion = (son) => {
    son.forEach((li) => {
      const { children } = li
      list.push(li)
      if (children && children.length > 0) {
        recursion(children)
      }
    })
  }
  tree.forEach((li) => {
    const { children } = li ?? {}
    list.push(li)
    if (children && children.length > 0) {
      recursion(children)
    }
  })

  return list
}

// 菜单是否存在重复
export function isrMenuNameRepeat(tree, name, noName, field) {
  const list = treeLeveling(tree)
  const nameList = []
  list.forEach((li) => {
    const name = li[field]
    if (name !== noName && name) nameList.push(name)
  })
  return nameList.includes(name)
}

// 获取当前菜单树
export function getCurrentTree(tree, vid) {
  if (!tree || tree.length < 1) return []

  const isId = (son) => {
    const pl = treeLeveling(son)
    return pl.some((li) => li.id === vid)
  }

  let currentTree = null
  tree.forEach((li) => {
    const { children, id } = li ?? {}
    if (id === vid) {
      currentTree = li
    } else if (children && children.length > 0) {
      if (isId(children)) currentTree = li
    }
  })

  return currentTree
}

// 树得到面包屑
export function treeToCrumbs(tree, vid) {
  const plist = treeLeveling([getCurrentTree(tree, vid)])
  const plistMap = new Map()
  const crumbs = []
  plist.forEach((li) => {
    const { id } = li ?? {}
    id && plistMap.set(id, li)
  })
  const recursion = (id) => {
    const cli = plistMap.get(id) ?? {}
    crumbs.unshift(cli)
    const { parentId } = cli
    if (parentId && parentId !== 0) recursion(parentId)
  }
  vid && recursion(vid)

  return crumbs
}

// 获得菜单总共多少级
export function getMenusSumLevel(tree) {
  if (!tree) return 0

  let finlevel = 1

  const recursion = (son) => {
    finlevel++
    son.forEach((li) => {
      const { childNodes } = li
      if (childNodes && childNodes.length > 0) {
        recursion(childNodes)
      }
    })
  }
  const { childNodes } = tree
  if (childNodes && childNodes.length > 0) {
    // 如果存在菜单则需要纳入计算
    if (childNodes.find((li) => li.data.type === localStore.menuType.menu)) recursion(childNodes)
  }

  return finlevel
}

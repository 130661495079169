export default {
  '938990-0': '預訂限制',
  '938990-1': '證件（國籍）限制',
  '938990-2': '本產品適用於持',
  '938990-3': '可在這裡注明具體證件及國籍',
  '938990-4': '客人預訂。',
  '938990-5': '本產品不接受非大陸籍客人預訂，敬請諒解！',
  '938990-6': '本產品僅適用於自備持有【',
  '938990-7': '請選擇',
  '938990-8': '】的中國大陸遊客。',
  '938990-9': '本產品僅接受中國大陸及港澳臺地區客人預訂，敬請諒解！',
  '938990-10': '由於',
  '938990-11': '原因，本產品起價適用於',
  '938990-12': '年齡限制',
  '938990-13': '本產品不接受',
  '938990-14': '輸入數字',
  '938990-15': '歲以上（含）客人預訂，敬請諒解。',
  '938990-16': '歲以下（不含）客人預訂，敬請諒解。',
  '938990-17': '18歲以下未成年人需要至少一名家長或成年旅客全程陪同。',
  '938990-18': '周歲以下兒童不占床，如需占床按成人提交訂單。',
  '938990-19': '周歲（含）以上老年人預訂出行需確保身體健康適宜旅遊，並有',
  '938990-20': '周歲以上家屬或朋友（因服務能力所限無法接待及限制接待的人除外）全程陪同出行。',
  '938990-21': '周歲以上（含）的客人單獨報名出遊，敬請諒解。',
  '938990-22': '此路線行程强度較大，預訂出行人需確保身體健康適宜旅遊，如出行人中有',
  '938990-23': '周歲以上（含）老人、',
  '938990-24': '周歲以下（含）青少年，須至少各有1位',
  '938990-25': '周歲--',
  '938990-26': '周歲親友陪同方可參團，敬請諒解！',
  '938990-27': '本產品不接受兒童按成人提交訂單，敬請諒解！',
  '938990-28': '周歲以上（含）老人、須至少一位18周歲-69周歲親友陪同方可參團，敬請諒解！',
  '938990-29': '此線路因服務能力有限，如出行人中有',
  '938990-30': '此產品因服務能力有限，不接受',
  '938990-31': '周歲以下（含）客人預訂，敬請諒解。',
  '938990-32': '人群限制',
  '938990-33': '出於安全考慮，本產品不接受孕婦預訂，敬請諒解！',
  '938990-34': '如您在决定旅行的時候已經懷孕，請仔細閱讀以下告知，並慎重决定是否參加此次旅行：',
  '938990-35':
    '1、本產品主要交通屬於長距離飛行，班機大多以夜班機或長時間轉機為主，加上出境班機需提前3小時左右赴機場準備安檢事宜，交通接駁較為疲憊。',
  '938990-36':
    '2、目的地行程雖較為自由，但也有相當數量的團隊活動； 當地主要活動較為激烈和刺激，請仔細評估您的身體能否適宜。',
  '938990-37':
    '3、中旅旅行不建議妊娠期在24周以內、32周以上的孕婦報名。 如您的妊娠期在此之間，需在出發前7天去醫院開具證明，證明內容需體現妊娠時長、醫生判斷您的狀況“適合搭乘飛機”並蓋有醫院紅章，此醫院證明有效期必須在出發前7天內。 如您隱瞞懷孕事宜或開具的證明有效期在7天之外，造成無法登機等一切後果及經濟損失由客人自行承擔。',
  '938990-38': '單房差',
  '938990-39':
    '報價是按照2人入住1間房計算的價格，如您的訂單產生單房，中旅旅行將安排您與其他客人拼房入住。 如您要求享受單房，須補足單房差。',
  '938990-40':
    '報價是按照2人入住1間房計算的價格，如您的訂單產生單房，中旅旅行將安排您與其他客人拼房入住。 在無法拼房的情况下，將會安排3人間加床（溫馨提示：當地酒店面積小，加床可能會引起您的不便，敬請諒解）。 如您要求享受單房，須補足單房差。',
  '938990-41': '此產品不接受3人間加床及拼房，敬請諒解。 如訂單人數為奇數，須補足單房差。',
  '938990-42':
    '報價是按照2成人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。 由於12歲以下兒童費用為不占床、不含早餐之報價，若兒童需占床含早，須按成人計算並補足單房差。',
  '938990-43':
    '報價是按照2人入住1間房計算的價格，如您的訂單產生單房，中旅旅行將安排您與其他客人拼房入住。 在無法拼房的情况下，將會安排3成人入住一間房，即標準房加',
  '938990-44':
    '床（溫馨提示：當地酒店面積小，加床可能會引起您的不便，敬請諒解）。如您要求享受單房，須補足單房差。',
  '938990-45':
    '本產品不接受拼房，不提供3人間或加床服務，3成人預訂須支付2間房費。 如需要3成人入住1間雙人標準間，請在預訂時備註清楚，以便客服操作第2間房的單房',
  '938990-46':
    '退款，第3人床位費當地現退，退款金額等同于單房差賣價，第3人不占床不含早，早餐請自理，謝謝配合！',
  '938990-47':
    '本產品接受拼房，但不提供3人間或加床服務，3成人預訂須支付1.5間房費，默認第3人拼房。 如需要3成人入住1間雙人標準間，請在預訂時備註清楚，第3人床位費當地現退賣價，第3人不占床不含早，早餐請自理，謝謝配合！',
  '938990-48':
    '每個房間最多可入住2大1小，如出現2位成人攜帶2位或2位以上兒童出遊的情况需補足2間房的差價。',
  '938990-49':
    '報價是按照2成人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。 由於12歲以下兒童費用為不占床、不含早餐之報價，若兒童',
  '938990-50':
    '需占床含早，須按成人計算並補足單房差。每個房間最多可入住2大1小，如2位成人攜帶2位或2位以上兒童出遊的情況需補足2間房的差價。',
  '938990-51':
    '報價是按照2人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。',
  '938990-52': '違約條款',
  '938990-53': '違約條款將帶入合約中，請確保填寫的內容準確',
  '938990-54': '旅行者在行程開始前',
  '938990-55':
    '日以內提出解除合約或者按照本合約第十二條第2款約定由旅行社在行程開始前解除合約的，按下列標準扣除必要的費用：',
  '938990-56': '在行程開始前',
  '938990-57': '日（含）至',
  '938990-58': '日（含），按旅遊費用總額的',
  '938990-59': '新增',
  '938990-60': '删除',
  '938990-61': '行程開始當日，按旅遊費用的',
  '938990-62': '如需有說明信息可在此維護',
  '938990-63': '預訂須知',
  '938990-64': '預訂說明',
  '938990-65':
    '請您在預訂時務必提供準確、完整的（姓名、性別、證件號碼、國籍、聯繫方式、是否成人或兒童等），以免產生預訂錯誤，影響出行。如因客人提供錯誤個人而造成損失，應由客人自行承擔因此產生的全部損失。',
  '938990-66':
    '根據國家旅遊局（2016年6號）公佈的《旅行社老年旅遊服務規範》行業標準、年滿60周歲（含）以上旅遊者，在報名參加（本產品）旅遊團時，必須填寫《老年人參團申明書》。 《申明書》原件必須和合約一同給到組團社留存歸檔。 請列印簽字後交至工作人員。',
  '938990-67':
    '由於海島旅遊的特殊性，出於安全考慮，請在出行前做一次必要的身體檢查。 患有嚴重心腦血管患者、肺功能不全、孕婦、80周歲以上及行動不便者不建議參加此行程，如需參加請主動申報並提供醫院的身體健康證明，請謹慎報名出行。',
  '938990-68':
    '為確保您能順利出行，下單後，務必提供所有出行人的護照首頁照片或掃描件，届時請及時提供。',
  '938990-69': '產品說明',
  '938990-70': '本產品為獨立成團產品。',
  '938990-71': '本產品為拼團產品，拼至',
  '938990-72': '輸入旅行社全稱',
  '938990-73': '。 可能與其他旅行社客人同團遊玩，敬請諒解。',
  '938990-74': '交通說明',
  '938990-75': '合約一經簽訂且付全款，團隊機票、列車票、船票即為出票，不得更改、簽轉、退票。',
  '938990-76': '飛行時間、車程時間、船程時間以當日實際所用時間為准。',
  '938990-77': '本產品如因淡季或收客人數較少，有可能與相近方向的發班線路拼車出遊，屆時請遊客見諒。',
  '938990-78': '如遇國家或航空公司政策性調整機票、燃油附加費等價格，請按調整後的價格補足差價。',
  '938990-79': '住宿說明',
  '938990-80':
    '產品報價按2人入住1間房核算，如要求三人間或加床，需視入住酒店房型及預訂情況而定。通常酒店標準間內加床為鋼絲床或床墊等非標準床。',
  '938990-81':
    '產品報價按2人入住1間房核算，如出現單男單女，儘量安排該客人與其他同性別團友拼房；如不願拼房或未能拼房，請補齊單房差以享用單人房間。',
  '938990-82':
    '產品報價按2人入住1間房核算，如出現單男單女，儘量安排該客人與其他同性團友拼房，如未能拼房者，可選擇與同行親友共用雙人房並加床（加床者按占半房收費，即3人1間）；如不願意與同行親友共用3人房或單人出行者，請補齊單房差以享用單人房間。',
  '938990-83':
    '由於目的地酒店較小，暫不提供3人間，敬請見諒。 房型以到酒店等級時確認為准，不可提前指定房型。',
  '938990-84':
    '由於旅遊旺季或其他特殊原因，凡無法確定準確的住宿場所名稱或酒店等級的，我社均填寫了參考候選住宿場所名稱。但所列酒店名稱範圍僅供參考，最終以《出遊通知》為准，保證酒店同級。',
  '938990-85':
    '在簽署本旅遊合約時，因特殊原因，對個別無法確定住宿場所名稱、等級的，我社可能會根據旅遊產品的具體情況，注明可以確保的入住場所房間類型，如：標準雙人房；雙人大床房等。',
  '938990-86': '酒店通常規定12點以前辦理退房手續，下午2點後辦理入住手續，少數酒店或特殊假期除外。',
  '938990-87': '出於環保考慮，所有酒店均不提供一次性衛生用品，請您自行攜帶好相關用品。',
  '938990-88': '用餐說明',
  '938990-89': '一般情况下，早餐為自助或桌式； 午、晚餐為十人一桌，',
  '938990-90':
    '菜一湯（不含酒水），人數不足或超過十人時，在每人用餐標準不變的前提下調整餐食的分量。',
  '938990-91': '如對飲食有特殊要求，請在報名時告知旅行社並提前與導遊說明，以便更好地給您安排飲食。',
  '938990-92': '當地特色風味餐屬旅遊生活體驗，具有當地特色，如不符合遊客口味，敬請理解。',
  '938990-93': '行程安排說明',
  '938990-94': '本行程與其他團隊客人拼往返用車。',
  '938990-95': '選填，輸入更多補充內容',
  '938990-96': '根據發團需要，行程中將換當地用車或換當地導遊。',
  '938990-97': '可根據需要選擇某段行程或升級行程，在該行程段分開安排或統一協調行程。',
  '938990-98': '景點遊覽、自由活動、購物店停留的時間以當天實際遊覽為准。',
  '938990-99': '行程中有需自理門票和當地導遊推薦項目，請自願選擇參加。',
  '938990-100':
    '請您仔細閱讀本行程，根據自身條件選擇適合自己的旅遊線路，出遊過程中，如因身體健康等自身原因需放棄部分行程的，或遊客要求放棄部分住宿、交通的，均視為自願放棄，已發生費用不予退還，放棄行程期間的人身安全由旅遊者自行負責。',
  '938990-101':
    '團隊遊覽中請勿擅自離團，中途擅自離團視同遊客違約，由此造成未參加行程內景點、用餐、住房、用車等費用不退，旅行社亦不承擔遊客擅自離團時發生意外的責任。',
  '938990-102':
    '如遇颱風、暴雪等不可抗因素導致無法按約定行程遊覽，行程變更後增加或減少的費用按旅行社團隊操作實際發生的費用結算。',
  '938990-103': '出遊過程中，如產生退費情况，以退費項目旅行社折扣價為依據，均不以掛牌價為准。',
  '938990-104': '購物說明',
  '938990-105': '全程不強制購物，當地購物時請慎重考慮，把握好質量與價格，務必索要發票。',
  '938990-106': '遊客在指定購物店中為自願購物，所購商品非品質問題一律不予退換；',
  '938990-107':
    '行程規定的景點、餐廳，長途中途休息站等這類購物店不屬於旅遊定點場所，若商品出現品質問題，旅行社不承擔任何責任。',
  '938990-108': '遊客自行前往的購物店所購商品出現品質問題，旅行社不承擔任何責任',
  '938990-109': '差價說明',
  '938990-110': '如遇國家政策性調整門票、交通價格等，按調整後的價格補足差額。',
  '938990-111': '行程中贈送的遊覽或娛樂項目，如因班機、天氣等不可抗因素導致未安排，恕不退賠費用。',
  '938990-112': '如遇區域性促銷活動，產生不同預訂城市價格差异，差價不予退還。',
  '938990-113':
    '由於產品報價中所含景點門票按旅行社折扣價核算，除免票條款，遊客不再享受景點門票其它優惠政策。 凡享受免票政策的遊客，須在參團中持相關有效證件，並提前告知導遊，由導遊按旅行社折扣價在旅遊地退費。',
  '938990-114':
    '中旅旅行產品只適用於中國大陸遊客，中國大陸以外有意參團的遊客，在認可行程安排和服務標準的情况下，根據產品要求補齊差價。',
  '938990-115': '出遊通知',
  '938990-116': '我司將最晚在出行前',
  '938990-117':
    '天向您發送《出遊通知書》，若能提前確定，我們將會第一時間通知您。 如未收到請及時聯系中旅旅行工作人員。',
  '938990-118': '成團說明',
  '938990-119': '本產品最少成團人數',
  '938990-120': '人，以最少成團人數發團，如報名人數不足最低人數時，已報名的客人須轉團或者退團。',
  '938990-121':
    '本產品最少成團人數1人，訂單一經中旅旅行以書面形式確認後默認發團，若因我司原因未發團（除不可抗力外），旅行社將按雙方合約約定的違約條款予以賠付。',
  '938990-122':
    '本產品宣佈成團前，旅遊者和旅行社取消訂單的，雙方互不承擔違約責任。 宣佈成團後，旅遊者和旅行社取消訂單，按照雙方合約約定各自承擔違約責任。',
  '938990-123': '每團最多人數為',
  '938990-124': '人（含占座兒童）。',
  '938990-125': '班機變動申明',
  '938990-126':
    '如因意外事件及不可抗力，包括但不限於，航空公司運力調配、航權審批、機場臨時關閉、天氣原因、航空管制等，導致班機取消或延期的，旅行社將盡最大努力協助您辦理變更事宜，如產生差價，多退少補。',
  '938990-127': '意見回饋',
  '938990-128':
    '我們非常重視您的意見和建議，《遊客意見表》是我們判定旅遊團服務質量的重要依據，請您如實填寫《遊客意見表》，感謝您留下寶貴的意見和建議，我們將不斷改進工作，更好地為廣大遊客提供服務。',
  '938990-129': '溫馨提示',
  '938990-130': '在此輸入溫馨提示',
  '938990-131': '簽證須知',
  '938990-132': '如您持外籍護照前往中國境外，請確保持有再次進入中國大陸的簽證。',
  '938990-133': '本產品出簽時間為團隊出發前一個工作日。我司將會用短信方式通知您出簽的結果。',
  '938990-134': '關於銷簽',
  '938990-135': '在此輸入條件和特殊情况',
  '938990-136': '團隊回國後將不再需要辦理銷簽手續。',
  '938990-137':
    '如您為自備簽證，由於產品中機票和酒店為團隊預訂，我司不提供個人預訂的相關證明材料。 如因自備簽證問題造成行程受阻，相應損失需自行承擔。',
  '938990-138': '團隊行程由領隊負責所有機票和護照，故出發前機票護照將不發放給您。',
  '938990-139': '海外遊客，港澳臺遊客請自行確認所持護照是否在',
  '938990-140': '輸入國家簡稱',
  '938990-141':
    '免簽範疇內； 若需辦理簽證請在出行前自行辦理，沒有有效簽證者，無法辦理登機牌，請知曉。',
  '938990-142':
    '在辦理簽證期間，我司會根據您的資料情况可能要求增補其他資料、擔保金或予以勸退，若因勸退產生損失由旅遊者自行承擔，敬請配合與諒解！',
  '938990-143':
    '持L簽證的遊客，敬請至少提前3天提交訂單，請您務必在備註中填寫出行客人的中文姓名一級簽證類型，以便我司正常預訂及行程安排。',
  '938990-144':
    '未成年兒童預訂說明：領館不建議18周歲以下的未成年人與非直系親屬同行，請您斟酌。 18周歲以下的未成年人，如父母雙方均不出行，需額外再提供未成年人出生證明影本，父母的身份證影本',
  '938990-145': '如有補充請在這裡說明',
  '938990-146':
    '團隊簽證客人在回國後需要進行銷簽，請將全程登機牌夾在護照首頁，交給領隊，由旅行社統一辦理銷簽手續，届時領館可能會通知您面試銷簽，敬請配合，辦理銷簽的交通費自理。 銷簽所需時間一般為7個工作日，具體時長視領館而定，請勿在此期間安排其他行程。',
  '938990-147': '免簽',
  '938990-148': '持有中國因私護照者前往',
  '938990-149': '輸入國家',
  '938990-150': '可以免簽，獲得不超過',
  '938990-151': '天的停留期。 請確保您的因私護照有效期距離出發日期，必須大於',
  '938990-152': '個月並具備',
  '938990-153': '頁以上空白簽證頁面； 提供的簽證資料信息必須真實、準確。 客人預訂產品後，請在',
  '938990-154': '個工作日內提供護照首頁掃描件，用於出票使用。 出發當天請客人攜帶好護照的原本',
  '938990-155': '說明照片尺寸（如需照片）及其他材料',
  '938990-156': '手工輸入落免簽須知',
  '938990-157': '落地簽',
  '938990-158': '輸入國家、目的地',
  '938990-159': '可以獲取',
  '938990-160': '天有效停留的落地簽證。 中國公民可以憑因私護照和',
  '938990-161': '登機牌出境，抵達',
  '938990-162': '後辦理落地簽手續。 請確保您的因私護照距離出發日期必須大於',
  '938990-163': '個月，並具備',
  '938990-164': '個工作日內提供護照首頁掃描件，用於出票使用。 出發當天請客人攜帶好護照的原本，',
  '938990-165': '手工輸入落地簽須知',
  '938990-166': '安全須知',
  '938990-167': '特別提示',
  '938990-168': '出發前準備事項',
  '938990-169':
    '（1）旅遊者在臨行前應考慮自身身體狀況，旅行社不建議年齡較大或有高血壓、心臟病、糖尿病等身體不適宜旅遊的旅遊者參團旅遊，如執意參加必須提前征得醫生同意，備好藥品，並征得家屬子女同意且有專人陪同，如因自身原因發生意外，旅遊者自行承擔責任。',
  '938990-170':
    '（2）旅行社不建議孕婦參團旅遊，如執意參加，必須徵求醫生及家屬同意且有專人陪同，如出現意外，旅遊者自行承擔責任。',
  '938990-171':
    '（3）臨行前，旅遊者應根據自己的身體狀況隨身攜帶一些必備的藥品及常用藥品（如感冒藥、止瀉藥、暈車藥等），患有慢性病，平時需要用藥治療者，出遊時切不可遺忘服藥。',
  '938990-172':
    '（4）臨行前，安排好家中相應事宜，把旅遊線路、旅行社緊急連絡人等信息告訴自己家人，同時，也可將自己家人的緊急聯繫方式告知旅行社。',
  '938990-173': '（5）國內旅遊要帶好本人有效身份證、軍官證、學生證、殘疾證、12歲以下兒童',
  '938990-174': '無身份證的',
  '938990-175':
    '帶好戶口本原件等，出團時請務必帶好車票、火車票、機票、船票等，準時到達約定的集合地點，請勿遲到。 如因旅遊者自身原因造成無法登機及延誤旅遊行程所產生的一切損失由旅遊者自行承擔。',
  '938990-176': '（6）在此特別提示旅遊者，強烈建議旅遊者出行前購買旅遊意外保險，保障出遊安全。',
  '938990-177': '交通安全',
  '938990-178':
    '（1）旅遊者乘坐交通工具時，應系好安全帶，在交通工具停穩後方可離開； 上下交通工具時，須排隊等候，講究文明禮貌，並優先照顧老人、兒童、婦女，切勿擁擠，以免發生意外。',
  '938990-179':
    '（2）要遵守交通規則，注意路上的過往車輛。 過馬路請走人行橫道，指示燈綠燈亮時才可通過； 在過馬路時要向右側觀察是否有來車並及時避讓； 有些城市設有自行車道，行人不可站立或行走在自行車道上。',
  '938990-180':
    '（3）上下車船和飛機、出入捷運等交通工具時，出入商場、酒店、餐廳、景區等場所時請注意在扶梯、臺階處站穩，以免扭傷和摔傷身體。 注意特定安全提示，以免受到傷害。',
  '938990-181':
    '（4）為了保證您的旅途安全，也為了方便導遊及時上下車為旅遊者提供服務，請讓出汽車的第一排座位。 儘量避免在車廂內走動或站立，禁止在車內吸烟。 請勿將頭、手、腳或行李物品伸出窗外，以免發生意外。 不要向車窗外扔物品，以免傷人。',
  '938990-182':
    '（5）如旅遊者在當地選擇自駕車遊覽或乘坐當地私營交通工具，建議客人審慎選擇真實、合法和安全的租車公司或交通工具，避免由於車輛原因發生交通事故。 建議旅遊者提前關注道路信息和天氣情況，避免疲勞駕駛，繞開風險過高地區，注意人身和財產安全。',
  '938990-183': '財物安全',
  '938990-184':
    '（1）外出旅遊攜帶行李要輕便，旅行箱要堅固耐用，應儘量避免攜帶貴重物品。 行李如需托運，切勿將現金及貴重物品放在托運行李中。',
  '938990-185':
    '（2）遊覽時，貴重物品如現金、首飾、銀行卡等要隨身攜帶，最好貼身存放，不要放在挎包裏，也不要放在行李寄存處、遊覽車內或酒店房間。 入住酒店後，建議將貴重物品存入飯店的保險箱內並設密碼，以免遺失。',
  '938990-186':
    '（3）如果發生行李或貴重物品損壞、遺失、被竊、被搶等，須及時報警、報損、報失，妥善保存報警回執，收集購置遺失物品的原始發票等資料，以備我社協助您向有關方面申請理賠。 但現金、珠寶首飾、信用卡等不予賠付。',
  '938990-187': '遊覽安全',
  '938990-188':
    '（1）為保證計劃行程順利完成，請遵守團隊作息時間，避免因個人遲到、掉隊影響全團正常行程。 請積極配合導遊的工作，聽清導遊的安全提示，跟隨導遊遊覽時，不要遠離團隊，注意腳下安全。',
  '938990-189':
    '（2）每次下車，請先記清集合地點和時間或地標性建築，記住所乘汽車的牌照號碼、導遊的姓名及手機號碼。 有的城市在遊覽時需轉乘當地的公交車或捷運，要注意緊跟隊伍，以免掉隊或走失。',
  '938990-190':
    '（3）在遊覽過程中，請遵守景區安全提示，按景區指定線路遊覽，禁止偏離遊道和進入非開放區域，禁止攀爬岩壁、樹木、翻越護欄。',
  '938990-191':
    '（4）雨雪天氣從室外進入酒店、餐廳、遊覽設施時，任何時候出入衛生間時，路面濕滑時，注意防滑。',
  '938990-192':
    '（5）我公司鄭重提醒旅遊者，謹慎參加有一定危險或高危的娛樂項目，包括但不限於：過山車、駕駛摩托艇、潜水、滑水、滑雪、跳傘、攀岩、探險、賽車、賽馬、騎馬、滑翔、探險性漂流、熱氣球、高空彈跳、衝浪、游泳、出海、泡溫泉、焗桑拿、高空溜索、登山等。 上述項目對參加者的身體狀況有一定要求，請充分了解活動須知，嚴格遵守相關法律和安全規定。 不要因為尋求刺激、逞能或心存僥倖，造成不必要的傷害。 旅遊者應仔細閱讀活動說明和景區提示，慎重選擇及參與活動。 旅遊者因參加危險活動造成人身和財產損失，旅行社概不負責。 酒後禁止參加有一定危險的娛樂項目。',
  '938990-193':
    '（6）在參加上述活動中，由於受到降雨、狂風、颱風、地震、火山噴發等自然環境和天氣變化等不確定因素所影響、或因設備故障或項目方操作及管理不善、安全設施水准不高、缺乏中文安全提示等原因，有可能會造成旅遊者受傷或死亡等安全事故； 同時一旦發生危及生命的事件，由於當地救援條件有限，沒有配備救生人員和教練、資源或者資源有限以及地理位置受限，極易導致無法及時送醫，錯過最佳搶救時間。 我公司建議旅遊者參加上述危險或高危娛樂項目時，投保人身意外傷害保險或帶救援的人身意外傷害保險。',
  '938990-194': '（7）如遇緊急情况，請保持鎮定，及時尋找工作人員或報警求助。',
  '938990-195': '住宿安全',
  '938990-196':
    '（1）抵達酒店後，請您在前台領取酒店地址名片，外出時隨身攜帶，如果旅遊者迷路時，可以按名片提示的電話、地址安全順利返回酒店。',
  '938990-197':
    '（2）入住酒店後，應了解酒店安全須知，熟悉自己所處位置及安全逃生的路線。 入住時立即檢查房間內設施（衛浴設備、房門及窗戶安全鎖、遙控器、烟缸、毛巾浴巾等）是否有損壞、缺少、污染。',
  '938990-198': '（3）在洗浴時請注意防滑，建議提前鋪好防滑墊，防止滑倒受傷。',
  '938990-199':
    '（4）睡前及離開酒店房間時請確保關閉房間門窗。 保管好房卡或鑰匙，切勿將房間號告訴陌生人，或邀請陌生人到房間內交談，以免發生人身和財物安全問題。',
  '938990-200': '飲食和藥品安全',
  '938990-201':
    '（1）旅遊期間少吃生食、生海鮮等，不可光顧路邊無牌照小店，忌暴飲暴食，應多喝飲用水，多吃蔬菜水果，少抽烟，少喝酒。 因私自食用不潔食品和海鮮引起的腸胃疾病，旅行社不承擔責任。',
  '938990-202':
    '（2）在旅遊目的地購買食物需注意商品品質，不要購買“三無”（無生產廠家、生產日期、廠家地址）商品，發現食物不衛生或者存在异味變質的情况，切勿食用。',
  '938990-203': '（3）不要隨意接受和食用陌生人贈送的香烟、食物和飲品，以免造成不必要的危險。',
  '938990-204':
    '（4）為防止旅途中水土不服，旅遊者應自備一些常用藥品以備不時之需，切勿隨意服用他人提供的藥品。 個人常用藥品請隨身攜帶。',
  '938990-205': '自由活動期間安全',
  '938990-206':
    '（1）旅遊者在自由活動期間請結伴而行，避免單獨行動，要注意安全，不要到偏僻的地方，不要到不安全的場所，不要太晚回酒店。',
  '938990-207':
    '（2）在景區內自由遊覽，拍照，攝影時請注意景區的安全提示標誌和工作人員的提醒。 帶孩子的旅遊者，請看管好小孩，注意安全。',
  '938990-208':
    '（3）旅遊者若想自己參加目的地的自費專案，請確認機构是否具備合法經營資質，尤其涉及出海、浮潜、叢林探險等有風險的旅遊活動時，建議確認具有專業救助設備，並投保大額意外險，以免發生人身傷亡、財產損失等意外事件。',
  '938990-209': '突發事件',
  '938990-210':
    '（1）飛機、火車、班輪、城際客運班車等公共客運交通工具延誤或者取消：根據《旅遊法》第七十一條相應責任應由公共交通經營者承擔責任，旅行社對此不承擔責任，但將積極協助您向公共交通經營者索賠。 如遇此情况，請旅遊者在候機廳或候車室耐心等待，密切關注班機或火車的信息，聽從工作人員和旅行社人員的安排，不要到處亂跑，以免誤機或誤車。 請勿採取拒絕登機、車、船等不當行為，否則由此造成的損失及後果由旅遊者承擔。',
  '938990-211':
    '（2）交通事故：當發生車輛交通事故時，應聽從導遊及旅行社相關工作人員的安排和指揮，不要慌張； 發生人員傷害時，旅遊者應盡力施救或自救，同時注意保護現場，避免損失擴大。',
  '938990-212':
    '（3）突發意外傷害（如動物傷害）或急性病：旅遊行程中應提高警惕，遠離動物侵襲，避免造成意外傷害。 如不慎遭受意外傷害或因個人原因突發疾病時，請及時向導遊請求援助，到醫院就診，費用由本人支付。 如已購買意外傷害保險，請通知旅行社運作人員並保管好相關票據，以便向保險公司申報理賠。',
  '938990-213':
    '（4）失竊、被搶：當發生財物被盜或遭受搶劫時，請及時聯系導遊，請求旅行社協助，並向公安機關報案，講明事實經過，將失竊或被搶物品列出清單，保護好現場，保存證據資料，積極配合公安機關偵查破案。',
  '938990-214':
    '（5）不可抗力：行程中因自然災害、惡劣天氣條件、政府行為、社會異常事件（如罷工、政變、騷亂、遊行等）、流行性疾病暴發等不可抗力或意外事件，影響旅遊行程或服務標準的，旅行社不承擔賠償責任，但會以確保團隊安全、減少損失為原則，採取積極補救措施，請旅遊者給予配合和諒解， 冷靜處理並儘快撤離危險地區。 由此新增的費用由旅遊者承擔，節省的費用退還旅遊者。',
  '938990-215': '高危項目安全注意事項',
  '938990-216':
    '出於安全考慮，建議患有高（低）血壓、心臟病、糖尿病、癲癇病、腰椎病、過量飲酒的旅遊者、孕婦、兒童、老人以及其他不宜參加高危項目的旅遊者，不得參與高危活動。 以上疾病類型只是簡要示例，如旅遊者患有其他疾病可能不適合參加某些旅遊活動的，請主動向旅行社告知或諮詢。 特別提醒，參加高危項目的旅遊者，建議投保高風險意外險種，參加活動時仔細閱讀提示，在指定區域內開展活動。',
  '938990-217': '1.山地',
  '938990-218': '（1）要事先對所登之山進行必要的了解或尋找專業嚮導帶領進行登山，以避免迷失方向。',
  '938990-219': '（2）極端天氣情況下，避免進行山地旅遊。',
  '938990-220':
    '（3）山地旅遊時被石頭或樹枝撞擊、刮傷，若只是手腳輕微碰傷，可用水冷敷或進行止血包紮等緊急處置； 若患者出現骨折或頭部受重傷後發生嘔吐等現象，必須儘快送醫院救治。',
  '938990-221': '2.高原',
  '938990-222':
    '（1）海拔較高的高原地帶或山地，氣壓低，空氣含氧量少，易導致人體缺氧，引起高原反應，請旅遊者避免劇烈運動和情緒興奮。 患有貧血、糖尿病、慢性肺病、較嚴重心腦血管疾病、精神病及懷孕等旅遊者不宜參加高原及高山旅遊。',
  '938990-223':
    '（2）進入高原途中注意事項：應盡可能預備氧氣和防治急性高原病的藥物，也需備有防治感冒的藥物、抗生素和維他命類藥物等，以防萬一。 需要帶上足够的防寒衣物，準備好抗紫外線的防護用品。 初進高原不宜過快，採取循序漸進，逐步升高的辦法，如果乘飛機直接進入高原地區，前一個晚上要保證充足的睡眠，不吃油膩的食物，不喝酒，如果乘汽車或火車進入高原地區，最好每天上升高度控制在400-600米。 進入高原的途中，不要劇烈活動，避免情緒興奮。 若出現比較嚴重的高山反應症狀，應立即處理，嚴重時應吸氧。 若出現嚴重的胸悶、劇烈咳嗽、呼吸困難，或反應遲鈍、神志淡漠、甚至昏迷，除作上述處理外，應儘快到附近醫院進行搶救，或儘快轉往海拔較低的地區，以便治療恢復。',
  '938990-224':
    '（3）到達高原後注意的事項：剛到高原，每個人都會感到不同程度的氣短、胸悶、呼吸困難等缺氧症狀，需要正確地保護自己，2～4天后，上述症狀一般都會好轉或消失； 如症狀未有好轉，請及時在當地就醫或者選擇返回海拔較低的地區。 宜用清淡、富含維他命、易消化飲食，不宜過飽，忌飲酒。 注意避免過度疲勞，飲食起居有規律。 初到高原的前幾天，不要頻頻洗浴，以免受凉引起感冒。 在整個活動中，務必要聽從導遊或者工作人員的訓示，注意安全。',
  '938990-225':
    '（4）由於在高原地區旅遊有發生高原反應的風險，旅行社在此特別提醒，建議旅遊者投保高原保險險種。',
  '938990-226': '3.漂流',
  '938990-227':
    '（1）漂流的岸邊經常濕滑，建議穿上軟底鞋以防滑倒、擦傷腳部。 在整個活動中，務必要聽從導遊或者工作人員的訓示，注意安全。',
  '938990-228':
    '（2）漂流前請將貴重物品交寄存處保管，以免遺失或浸水，漂流時，請正確穿戴安全帽、救生衣，注意航道引導標誌。',
  '938990-229':
    '（3）漂流艇進入滑道和跳臺前，務必要正確放置劃槳，雙手緊抓安全繩，坐穩身體，嚴禁鬆手、站立。',
  '938990-230':
    '（4）漂流過程中，不要過度打水仗，嬉戲； 不要進入河道游泳，玩水，以免劃傷腳部； 不得脫下安全帽和救生衣； 不要隨意將橡皮艇、安全帽交與他人或隨意丟棄，全程禁止離艇玩耍或單獨到危險地帶，以免發生意外。',
  '938990-231': '4.浮潛',
  '938990-232': '（1）注意天氣狀況，陰天、雨天或風較大的天氣都不適合浮潛。',
  '938990-233':
    '（2）浮潛時需穿戴面鏡、呼吸管、蛙鞋及救生衣，要在指定區域浮潛，注意安全，並且有教練員或者工作人員的陪同。在整個活動中，務必要聽從導遊或工作人員的指示。',
  '938990-234': '（3）浮潛時間建議以一小時為限，以免體力透支。',
  '938990-235': '5.潛水',
  '938990-236': '（1）感到身體不適，不要潛水，潛水前不要吸煙，不可飲用含酒精飲品或服用不適當藥物。',
  '938990-237':
    '（2）潛水時，請調整潛水衣的浮力，一定要穿配救生衣，務必要聽從工作人員的指示，注意安全。遵守二人同行的原則，避免單獨潛水，要緊跟教練，在指定區域潛水。務必要把面罩內的水清除乾淨，以免發生窒息。不要做超呼吸的動作，以免體力不支。',
  '938990-238':
    '（3）在參加培訓時，必須認真聽取教練的理論講解並認真學習掌握所要求的基本技術，了解潛水的操作程式，聽從教練的安排和指揮，不可嘗試超越個人身體所能承受的深度限制。',
  '938990-239': '6.快艇',
  '938990-240': '（1）乘坐快艇時必須穿救生衣，找到安全繩。 上艇時不要站在纜繩附近，避免絆倒受傷。',
  '938990-241':
    '（2）船頭顛簸劇烈，老人、兒童不能坐船頭，以免發生意外身體傷害。 帶小孩的旅遊者，看管好自己的孩子。',
  '938990-242':
    '（3）在整個活動中，務必要聽從導遊或者工作人員的訓示，注意安全，參與快艇活動者在途中未經許可不得離艇下水。',
  '938990-243':
    '（4）乘搭快艇時，不要集中在快艇的一側，以免快艇失去平衡。 嚴禁在快艇內走動，頭、手不要放在快艇的邊緣外，以免被碰撞及發生其他意外。',
  '938990-244': '7.溫泉',
  '938990-245': '（1）溫泉區域請小心地滑，謹防著凉，小心臺階，注意安全。',
  '938990-246':
    '（2）溫泉一般不宜長時間浸泡，在溫泉過程中出現頭暈、胸悶、心跳加速或身體不適者，請起來休息，喝杯溫水補充水分，並與工作人員聯系。 在浸泡溫泉時請照顧好老人和小孩，嚴禁跳水或打鬧。',
  '938990-247': '（3）高血壓、心臟病、傳染病以及酗酒者等切勿下池浸泡。',
  '938990-248': '8.滑雪',
  '938990-249':
    '（1）注意天氣預報，避開大風天、暴雪天等惡劣天氣，滑雪前要做一些簡單的準備活動及熱身運動，避免受傷。',
  '938990-250':
    '（2）初到雪場時應先了解滑雪場的大概情况，不可擅自越過雪場界線，了解雪道的高度、寬度、長度、坡度以及走向，了解滑雪的有關規定，嚴格遵守滑雪場有關安全管理的規定。 注意索道開放時間，在無人看守時切勿乘坐。',
  '938990-251':
    '（3）初學者應注意循序漸進，量力而行。 在訓練期間應聽從教練和雪場工作人員的安排和指揮，在未達一定水準時不可擅自到對科技要求較高的雪區滑雪，以免發生意外。',
  '938990-252':
    '（4）滑雪時注意保暖，在手腳、耳朵等易凍傷的部位做好保溫措施，滑雪時注意與他人保持一定距離。',
  '938990-253': '9.自行車',
  '938990-254': '（1）自行車騎行過程中嚴格遵守交通法規，縱隊騎行、不搶道、不飆車。',
  '938990-255': '（2）必須佩戴頭盔，建議佩戴眼鏡、手套等防護裝備。',
  '938990-256':
    '（3）根據天氣做好防風、雨、雪準備，盡可能穿著騎行服裝或運動服裝，散腿褲腳應用綁帶固定好。',
  '938990-257':
    '（4）轉彎和下坡時，要控制車速，尤其是多車道轉盤要仔細觀察來往車輛，打明手勢，示意清楚你的走向。 遇路口要注意减速，觀察是否有道口和逆行者。',
  '938990-258':
    '（5）騎行中嚴禁接聽電話、用耳機聽音樂等，防止分神，如遇特殊情况需接聽電話，不要突然刹車，要先給後面的車友手勢及語言提示，安全地從車隊兩側退出、靠邊停車接聽電話。',
  '938990-259': '10.高空',
  '938990-260':
    '（1）熱氣球飛行最好的時間是在日出後兩小時內或日落前兩小時，因為該時段氣流最為穩定。 風大的季節不適宜飛熱氣球。 為安全考慮請穿著棉質面料的服裝和運動鞋，不宜穿裙裝、高跟鞋、凉鞋等。 熱氣球的結構很特殊，即使出現突然熄火的現象，熱氣球也不會急速下降，而是非常緩慢地降落到地面，乘坐者不慌張，聽從飛行員的指揮，安心等待熱氣球自然降落。',
  '938990-261':
    '（2）兒童以及行動不便者請勿單獨乘坐直升機。 嚴禁要求隨意更改飛行航線，若因天氣變化、政府行為及航管等不可抗力因素而導致原定的飛行任務無法執行，旅遊者應給予理解和配合。 登機後請系好安全帶，直到落地後方可解開乘坐直升機全程應聽從工作人員引導與訓示飛行過程中請勿擅動任何按鈕和設施，空中請勿拍照。',
  '938990-262': '11.騎馬',
  '938990-263':
    '（1）騎馬前要仔細聽從馬倌的講解及安排。 接近馬時要從馬的左前方慢慢自然靠近馬匹，不要做劇烈或誇張的動作，否則容易使馬受驚，不要從馬的右側或後方接近馬，也不要站在馬屁股的後面。',
  '938990-264':
    '（2）騎馬必須佩戴頭盔，為安全考慮最好穿緊湊的衣服和鞋子，鞋子最好是長筒鞋或運動鞋，防止腳踝被磨破。 衣服、帽子和圍巾要穿緊紮好，避免被風吹起嚇驚馬匹。',
  '938990-265':
    '（3）騎馬時不要在馬背上作劇烈的動作或互遞東西，最好不要中途下馬。 只要您在馬上，無論何種情況，都要抓緊馬韁繩，因為馬隨時會因各種原因突然驚跑。',
  '938990-266':
    '（4）下馬時應該先將左腳從馬鐙中向外踩到腳的前部，然後左手緊握馬韁，抬右腿翻身下馬，下馬後的姿勢應該和上馬前的姿勢相同。 下馬時一定要先確認左腳可以脫開蹬時再下馬。',
  '938990-267': '12.過山車',
  '938990-268': '（1）認真查看設備安全資質，另外，要觀察過山車是否有嚴重銹蝕現象。',
  '938990-269': '（2）仔細閱讀注意事項，並按照要求乘坐過山車。',
  '938990-270': '自駕車安全注意事項',
  '938990-271': '1.出行前準備',
  '938990-272':
    '（1）車輛檢查。 出發前請您務必檢查好車輛的科技狀況和設備狀況是否一切正常，各種隨車證件是否有效，並掌握車輛的各種效能和操作方法，點清車輛的各種證件和必備工具，驗收確認無誤後，方可將車輛駛離。',
  '938990-273':
    '（2）備好隨行物品。 準備自救工具：千斤頂、換胎扳手、警示牌、備用車胎； 應急工具：應急燈、指南針、機油、玻璃水、冷卻液、燃油添加劑； 醫用藥品：感冒藥、消炎藥、止血繃帶、OK繃、維他命藥片、眼藥水； 生活用品：不同時令的衣服、食品、水； 野營用品：防潮墊、折疊桌椅、睡袋、遮陽傘等。',
  '938990-274':
    '（3）注意了解出行信息。 出遊前，應提前了解前往目的地途中的天氣，尤其是否有豪雨、暴雪、颱風等情况； 要了解沿途經過的路况、道路代號、里程、飲食、住宿、加油站等信息，以及一些具有特殊人文地理的地方，做好旅遊計劃； 及時收聽當地交通台的廣播，了解行車路况。',
  '938990-275': '2.旅行中注意事項',
  '938990-276':
    '（1）每日出車前，請您按車輛使用說明對車輛進行日常的檢查，特別對機油、刹車油、冷卻液、電解液、輪胎氣壓的檢查，對制動、轉向、燈光、刮水器、喇叭性能的監控。如發現車輛異常，請及時聯繫我們。',
  '938990-277': '（2）請注意及時加油。',
  '938990-278': '（3）嚴禁疲勞駕駛，連續駕駛不能超過4小時，儘量避免夜間行車。',
  '938990-279': '（4）路邊或夜間停車時，務必檢查門窗是否鎖好，不要隨便搭乘陌生人。',
  '938990-280':
    '（5）遵守交通規則，系好安全帶。 注意掌握行駛速度，與前車要保持一定距離，停車或緩行一定要靠右邊，路面聚積雨水時，不要加大油門沖水。',
  '938990-281':
    '（6）經過易發生滑坡地區時，應嚴格觀察，注意路上隨時可能出現的危險，如掉落的石頭等。',
  '938990-282': '（7）遇沙塵暴，由於能見度較差，應減速慢行，密切注意路況，謹慎駕駛。',
  '938990-283': '（8）遇大霧天氣，注意交通安全，要及時打開霧燈，特別注意慢行。',
  '938990-284': '（9）遇大雪天氣，可以適當給車輛輪胎放些氣，慢速行駛，以免打滑。',
  '938990-285': '其他',
  '938990-286':
    '報價是按照2成人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。 由於12歲以下兒童費用為不占床、不含早餐之報價，若兒童需占床含早，須按成人計算並補足單房差。',
  '938990-287':
    '報價是按照2成人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。 由於12歲以下兒童費用為不占床、不含早餐之報價，若兒童',
  '938990-288': '證件安全',
  '938990-289':
    '（1）護照、港澳通行證、臺灣通行證是旅遊者在境外唯一有效的身份證明，遺失證照將直接影響到您的旅行。 證照、簽證、身份證、機車船票及文件等身份證明和憑據，請隨身攜帶或把證照交由領隊、導遊妥善保管，以避免遺忘、遺失。',
  '938990-290':
    '（2）出境旅遊最好將上述證件複印，一份留在家中，一份連同幾張護照相片隨身攜帶，並記下所持護照號碼，以備急用。',
  '938990-291':
    '（3）在整個旅行中，請隨身攜帶我公司發給您的行程及緊急聯絡通訊錄（聯繫方式詳見出遊通知書），以防走失時聯系。',
  '938990-292':
    '（4）當地遇到有人檢查證件時，不要輕易應允，而應報告導遊或領隊處理。 如導遊或領隊不在現場，要有禮貌地請對方出示其身份或工作證件，否則拒絕接受檢查。',
  '938990-293':
    '（5）證件一旦遺失或被偷被搶，要立即報告領隊並向警方報案，同時請警方出具書面遺失證明。 出國或出境遊客要向目的地相關機構申請補發簽證，向我使（領）館等機構申請補辦旅行證件，並承擔因補辦證件產生的相關費用。',
  '938990-294': '人身安全',
  '938990-295':
    '（1）注意交通安全，過馬路請走班馬線，指示燈綠燈亮時才可通過。 注意當地駕駛習慣，如遇靠左行車的國家或地區請注意不要走入逆行道，在過馬路時要向右側觀察是否有來車並及時避讓； 有些城市設有自行車道，行人不可站立或行走在自行車道上。 以上情形下，一旦發生事故，責任須由行人承擔。',
  '938990-296':
    '（2）上下車船和飛機、出入地鐵等交通工具時，出入商場、酒店、餐廳、景區等場所時請注意在電梯、台階處站穩，以免扭傷和摔傷身體。 注意特定安全提示，以免受到傷害。',
  '938990-297':
    '（3）自由活動要結伴而行，尤其是夜間不要單獨出行，不要太晚回酒店； 外出時要告知領隊或導遊，不安全的場所不要去； 要帶好酒店卡片（上有酒店的名稱、地址、電話）和緊急聯繫通訊錄，防止意外情况的發生。',
  '938990-298':
    '（4）遇到地震、颱風、火災、洪水等自然災害或政治動亂、戰亂、突發恐怖事件或意外傷害時，要冷靜處理並盡快撤離危險地區。 在境外可向中國使（領）館尋求諮詢和協助。 人身安全受到威脅和傷害時，應立即向當地警方報案，要求警方提供保護，並取得警方的書面證明。',
  '938990-299':
    '（5）如旅遊者在當地選擇自駕遊或乘坐當地私營交通工具，建議客人審慎選擇真實、合法和安全的租車公司或交通工具，避免由於車輛原因發生交通事故：另外，由於各國基礎設施狀況和交通規則不同，建議遊客提前熟悉當地交通規則、做好路線和路况規劃； 對於一些基礎設施較為落後、自然災害較多的地區和國家，建議遊客提前關注道路和天氣情況，避免疲勞駕駛，繞開風險過高地區，注意人身和財產安全。',
  '938990-300':
    '（6）我公司提醒旅遊者：出境旅遊，特別是海外自助遊應增强風險防範意識，警惕各類風險，平安出遊，理性出遊。',
  '938990-301':
    '應做好行前準備，關注旅遊、外交等有關部門發佈的出行提示，了解目的地天氣、衛生、交通和社會治安情况，謹慎或暫勿前往恐怖事件頻發、政局動盪不穩或發生重大疫情的國家和地區。',
  '938990-302': '出行中旅遊者應關注當地天氣預報和風險提示，避免在不適宜的惡劣天氣開展旅遊活動。',
  '938990-303':
    '要提高安全意識，理性消費，看管好隨身物品，遵守當地交通法規，遇突發事件時保持冷靜，妥善應對，及時報警。',
  '938990-304':
    '合理規劃出遊線路，選擇有運營資質的交通工具，謹慎選擇高風險旅遊項目，不在旅遊探奇中盲目追求刺激。',
  '938990-305': '出行前應購買旅遊意外保險，保障出遊安全。',
  '938990-306':
    '報價是按照2成人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。 由於12歲以下兒童費用為不占床、不含早餐之報價，若兒童需占床含早，須按成人計算並補足單房差。 每個房間最多可入住2大1小，如2位成人攜帶2位或2位以上兒童出遊的情况需補足2間房的差價。',
  '938990-307':
    '本產品不接受拼房，不提供3人間或加床服務，3成人預訂須支付2間房費。 如需要3成人入住1間雙人標準間，請在預訂時備註清楚，以便客服操作第2間房的單房退款，第3人床位費當地現退，退款金額等同于單房差賣價，第3人不占床不含早，早餐請自理，謝謝配合！',
  '938990-308':
    '報價是按照2人入住1間房計算的價格，如您的訂單產生單房，中旅旅行將安排您與其他客人拼房入住。 在無法拼房的情况下，將會安排3成人入住一間房，即標準房加床（溫馨提示：當地酒店面積小，加床可能會引起您的不便，敬請諒解）。 如您要求享受單房，須補足單房差。',
  '938990-309': '證件安全',
}

export default function PosterServer(ajax, config) {
  return {
    // NBS-获取团期价格日历
    departurePrice: (opt) =>
      ajax({
        reLogin: false,
        url: '/poster/get-departure-price',
        method: 'POST',
        ...opt,
      }),
    // NBS-获取产品下所有海报图片
    getPicture: (opt) =>
      ajax({
        reLogin: false,
        url: '/poster/get-picture',
        method: 'GET',
        ...opt,
      }),
    // NBS-获取产品下海报文案
    getCopywriting: (opt) =>
      ajax({
        reLogin: false,
        url: '/poster/get-copywriting',
        method: 'GET',
        ...opt,
      }),
    // 保存更新海报文案(云店文案)
    saveCopywriting: (opt) =>
      ajax({ reLogin: false, url: '/poster/save-copywriting', method: 'post', ...opt }),
    // 用户上传本地海报
    localUploadPoster: (opt) =>
      ajax({
        reLogin: false,
        url: '/poster/upload-poster',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        ...opt,
      }),
  }
}

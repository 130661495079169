import SvgIcon from './src/index.vue'
// import svgList from './svgList'

SvgIcon.install = function (Vue) {
  // 按需导入svg图片
  /*svgList.forEach((name) => {
    import(`@/components/SvgIcon/svg/${name}.svg`)
  })*/

  Vue.component(SvgIcon.name, SvgIcon)
}

export default SvgIcon

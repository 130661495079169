import appointTool from './appoint-tool'
import batchOrder from './batch-order'
import contractTemplate from './contractTemplate'
import orderList from './orderList'
import orderBasicInfor from './orderBasicInfor'
import travelDemand from './travelDemand'
import exitTemple from './exitTempleDialog.json'
import sign from './sign'
import invoiceInfor from './invoiceInfor'
import words from './words'

export default {
  appointTool,
  batchOrder,
  contractTemplate,
  orderList,
  orderBasicInfor,
  travelDemand,
  exitTemple,
  sign,
  invoiceInfor,
  ...words,
}

// 树菜单格式化
import { mockStore } from './mock'
import noShowMenu from './noShowMenu'
import service from '@/services'
import { FlatToTree } from '@/views/sysConfig/SysMenuSet/tool'
import Vuex from '@/store'
import { setDocumentTitle } from '@/store/allMenu'
const config = require('/config/config')

// 无缓存的数据菜单更新
export async function noCacheMenus() {
  try {
    if (!Vuex.state.user.token) return null
    const menus = await service.AuthServer.menu.currentUserMenus()
    Vuex.commit('set_data', {
      userMenus: menus,
    })
  } catch (e) {
    console.log(e)
  }
}

// 处理后台返回菜单改为树数据
const handlerMenus = (menus) => {
  let menuList = JSON.parse(JSON.stringify(menus))
  menuList = menuList.map((li) => {
    const { icon, id, parentId, url, name } = li
    return {
      ...li,
      id,
      parentId,
      path: url,
      meta: { title: name, icon },
    }
  })
  return FlatToTree(menuList)
}
// 获取服务器菜单地址
export async function getServerMenus() {
  try {
    if (!Vuex.state.user.token) return Promise.reject('未登录')

    let menus = null
    if (Vuex.state.userMenus && Vuex.state.userMenus.length > 0) menus = Vuex.state.userMenus
    else menus = await service.AuthServer.menu.currentUserMenus()

    if (!menus || menus.length < 1) return Promise.reject('菜单数据获取失败')

    Vuex.commit('set_data', {
      userMenus: menus,
    })
    const gshMenus = handlerMenus(menus)
    mockStore.routerList = gshMenus
    return gshMenus
  } catch (e) {
    return Promise.reject(e)
  }
}

// 根据缓存情况判断获取的路由数据
export async function isMockRouter() {
  try {
    await getServerMenus()
    // 注意这里要保证数据是新的内存地址
    const treeRouter = config.mockRouter ? mockStore.allRouter : mockStore.routerList
    return JSON.parse(JSON.stringify(treeRouter))
  } catch (e) {
    console.log(e)
  }
}

// 树数据补全,把不会在左侧列表展示的数据补全到树中
export function treeCompletion(routerTree) {
  if (!routerTree || routerTree.length < 1) return null
  const recursion = (li) => {
    const { path, children } = li

    if (children && children.length > 0) {
      children.forEach((li) => {
        recursion(li)
      })
    }

    if (!children) li.children = []
    const matchingPath = noShowMenu[path]
    matchingPath && li.children.push(...matchingPath)
  }

  routerTree.forEach((li) => {
    recursion(li)
  })

  return routerTree
}

// 对树数据标记位置，并且找到当前页面对应的路径
export function getTreeIndex(routerTree, route) {
  return new Promise((resolve, reject) => {
    if (!routerTree || routerTree.length < 1) {
      reject('')
      return null
    }

    const recursion = (cur, i) => {
      const { children, path } = cur
      cur.links = i.toString()
      if (path === route.path) {
        resolve(cur.links)
        return null
      }
      if (children && children.length > 0) {
        children.forEach((li, index) => {
          recursion(li, i + '-' + index)
        })
      }
    }
    routerTree.forEach((li, index) => {
      recursion(li, index)
    })
  })
}

// 提供外部面包屑上面菜单修改
export function UpdateCrumbsName(path, name, wait = 300) {
  if (!path) return null
  setTimeout(() => {
    mockStore.levelList.forEach((li) => {
      if (li.path === path) {
        li.meta.title = name
        setDocumentTitle(name)
      }
    })
  }, wait)
}

// 获取返回页面的数组第一项
export function getOneRouter() {
  const treeMens = handlerMenus(Vuex.state.userMenus)

  let oneRouter = ''
  const recursion = (son) => {
    for (let j = 0; j < son.length; j++) {
      const { children, path } = son[j]
      if (path && !oneRouter) {
        oneRouter = path
      } else if (children && children.length > 0) {
        recursion(children)
      }
    }
  }
  for (let i = 0; i < treeMens.length; i++) {
    const { children, path } = treeMens[i] ?? {}
    if (path) {
      oneRouter = path
    } else if (children && children.length > 0) {
      recursion(children)
    }
  }
  return oneRouter ? oneRouter : '/404' // 所有权限都没有则进入404页面
}

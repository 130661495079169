import i18n from '@/i18n'
export default [
  {
    /*  */
    path: '/credit/credit-manage',
    name: '/credit/credit-manage',
    component: () => import('@/views/credit/creditManage'),
    meta: { title: '实体客户授信申请' },
  },
  {
    /*  */
    path: '/credit/credit-manage/detail',
    name: '/credit/credit-manage/detail',
    component: () => import('@/views/credit/creditManage/detail'),
    meta: { title: '详情' },
  },
  {
    /*  */
    path: '/credit/credit-change',
    name: '/credit/credit-change',
    component: () => import('@/views/credit/creditChange'),
    meta: { title: '实体客户授信变动查看' },
  },
  {
    /*  */
    path: '/credit/credit-audit',
    name: '/credit/credit-audit',
    component: () => import('@/views/credit/creditAudit'),
    meta: { title: '实体客户授信审核' },
  },
  {
    /*  */
    path: '/credit/credit-audit/detail',
    name: '/credit/credit-audit/detail',
    component: () => import('@/views/credit/creditAudit/detail'),
    meta: { title: '审核详情' },
  },
]

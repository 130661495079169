import Vue from 'vue'
import VueI18n from 'vue-i18n'

// elementUI语言包
import elUiEn from 'element-ui/lib/locale/lang/en' // 英文
import elUiZh from 'element-ui/lib/locale/lang/zh-CN' // 简体中文
import elUiZhTw from 'element-ui/lib/locale/lang/zh-TW' // 繁体中文

// 本地语言包
import baseZhHK from './zh-hk'

import { Lang } from './config'

Vue.use(VueI18n)

// 准备翻译的语言环境信息
// 英文:en,简体中文:zh-CN,繁体中文:zh-TW
// 默认加载简体中文
const messages = {
  // 简体中文
  [Lang.zhHk]: {
    ...baseZhHK,
    ...elUiZhTw, // 该文件为elementUI翻译，一般不建议使用
  },
}

// 通过选项创建 VueI18n 实例

export const i18n = new VueI18n({
  locale: Lang.zhHk, // 设置语言环境
  fallbackLocale: Lang.zhHk,
  messages, // 设置语言环境信息
  silentTranslationWarn: true, // 移除多语言警告-临时
})

const loadedLanguages = [Lang.zhHk, Lang.zhCn] // 预加载简体中文

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  if (!lang) lang = Lang.zhCn

  lang = lang?.toLocaleLowerCase()

  // 如果语言相同
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // 如果语言已经加载
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // 如果尚未加载语言
  return import(`@/i18n/${lang}/index.js`).then((messages) => {
    let elementUILang = {}
    if (lang === 'en') elementUILang = elUiEn
    if (lang === Lang.zhCn) elementUILang = elUiZh
    if ([Lang.zhTw, Lang.zhHk].includes(lang)) elementUILang = elUiZhTw

    i18n.setLocaleMessage(lang, {
      ...messages.default,
      ...elementUILang,
    })
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default i18n

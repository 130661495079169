<template>
  <div ref="selfDom" class="popover-form-item">
    <span v-show="visible" ref="tooltip" class="popover-form-item-tooltip">
      {{ tips }}
    </span>
    <div
      class="item-con"
      ref="popcorn"
      :class="{
        'item-error': visible,
        'el-form-item': visible,
        'is-error': visible,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
export default {
  name: 'PopoverFormItem',
  inject: ['RowPopoverFormItemCheck', 'RowPopoverFormItemClear'],
  props: {
    placement: {
      type: String,
      default: 'top-start',
    },
    // 函数规则校验，返回一个promise函数
    rules: {
      require: true,
      type: Function,
      default: () => {
        return new Promise((resolve, reject) => {
          resolve()
        })
      },
    },
    prop: String,
    index: {
      require: true,
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      tips: '',
    }
  },
  computed: {
    showMessage() {
      this.popperInstance && this.popperInstance.update()
      return this.visible && this.tips
    },
  },
  created() {},
  mounted() {
    this.RowPopoverFormItemCheck &&
      this.RowPopoverFormItemCheck(this.checkItem, this.index, this.prop, this)

    this.popperInstance = null
    const popcorn = this.$refs.popcorn
    const tooltip = this.$refs.tooltip

    this.popperInstance = createPopper(popcorn, tooltip, {
      strategy: 'fixed',
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    })
  },
  beforeDestroy() {
    this.RowPopoverFormItemClear && this.RowPopoverFormItemClear(this.index, this.prop)

    // 卸载
    this.popperInstance && this.popperInstance.destroy()
    this.popperInstance = null
  },
  methods: {
    // 校验当前是否符合规则
    async checkItem() {
      try {
        this.visible = false

        await this.rules()
        this.visible = false
        return Promise.resolve()
      } catch (e) {
        this.tips = e
        this.visible = true

        return Promise.reject(e)
      }
    },
  },
}
</script>

<style lang="scss">
// 黑色边框问题
span:focus {
  border: none;
  outline: none;
}
div:focus {
  border: none;
  outline: none;
}
</style>
<style scoped lang="scss">
.popover-form-item {
  .popover-form-item-tooltip {
    background: #fff;
    padding: 0 4px;
    min-width: 30px;
    font-size: var(--h3);
    color: var(--error);
    height: 25px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      bottom: -4px;
      display: block;
      width: 0;
      height: 0;
      border-radius: 2px;
      border: 6px solid transparent;
      border-right-color: #fff;
      border-bottom-color: #fff;
      transform: scaleX(0.8) rotate(45deg);
    }
  }
}
.item-con {
  display: inline-block;
  width: 100%;
  height: inherit;
  border: none;
  &:focus,
  &:active {
    border: none;
  }
  .item-error {
    border: var(--error) 1px solid !important;
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
</style>

export default function JourneyServer(ajax, config) {
  return {
    enum: {
      // 获取所有枚举类型
      names: (opt) => ajax({ url: `/enum/names`, method: 'GET', ...opt }),
      // 查询某个类型下所有枚举值
      values: (opt) => ajax({ url: `/enum/values`, method: 'GET', ...opt }),
    },
    // 编号查询行程详情
    info: (opt) =>
      ajax({
        url: `/journey/details/${opt.cId}`,
        method: 'GET',
        ...opt,
      }),
    allHotel: (opt) => ajax({ url: `/journey-days/get-hotel/${opt.cId}`, method: 'GET', ...opt }),
    journeyDays: {
      info: (opt) => ajax({ url: `/journey-days/${opt.dayId}`, method: 'GET', ...opt }),
      list: (opt) => ajax({ url: `/journey-days/days/${opt.cId}`, method: 'get', ...opt }),
      add: (opt) => ajax({ url: `/journey-days/add`, method: 'post', ...opt }),
      del: (opt) => ajax({ url: `/journey-days/${opt.dayId}`, method: 'DELETE', ...opt }),
      sort: (opt) => ajax({ url: `/journey-days/${opt.cId}`, method: 'PUT', ...opt }),
      activitySort: (opt) => ajax({ url: `/journey-days/activity/sort`, method: 'post', ...opt }),
    },
    journey: {
      // 删除活动  1住宿 2活动时间段 3自费项目 4自由活动 5交通
      delActivity: (opt) =>
        ajax({ url: `/journey/activity/${opt.ActivityId}`, method: 'DELETE', ...opt }),
      delPoiList: (opt) =>
        ajax({ url: `/journey/${opt.activityId}/journey-poi`, method: 'DELETE', ...opt }),
      // 更新行程基本信息
      baseUpdate: (opt) => ajax({ url: `/journey/${opt.cId}`, method: 'PUT', ...opt }),
      poiAdd: (opt) => ajax({ url: `/journey/journey-poi`, method: 'POST', ...opt }),
      poiUpdate: (opt) =>
        ajax({ url: `/journey/${opt.activityTimeId}/journey-poi`, method: 'put', ...opt }),
      // 自费项目新增
      ownpayAdd: (opt) => ajax({ url: `/journey/ownpay-activity`, method: 'POST', ...opt }),
      ownpayUpdate: (opt) =>
        ajax({ url: `/journey/${opt.ownPayActivityId}/ownpay-activity`, method: 'put', ...opt }),
      trafficAdd: (opt) => ajax({ url: `/journey/traffic`, method: 'POST', ...opt }),
      trafficUpdate: (opt) =>
        ajax({ url: `/journey/${opt.trafficId}/traffic`, method: 'put', ...opt }),
      freeActivityAdd: (opt) => ajax({ url: `/journey/free-activity`, method: 'POST', ...opt }),
      freeActivityUpdate: (opt) =>
        ajax({ url: `/journey/${opt.freeActivityId}/free-activity`, method: 'put', ...opt }),
      hotelAdd: (opt) => ajax({ url: `/journey/hotel`, method: 'POST', ...opt }),
      hotelDel: (opt) => ajax({ url: `/journey/${opt.hotelId}/hotel`, method: 'DELETE', ...opt }),
    },
  }
}

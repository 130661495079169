<template>
  <el-select v-bind="$attrs" v-on="$listeners" ref="select" @visible-change="onVisibleChange">
    <slot></slot>
  </el-select>
</template>

<script>
export default {
  name: 'ZlSelect',
  props: {},
  methods: {
    // 产品需求当打开弹窗的时候，不要清空输入框内容
    onVisibleChange(val) {
      const { currentPlaceholder, placeholder } = this.$refs.select
      if (val && placeholder !== currentPlaceholder) {
        this.$refs.select.selectedLabel = currentPlaceholder
      }
      this.$emit('visible-change', val)
    },
  },
}
</script>

<style scoped lang="scss"></style>

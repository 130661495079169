export default function BaseChannelServer(ajax, config) {
  return {
    subChannelTree: (opt) =>
      ajax({ url: '/cooperation-channel/subChannelTree', method: 'get', ...opt }),
    // 根据B2B账号ID/NBS账号ID/云店店主ID查询推广码信息
    queryPromotionCodeInfo: (opt) =>
      ajax({
        url: '/promotion-code-info',
        method: 'get',
        ...opt,
      }),

    //终端枚举列表-订单部分用
    queryTerminalEnum: (opt) =>
      ajax({
        url: `/enum/values?name=${opt}`,
        method: 'get',
      }),

    //查询合作渠道
    queryDirectSubChannels: (opt) =>
      ajax({
        url: `/cooperation-channel/directSubChannels`,
        method: 'get',
        ...opt,
      }),
  }
}

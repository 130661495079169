import vuex from '@/store'
import router from '@/router'
import services from '@/services'

export async function loginOut() {
  const ua = navigator.userAgent.toLowerCase()
  const isWeixin = ua.indexOf('micromessenger') != -1
  const outPage = () => {
    const params = {
      path: isWeixin ? '/weixin-login' : '/login',
    }
    window.location.href = router.resolve(params.path).href
    // router.replace(params)
  }
  const clearData = () => {
    vuex.commit('user/CLEAR')
    vuex.commit('clear_data')
  }
  try {
    await services.Nbs.logout()
    outPage()
    clearData()
    return Promise.resolve(true)
  } catch (e) {
    outPage()
    clearData()
    return Promise.resolve(e)
  }
}

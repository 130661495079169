const basePath = '/confirm/'
export default [
  {
    path: basePath + 'occupy',
    name: basePath + 'occupy',
    component: () => import('@/views/confirm/occupy/index'),
    meta: { title: '占位单查询' },
  },
  {
    path: basePath + 'occupy/handle', // 处理占位单的路由
    name: basePath + 'occupy/handle',
    component: () => import('@/views/confirm/occupy/handle'),
    meta: { title: '占位单处理' },
  },
  {
    path: basePath + 'occupy/handle-cancel', // 处理取消占位单的路由
    name: basePath + 'occupy/handle-cancel',
    component: () => import('@/views/confirm/occupy/handle-cancel'),
    meta: { title: '取消占位单处理' },
  },
  {
    path: basePath + 'occupy/show', // 处理取消占位单的路由
    name: basePath + 'occupy/show',
    component: () => import('@/views/confirm/occupy/show'),
    meta: { title: '占位单查看' },
  },
  {
    path: basePath + 'occupy/show-cancel', // 处理取消占位单的路由
    component: () => import('@/views/confirm/occupy/show-cancel'),
    meta: { title: '取消占位单查看' },
  },
  {
    path: basePath + 'confirm',
    name: basePath + 'confirm',
    component: () => import('@/views/confirm/confirm/index'),
    meta: { title: '确认单查询' },
  },
  {
    path: basePath + 'confirm/handle', // 处理确认单的路由
    name: basePath + 'confirm/handle',
    component: () => import('@/views/confirm/confirm/handle'),
    meta: { title: '确认单处理' },
  },
  {
    path: basePath + 'confirm/batchConfirm', // 团队确认单处理
    name: basePath + 'confirm/batchConfirm',
    component: () => import('@/views/confirm/confirm/batchConfirm'),
    meta: { title: '批量确认' },
  },
  {
    path: basePath + 'confirm/teamConfirm', // 团队确认单处理
    name: basePath + 'confirm/teamConfirm',
    component: () => import('@/views/confirm/confirm/teamConfirm'),
    meta: { title: '确认单处理' },
  },
  {
    path: basePath + 'confirm/teamConfirm/view', // 团队确认单查看
    name: basePath + 'confirm/teamConfirm/view',
    component: () => import('@/views/confirm/confirm/teamConfirm'),
    meta: { title: '确认单查看' },
  },
  {
    path: basePath + 'confirm/teamConfirm/add', // 团队确认单查看
    name: basePath + 'confirm/teamConfirm/add',
    component: () => import('@/views/confirm/confirm/teamConfirm'),
    meta: { title: '确认单查看' },
  },
  {
    path: basePath + 'confirm/handle-cancel', // 取消确认单的路由
    name: basePath + 'confirm/handle-cancel',
    component: () => import('@/views/confirm/confirm/handle-cancel'),
    meta: { title: '确认单处理' },
  },
  {
    path: basePath + 'confirm/show', // 处理取消占位单的路由
    name: basePath + 'confirm/show',
    component: () => import('@/views/confirm/confirm/show'),
    meta: { title: '确认单查看' },
  },
  {
    path: basePath + 'confirm/show-cancel', // 处理取消占位单的路由
    name: basePath + 'confirm/show-cancel',
    component: () => import('@/views/confirm/confirm/show-cancel'),
    meta: { title: '取消确认单查看' },
  },
  {
    path: basePath + 'assloss',
    name: basePath + 'assloss',
    component: () => import('@/views/confirm/assloss/index'),
    meta: { title: '核损单查询' },
  },
  {
    path: basePath + 'assloss/handle', // 处理确认单的路由
    name: basePath + 'assloss/handle',
    component: () => import('@/views/confirm/assloss/handle'),
    meta: { title: '核损单处理' },
  },
  {
    path: basePath + 'assloss/show', // 取消确认单的路由
    name: basePath + 'assloss/show',
    component: () => import('@/views/confirm/assloss/show'),
    meta: { title: '核损单处理' },
  },
  {
    path: basePath + 'travel', // 出游通知查询的路由
    name: basePath + 'travel',
    component: () => import('@/views/confirm/travel/index'),
    meta: { title: '出游通知查询' },
  },
  {
    path: basePath + 'voucher', // 凭证回传查询的路由
    name: basePath + 'voucher',
    component: () => import('@/views/confirm/voucher/index'),
    meta: { title: '凭证回传查询' },
  },
  {
    path: basePath + 'voucher/handle', // 凭证回传处理的路由
    name: basePath + 'voucher/handle',
    component: () => import('@/views/confirm/voucher/handle'),
    meta: { title: '凭证回传处理' },
  },
  {
    path: basePath + 'voucher/show', // 凭证回传查看的路由
    name: basePath + 'voucher/show',
    component: () => import('@/views/confirm/voucher/show'),
    meta: { title: '凭证回传查看' },
  },
]

export default function InventoryServer(ajax, config) {
  return {
    // 查询指定日期区间内的团期是否能够开团
    canOpenDate: (opt) => ajax({ url: '/stock/can-open-departure-date', method: 'get', ...opt }),
    // 查询指定日期区间内的团期是否能够打开方案
    canOpenScheme: (opt) => ajax({ url: '/stock/can-open-scheme', method: 'get', ...opt }),
    // 批量打开方案
    batchOpenScheme: (opt) => ajax({ url: '/stock/open-schemes', method: 'post', ...opt }),
    // 获取日历数据
    dataList: (opt) => {
      return ajax({
        url: '/stock/departure-date-manage',
        method: 'post',
        ...opt,
      })
    },
    // 打开团期
    openList: (opt) => {
      return ajax({
        url: '/stock-in/open',
        method: 'POST',
        ...opt,
      })
    },
    // 获得已开团期数据
    openedList: (opt) => {
      return ajax({
        url: '/stock/departure-date',
        method: 'get',
        ...opt,
      })
    },
    // 获取采购规则数据
    purchaseList: (opt) => {
      return ajax({
        url: '/stock/purchase-rule-list',
        method: 'GET',
        ...opt,
      })
    },
    loadPurchaseList: (opt) => {
      return ajax({
        url: '/stock/load-purchase-rule-list',
        method: 'GET',
        ...opt,
      })
    },
    // 入库请求的接口
    stockIn: (opt) => {
      return ajax({
        url: '/stock-in',
        method: 'POST',
        ...opt,
      })
    },
    // 关团展示关联订单
    closeDeparture: (opt) => {
      return ajax({
        url: '/stock/close-departure',
        method: 'GET',
        ...opt,
      })
    },
    BatchCloseDeparture: (opt) => {
      return ajax({
        url: '/stock/close-departure',
        method: 'PATCH',
        ...opt,
      })
    },
    // 获取修改采购规则列表弹层数据
    purchaseRule: (opt) => {
      return ajax({
        url: `/stock/purchase-rule/${opt.cId}`,
        method: 'get',
        ...opt,
      })
    },
    // 批量修改采购规则
    EditPurchaseRule: (opt) => {
      return ajax({
        url: '/stock/purchase-rule',
        method: 'PATCH',
        ...opt,
      })
    },
    // 占位单列表数据
    reserveOrderList: (opt) => {
      return ajax({
        url: `/stock/reserve-order-list`,
        method: 'get',
        ...opt,
      })
    },
    // 取消占位
    occupyCancel: (opt) => ajax({ url: `/occupy/cancel`, method: 'POST', ...opt }),
    // 预收占位
    prepaidPlaceholder: (opt) => ajax({ url: `/stock/occupy-list`, method: 'get', ...opt }),
    // 获取库存批次列表数据
    roundList: (opt) => {
      return ajax({
        url: '/stock/round-list',
        method: 'GET',
        ...opt,
      })
    },
    // 获取库存批次人工出库单列表弹层数据
    stockOut: (opt) => {
      return ajax({
        url: `/stock/stock-out/${opt.id}`,
        method: 'get',
        ...opt,
      })
    },
    // 人工出库
    stockOutList: (opt) => {
      return ajax({
        url: '/stock/stock-out',
        method: 'POST',
        ...opt,
      })
    },
    // 调整成本价
    costPrice: (opt) => {
      return ajax({
        url: `/stock/cost-price`,
        method: 'PATCH',
        ...opt,
      })
    },
    // 获取操作记录数据列表
    stockLog: (opt) => {
      return ajax({
        url: `/operation-log/list`,
        method: 'GET',
        ...opt,
      })
    },
    // 获取入库单列表数据
    inList: (opt) => {
      return ajax({
        url: '/stock/stock-in-list',
        method: 'GET',
        ...opt,
      })
    },
    // 入库数据
    inListSum: (opt) => {
      return ajax({
        url: '/stock/stock-in-list-incomplete',
        method: 'GET',
        ...opt,
      })
    },
    // 获取入库单详情列列表数据
    inDetails: (opt) => {
      return ajax({
        url: `/stock/stock-in-details/${opt.c_id}`,
        method: 'get',
        ...opt,
      })
    },
    // 获取出库单列表数据
    outList: (opt) => {
      return ajax({
        url: `/stock/stock-out-list`,
        method: 'GET',
        ...opt,
      })
    },
    // 获取出库单详情列列表数据,
    outDetails: (opt) => {
      return ajax({
        url: `/stock/stock-out-details/${opt.c_id}`,
        method: 'get',
        ...opt,
      })
    },
    // 库存-采购规则+库存批次列表专用---根据资源id+团期范围，查询已有供应商
    inventoryVendors: (opt) => {
      return ajax({
        url: `/stock/vendors`,
        method: 'get',
        ...opt,
      })
    },
    // 库存开团采购公司
    OpenPurchaseCompyList: (opt) => ajax({ url: `/corp-info/getList`, method: 'post', ...opt }),
    // 库存已存在的采购公司列表
    purchaseCompyList: (opt) => ajax({ url: `/stock/purchase-companies`, method: 'get', ...opt }),
    // 打开团期回溯历史采购规则-60天
    usedPurchase: (opt) => ajax({ url: `/stock/used-purchase`, method: 'get', ...opt }),
    // 关闭方案
    closeScheme: (opt) => ajax({ url: `/stock/close-schemes`, method: 'post', ...opt }),
    // 预收占位
    reservedOccupy: (opt) => ajax({ url: `/reserved-occupy`, method: 'post', ...opt }),
    /*----------机票库存-----------*/
    airplane: {
      resourceStock: (opt) => ajax({ url: `/stock/resource-stock`, method: 'get', ...opt }),
      resourceStockSub: (opt) => ajax({ url: `/stock/resource-stock-sub`, method: 'get', ...opt }),
    },
  }
}

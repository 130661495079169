export default {
  '380384-0': '產品碼',
  '380384-2': '產品名稱',
  '380384-3': '產品品類',
  '380384-4': '產品公司',
  '380384-5': '請輸入產品上架公司',
  '380384-6': '行程天晚',
  '380384-7': '天',
  '380384-8': '晚',
  '380384-9': '產品類目',
  '380384-10': '產品負責人',
  '380384-14': '團期價格',
  '380384-15': '本清單顯示產品：其他企業上架，並且共享到我司進行分銷的產品清單。',
}

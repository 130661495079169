export default {
  total: '總計',
  inventoryCost: '庫存成本',
  replenishment: '補庫',
  autbound: '出庫',
  guantuan: '關團',
  regimentalPeriod: '團期',
  missionManagement: '團期管理',
  inventoryBatch: '庫存批次',
  batchOpening: '批量開團',
  batchClosing: '批量關團',
  closedMission: '關閉團期',
  reasonForClosing: '關閉原因',
  reason: '原因描述',
  batchOnOffScheme: '批量開/關方案',
  batchEditing: '批量編輯',
  supplierFailure: '資源供應商已失效，無法打開新的團期',
  noSelect: '未選擇',
  replaceResources: '更換資源',
  managementPlan: '管理升級方案',
  chargeUnitTips: `組織將對客呈現，目前有：<br />
1、位：按人管理成本價和庫存，對客呈現元/位，可按成人、兒童預訂。<br />
2、套：按套管理成本價和庫存，對客呈現元，預訂時不區分成人、兒童，購買套數。<br />
3、張：按人群（成人、兒童、老人、學生）管理成本價和庫存，對客呈現元，僅需維護成人價。<br />
4、架：適用於直升機按架管道管理成本價和庫存，對客呈現元，預訂時不區分預訂人群，只選擇架數<br />`,
  dateRange: '可銷售日期',
  statistics: '統計',
  statisticsTips:
    '總數，統計團期批次內的庫存數量合計，包括庫存、現詢和自由售賣類型的批次。<br />\n              占位，狀態為“占位中”的訂單出庫類型的出庫單，統計批次庫存數量。<br />\n              出庫，狀態為“已出庫”的所有出庫單，統計批次庫存數量。<br />\n              餘位，統計團期批次內的餘位數量合計，僅庫存的批次。庫存可超和自由售賣的採購規則餘位無窮大（現詢），不做統計。',
  reserveCountTips: '無占位訂單',
  dateRangeRules: '請選擇可銷售日期',
  statusTips: '餘位>0的批次',
  roundIdRules: '批次號只能輸入數字',
  bookingEndTimeTit: {
    1: '天前的',
    2: '點報名截止',
  },
  addStock: '增加庫存',
  departureDateDescTips: '請簡要描述所選的團期',
  allRound: '同步更新庫存批次',
  allRoundTips: '最新設定的成本價、預訂留位時間、截止銷售時間，將同步更新至已有批次。',
  rules: {
    occupyStayTime: '請輸入0-1000數字',
    occupyStayMinute: {
      2: '預訂留位時間請>5分鐘',
    },
    bookingEndDay: '請輸入≥0的整數',
    bookingEndHour: '請選擇截止銷售時間',
    purchaseType: '請選擇庫存類型',
    departureDateDesc: '請輸入備註',
    latestExchangeRate: '財務設定匯率為空，無法開團，請聯系人員維護',
    originalCurrencyCode: '請選擇原幣幣種',
    purchaseExRate: {
      1: '請填寫匯率',
      2: '請輸入大於0的正數',
      3: '請輸入正確格式的匯率',
      4: '請輸入大於0的正數',
      5: '最多輸入8位小數',
    },
    exchangeRateSet: {
      1: '財務設定匯率',
      2: '比財務設定匯率',
      3: '低',
      4: '高',
    },
    adultCost: {
      1: '請輸入成本價',
      2: '請輸入成人成本價',
    },
    childCost: '請輸入兒童成本價',
    singleRoomCost: '請輸入單房差',
    selectSchemeName: '請至少選擇一個方案',
    sessionName: '請輸入場次名稱',
    selectScheme: '請選擇方案',
    reason: '請輸入原因描述',
    label: '請選擇關閉原因',
    vendorId: '請選擇供應商',
    outCount: {
      1: '出庫數量為空',
      2: '出庫數量不能大於餘位數量',
    },
    departureDates: '請選擇可銷售日期或切換至有價格團期',
  },
  pleaseVendorName: '請輸入供應商名稱',
  currencyAmountCompute: '本幣金額：',
  popoverTips: {
    1: '“1.庫存為訂單保留庫存占位的時長，超出該時長訂單所占位的庫存會自動釋放。\n2.修改預訂留位時間後將會更新至所有方案“',
    2: '“1.到設定時間後該批次的可用庫存將不能再售賣\n2.修改截止銷售時間後將會更新至所有方案“',
    3: '“①庫存可超\n可以設庫存，優先售賣庫存餘位，餘位不足時向供應商現詢確認餘位。\n如不設庫存，可在新增庫存時填0或不填（即每單現詢）\n如需設庫存，可在新增庫存時填寫數量，或後續通過入庫新增餘位。\n②庫存不可超\n必須設庫存，僅售賣庫存餘位，餘位不足時不可售\n可在新增庫存時設定，或後續通過入庫新增餘位。\n③現詢\n不設庫存，每單向供應商現詢確認餘位。\n注意：該項僅適用於團隊機票資源\n④自由售賣\n不設庫存，每單向供應商現詢，供應商默認有餘位（庫存無限大）。 “',
    4: '勾選可以維護方案成本價格和對客銷售',
  },
  textPrompt: {
    1: '給銷售的位置鎖定時長，要銷售限時完成訂單收款/簽約',
    2: '0天指出遊當天，1天指前1天',
  },
  selectScheme: '選擇方案',
  selectOperation: '選擇操作',
  openScheme: '打開方案團期',
  closeScheme: '關閉方案團期',
  dateRangeTips: '關閉團期方案不影響其他方案； 最後一個方案不能直接關閉，請操作關團',
  batchSwitchSolutionTips:
    '團期下的任一個方案，使用相同的供應商、採購公司、預訂留位時間、截止銷售時間、庫存類型、庫存數量和匯率。',
  associatedOrder: '關聯訂單',
  orderTips: '此團期存在上述訂單，請及時通知銷售人員處理。',
  closedMissionTips: '請務必檢查該團期的庫存批次，如有餘位請手動出庫。',
  reserveCount: '占位數',
  occupyStayTime: '預訂留位時間',
  outCount: '出庫數量',
  single: '單',
  time: '時間',
  type: '類型',
  quantity: '數量',
  occupyList: '占位單清單',
  orderOccupation: '訂單占位',
  collectInAdvanceOccupation: '預收占位',
  release: '釋放',
  releaseTips1: '確認釋放為餘位？',
  releaseTips2: '確認手動出庫？',
  openMissionPeriod: '打開團期',
  openSchemeTit: '打開方案',
  canDeleteSession: '場次存在占位不可刪除',
  validityEnum: {
    1: '預訂後',
    2: '日內有效',
  },
}

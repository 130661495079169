export default {
  officeLeader: '室主任',
  departmentCenterLeader: '中心總經理',
  departmentViceLeader: '部門副總經理',
  departmentLeader: '部門總經理',
  companyInChargeLeader: '公司分管領導',
  companyGeneralManagerLeader: '公司總經理',
  orgName: '組織名稱',
  编辑组织基础配置: '編輯組織基礎配置',
  配置类型: '配置類型',
  用户姓名: '用戶姓名',
  请输入用户姓名: '請輸入用戶姓名',
  '相同配置类型下，姓名不能重复': '相同配置類型下，姓名不能重複',
  请选择配置类型: '請選擇配置類型',
  编辑成功: '編輯成功',
  是否确定删除: '是否確定刪除',
  部门经理: '部門經理',
  部门总经理室成员: '部門總經理室成員',
  财务人员: '財務人員',
  财务经理: '財務經理',
  财务部总经理室成员: '財務部總經理室成員',
  财务总监: '財務總監',
  remove: '移除',
  editSuccess: '編輯成功',
}

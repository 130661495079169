export default function CommunityShopKeeperServer(ajax, config) {
  return {
    // 店主列表接口
    list: (opt) =>
      ajax({
        url: `/shopkeeper-info/list`,
        method: 'POST',
        ...opt,
      }),
    operationLog: (opt) =>
      ajax({
        url: `/shopkeeper-info/log`,
        method: 'POST',
        ...opt,
      }),
    // 店主信息详情
    detail: (opt) =>
      ajax({
        url: `/shopkeeper-info/detail`,
        method: 'GET',
        ...opt,
      }),
    // 更新店主信息
    update: (opt) =>
      ajax({
        url: `/shopkeeper-info/update`,
        method: 'POST',
        ...opt,
      }),
    // 获取代理人流程信息
    getAgentDetail: (opt) =>
      ajax({
        url: `/agent-remittance-info/detail`,
        method: 'POST',
        ...opt,
      }),
    // 保存代理人信息
    saveAgent: (opt) =>
      ajax({
        url: `/agent-remittance-info/save`,
        method: 'POST',
        ...opt,
      }),
  }
}

export default function ResourceCenterServer(ajax, config) {
  return {
    list: (opt) => ajax({ url: '/list', method: 'get', ...opt }),
    //动态打包查机票
    dynamicGroupFlight: (opt) =>
      ajax({
        url: '/group-flights',
        method: 'POST',
        ...opt,
      }),
    // 查团期下产品对应的可售资源
    resourceBatchCalculate: (opt) =>
      ajax({
        url: '/resource-batch-calculate',
        method: 'POST',
        ...opt,
      }),
  }
}

import i18n from '@/i18n'
export default [
  // 基础配置
  {
    path: '/finance/config/currency',
    name: '/finance/config/currency',
    component: () => import('@/views/financialSys/config/Currency'),
    // meta: { title: '币种信息管理' },
    meta: { title: i18n.t('FinancialSys.config.manageCurrencyInfo') },
  },
  {
    path: '/finance/config/exchange-rate',
    name: '/finance/config/exchange-rate',
    component: () => import('@/views/financialSys/config/ExchangeRate'),
    // meta: { title: '币种汇率管理' },
    meta: { title: i18n.t('FinancialSys.config.manageCurrencyExchangeRate') },
  },
]

export default {
  resourceType: '資源類型',
  resourceId: '資源編號',
  resourceCode: '資源碼',
  name: '資源名稱',
  journeyDayNight: '行程天晚',
  ownerName: '資源負責人',
  vendorId: '供應商',
  depCode: '負責人部門',
  auditStatusField: '資源狀態',
  ticketType: '票種',
  chargeUnit: '銷售單位',
  salesMethod: '資源選擇方式',
  batchList: '批次列表',
  vendorResourceCode: '供应商產品編號',
  资源供应商: '資源供應商',
  选择资源: '選擇資源',
  城市名称: '城市名稱',
}

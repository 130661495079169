export default function VisaServer(ajax, config) {
  return {
    enums: {
      values: (opt) => ajax({ url: `/enum/values`, method: 'get', ...opt }),
    },
    // 国家
    country: (opt) =>
      ajax({
        url: '/visa-country/query',
        method: 'POST',
        ...opt,
      }),

    // 领区
    territoryQuery: (opt) =>
      ajax({
        url: '/visa-territory/query',
        method: 'POST',
        ...opt,
      }),

    // 根据签证/认证 + 国家id 查询分类列表
    countryToVisaCategory: (opt) =>
      ajax({
        url: '/visa-category/query-by-country-id',
        method: 'POST',
        ...opt,
      }),

    // 枚举值公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
  }
}

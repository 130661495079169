export default {
  OrderDetail: {
    '504029-0': '面積：',
    '504029-1': '可住：',
    '504029-2': '樓層：',
    '504029-3': '無線寬頻：',
    '504029-4': '有線寬頻：',
    '504029-5': '窗戶：',
    '504029-6': '預付',
    '504029-7': '現付',
    '504029-8': '酒店開票',
    '504029-9': '中旅開票',
    '504029-10': '共享',
    '504029-11': '對內：',
    '504029-12': '對客：',
    '504029-13': '環影周年慶活動專屬，每買1間酒店需要購買2張周年慶標準票，酒店和門票需同一天使用。',
    '504029-14': '份早餐',
    '504029-15': '無早餐',
    '504029-16': '同行價',
    '504029-17': '建議銷售價',
    '504029-18': '現付及酒店開票房型暫不支持預訂',
    '504029-19': '確認',
    '504029-20': '無可售賣房型',
    '504029-21': '房型',
    '504029-22': '房型名稱',
    '504029-23': '床型',
    '504029-24': '政策',
    '504029-25': '早餐',
    sourceName: '來源',
    '504029-26': '價格（均價）',
    '504029-28': '入住時間',
    '504029-29': '間數',
    '504029-30': '不可取消',
    '504029-31': '前可免費取消',
    '504029-32': 'MM月DD日',
    '492580-0': '添加',
    '492580-1': '更換',
    '492580-2': '{0}酒店',
    '492580-3': '提示',
    '492580-4': '確定',
    '492580-5': '班機類型錯誤',
    '480970-0': '目的地',
    '480970-1': '入住-退房時間',
    '480970-2': '至',
    '480970-3': '入住時間',
    '480970-4': '退房時間',
    '480970-5': 'yyyy年MM月dd日',
    '480970-6': '共',
    '480970-7': '晚',
    '480970-8': '關鍵字',
    '480970-9': '名稱/地址/商圈',
    '480970-10': '搜索',
    '480970-11': '請選擇商業區',
    '480970-12': '請選擇行政區',
    '480970-13': '請選擇地鐵站',
    '480970-14': '請選擇星級',
    '480970-15': '請選擇價格',
    '480970-16': '請選擇酒店品牌',
    '480970-17': '請選擇掛牌等級',
    '480970-18': '請選擇現付/預付',
    '480970-19': '請選擇開票類型',
    '480970-20': '共找到',
    '480970-21': '家酒店',
    '480970-22': '酒店',
    '480970-23': '星級',
    '480970-24': '距離',
    '480970-25': '距',
    '480970-26': '市中心',
    '480970-27': '政策',
    '480970-28': '時間',
    '480970-29': '設施',
    '480970-30': '來源',
    '480970-31': '其他',
    '480970-32': '價格',
    '480970-33': '同行價',
    '480970-34': '建議銷售價',
    '480970-35': '起',
    '480970-36': '操作',
    '480970-37': '已選',
    '480970-38': '查看房型',
    '480970-39': '收起',
    '480970-40': '查看更多',
    '480970-41': '已無更多',
    '480970-42': '不限',
    '480970-43': '請求下面的資料',
    '480970-44': '請選擇房間',
    '480970-45': '提示',
    '480970-46': '繼續',
    '480970-47': '重選',
    '480970-48': '請選擇目的地',
    '480970-49': '入住日期不可早於今日，請修改',
    '480970-50': '關鍵字最多20個字',
    '480970-51': '金牌',
    '480970-52': '特牌',
    '464445-0': '團隊機票',
    '464445-1': '散客機票',
    '464445-2': '新增{0}機票',
    '464445-3': '更換{0}機票',
    '464445-4': '更換機票',
    '464445-5': '添加{0}機票',
    '464445-6': '新增{0}機票',
    '433168-0': '詳情',
    '433168-1': '下載資料',
    '433168-2': '發送材料',
    '433168-3': '直連類資源占位價格實时變動，以實際支付為准，請聯系客人儘快付款簽約。',
    '433168-4': '資源編號：',
    '433168-5': '加急',
    '433168-6': '添加資源',
    '433168-7': '更換資源',
    '433168-8': '加人',
    '433168-9': '辦理時長',
    '433168-10': '出發日期',
    '433168-11': '目的城市',
    '433168-12': '遊玩日期',
    '433168-13': '天',
    '433168-14': '晚',
    '433168-15': '門票入園時間：',
    '433168-16': '場次：',
    '433168-17': '方案',
    '433168-18': '單價',
    '433168-19': '成人：',
    '433168-20': '兒童：',
    '433168-21': '單房差：',
    '433168-22': '數量',
    '433168-23': '套',
    '433168-24': '人數：',
    '433168-25': '大',
    '433168-26': '小',
    '433168-27': '份',
    '433168-28': '損失單價',
    '433168-29': '損失總價',
    '433168-30': '預計總價變化',
    '433168-31': '總價',
    '433168-32': '申請人',
    '433168-33': '出遊人',
    '433168-34': '空',
    '433168-35': '資源狀態',
    '433168-36': '清位時間：',
    '433168-37': '解決方案',
    '433168-38': '原因',
    '433168-39': '方案有效期：',
    '433168-40': '使用憑證',
    '433168-41': '資源負責人',
    '433168-42': '占位方式',
    '433168-43': '操作',
    '433168-44': '占位',
    '433168-45': '取消占位',
    '433168-46': '重試',
    '433168-47': '編輯',
    '433168-48': '删除',
    '433168-49': '此跟團資源不可搭配大交通，如您已搭配，請檢查行程安排',
    '433168-50': '關聯出遊人數量與出遊人數不匹配，請先維護出遊人，再占位',
    '433168-51': '確定要取消占位嗎？',
    '433168-52': '提示',
    '433168-53': '確定',
    '433168-54': '取消',
    '433168-55': '當前資源占位中或已占位，不可更換',
    '433168-56': '請先取消已參加的促銷活動，再更換',
    '433168-57': '最後一行資源不可删除',
    '433168-58': '確定要删除該資源嗎？',
    '433168-59': '當前資源在占位中，不可{0}',
    '433168-60': '當前資源已占位成功，請先取消占位，再{0}',
    '433168-61': '請先取消已參加的促銷活動，再{0}',
    '433168-62': '{0}{1}人',
    '433168-63': '（申請人數{0}人{1}）',
    '422470-0': '資源相關',
    '422470-1': '添加資源',
    '422470-2': '新增機票',
    '422470-3': '更換機票',
    '422470-4': '添加機票',
    '422470-5': '添加酒店',
    '422470-6': '存在占位中的資源，不可更換班機',
    '422470-7': '提示',
    '422470-8': '確定',
    '422470-9': '更換班機會清空已選機票資源，確認繼續操作？',
    '422470-10': '取消',
    '422470-11': '更換航班會清空已選機票，請先取消所有已占位成功機票，再更換！',
    '409031-0': '酒店',
    '409031-1': '直連類資源占位價格實时變動，以實際支付為准，請聯系客人儘快付款簽約。',
    '409031-2': '添加房型',
    '409031-3': '房型',
    '409031-4': '環影周年慶活動專屬，每買1間酒店需要購買2張周年慶標準票，酒店和門票需同一天使用。',
    '409031-5': '對內：',
    '409031-6': '對客：',
    '409031-7': '退改政策',
    '409031-8': '床型',
    '409031-9': '早餐',
    '409031-10': '份早餐',
    '409031-11': '無早餐',
    '409031-12': '均價（間/夜）',
    '409031-13': '間數',
    '409031-14': '使用日期',
    '409031-15': '損失單價',
    '409031-16': '損失總價',
    '409031-17': '預計總價',
    '409031-18': '總價',
    '409031-19': '入住人',
    '409031-20': '每間房只需添加一個出遊人，用於占位，不影響實際入住。',
    '409031-21': '空',
    '409031-22': '狀態',
    '409031-23': '清位時間：',
    '409031-24': '原因：',
    '409031-25': '方案有效期：',
    '409031-26': '資源負責人',
    '409031-27': '占位方式',
    '409031-28': '即時反饋（系統對接）',
    '409031-29': '操作',
    '409031-30': '占位',
    '409031-31': '取消占位',
    '409031-32': '重試',
    '409031-33': '更換酒店',
    '409031-34': '删除',
    '409031-35': '關聯出遊人數量與出遊人數不匹配，請先維護出遊人，再占位',
    '409031-36': '確定要取消占位嗎？',
    '409031-37': '提示',
    '409031-38': '確定',
    '409031-39': '取消',
    '409031-40': '最後一行資源不可删除',
    '409031-41': '確定要刪除酒店嗎？',
    '409031-42': '不可取消',
    '409031-43': '之前，可免費取消',
    '369265-0': '資源編號：',
    '369265-1': '加人',
    '369265-2': '艙等',
    '369265-3': '退改簽',
    '369265-4': '行李額',
    '369265-5': '售價',
    '369265-6': '成人{0}',
    '369265-7': '兒童{0}',
    '369265-8': '成人{0}',
    '369265-9': '兒童{0}',
    '369265-10': '數量',
    '369265-11': '成人{0}',
    '369265-12': '兒童{0}',
    '369265-13': '成人{0}',
    '369265-14': '兒童{0}',
    '369265-15': '損失單價',
    '369265-16': '成人：{0}',
    '369265-17': '兒童：{0}',
    '369265-18': '損失總價',
    '369265-19': '預計總價',
    '369265-20': '總價',
    '369265-21': '乘機人',
    '369265-22': '空',
    '369265-23': '狀態',
    '369265-24': '清位時間：',
    '369265-25': '原因：',
    '369265-26': '方案有效期：',
    '369265-27': '使用憑證',
    '369265-28': '行程單：',
    '369265-29': '票號：',
    '369265-30': '資源負責人',
    '369265-31': '占位方式',
    '369265-32': '即時反饋（系統對接）',
    '369265-33': '二次確認（現詢）',
    '369265-34': '操作',
    '369265-35': '占位',
    '369265-36': '取消占位',
    '369265-37': '重試',
    '369265-38': '更換艙位',
    '369265-39': '編輯',
    '369265-40': '删除',
    '369265-41': '兒童票為線下黑屏占位，占位結果比成人票反饋慢，建議先發起兒童占位',
    '369265-42': '頁面滾動',
    '369265-43': '關聯出遊人數量與出遊人數不匹配，請先維護出遊人，再占位',
    '369265-44': '請先取消已參加的促銷活動，再編輯',
    '369265-45': '確定要删除當前艙位嗎？',
    '369265-46': '提示',
    '354518-0': '經停',
    '354518-1': '退改簽',
    '354518-2': '行李額',
    '319259-0': '可關聯2-12歲（不含）之間的出遊人',
    '319259-1': '可關聯12歲及以上的出遊人',
    '319259-2': '暫無可關聯出遊人，請',
    '319259-3': '點擊',
    '319259-4': '維護',
    '289781-0': '需求',
    '289781-1': '產品',
    '280140-0': '存在占位中的資源時，不可更換{0}',
    '280140-1': '更換{0}會清空已選擇資源，請先取消所有已占位成功的資源後才可更換',
    '261858-0': '入離日期',
    '261858-1': '入住',
    '261858-2': '退房',
    '261858-3': '共',
    '261858-4': '晚',
    '261858-5': '修改',
    '261858-6': '入住日期',
    '261858-7': '退房日期',
    '261858-8': '購買數量',
    '261858-9': '間',
    '261858-10': '特殊需求',
    '261858-11': '編輯',
    '261858-12': '取消',
    '261858-13': '確定',
    '261858-14': '更換酒店/房型',
    '261858-15': '更換酒店',
    '261858-16': '酒店房型已售完，請重新選擇',
    '261858-17': '提示',
    '261858-18': '重新選擇',
    '261858-19': '更換產品/團期，當前訂單中團代號、已選資源會被更新，確認繼續操作？',
    '261858-20':
      '您選擇的產品為本公司上架產品，原產品方訂單會取消（如已創建），當前訂單中團代號、已選資源會被更新，確認繼續操作？',
    '261858-21':
      '您選擇的產品為{0}共享分銷產品，原產品方訂單會取消（如已創建），重新創建{1}訂單，當前訂單中團代號、已選資源會被更新，確認繼續操作？',
    '261858-22':
      '您選擇的產品為{0}共享分銷產品，並會創建{1}訂單，當前訂單中已選資源會被更新，確認繼續操作？',
    '261858-23': 'MM月DD日',
    '244961-0': '產品信息',
    '244961-1': '詳情',
    '244961-2': '下載行程',
    '244961-3':
      '本公司跟產品公司無分銷關係，請先核實兩公司是否簽署分銷協定，添加客商數據，並請產品公司提前申請授信額度； 或重新更換其他產品',
    '244961-4': '產品不允許共享到事業群，請先聯系產品負責人調整設定，或重新更換其他產品',
    '244961-5': '產品碼：',
    '244961-6': '產品類目：',
    '244961-7': '方案：',
    '244961-8': '搭配大交通：',
    '244961-9': '是（{0}）',
    '244961-10': '否',
    '244961-11': '出發-目的城市：',
    '244961-12': '行程天數：',
    '244961-13': '天',
    '244961-14': '晚',
    '244961-15': '入境次數：',
    '244961-16': '停留時長：',
    '244961-17': '有效期：',
    '244961-18': '受理範圍：',
    '244961-19': '資料受理地：',
    '244961-20': '產品負責人：',
    '244961-21': '更換需求',
    '244961-22': '出發日期',
    '244961-23': '出遊日期',
    '244961-24': '此日期是系統根據規則默認帶出，並非客人真正所選日期，請跟客人再次核實出行日期',
    '244961-25': '購買數量',
    '244961-26': '套',
    '244961-27': '出遊人數',
    '244961-28': '成人',
    '244961-29': '兒童',
    '244961-30': '單房差',
    '244961-31': '份',
    '244961-32': '特殊需求',
    '244961-33': '編輯',
    '244961-34': '取消',
    '244961-35': '確定',
    '244961-36': '更換需求會刷新已選資源，確認繼續操作？',
    '244961-37': '更換產品/團期，當前訂單中團代號、已選資源會被更新，確認繼續操作？',
    '244961-38':
      '您選擇的產品為本公司上架產品，原產品方訂單會取消（如已創建），當前訂單中團代號、已選資源會被更新，確認繼續操作？',
    '244961-39':
      '您選擇的產品為{0}共享分銷產品，原產品方訂單會取消（如已創建），重新創建{1}訂單，當前訂單中團代號、已選資源會被更新，確認繼續操作？',
    '244961-40':
      '您選擇的產品為{0}共享分銷產品，並會創建{1}訂單，當前訂單中已選資源會被更新，確認繼續操作？',
    '244961-41': '提示',
    '244961-42': '行程',
    '244961-43': '該產品沒有行程信息',
    '225642-0': '品類',
    '225642-1': '出發城市',
    '225642-2': '目的城市',
    '225642-3': '出遊日期',
    '225642-4': '返回日期',
    '225642-5': '出遊人數',
    '225642-6': '大',
    '225642-7': '小',
    '225642-8': '特殊需求',
    '225642-9': '更換需求',
    '225642-10': '選擇日期',
    '225642-11': '成人',
    '225642-12': '兒童',
    '225642-13': '1個成人最多可攜帶2個兒童',
    '225642-14': '取消',
    '225642-15': '確定',
    '225642-16': '請選擇返回日期',
    '225642-17': '返回日期不可以小於出遊日期',
    '225642-18': '跟團遊',
    '225642-19': '自由行',
    '225642-20': '單程',
    '225642-21': '往返',
    '225642-22': '請選擇品類',
    '225642-23': '請選擇出發城市',
    '225642-24': '請選擇到達城市',
    '225642-25': '請選擇出遊日期',
    '225642-26': '請選擇出遊人數',
    '225642-27': '請選擇機票配置',
    '225642-28': '1個成人最多攜帶2個兒童，請修改',
    '225642-29': '更換需求會清空已選資源，確認繼續操作嗎？',
    '225642-30': '提示',
    '189500-0': '行程類型',
    '189500-1': '删除',
    '189500-2': '添加一程',
    '189500-3': '査詢',
    '176057-0': '出發',
    '176057-1': '返回',
    '176057-2': '去程：',
    '176057-3': '返程：',
    '176057-4': '{0}成人',
    '176057-5': '{0}兒童',
    '176057-6': '班機',
    '176057-7': '起飛到達',
    '176057-8': '餐食',
    '176057-9': '艙等',
    '176057-10': '說明',
    '176057-11': '退改簽行李額說明',
    '176057-12': '參考價格',
    '176057-13': '數量',
    '176057-14': '成人',
    '176057-15': '兒童',
    '176057-16': '操作',
    '176057-17': '選擇',
    '176057-18': '不支持兒童預訂',
    '176057-19': '請先選擇乘機人數',
    '176057-20': '已選',
    '161513-0': '星期',
    '161513-1': '成人{0}',
    '161513-2': '兒童{0}',
    '161513-3': '起飛時段',
    '161513-4': '航空公司',
    '161513-5': '經濟艙',
    '161513-6': '起飛機場',
    '161513-7': '降落機場',
    '161513-8': '機型',
    '161513-9': '顯示價格已包含民航基金及基建燃油稅，所有班機起飛/到達時間均為當地時間',
    '161513-10': '班機',
    '161513-11': '共享',
    '161513-12': '實際承運',
    '161513-13': '起飛到達',
    '161513-14': '天',
    '161513-15': '時長',
    '161513-16': '經停/直飛',
    '161513-17': '經停',
    '161513-18': '直飛',
    '161513-19': '餐食',
    '161513-20': '有餐食',
    '161513-21': '無餐食',
    '161513-22': '艙等',
    '161513-23': '價格',
    '161513-24': '同行價：￥',
    '161513-25': '起',
    '161513-26': '建議銷售價：￥',
    '161513-27': '已含機建燃油費￥',
    '161513-28': '操作',
    '161513-29': '展開',
    '161513-30': '收起',
    '161513-31': '全價',
    '161513-32': '折',
    '161513-33': '餘：',
    '161513-34': '9張',
    '161513-35': '張',
    '161513-36': '退改簽規則',
    '161513-37': '行李額說明',
    '161513-38': '成人票價：',
    '161513-39': '兒童票價：',
    '161513-40': '同行價',
    '161513-41': '建議銷售價',
    '161513-42': '乘機人在12歲以上請選擇成人機票，2至12歲（不含）之間請選擇兒童機票',
    '161513-43': '已選：',
    '161513-44': '確認',
    '161513-45': '單程',
    '161513-46': '去程',
    '161513-47': '返程',
    '161513-48': '第{0}程',
    '161513-49': '請先選擇上一程航班',
    '161513-50': '暫無數據',
    '161513-51': '請繼續選擇下一程機票…',
    '161513-52': '時',
    '161513-53': '分',
    '161513-54': '僅看直飛',
    '161513-55': '日',
    '161513-56': '一',
    '161513-57': '二',
    '161513-58': '三',
    '161513-59': '四',
    '161513-60': '五',
    '161513-61': '六',
    '161513-62': '不限',
    '161513-63': '影藏共享班機',
    '161513-64': '已選',
    '129366-0': '出發城市',
    '129366-1': '請輸入出發城市',
    '129366-2': '請選擇出發城市',
    '129366-3': '目的城市',
    '129366-4': '請輸入目的城市',
    '129366-5': '請選擇目的城市',
    '129366-6': '出發-返回日期',
    '129366-7': '請選擇出發-返回日期',
    '129366-8': '開始日期',
    '129366-9': '結束日期',
    '129366-10': '出發日期',
    '129366-11': '請選擇出發日期',
    '111036-0': '提示',
    '111036-1': '取消',
    '111036-2': '添加',
    '111036-3':
      '當前附加項，會影響訂單總價及對客應收、應退金額，且需要客人簽訂補充協議，確認繼續操作？',
    '111036-4': '確認，稍後發起簽約收退款',
    '111036-5': '確認並同步發起簽約收退款',
    '111036-6': '編輯',
    '111036-7': '删除',
    '111036-8': '當前附加項，會影響訂單總價及對客應收、應退金額，確認繼續操作？',
    '111036-9': '確認，稍後發起收退款',
    '111036-10': '確認並同步發起收退款',
    '069452-0': '其它附加項',
    '069452-1': '添加',
    '069452-2': '附加項類型',
    '069452-3': '請選擇附加項類型',
    '069452-4': '說明',
    '069452-5': '金額',
    '069452-6': '費用歸屬方',
    '069452-7': '內部溝通記錄',
    '069452-8': '上傳',
    '069452-9': '操作',
    '069452-10': '編輯',
    '069452-11': '保存',
    '069452-12': '删除',
    '069452-13':
      '【費用歸屬方】為供應商時，請及時聯繫資源負責人在資源平台-確認管理手工添加供應商確認單',
    additionTip: '附加項調整，需經過產品人員審核，審核通過後，才生效',
    '069452-14': '第一步訂單跟進人跟產品負責人溝通確認損失金額；',
    '069452-15': '第二步',
    '069452-16':
      '資源負責人線下跟供應商聯系退單，並在資源平臺-確認管理手工添加供應商確認單，等待業務財務審核；',
    '069452-17': '第三步業務財務審核供應商確認單；',
    '069452-18': '第四步訂單跟進人添加附加項，調整訂單總價；',
    '069452-19': '第五步個人客戶線上簽署補充協議/企業客戶由訂單跟進人上傳簽約文件；',
    '069452-20':
      '第六步訂單跟進人，點擊【同步財務審核】按鈕，將退款資料同步到業財模塊操作實際退款；',
    '069452-21': '訂單部分退操作步驟',
    '069452-22': '優惠',
    '069452-23': '加收',
    '069452-24': '退款',
    '069452-25': '加價',
    '069452-26': '請填寫說明',
    '069452-27': '請填寫數字',
    '069452-28': '請填寫正數',
    '069452-29': '金額過大',
    '069452-30': '請輸入兩位小數',
    '069452-31': '請選擇費用歸屬方',
    '069452-32': '請輸入供應商',
    '069452-33': '請先保存正在編輯的附加項',
    '069452-34': '新增附加項成功',
    '069452-35': '保存附加項成功',
    '069452-36': '您調整附加項後，可能影響當前訂單的毛利，請確認後再操作',
    '069452-37': '提示',
    '069452-38': '{0}當前附加項，會影響訂單總價及對客應收、應退金額，確認繼續操作？',
    differentRoomTypeTips: '預訂的房型價格需來自同一家公司，請核實無誤後再確認',
    确定撤回吗: '確定撤回嗎？',
  },
}

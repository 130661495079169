const basePath = '/product-sys/'
export default [
  {
    path: basePath + 'machine-wine-list',
    name: basePath + 'machine-wine-list',
    component: () => import('@/views/ProductSys/DynamicPackaging/index.vue'),
    meta: { title: '动态打包产品列表' },
  },
  {
    path: basePath + 'ticket-edit',
    name: basePath + 'ticket-edit',
    component: () => import('@/views/ProductSys/TicketEdit/index.vue'),
    meta: { title: '门券详情' },
  },
  {
    path: basePath + 'ticket-list',
    name: basePath + 'ticket-list',
    component: () => import('@/views/ProductSys/TicketList/index.vue'),
    meta: { title: '门券产品' },
  },
  {
    path: basePath + 'sales-record-list',
    name: basePath + 'sales-record-list',
    component: () => import('@/views/ProductSys/SalesRecordList/index.vue'),
    meta: { title: '销售进度' },
  },
  {
    path: '/grounding/general-product/list',
    name: '/grounding/general-product/list',
    component: () => import('@/views/ProductSys/GeneralProductList/index.vue'),
    meta: { title: '常规产品列表' },
  },
  {
    path: '/grounding/general-product/edit',
    name: '/grounding/general-product/edit',
    component: () => import('@/views/ProductSys/GeneralProductEdit/index.vue'),
    meta: { title: '产品编辑' },
  },
  {
    path: '/grounding/product-info',
    name: '/grounding/product-info',
    component: () => import('@/views/ProductSys/ProductInfo/index.vue'),
    meta: { title: '产品详情' },
  },
  {
    path: '/grounding/distribution-product/list',
    name: '/grounding/distribution-product/list',
    component: () => import('@/views/ProductSys/DistributionProductList/index.vue'),
    meta: { title: '分销产品列表' },
  },
  // 酒店套餐列表
  {
    path: basePath + 'hotel-set/list',
    component: () => import('@/views/ProductSys/HotelSet/list/index.vue'),
    meta: { title: '酒店套餐产品' },
  },
  {
    path: basePath + 'hotel-set/edit',
    component: () => import('@/views/ProductSys/HotelSet/edit/index.vue'),
    meta: { title: '酒店套餐编辑' },
  },
  {
    path: basePath + 'hotel-set/info',
    component: () => import('@/views/ProductSys/HotelSet/info/index.vue'),
    meta: { title: '酒店套餐详情' },
  },
]

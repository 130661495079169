export default {
  muDeDiZuMingCheng: '目的地組名稱',
  BaiYu: '目的地組修改失敗.原因：{0}',
  MingC: '請輸入目的地組名稱',
  muDeDiFangXiang: '目的地方向',
  'angXia-0': '請選擇目的地方向',
  eDiZu: '請選擇一級目的地組',
  eZiFu: '不能超過30個字符',
  engGon: '目的地組新增成功',
  iBaiY: '目的地組新增失敗.原因：{0}',
  queDingTingYongGaiMuDeD:
    '確定停用該目的地分組嗎？ 停用後該目的地分組將不能被類目關聯，但不影響已關聯的類目！',
  queDingTingYong: '確定停用',
  qiYongShiBaiYuanYinResme: '啟用失敗.原因：{0}',
  tingYongShiBaiYuanYinRes: '停用失敗.原因：{0}',
  ngGong: '目的地組修改成功',
  uDeDi: '請輸入上級目的地組',
  uJiBi: '請選擇目的地組級別',
  muDeDiZuJiBie: '目的地組級別',
  'uJiBi-0': '請選擇目的地組級別',
  xinZengMuDeDiZu: '新增目的地組',
  tianJiaMuDeDiZu: '添加目的地組',
  bianJiMuDeDiZu: '編輯目的地組',
  qingShuRuShangJiMuDeDi: '請輸入上級目的地',
  shangJiMuDeDiZu: '上級目的地組',
  tingYongChengGong: '停用成功',
  qiYongChengGong: '啟用成功',
  jingNei: '境內',
  jingWai: '境外',
  zhengChang: '正常',
  yiTingYong: '已停用',
  chuangJianRen: '創建人',
  muDeDiZuBianMa: '目的地編碼',
}

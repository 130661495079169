export default {
  company: '所屬公司',
  companyName: '所屬公司名稱',
  billStatus: '賬單狀態',
  billTransfer: '發放方式',
  settleStatus: '賬單結算狀態',
  auditStatus: '付款單審核狀態',
  paymentStatus: '付款狀態',
  billCycleTime: '賬單月份',
  placeholderStartTime: '請輸入開始時間',
  placeholderEndTime: '請輸入結束時間',
  billTipDesc: '請先在財務共享系統，申請需發放的傭金額度並充值至對應公司微信賬戶',
  transferBill: '傭金發放',
  confirmInvoice: '確認發票報銷',
  confirmInvoiceDesc: '是否確認已完成發票報銷？',
  confirmInvoiceSuccess: '發票報銷成功',
  orderCount: '訂單筆數',
  orderAmount: '訂單金額',
  commissionCount: '傭金總筆數',
  commissionAmount: '傭金總計金額',
  transferredAmount: '發放金額',
  toTransferAmount: '待發金額',
  settleStatusDesc: '賬單結算狀態',
  subPaymentCode: '應付單號',
  subPaymentAuditStatusDesc: '付款單審核狀態',
  transferTimeNew: '最新發放時間',
  confirmPayment: '確認工資發放',
  confirmBill: '確認發票報銷',
  confirmPaymentDesc: '是否確認已完成工資發放？',
  confirmSuccess: '確認薪水成功',
  sureOrderAmount: '已確認訂單金額',
  lockCommissionAmount: '鎖客傭金金額',
  orderCommissionAmount: '下單傭金金額',
  followCommissionAmount: '跟單傭金金額',
  salesmanName: '店主/用戶姓名',
  salesmanRoleName: '店主/用戶身份',
  transferStatusDesc: '傭金發放狀態',
  transferForm: '傭金發放方式',
  grantDetail: '發放明細',
  transferTime: '發放時間',
  quantity: '筆',
  people: '人',
  salesmanId: 'NBS賬號/雲店店主id',
  sureOrderCount: '已確認訂單筆數',
  lockCommissionCount: '鎖客傭金筆數',
  orderCommissionCount: '下單傭金筆數',
  followCommissionCount: '跟單傭金筆數',
  commissionTaxAmount: '傭金總金額',
  tax: '稅額',
  commissionAmountTable: '應發金額',
  salesmanCommissionDetail: '雲店傭金賬單詳情',
  orderCountStatistics: '已確認訂單',
  lockCommission: '鎖客傭金',
  orderCommission: '下單傭金',
  followCommission: '跟單傭金',
  sumCommissionCount: '獲得傭金人數總計',
  sumCommissionAmount: '總計傭金金額',
  transferredCount: '已發放',
  toTransferCount: '待發放',
  placeholderSalesmanName: '請輸入姓名',
  exportTaxForm: '導出人力報稅用錶',
  exportPayFinanceForm: '導出付款財務用錶',
  exportPayApplyForm: '導出付款申請用錶',
  exportDetail: '導出明細',
  transferSerialNumber: '微信流水號',
  confirmCommission: '確認傭金發放',
  transferBillDesc: '點擊傭金發放後不可撤回，是否確認傭金發放？',
  transferBillSuccess: '傭金發放成功',
}

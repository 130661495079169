import { dbTable } from '../config'
import localforage from 'localforage'

// 获取页面地址，配合表名称形成数据表名称
export function getPathName(cacheKey = '') {
  return window.location.pathname + cacheKey
}

// 存入表格数据
export async function setCol(labels = [], cacheKey = '') {
  let db = await localforage.getItem(dbTable.twoTableCol)
  if (!db) db = {}
  db[getPathName(cacheKey)] = labels

  return localforage.setItem(dbTable.twoTableCol, db)
}
// 获取单个页面配置列数据
export async function getCol(cacheKey = '') {
  let db = await localforage.getItem(dbTable.twoTableCol)
  if (!db) db = {}
  return db[getPathName(cacheKey)] ?? []
}

import resourceEdit from './resourceEdit'
import resourceInfo from './resourceInfo'
import resourceList from './resourceList'
import costDescription from './costDescription'
import explain from './explain'
import ticketInventory from './ticketInventory'

export default {
  resourceInfo,
  resourceList,
  resourceEdit,
  costDescription,
  explain,
  ticketInventory,
}

import 'normalize.css/normalize.css' // 初始化默认css

import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import i18n from './i18n'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { deepClone } from '@/utils'

// 全局Mixin混入，用于检测更新版本
// import GlLoad from '@/mixin/GlLoad'

// 全局组件注册
import components from './components'

import '@/styles/index.scss' // 全局样式定义和样式开发规范

import '@/permission' // 路由守卫控制

import CustomMessage from '@/utils/CustomMessage'

// 项目资源预加载
import Preload from './Preload'
import { startLocalforage } from '@/store/localforage'

startLocalforage() // 初始化
Vue.prototype.$c_message = CustomMessage

Vue.use(components)
Vue.config.productionTip = false
//  要是想调试移动端 先去public\index.html把 initial-scale改成 1 不然收获一个巨大无比的vconsole
// import VConsole from 'vconsole'
// if (process.env.VUE_APP_NODE_ENV === 'development') {
//   new VConsole({ maxLogNumber: 1000 })
// }

// 进入vue项目执行命令
// Vue.mixin(GlLoad)
// 使用pinia插件
Vue.use(PiniaVuePlugin)
async function CreateApp() {
  const pinia = createPinia()
  pinia.use(({ store }) => {
    const initialState = deepClone(store.$state)
    store.$reset = () => store.$patch(($state) => Object.assign($state, deepClone(initialState)))
  })
  const mountApp = () => {
    new Vue({
      router,
      store,
      i18n,
      pinia,
      render: (h) => h(App),
    }).$mount('#app')
  }
  try {
    await Preload()
    mountApp()
  } catch (e) {
    console.log('create app error', e)
    mountApp()
  }
}

CreateApp()

const basePath = '/org-config/'
export default [
  {
    path: basePath + 'department-leader',
    name: basePath + 'department-leader',
    component: () => import('@/views/sysConfig/departmentLeader/index.vue'),
    meta: { title: '组织基础配置' },
  },
  {
    path: '/sys-menu-set',
    name: '/sys-menu-set',
    component: () => import('@/views/sysConfig/SysMenuSet/index.vue'),
    meta: { title: '系统菜单' },
  },
  {
    path: '/user-org',
    name: '/user-org',
    component: () => import('@/views/sysConfig/UserAndOrg/index.vue'),
    meta: { title: '用户与组织' },
  },
  {
    path: '/role-mange',
    name: '/role-mange',
    component: () => import('@/views/sysConfig/RoleMange/index.vue'),
    meta: { title: '角色管理' },
  },
  {
    path: '/close-user',
    name: '/close-user',
    component: () => import('@/views/sysConfig/CloseUser/index.vue'),
    meta: { title: '已关闭用户' },
  },
  {
    path: '/abnormal-statistical-list',
    name: '/abnormal-statistical-list',
    component: () => import('@/views/sysConfig/abnormalStatisticalList/index.vue'),
    meta: { title: '错误清单' },
  },
  {
    path: '/abnormal-statistical-chart',
    name: '/abnormal-statistical-chart',
    component: () => import('@/views/sysConfig/abnormalStatisticalChart/index.vue'),
    meta: { title: '错误报表' },
  },
]

export default {
  collection: {
    receiptsUserName: '收款人',
    followerInfo: '對賬操作人',
    category: '產品品類',
    number: '對賬批次號',
    followerInfos: '訂單跟進人',
    customerName: '客戶名稱',
    externalOrderId: '外部訂單號',
    channelNo: '合作渠道',
    externalCategoryId: '外部合作類目',
    userName: '收款操作人',
    refuse: '已駁回',
    accept: '已審核',
    wait: '待審核',
    handlingFee: '手續費',
    handlingFeeRate: '手續費率',
    departureDate: '出遊日期',
    guaranteePaidAmount: '擔保實收金額',
    receiptsTime: '收款日期',
    receiptsAmount: '訂單待收金額',
    guaranteeAmount: '訂單擔保金額',
    receivedAmount: '訂單實收金額',
    orderAmount: '訂單應收金額',
    orderStatusDesc: '訂單狀態',
    returnDate: '歸來日期',
    tabs: {
      0: '待收款',
      1: '對賬查詢',
      2: '收款審核進度',
    },
    defOptions: {
      1: '自由行',
      2: '跟團遊',
      3: '動態打包跟團遊',
    },
    rules: {
      checkOrderAmount: '最多輸入8位數字',
      category: {
        1: '請選擇一級品類',
        2: '請選擇二級品類',
      },
      amount: '請輸入大於等於0的金額',
      orderId: '請輸入數字',
      currencyCode: '請選擇幣種',
      handlingFeeRate: '請選擇手續費率',
      flowClaimList: '請選擇支付水單編號',
      sharedBillNo: '請輸入手工報賬單號',
      preReceiptAgreement: '請上傳預收款使用協定',
      attachmentTOList: '請上傳{text}',
      handlingFee: '請填寫手續費',
    },
    query: {
      all: '全部査詢',
      common: '常用査詢',
    },
    addOrder: '添加訂單',
    export: '導出明細',
    pay: '收款',
    delete: {
      tips: '已删除',
      confirm: '確定删除？',
      text: '删除收款匯總單後，需要在待收款頁面重新操作收款',
    },
    checkOrderCount: '對賬訂單數',
    checkOrderAmount: '對賬金額',
    exportOrderDetail: '導出訂單明細',
    importBills: '導入訂單',
    addPay: '添加收款',
    check: '已勾選{len}個訂單，當前待收金額{money}',
    handle: '處理',
    fail: '匹配失敗，是否需要手動提交實收款？',
    exportPay: '導出收款明細',
    customer: '客戶',
    batchPay: '批量收款',
    selectError: '根據銷售公司和客戶名稱沒有匹配到訂單，請核實',
    result: {
      1: '本次導入明細：{count}筆，共計{totalAmount}',
      2: '已匹配成功：{matchOrderCount}筆，共計{matchOrderAmount}',
      3: '匹配失敗：{failMatchCount}筆',
      4: '請點擊【添加收款】進行收款操作',
      5: '請點擊【確定】查看賬單核對結果',
      title: '導入結果',
    },
    success: '成功',
    failStatus: '失敗',
    match: '賬單匹配{text}',
    importOrder: '導入賬單',
    upload: '上傳賬單',
    download: {
      name: '下載範本',
      tips: '請按標準範本導入客戶賬單快速匹配',
      drag: '將文件拖到此處，或{text}',
      upload: '點擊上傳',
      ext: '支持副檔名：xls、xlsx',
      support: '文件僅支持{text}，請調整',
    },
    refuseReason: '退回原因',
    orderInfo: '訂單明細',
    refresh: '刷新',
    waitReceiptsAmountTotal: '待收總額',
    waitReceiptsAmount: '當前待收金額',
    receiptsTips: '待收總額-財務審核中金額，所有未操作收款的金額、財務審核退回金額',
    sharedBillNo: '共享報賬單號',
    cash: {
      1: '現金存單',
      2: '使用憑證',
    },
    attachment: {
      name: '上傳文件',
      tips: '請上傳銀行水單截圖/現金收據等我司收款憑證，',
      support: '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv、rar、zip',
      warn: '請保證預收款創建部門與當前使用部門在同一個部門內',
    },
    preReceipt: '使用預收款金額',
    preReceiptAgreement: '預收款使用協定',
    bankInfo: '收款銀行',
    receiptsAccount: '銀行賬號',
    receiptsTotalAmount: '本次合計收款金額',
    handlingFeeSummary: '訂單明細手續費匯總',
    amountReceived: '到賬金額',
    CNY: '人民幣',
    flowClaimList: '支付水單編號',
    proveAttachmentTOList: '非員工舉證材料',
    receiptsAmountTips: '此處不可部分收款',
    enclosure: '附件',
    saveResult: '保存對賬結果',
    examine: '提交財務審核',
    validate: {
      bank: '請選擇收款銀行',
      receiptsMode: '請選擇收款方式',
      receiptAmount: {
        1: '請填寫收款金額',
        2: '請填寫大於0的金額',
        3: '收款金額不能大於待收金額',
        4: '請小於當前待收金額',
        5: '最多兩位小數的數字',
        6: '請輸入使用金額',
        7: '請小於可用餘額',
        8: '請等於待收金額',
      },
      proveAttachmentTOList: '請上傳非員工舉證資料',
    },
    tipDialog: {
      title: '如需線下與財務核對水單明細，再提交財務審核，可以先點擊【保存對賬結果】',
      message: '請根據對賬批次號：{checkBatchNumber}在【對賬査詢裏繼續發起收款】',
    },
    submit: {
      1: '批量收款中存在訂單號：{orderId}關聯預借發票，請去【訂單管理-訂單中心】對該訂單進行單獨收款操作',
      2: '操作成功',
      3: '已提交財務審核，請在【收款審核進度】查看審核結果',
      4: '收款匯總單號：{orders}',
    },
    capping: {
      1: '未封頂',
      2: '已封頂',
    },
    remove: '至少保留一個訂單',
    receiptsAmountStart: '最低金額',
    receiptsAmountEnd: '最高金額',
    externalOrderAmount: '外部訂單金額',
    currentReceiptsAmount: '本次收款金額',
    yichu: '移除',
    saveWarn: '請保存或者取消',
    max: '最大值{receiptsAmount}',
    henQingBuMenMingChengShaiXuan: '輸入申請部門名稱篩選',
    hiJiKeHuMingChengShaiXuan: '輸入實際客戶名稱篩選',
    choose: '選擇',
    KuanDingDanHao: '預收款訂單號',
    nE: '總金額',
    JinE: '已用金額',
    hiYongJinE: '本次使用金額',
    keYongJinE: '可用金額',
    eHu: '實際客戶',
    creditManage: {
      customerName: '客戶名稱',
      typeName: '授信類型',
      applicationNumber: '授信申請單號',
      statusName: '狀態',
      companyName: '我方公司主體名稱',
      deptName: '申請部門',
      userName: '申請人',
      creditLimit: '授信額度',
      leaveCredit: '授信餘額',
      creditValidityBeginDate: '授信有效期',
      oaContractNo: '審批編號',
      contractValidityBeginDate: '客戶合約的協定週期',
      cooperationWay: '合作類型',
      financialDateType: '賬期類型/出賬日',
      financialDateOrderType: '每賬期出賬範圍',
      acceptMoneyDate: '出賬訂單最晚收款日',
      createTime: '創建時間',
    },
  },
}

export default {
  '116438-1': '請輸入交易號碼',
  '116438-3': '請選擇資源類型',
  '116438-5': '請輸入資源編號',
  '116438-6': '資源名稱',
  '116438-7': '請輸入資源名稱',
  '116438-8': '確認單號',
  '116438-9': '請輸入確認單號',
  '116438-11': '至',
  '116438-12': '開始日期',
  '116438-13': '結束日期',
  '116438-14': '供應商',
  '116438-15': '請輸入供應商名稱',
  '116438-16': '負責人',
  '116438-17': '請輸入負責人',
  '116438-18': '生成時間',
  '116438-19': '開始時間',
  '116438-20': '結束時間',
  '116438-21': '資源負責人',
  '116438-22': '回傳時間',
  '116438-23': '回傳人',
  '116438-24': '請輸入回傳人',
  '116438-25': '反馈渠道',
  '116438-26': '請選擇反馈渠道',
  '116438-27': '產品碼',
  '116438-28': '請輸入產品碼',
  '116438-30': '請輸入產品名稱',
  '116438-31': '出發日期',
  '116438-32': '採購公司',
  '116438-33': '請輸入採購公司',
  '116438-34': '全部査詢',
  '116438-35': '常用査詢',
  '116438-36': '査詢',
  '116438-37': '今天',
  '116438-38': '請選擇生成時間',
  '116438-39': '請選擇回傳時間',
  '121799-3': '資源名稱',
  '121799-4': '資源負責人',
  '121799-5': '確認單號',
  '121799-8': '數量',
  '121799-9': '人數：',
  '121799-10': '大',
  '121799-11': '小',
  '121799-12': '供應商',
  '121799-13': '負責人',
  '121799-14': '反馈渠道',
  '121799-15': '產品碼',
  '121799-17': '出發日期',
  '121799-18': '生成時間',
  '121799-19': '回傳時間',
  '121799-20': '回傳人',
  '121799-21': '採購公司',
  '121799-22': '操作',
  '121799-23': '處理',
  '121799-24': '詳情',
  '126737-1': '資源名稱',
  '126737-3': '客人使用憑證',
  '126737-5': '產品方交易號碼',
  '126737-6': '分銷方交易號碼',
  '126737-7': '產品方',
  '126737-8': '分銷方',
  '126737-9': '供應商',
  '126737-10': '出遊人',
  '126737-11': '航班信息',
  '126737-12': '票號',
  '126737-13': '行程單',
  '126737-14': '上傳文件',
  '126737-15': '文件格式doc、xls、txt、pdf、jpg、png、gif、bmp、csv、docx、xlsx',
  '126737-16': '取消',
  '126737-17': '確認',
  '126737-18': '請填寫票號',
  '126737-19': '上傳失敗',
  '126737-20': '上傳成功',
  '126737-21': '删除成功',
  '126737-22': '上傳文件大小不能超過5MB',
  '126737-23': '提示',
  '126737-24': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、gif、bmp、csv',
  '126737-25': '文件名稱重複，請修改後重新上傳',
  '126737-26': '請等待文件上傳完成',
  '126737-27': '保存成功',
  '388866-0': '查看出遊通知',
  '388866-1': '關閉',
  '388866-2': '出遊通知書',
  '388866-3': '內容',
  '400488-0': '製作出遊通知',
  '400488-1': '保存並發送',
  '400488-2': '出遊通知書',
  '400488-3': '上傳附件',
  '400488-4': '（僅支持格式pdf）',
  '400488-5': '請上傳出遊通知書',
  '400488-6': '請上傳pdf文件',
  '400488-7': '文件大於5M',
  '400488-8': '上傳失敗',
  '414973-0': '待處理',
  '414973-1': '已處理',
  '414973-2': '已取消',
  '419878-0': '出遊日期',
  '419878-1': '至',
  '419878-2': '開始日期',
  '419878-3': '結束日期',
  '419878-4': '產品碼',
  '419878-5': '請輸入產品碼',
  '419878-7': '請輸入產品名稱',
  '419878-9': '請輸入交易號碼',
  '419878-10': '負責人',
  '419878-11': '請輸入負責人',
  '419878-12': '生成時間',
  '419878-13': '開始時間',
  '419878-14': '結束時間',
  '419878-15': '過期狀態',
  '419878-16': '請選擇過期狀態',
  '419878-17': '處理狀態',
  '419878-18': '請選擇處理狀態',
  '419878-19': '處理時間',
  '419878-20': '査詢',
  '419878-21': '出遊日期：',
  '419878-22': '產品碼：',
  '419878-23': '產品名稱：',
  '419878-24': '發送方式：',
  '419878-25': '有變更',
  '419878-26': '出發-目的城市',
  '419878-27': '操作',
  '419878-28': '查看確認單',
  '419878-29': '上傳',
  '419878-30': '詳情',
  '199719-0': '手工添加團隊確認單',
  '199719-1': '團代號',
  '199719-2': '團名稱',
  '199719-3': '團期',
  '199719-5': '資源名稱',
  '199719-8': '數量',
  '199719-9': '聯絡人',
  '199719-10': '操作',
  '199719-11': '下載出遊人信息',
  '199719-12': '查看確認單',
  '199719-13': '取消',
  '199719-14': '確認',
  '199719-15': '請填寫門市全稱',
  '199719-16': '請填寫門市簡稱',
  '199719-17': '請選擇門市類型',
  '199719-18': '請填寫B2B門市代碼',
  '205915-0': '團代號',
  '205915-1': '團名稱',
  '205915-2': '團期',
  '205915-3': '銷售數量',
  '205915-4': '資源名稱',
  '205915-5': '資源使用日期',
  '205915-6': '團隊確認狀態',
  '205915-7': '團隊確認金額匯總',
  '205915-8': '產品公司',
  '205915-9': '產品碼',
  '205915-11': '團類型',
  '205915-12': '操作',
  '205915-13': '團隊確認單號',
  '205915-16': '資源負責人',
  '205915-17': '數量',
  '205915-18': '單價',
  '205915-19': '成本總價',
  '205915-20': '供應商',
  '205915-21': '負責人',
  '205915-22': '發起時間',
  '205915-23': '確認單類型',
  '205915-24': '確認結果',
  '205915-25': '確認反饋時間',
  '205915-26': '反饋人',
  '205915-27': '發起人',
  '205915-28': '確認反馈渠道',
  '205915-29': '出發日期',
  '205915-30': '採購公司',
  反馈时间: '反饋時間',
}

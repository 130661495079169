export default {
  qingShuRuGongYin: '請輸入供應商名稱',
  buXian: '不限',
  noResult: '搜索無結果請重新搜索',
  baseInfo: '基本信息',
  creditManage: {
    tiShiYiXiaNeiRo:
      '提示：以下內容，申請人根據合約審批、訂單流程中的相關內容進行填寫，審核人員根據具體場景進行審核。',
    qingShangChuanZhe: '請上傳證明掛賬依據的審批截圖(OA、用印等)',
    queRenShanChuCi: '確定删除此條掛賬申請嗎？',
    shiXiaoChengGong: '失效成功',
    queRenShiXiaoCi: '確定失效此條掛賬申請嗎？',
    qingXianXuanZeGo: '請先選擇公司名稱',
    shiXiao: '失效',
    eDuChaKan: '額度查看',
    faQiShouXinShen: '發起掛賬申請',
    updateUserName: '申請人',
    customerName: '客戶名稱',
    typeName: '掛賬類型',
    useScope: '使用範圍',
    applicationNumber: '申請單號',
    statusName: '掛賬狀態',
    companyName: '我方公司主體名稱',
    deptName: '申請部門',
    userName: '申請人',
    creditLimit: '掛賬額度',
    changeType: '變動類型',
    leaveCredit: '掛賬餘額',
    creditValidityBeginDate: '掛賬有效期',
    oaContractNo: '審批編號',
    contractValidityBeginDate: '客戶合約的協定週期',
    cooperationWay: '合作類型',
    financialDateType: '賬期類型/出賬日',
    financialDateOrderType: '每賬期出賬範圍',
    acceptMoneyDate: '出賬最晚收款日',
    createTime: '創建時間',
    qingShuRuYuanShiYongeduBianDongLiuShui: '請輸入原使用額度變動流水',
    qingShuRuBianDongLiuShui: '請輸入變動流水',
    yuanShiYongeduBianDongLiuShui: '原使用額度變動流水',
    bianDongShiJian: '變動時間',
    bianDongCaoZuoRen: '變動操作人',
    bianDongLiuShui: '變動流水',
    shiShouQingKuang: '實收情况',
    shenQingDanHao: '申請單號',
    gongSiMingCheng: '公司名稱',
    getthousandzerodatac: '{0}',
    heZuoFangShi: '合作方式',
    shouXinXinXi: '掛賬信息',
    qiTaFuJian: '其他附件',
    heTongFuJian: '合約附件',
    fuJian: '附件',
    diary: 'Diary',
    pleSelectCustomerName: '請輸入客戶名稱',
    qingXianXuanZeWo: '請先選擇我方公司主體',
    '15KaiShiJiSuanZ': '1.5開始計算賬期。',
    jiaLabelZhangQiZ: '加{0}賬期，最晚收款。',
    keHuShouDaoFaPi: '客戶收到發票後',
    qingShangChuanHe: '請上傳合約附件',
    qingShangChuanShe: '請上傳審批截圖',
    'qingXuanZeShouXi-0': '請選擇掛賬有效期',
    qingXuanZeKeHuH: '請選擇客戶合約的協定週期',
    qingXuanZeChuZha: '請選擇出賬訂單最晚收款日',
    qingXuanZeMeiZha: '請選擇每賬期出賬範圍',
    qingXuanZeZhangQ: '請選擇賬期類型/出賬日',
    qingXuanZeHeZuo: '請選擇合作類型',
    qingShuRuShenPi: '請輸入審批編號',
    qingXuanZeShenQi: '請選擇申請部門',
    qingShuRuWoFang: '請輸入我方公司主體名稱',
    qingXuanZeShouXi: '請選擇掛賬類型',
    qingShuRu_09999999: '請輸入0~999999999.99的數字，最多兩位小數',
    warningLimitTip: '預警餘額不能超過掛賬額度',
    qingShuRuShouXin: '請輸入掛賬額度',
    shenPiJieTu: '審批截圖',
    dingDanZhiNengZa: '訂單只能在有效期內使用本筆掛賬。',
    'ru_15KeHuShouDao-0':
      '如1.5客戶收到發票（默認開票申請完成共享審核後2天），加30天賬期，2.4最晚收款。',
    'ru_1Yue_11131WeiY-0': '如1月（1.1-1.31）為一個賬期，2月最晚收款。',
    'ru_1JiDu_13YueWe-0': '如1季度（1-3月）為一個賬期，4月最晚收款。',
    qingShuRuRiQi: '請輸入日期',
    ru_1Yue_11131WeiY: '如1月（1.1-1.31）為一個賬期，最後一天出賬。',
    ru_1JiDu_13YueWe: '如1季度（1-3月）為一個賬期，最後一天出賬。',
    ru_15KeHuShouDao: '如1.5客戶收到發票（預設開票申請完成共享審核後2天），{0}',
    shouXinYiJuDeSh: '掛賬依據的審批編號(OA、用印等)',
    formdataRemarkNull: '({0}/500)',
    creditStatus: '掛賬狀態',
    originalCurrencyCode: '額度幣種',
    standardCreditLimit: '掛賬本幣額度',
    standardLeaveCredit: '掛賬本幣餘額',
    warningCredit: '預警餘額',
    预警修改: '預警修改',
    applyDeptOnlyTip:
      '訂單銷售公司與我方公司主體名稱一致、且訂單銷售部門與申請部門一致，可使用該筆掛賬',
    allDeptTip: '訂單銷售公司與我方公司主體名稱一致，可使用該筆掛賬',
  },
  creditChange: {
    yuanShiY: '原使用額度變動流水',
    dingDanT: '訂單統計時間',
    orderId: '訂單號',
    'bianDong-4': '變動時間',
    shiShouQ: '實收情况',
    'bianDong-3': '變動操作人',
    zuiJinYi: '最近一次返還時間',
    yiFanHua: '已返還額度',
    shiYonged: '使用額度最晚收款日',
    'bianDong-2': '變動後額度',
    benCiBia: '本次變動額度',
    'bianDong-1': '變動前額度',
    yeWuBian: '業務變動類型',
    'bianDong-0': '變動類型',
    updateUserName: '申請人',
    creditManage: {
      deptName: '申請部門',
      companyName: '我方公司主體名稱',
      applicationNumber: '掛賬申請單號',
      typeName: '掛賬類型',
    },
    customerName: '客戶名稱',
    bianDong: '變動流水',
    data: {
      index: '序號',
    },
    tongYiPi:
      '同一批次審核結轉的收款記錄必須客戶相同、幣種相同、收款類型相同、收款方式相同，請檢查',
    receivePay: {
      transferSuccessTip: '單據已推送共享，請稍後在共享介面進行查看',
    },
    queRenGo: '確認勾選的收款推送財務共享進行審核',
    qingGouX: '請勾選數據',
    'daoChuEx-0': '導出明細成功。',
    'dangQian-0': '掛賬使用后，超過最遲還款日，逾期未還，請儘快在訂單上完成實收',
    dangQian: '掛賬使用后，未實收全款，即將逾期，請儘快在訂單上完成實收',
    daoChuEx: '導出明細',
    挂账余额不足: '當前掛賬申請單餘額低於您設置的預警餘額，請及時申請新的掛賬額度',
    orderCurrency: '交易幣種',
    useCurrencyCredit: '本次變動交易額度',
    changeStandardCredit: '本次變動本幣額度',
    beforeChangeStandardCredit: '變動前本幣額度',
    afterChangeStandardCredit: '變動後本幣額度',
    receivedStandardMoney: '已返還本幣額度',
  },
  creditAudit: {
    shenHeChengGong: '審核成功',
    qingShuRuShenHe: '請輸入審核意見',
    qingShuRu_09999999: '請輸入0~999999999.99的數字，最多兩位小數',
    qingShuRuShouXin: '請輸入掛賬額度',
    tongGuo: '通過',
    tuiHui: '退回',
    shenHeYiJian: '審核意見',
    shenHe: '審核',
  },
}

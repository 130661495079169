export default {
  salesman: '銷售員',
  creator: '提交人',
  salesDepart: '銷售點',
  reportId: '日結單號',
  approveTime: '批時間',
  submitTime: '提交時間',
  productClass: '產品品類',
  addReport: '提交日結',
  batchApprove: '批量審核',
  operateTime: '收退款時間',
  过账时间: '過賬時間',
  reportStatus: '日結單狀態\t',
  summa: '收退款匯總',
  approveReason: '退回原因',
  salesCode: '電子單據',
  approveRemark: '審核意見',
  auditConfirm: '確定{0}銷售日結單？',
  table: {
    Cash: 'CH現金',
    Check: '支票',
    EPS: 'EPS',
    CreditCard: '信用卡',
    CreditLimit: '掛賬',
    Other: '其他+',
    Total: '合計',
    deleteConfirm: '確定作廢銷售日結單？',
    submitConfirm: '確定提交銷售日結單？',
    resaveConfirm: '確定撤回銷售日結單？',
    updateConfirm: '提交後，原{0}會同步作廢，如已列印給財務，請務必跟財務溝通，收回{0}後再操作',
    submitSuccess: '您可以列印{0}給財務',
    CurrencyTotal: '幣種合計',
  },
  receiptRefund: {
    receiptRefundCode: '收退款單號',
    receiptModeName: '收退款方式',
    receiptRefundTime: '收退款時間',
    originalAmount: '收退款金額',
  },
  financeApprovePass: '財務審核通過',
}

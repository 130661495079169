export default {
  firstDesc: '確認渠道、外部合作類目',
  secondDesc: '輸入外部訂單信息',
  thirdDesc: '複製連結',
  productId: 'NBS產品碼',
  press: '按',
  numOfSheets: '張數',
  numOfSets: '套數',
  adult: '成人',
  child: '兒童',
  generateLink: '生成連結',
  bookLink: '外部訂單預約連結：',
  pleaseEnterNum: '請輸入數量',
  pleaseEnterProduct: '請輸入NBS產品碼',
  pleaseSelectCate: '請選擇外部合作類目',
  pleaseEnterOrderId: '請輸入外部訂單號',
  num: '數量',
  pleaseSelectChannel: '請選擇合作渠道',
  lowerShelf: '該產品已下架',
  noPrice: '該產品無團期',
  noExist: '暫無該產品',
  channelNo: '合作渠道',
  externalCategoryId: '外部合作類目',
  externalOrderId: '外部訂單號',
}

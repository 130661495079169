export default {
  orderId: '訂單編號',
  placeholderOrderId: '訂單ID',
  productId: '產品碼',
  placeholderProductId: '產品ID',
  salesmanRoleId: '店主身份',
  companyId: '所屬公司',
  placeholderCompanyId: '所屬公司名稱',
  commissionType: '傭金類型',
  salesmanName: '店主姓名',
  commissionStatus: '傭金狀態',
  commissionBillStatus: '傭金賬單狀態',
  paymentStatus: '付款狀態',
  settleStatus: '結算狀態',
  commissionCreateTime: '傭金生成時間',
  commissionConfirmTime: '傭金確認時間',
  isMoreThanZero: '只看有效傭金（非0）',
  exportDetail: '導出明細',
}

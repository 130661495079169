export default function ProductCenterServer(ajax, config) {
  return {
    list: (opt) =>
      ajax({
        url: '/products',
        method: 'get',
        ...opt,
      }),
    listV2: (opt) =>
      ajax({
        url: '/v2/products',
        method: 'get',
        ...opt,
      }),
    listV2Post: (opt) =>
      ajax({
        url: '/v2/products',
        method: 'post',
        ...opt,
      }),
    priceCalendar: (opt) =>
      ajax({
        url: '/product-price/calendar',
        method: 'post',
        ...opt,
      }),
    resourceList: (opt) =>
      ajax({
        url: '/resource/resources',
        method: 'post',
        ...opt,
      }),
    onlyProductDetail: (opt) =>
      ajax({
        url: '/only-product-details',
        method: 'get',
        ...opt,
      }),
    productDetail: (opt) =>
      ajax({
        url: '/product-details',
        method: 'get',
        ...opt,
      }),
    productResourceCalculate: (opt) =>
      ajax({
        url: '/product-resource/calculate',
        method: 'post',
        ...opt,
      }),
  }
}

<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="c-dialog-one"
    :title="title"
    :visible="value"
    :width="width"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :class="[titSet[lcr], themes[theme], cpadding]"
    :show-close="showClose"
    :top="top"
    :modal="isModal"
    :destroy-on-close="destroyOnClose"
    :center="value"
    @close="onclose"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :lock-scroll="lockScroll"
  >
    <slot name="header" />
    <div :style="{ padding: showPadding + 'px' }">
      <slot />
    </div>
    <slot name="footer" />
    <NoPaddingFooter
      v-if="isFooterBut"
      :is-cancel="isCancel"
      :is-confirm="isConfirm"
      :cancel-text="cancelText"
      :confirm-text="confirmText"
      :loading="loading"
      @cancel="onclose"
      @confirm="onConfirm"
      :confirmDisabled="confirmDisabled"
    />
  </el-dialog>
</template>

<script>
import NoPaddingFooter from './NoPaddingFooter'
import { Dialog } from 'element-ui'
import i18n from '@/i18n'
export default {
  name: 'DialogTemOne',
  components: {
    NoPaddingFooter,
    ElDialog: Dialog,
  },
  emits: ['change', 'input', 'close', 'confirm'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    closeOnClickModal: Boolean,
    closeOnPressEscape: Boolean,
    isFooterBut: Boolean, // 是否要底部的按钮组件
    loading: Boolean,
    destroyOnClose: {
      type: Boolean,
      default: true,
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: false,
    },
    top: String,
    showClose: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '30%',
    },
    lcr: {
      type: String,
      default: 'l', // l 居左，c：居中，r居右
    },
    theme: {
      type: String,
      default: '1', //2 白色主题背景
    },
    noPadding: Boolean, // body不要padding
    showPadding: {
      type: [String, Number],
      default: 0,
    },
    modalAppendToBody: {
      type: Boolean,
      default: false,
    },
    appendToBody: Boolean,
    lockScroll: Boolean,
    isCancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: i18n.t('common.button.cancel'),
    },
    isConfirm: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: i18n.t('common.button.confirm'),
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
      // 确认按钮禁用
    },
  },
  data() {
    return {
      titSet: {
        l: 'c-dialog-tit-left',
        c: '',
        r: 'c-dialog-tit-right',
      },
      themes: {
        1: '',
        2: 'c-dialog-one2',
      },
      cpadding: { 'c-dialog-no-padding': this.noPadding },
    }
  },
  methods: {
    onclose() {
      this.$emit('change', false)
      this.$emit('input', false)
      this.$emit('close', false)
    },
    onConfirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style scoped lang="scss">
.c-dialog-tit-left {
  ::v-deep .el-dialog__header {
    justify-content: flex-start !important;
    padding-left: 15px !important;
  }
}
.c-dialog-tit-right {
  ::v-deep .el-dialog__header {
    justify-content: flex-end !important;
    padding-right: 40px !important;
  }
}
.c-dialog-one {
  ::v-deep .el-dialog {
    border-radius: 8px;
  }
  ::v-deep .el-dialog__header {
    background: #2a303c;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
    .el-dialog__title {
      color: #ffffff;
      font-size: var(--h2);
      display: inline-block;
      text-align: left;
      white-space: nowrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .el-dialog__headerbtn {
      right: 20px;
      top: 14px;
      padding: 0 !important;
    }
    .el-dialog__close {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
.c-dialog-no-padding {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
.c-dialog-one2 {
  ::v-deep .el-dialog__header {
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 2px;
    .el-dialog__title {
      color: #555 !important;
      font-weight: bold;
    }
    .el-dialog__close {
      color: #555 !important;
    }
  }
}
</style>

export default [
  {
    path: '/basedata-company',
    name: '/basedata-company',
    component: () => import('@/views/baseDataSys/BaseDataCompany/index.vue'),
    meta: { title: '公司列表' },
  },
  {
    path: '/bd-company-details',
    name: '/bd-company-details',
    component: () => import('@/views/baseDataSys/BDCompanyLookOrEdit/index.vue'),
    meta: { title: '公司详情' },
  },
  {
    path: '/basedata-store',
    name: '/basedata-store',
    component: () => import('@/views/baseDataSys/BaseDataStore/index.vue'),
    meta: { title: '门市列表' },
  },
  {
    path: '/bd-store-details',
    name: '/bd-store-details',
    component: () => import('@/views/baseDataSys/BDStoreLookOrEdit/index.vue'),
    meta: { title: '门市详情' },
  },
  {
    path: '/grounding/poi-list',
    name: '/grounding/poi-list',
    component: () => import('@/views/baseDataSys/POIList/index.vue'),
    meta: { title: 'POI列表' },
  },
  {
    path: '/grounding/poi-detail',
    name: '/grounding/poi-detail',
    component: () => import('@/views/baseDataSys/PoiInfoOrEdit/index.vue'),
    meta: { title: 'POI详情' },
  },
]

export default function OrderCenterServer(ajax, config) {
  return {
    orderBooking: (opt) => ajax({ url: '/order/booking', method: 'post', ...opt }),
    orderImportList: (opt) => ajax({ url: '/order-import', method: 'get', ...opt }),
    orderSendSms: (opt) => ajax({ url: '/order/send-pre-sms', method: 'get', ...opt }),
    downloadTemp: (type) =>
      ajax({
        url: `/order-import/download-template?type=${type}`,
        method: 'get',
        file: true,
        responseType: 'blob',
      }),
    orderOutputdetail: (id) =>
      ajax({
        url: `/order-import/output-detail?id=${id}`,
        method: 'get',
        file: true,
        responseType: 'blob',
      }),
    upload: config.domainName + config.gateway + '/order-center/order/import',
    importDetail: (id) => ajax({ url: `/order-import/${id}`, method: 'get' }),
  }
}

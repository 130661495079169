export default {
  chuFaChengShiDiQuBianMa: '出發城市/地區編碼',
  chuFaChengShiDiQuMingCheng: '出發城市/地區名稱',
  chuFaDiLaiYuan: '出發地來源',
  chuFaDiLieBiao: '出發地清單',
  chuFaDiZu: '出發地組',
  chuFaDiZuBianMa: '出發地組編碼',
  chuFaDiZuMingCheng: '出發地組名稱',
  departure: 'Departure',
  guanLianChengShi: '關聯城市',
  huoQuChuFaDiZuShiBaiYuanYinRes: '獲取出發地組失敗.原因：',
}

export default {
  addRole: '新增角色',
  editRole: '編輯角色',
  roleName: '角色名稱',
  roleType: '角色類型',
  userType: '適用用戶',
  roleDesc: '角色說明',
  roleNameTips: '請填寫角色名稱',
  roleTypeTips: '請選擇角色類型',
  userTypeTips: '請選擇適用用戶',
  tips: '已關聯用戶的角色一旦删除，可能關聯這個角色用戶暫無相關許可權，確定要删除嗎？',
  save: '保存',
}

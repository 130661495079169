const basePath = '/supplier-sys/'
export default [
  {
    path: basePath + 'resource-audit',
    name: basePath + 'resource-audit',
    component: () => import('@/views/supplierSys/resourceAudit/index.vue'),
    meta: { title: '旅游资源审核' },
  },
  {
    path: basePath + 'account',
    name: basePath + 'account',
    component: () => import('@/views/supplierSys/account/index.vue'),
    meta: { title: '供应商账号管理' },
  },
  {
    path: basePath + 'resource/info',
    name: basePath + 'resource/info',
    component: () => import('@/views/supplierSys/resourceInfo/index.vue'),
    meta: { title: '审核详情' },
  },
]

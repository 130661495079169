export default {
  calculateType: '計算方式',
  numberRatio: '比例/數值',
  ruleStatus: '規則狀態',
  ruleValid: '規則有效期',
  categoryName: '類目',
  ruleId: '規則ID',
  consultType: '合作渠道',
  companyName: '所屬公司',
  salesmanNameTable: '店主/用戶姓名',
  salesmanRoleName: '店主/用戶身份',
  direction: {},
  product: {
    productId: '產品編碼',
    productName: '產品名稱',
    departureDates: '適用團期範圍',
  },
  channel: {
    productClassName: '適用品類',
    terminalName: '終端',
    lockCommissionName: '鎖客傭金',
    orderCommissionName: '下單傭金',
    docCommissionName: '跟單傭金',
    ownProducts: '銷售自己公司產品',
    superiorRoleName: '，上級',
    otherProducts: '分銷別家公司產品',
    enabledStatus: '已啟用',
    obsoleteStatus: '已返還本幣額度',
  },
  paymentMethod: {
    state: '狀態',
  },
  details: {
    orderIdNumber: '訂單號',
    placeholderProductId: '產品ID',
    productName: '產品名稱',
    saleAmount: '訂單金額',
    commissionCreateTime: '傭金生成時間',
    commissionType: '傭金類型',
    commissionSourceDesc: '傭金來源',
    commissionAmount: '傭金金額',
    commissionStatus: '傭金狀態',
    commissionConfirmTime: '傭金確認時間',
    companySettlementCode: '結算單號',
    settleStatus: '結算狀態',
    paymentStatus: '付款狀態',
    commissionBillStatus: '傭金賬單狀態',
    commissionGrantTime: '傭金發放時間',
  },
  bill: {
    billTransfer: '發放方式',
    billCycleTime: '賬單月份',
    orderCount: '訂單筆數',
    orderAmount: '訂單金額',
    commissionCount: '傭金總筆數',
    commissionAmount: '傭金總計金額',
    transferredAmount: '發放金額',
    toTransferAmount: '待發金額',
    settleStatusDesc: '賬單結算狀態',
    subPaymentCode: '應付單號',
    paymentStatus: '付款狀態',
    billStatus: '賬單狀態',
    transferTimeNew: '最新發放時間',
    subPaymentAuditStatusDesc: '付款單審核狀態',
  },
  billDetail: {
    salesmanId: 'NBS賬號/雲店店主id',
    sureOrderCount: '已確認訂單筆數',
    sureOrderAmount: '已確認訂單金額',
    lockCommissionCount: '鎖客傭金筆數',
    lockCommissionAmount: '鎖客傭金金額',
    orderCommissionCount: '下單傭金筆數',
    orderCommissionAmount: '下單傭金金額',
    followCommissionCount: '跟單傭金筆數',
    followCommissionAmount: '跟單傭金金額',
    commissionTaxAmount: '傭金總金額',
    tax: '稅額',
    commissionAmountTable: '應發金額',
    transferForm: '傭金發放方式',
    transferStatusDesc: '傭金發放狀態',
    grantDetail: '發放明細',
  },
  placeholderRuleId: '輸入規則ID',
}

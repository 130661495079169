export default {
  showDeactivatedRules: '顯示已停用規則',
  addRule: '添加規則',
  editRule: '編輯規則',
  isStopTips: '確定{isStop}該規則',
  lookDialogTitle: '查看加價規則',
  priceIncreaseRange: '加價幅度',
  salesPrice: '銷售價',
  peerPrice: '同行價',
  wholeCountry: '全國',
  northeast: '東北',
  northChina: '華北',
  eastChina: '華東',
  southChina: '華南',
  centralChina: '華中',
  northwest: '西北',
  southwest: '西南',
  aircraftRules: {
    firstProductClassId: '一級品類不能為空',
    secondProductClassId: '二級品類不能為空',
    destinationCityId: {
      1: '到達城市不能爲空',
      2: '請先選擇一二級品類',
      3: '該到達城市已無出發城市和適用品類的規則可設置',
      4: '請更換類目',
    },
    departureCityList: '出發城市不能為空',
  },
  firstProductClassIdNoNull: '一級品類不能為空',
  secondProductClassIdNoNull: '二級品類不能為空',
  EditMarkup: {
    rules: {
      ruleType: '加價類型不能為空',
      ruleScope: {
        1: '加價幅度不能為空',
        2: '請輸入≥0數字',
        3: '最多一位小數',
      },
      tradeScope: {
        1: '加價幅度不能為空',
        2: '請輸入≥0數字',
        3: '最多一位小數',
        4: '同行加價幅度不能大於零售加價幅度',
      },
    },
  },
}

import { ClientEnum } from '@/common/const/WbpServer'

export default function RouterServer(ajax, config) {
  const cop = '/cop/cop-server'
  return {
    wbp: {
      // 日志
      log: (opt) => ajax({ url: `${cop}/product/log/list`, method: 'post', ...opt }),
      list: (opt) => {
        if (opt && opt.data) {
          opt.data.client = ClientEnum.NBS
        }
        return ajax({ url: `${cop}/product/base/list`, method: 'post', ...opt })
      },
      info: (id, opt) => ajax({ url: `${cop}/product/base/detail/${id}`, method: 'get', ...opt }),
      recommend: (id, opt) =>
        ajax({ url: `${cop}/product/recommend/detail/${id}`, method: 'get', ...opt }),

      structural: (productBaseId, opt) =>
        ajax({ url: `${cop}/product/base/structural/${productBaseId}`, method: 'get', ...opt }),
      // 审核
      audit: (opt) => ajax({ url: `${cop}/product/base/receive-audit`, method: 'post', ...opt }),
      // 草稿变更团期日历接口
      priceChangeList: (opt) =>
        ajax({ url: `${cop}/departure/date/changeList`, method: 'get', ...opt }),
      // 退回原因
      returnLook: (id, opt) =>
        ajax({ url: `${cop}/product/base/get-reject-remark/${id}`, method: 'get', ...opt }),
    },
  }
}

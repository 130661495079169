/*
 * 权限组成部分主要由菜单layout部分和power下方代码组成
 * */
import noShowMenu from '@/layout/noShowMenu.js' // 不会展示在左侧菜单，但是需要在面包屑中展示的
import Vuex from '@/store'
import { treeLeveling } from '@/views/sysConfig/SysMenuSet/tool'
import { mockStore } from '@/layout/mock'
import noPowerPage from './noPowerPage'
const config = require('/config/config')

// 是否有访问页面的权限
export function isVisit(pageRoute) {
  // 未登录返回失败
  if (!Vuex.state.user.token) return false

  let userMenus = []
  if (config.mockRouter) {
    userMenus = treeLeveling(mockStore.allRouter)
  } else {
    Vuex.state.userMenus.forEach((li) => {
      li.path = li.url
    })
    userMenus = Vuex.state.userMenus
  }
  const pathList = [] // 可访问列表
  userMenus.forEach((li) => {
    const { path } = li
    if (path && path[0] === '/') pathList.push(path)
  })
  for (let key in noShowMenu) {
    // 递归获取面包屑菜单里面的地址
    const sonMenuPath = (menu) => {
      if (menu.children && menu.children.length > 0) {
        for (let i = 0; i < menu.children.length; i++) {
          const item = menu.children[i]
          if (item.path) pathList.push(item.path)
          if (item.children && item.children.length > 0) sonMenuPath(item.children)
        }
      }
    }
    // 需要后台返回的路由能匹配的上才可以放入权限管控
    if (pathList.includes(key)) {
      noShowMenu[key].forEach((li) => {
        const { path } = li
        path && pathList.push(path)
        sonMenuPath(li)
      })
    }
  }
  // 既不会被auth控制又需要被权限管控放行的页面加入到数组中
  pathList.push(...noPowerPage)

  // 过滤
  const powerPath = []
  pathList.forEach((li) => {
    powerPath.push(li.split('?')[0])
  })
  // 如果存在返回true,不存在返回false
  // return true
  return powerPath.includes(pageRoute)
}

import i18n from '@/i18n'
import { ajax } from './base/axiosAjax'
import config from './config'
import Tips from './base/tips'

import { loginOut } from '@/services/tool/LoginSet'
import vuex from '@/store/index'
import qs from 'qs'
import { antiShake } from '@/utils/antiShakingAndThrottling'
import {
  encryptionAES,
  sha256WithRSA,
  uuid,
  decryptAES,
  getSecretHash,
  encryptionSM2,
  encryptSM4,
  decryptSM4,
} from '@/utils/crypto'
import { isSysNeedEncryptFunc } from '@/services/tool/isSysNeedEncrypt'

// 口令封装处理
const handlerToken = (header = {}) => {
  header['requestId'] = new Date().getTime()

  const token = vuex.getters.token
  if (!token) return header
  header['Authorization'] = token
  return header
}
// 401退出登录
const signOut = antiShake(() => {
  loginOut()
  Tips.error({
    msg: i18n.t('用户登录失效将重新登录'),
    title: i18n.t('错误'),
  })
}, 1000)

// 处理opt传入参数
const handlerData = (opt, apiBase = {}) => {
  const { prefix } = apiBase
  opt.baseURL = opt.baseURL ? opt.baseURL : config.domainName
  opt.url = prefix + opt.url

  opt.headers = opt.headers ?? { 'Content-Type': 'application/json' } // 设置默认headers
  opt.headers = handlerToken(opt.headers)
  opt.file = opt.file ?? false // 是否为文件模式，文件下载模式为后端直接下载文件，不做处理判断
  opt.mock = opt.mock ?? process.env.VUE_APP_NODE_ENV === 'rapmock' // 是否为mock模式

  // opt.responseType = opt.responseType ?? (opt.mock ? 'json' : 'text') // 细节需要加括号,上环境情况下后端返回的数据是base64字符串
  opt.responseType = opt.responseType ?? 'json'
  opt.isResponse = opt.isResponse ?? false // 是否直接获取response数据，避免因为简化data数据获取导致无法获取完整数据情况
  opt.reLogin = opt.reLogin ?? true // 是否判断401状态跳转到登录页面
  const lang = vuex.state.language //因为项目中使用到了i18n国际化语言配置，请根据实际情况自行修改
  // console.log('lang', lang)
  opt.headers['Accept-Language'] = lang
  return opt
}
// 错误信息
const handlerErrorMessage = (error, message, tipsCode) => {
  error &&
    tipsCode !== 'ORD0230001' &&
    Tips.error({
      msg: error !== true ? error : message ?? '網絡環境存在異常，請稍後重試！',
      tipsCode,
    })
}
// 成功信息
const handlerSuccessMessage = (success, message, tipsCode = '') => {
  success &&
    Tips.success({
      msg: success !== true ? success : message ?? i18n.t('成功'),
      tipsCode,
    })
}

// 业务接口
async function BaseApi(
  opt = {},
  {
    prefix = '',
    codeField = 'success',
    dataField = 'data',
    codeNum = true,
    msgField = 'message',
    tipsCode = 'errorCode',
    needEncrypt = false, // 默认是不需要加密的-接口级别的特殊加密
  },
) {
  opt = handlerData(opt, { prefix }) // 参数预处理
  const error = opt.error ?? true // 成功提醒
  const success = opt.success ?? false // 错误提醒

  // 特殊格式请求处理
  const posts = ['put', 'post', 'patch']
  if (
    posts.includes(opt.method) &&
    opt.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    opt.data = qs.stringify(opt.data)
  }
  // console.log('params-----', opt.url, opt.data) // 打印未加密的请求参数

  let keyStr = ''
  let isSysNeedEncrypt = isSysNeedEncryptFunc(opt) // 服务是否需要加密
  // 设置需要加密
  if (needEncrypt) {
    // 接口级别
    // 签名、加密
    const systemId = 2201,
      signCode = {
        uuid: uuid(),
        timestamp: new Date().getTime(),
        versionNo: '1.0',
      },
      signedStr = sha256WithRSA({ systemId, ...signCode }),
      requestBody = {
        ...signCode,
        data: { ...opt.data },
        sign: signedStr,
      }
    opt.data = {
      systemId,
      body: encryptionAES(requestBody),
    }
  } else if (isSysNeedEncrypt) {
    // 服务级别
    // 签名、加密
    const { key, encryptorStr } = encryptionSM2()
    const { iv, encryptBody } = encryptSM4(key, JSON.stringify(opt.data))
    keyStr = key
    opt.headers['secret-hash'] = getSecretHash()
    opt.headers['secret'] = encryptorStr
    /** web加解密针对不同请求方式的处理
     * POST PUT 请求方式，请求和响应都加解密
     * 其他请求方式，请求不加解密，响应加解密
     */
    // POST PUT 请求方式，请求要加密
    if (opt.method.toLowerCase() === 'post' || opt.method.toLowerCase() === 'put') {
      opt.data = {
        data: encryptBody,
        iv,
      }
    }
  }

  //   2021/11/26  鲁:修改,解决get特殊字符转码问题（源于波总）
  if (opt.method === 'get' || opt.method === 'GET') {
    if (opt.data) {
      opt.url = `${opt.url}?${qs.stringify(opt.data)}`
      delete opt.data
    }
  }

  try {
    let result = await ajax(opt) // 请求接口
    // 服务级别解密
    if (!needEncrypt && isSysNeedEncrypt) {
      result = {
        ...result,
        data: decryptSM4(keyStr, result.data),
      }
    }
    if (result.headers['authorization']) {
      vuex.commit('user/SET_TOKEN', result.headers['authorization'])
    }
    if (opt.reLogin && result.status === 401) {
      signOut()
      return Promise.reject(result)
    }

    switch (opt.file) {
      case false: {
        // 解密后端返回信息
        /*const response = opt.mock ? result.data
          : result.data
          ? JSON.parse(base64Decode(result.data))
          : result.data;*/
        let response = result.data
        // 如果需要解密-接口级别解密，兼容原解密逻辑
        if (needEncrypt && response.body) {
          response = decryptAES(response.body)
          if (!response) {
            return Promise.reject(result)
          }
        }

        const code = response[codeField]
        const data = response[dataField]
        const message = response[msgField]
        const errCode = response[tipsCode]
        // console.log('response-----', opt.url, response) // 打印解密后的响应参数
        if (code === codeNum) {
          // 提前处理正确错误
          handlerSuccessMessage(success, message)

          return Promise.resolve(opt.isResponse ? response : data)
        } else {
          handlerErrorMessage(error, message, errCode)

          return Promise.reject(response)
        }
      }
      // 走文件模式下
      case true: {
        return Promise.resolve(result)
      }
    }
  } catch (e) {
    switch (opt.file) {
      case false: {
        const response = e.response
        if (opt.reLogin && response?.status === 401) signOut()
        else {
          const resData = response?.data ?? {}
          const message = resData[msgField]
          const errCode = resData[tipsCode]
          handlerErrorMessage(error, message, errCode)
        }

        return Promise.reject(e)
      }
      case true: {
        return Promise.reject(e)
      }
    }
  }
}

export { BaseApi }

export default {
  '396534-0': '一體化旅行服務運營平臺',
  '396534-1': '專業高效',
  '396534-2': '智慧管理',
  '396534-3': '用戶名',
  '396534-4': '密碼',
  '396534-5': '登入',
  '396534-6': '忘記密碼？',
  '396534-7': '請輸入用戶名',
  '396534-8': '請輸入密碼',
  '396534-9': '用戶名或密碼不能為空',
  '609895-1': '修改密碼',
  '609895-2': '原密碼',
  '609895-3': '請輸入原密碼',
  '609895-4': '密碼輸入不正確，是否',
  '609895-5': '忘記密碼？',
  '609895-6': '新密碼',
  '609895-7': '請輸入新密碼',
  '609895-8': '確認密碼',
  '609895-9': '請再次輸入新密碼',
  '609895-10': '驗證碼',
  '609895-11': '請輸入驗證碼',
  '609895-13': '回到上一頁',
  '609895-14': '原密碼輸入錯誤',
  '609895-15': '請輸入賬號',
  '609895-16': '請確認當前所輸賬號，如有問題，請聯繫系統管理員',
  '609895-17': '請輸入手機號',
  '609895-18': '請輸入正確的手機號',
  '609895-19': '當前手機號未綁定任何NBS賬號，無法通過簡訊驗證碼修改密碼',
  '609895-20': '請設置密碼至少12位，3種以上大、小寫字母、數字、特殊字符組合',
  '609895-21': '請輸入密碼',
  '609895-22': '請再次輸入密碼',
  '609895-23': '兩次輸入密碼不一致！',
  '609895-24': '弱',
  '609895-25': '中',
  '609895-26': '強',
  '609895-27': '發送驗證碼',
  '609895-28': '密碼修改成功',
  '848840-1': '忘記密碼',
  '848840-2': '驗證身份',
  '848840-3': '設定登入密碼',
  '848840-4': '完成',
  '848840-5': '登入賬號',
  '848840-6': '請輸入賬號',
  '848840-7': '手機號碼',
  '848840-8': '根據登入賬號自動帶出手機號碼',
  '848840-11': '設置密碼',
  '848840-12': '請輸入新密碼',
  '848840-13': '二次確認',
  '848840-14': '請再次輸入新密碼',
  '848840-17': '已成功修改密碼，正在為您跳轉到登錄介面',
  '848840-18': '若沒有自動跳轉，請點擊',
  '848840-19': '登入',
  '848840-20': '請確認當前所輸賬號，如有問題，請聯繫系統管理員',
  '848840-32': '驗證碼發送成功',
  '848840-33': '{0}s後重新發送',
  '848840-35': '校驗通過',
  '848840-36': '驗證碼輸入不正確，請重新輸入',
}

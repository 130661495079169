export default {
  agree: '同意',
  disagree: '不同意',
  shanghaiAbroadTourDialog: {
    '106093-0': '請輸入正整數或“/”',
    '106093-1': '請輸入大於等於0的整數',
    '106093-2': '請輸入大於等於0的整數或“/”',
    '106093-3': '請輸入小於等於結束天數的整數',
    '106093-4': '請輸入大於等於開始天數的整數',
    '106093-5': '請輸入比例',
    '106093-6': '請輸入數字',
    '106093-7':
      '      1.因疫情影響，旅遊者應在參加旅遊項目前如實申報健康情況並按照旅遊經營者的要求提供相關證明文件；\r\n      2.旅遊者患有 新冠肺炎等傳染病等疾病可能危害其他旅遊者健康和安全的，應按照國家防疫相關政策規定採取相應措施。發生前述情況的，旅 遊經營者可以依據相關規定解除合約，旅遊者需就給旅遊經營者造成的全部損失承擔賠償責任；\r\n      3.因疫情影響旅遊者人身安 全，旅遊經營者應當採取相應的安全措施，因此支出的費用，由旅遊經營者與旅遊者分擔。因疫情或者疫情防控措施造成旅遊者 滯留的，旅遊經營者應當採取相應的合理安置措施，因此增加的食宿費用由旅遊者承擔，增加的返程費用由旅遊經營者與旅遊者 分擔。\r\n      ',
    '106093-8': '請選擇組團方式',
    '106093-9': '請選擇交納方式',
    '106093-10': '請輸入委託社全稱',
    '106093-11': '請選擇成團人數與不成團的約定',
    '106093-12': '請選擇餘款退還方式',
    '106093-13': '請輸入正整數',
    '106093-14': '請輸入小於9999的正整數',
    '106093-15': '請輸入文字',
    '402969-0': '重新',
    '402969-1': '生成合約',
    '402969-2': '第一條 合約標的',
    '402969-3': '組團方式（三選一）',
    '402969-4': '自行組團',
    '402969-5': '委託組團',
    '402969-6': '（委託社全稱',
    '402969-7': '拼團',
    '402969-8': '第三條旅遊者保險',
    '402969-9': '乙方提示甲方購買旅遊意外險。 經乙方推薦，甲方',
    '402969-12': '（應填同意或不同意，打勾無效）委託乙方辦理個人投保的旅遊意外保險。）',
    '402969-13': '保險公司及產品名稱：',
    '402969-14': '保險金額：',
    '402969-15': '元，保險費：',
    '402969-16': '元。',
    '402969-17': '第四條旅遊費用及其支付',
    '402969-18': '旅遊費用交納期限',
    '402969-19': '選擇日期',
    '402969-20': '旅遊費用交納方式：',
    '402969-21': '現金',
    '402969-22': '支票',
    '402969-23': '信用卡',
    '402969-24': '其他',
    '402969-25': '第五條雙方的權利義務',
    '402969-26': '（二）乙方的權利與義務',
    '402969-27': '8.成團人數與不成團的約定（二選一）',
    '402969-28': '最小成團人數',
    '402969-29': '人；',
    '402969-30':
      '低於此人數不能成團時，乙方應當提前30日通知甲方，本合約解除，向甲方退還已收取的全部費用。',
    '402969-31': '本團成團不受最低人數限制。',
    '402969-32': '第六條甲方解除合約及承擔必要費用',
    '402969-33':
      '因甲方自身原因導致合約解除，乙方按下列標準扣除必要費用後，將餘款退還甲方（二選一）',
    '402969-34': '（一）雙方可以進行約定並從其約定：',
    '402969-35': '1.在行程前解除合約的，必要的費用扣除標準為：',
    '402969-36': '（1）行程前',
    '402969-37': '日至',
    '402969-38': '日，旅遊費用',
    '402969-39': '（2）行程前',
    '402969-40': '（3）行程前',
    '402969-41': '（4）行程前',
    '402969-42': '（5）行程開始當日，旅遊費用',
    '402969-43': '甲方行程前逾期支付旅遊費用超過',
    '402969-44':
      '日的，或者甲方未按約定時間到達約定集合出發地點，也未能在中途加入旅遊團隊的，乙方可以視為甲方解除合約，乙方可以按本款約定扣除必要的費用後，將餘款退還甲方。',
    '402969-45': '2.在行程中解除合約的，必要的費用扣除標準為：',
    '402969-46': '旅遊費用－',
    '402969-47': '（二）雙方沒有約定的，按照下列標準扣除必要的費用。',
    '402969-48': '1.在行程前解除合約的，機（車、船）票費用按實結算後，其餘必要的費用扣除標準為：',
    '402969-49': '（1）行程前30日至15日，旅遊費用5%；',
    '402969-50': '（2）行程前14日至7日，旅遊費用10%；',
    '402969-51': '（3）行程前6日至4日，旅遊費用15%；',
    '402969-52': '（4）行程前3日至1日，旅遊費用25%；',
    '402969-53': '（5）行程開始當日，旅遊費用30%。',
    '402969-54':
      '日的，或者甲方未按約定時間到達約定集合出發地點，也未能在中途加入旅遊團隊的，乙方可以視為甲方解除合約，乙方可以按本款規定扣除必要的費用後，將餘款退還甲方。',
    '402969-55':
      '（旅遊費用-旅遊費用 × 行程開始當日扣除比例）÷旅遊天數 × 已經出遊的天數+旅遊費用 × 行程開始當日扣除比例。',
    '402969-56':
      '如按上述1、2方式支付的必要的費用低於實際發生的費用，按照實際發生的費用扣除，但最高額不應超過旅遊費用總額。',
    '402969-57': '行程前解除合約的，乙方扣除必要的費用後，應當在合約解除之日起',
    '402969-58': '個工作日內向甲方退還剩餘旅遊費用；',
    '402969-59':
      '行程中解除合約的，乙方扣除必要的費用後，應當在協助甲方返回出發地或者到達甲方指定的合理地點後',
    '402969-60': '個工作日內向甲方退還剩餘旅遊費用。',
    '402969-61': '第七條違約責任',
    '402969-62':
      '（一）乙方在行程前30日以內（不含第30日，下同）提出解除合約的，向甲方退還全額旅遊費用（不得扣除簽證／簽證等費用），並按下列標準向甲方支付違約金（二選一）',
    '402969-63': '1.雙方可以進行約定並從其約定。',
    '402969-64': '（1）行程前',
    '402969-65': '日，支付旅遊費用總額',
    '402969-66': '%的違約金',
    '402969-67': '（2）行程前',
    '402969-68': '（3）行程前',
    '402969-69': '（4）行程前',
    '402969-70': '（5）行程開始當日，支付旅遊費用總額',
    '402969-71': '%的違約金。',
    '402969-72': '2.雙方沒有約定的，按照下列標準承擔違約責任。',
    '402969-73': '（1）行程前30日至15日，支付旅遊費用2%的違約金；',
    '402969-74': '（2）行程前14日至7日，支付旅遊費用5%的違約金；',
    '402969-75': '（3）行程前6日至4日，支付旅遊費用10%的違約金；',
    '402969-76': '（4）行程前3日至1日，支付旅遊費用總額15%的違約金；',
    '402969-77': '（5）行程開始當日，支付旅遊費用20%的違約金。',
    '402969-78': '如上述違約金不足以賠償甲方的實際損失，乙方應當按實際損失對甲方予以賠償。',
    '402969-79': '乙方應當在取消出團通知到達日起',
    '402969-80': '個工作日內，向甲方退還全額旅遊費用並支付違約金。',
    '402969-81': '（二）甲方逾期支付旅遊費用的，應當每日按照逾期支付部分旅遊費用的',
    '402969-82': '%，向乙方支付違約金。',
    '402969-83':
      '（三）甲方提供的個人信息及相關材料不真實而造成損失，由其自行承擔； 如給乙方造成損失的，甲方還應當承擔賠償責任。',
    '402969-84':
      '（四）甲方因不聽從乙方的勸告、提示而影響團隊行程，給乙方造成損失的，應當承擔相應的賠償責任。',
    '402969-85':
      '（五）乙方未按合約約定標準提供交通、住宿、餐飲等服務，或者未經甲方同意調整旅遊行程，給甲方造成損失的，責任由乙方承擔。',
    '402969-86':
      '（六）乙方未經甲方同意，擅自將旅遊者轉團、拼團的，甲方在行程前（不含當日）得知的，有權解除合約，乙方全額退還已交旅遊費用，並按旅遊費用的15%支付違約金； 甲方在行程開始當日或者行程開始後得知的，乙方應當按旅遊費用的25%支付違約金。 如違約金不足以賠償甲方的實際損失，乙方應當按實際損失對旅遊者予以賠償。',
    '402969-87':
      '（七）乙方未經與甲方協商一致或者未經甲方要求，指定具體購物場所或安排另行付費旅遊項目的，甲方有權在旅遊行程結束後30日內，要求乙方為其辦理退貨並先行墊付退貨貨款，或者退還另行付費旅遊項目的費用。',
    '402969-88':
      '（八）乙方具備履行條件，經甲方要求仍拒絕履行合約，造成甲方人身損害、滯留等嚴重後果的，甲方除要求乙方承擔相應的賠償責任外，還可以要求乙方支付旅遊費用',
    '402969-89': '倍（一倍以上三倍以下）的賠償金。',
    '402969-90': '（九）其他違約責任：',
    '402969-91': '第九條附則',
    '402969-92': '補充條款',
    '402969-93': '請檢查合約必填項。',
    '402969-94': '合約生成成功',
    '106093-22': '最大值11位數，最多兩位小數',
  },
}

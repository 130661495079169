export default function CommunityManageServer(ajax, config) {
  const { domainName, CommunityManageServer } = config
  return {
    // 枚举值公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    // 公共日志接口
    operationLog: (opt) =>
      ajax({
        url: `/operation-log/list`,
        method: 'GET',
        ...opt,
      }),
    // banner管理
    banner: {
      // 根据参数查询云店广告信息表数据列表
      list: (opt) =>
        ajax({
          url: '/banner/banners',
          method: 'GET',
          ...opt,
        }),
      // 添加云店广告信息表数据
      add: (opt) =>
        ajax({
          url: '/banner/add',
          method: 'POST',
          ...opt,
        }),
      // 修改云店广告信息表数据
      update: (opt) =>
        ajax({
          url: '/banner/update',
          method: 'POST',
          ...opt,
        }),
      // 根据ID查询云店广告信息表数据
      getDetail: (opt) =>
        ajax({
          url: `/banner/detail/${opt.c_id}`,
          method: 'GET',
          ...opt,
        }),
      // 修改云店广告上线/下线状态
      updateStatus: (opt) =>
        ajax({
          url: '/banner/update-banner-status',
          method: 'POST',
          ...opt,
        }),
      // 根据ID删除云店广告信息表数据
      del: (opt) =>
        ajax({
          url: '/banner/delete',
          method: 'POST',
          ...opt,
        }),
    },
    // 产品推荐
    product: {
      // 产品推荐列表查询
      list: (opt) =>
        ajax({
          url: '/product/list',
          method: 'GET',
          ...opt,
        }),
      // 更新产品信息
      update: (opt) =>
        ajax({
          url: '/product/update-information',
          method: 'PATCH',
          ...opt,
        }),
    },
    // 文案审核
    copywriting: {
      // 文案审核列表查询
      list: (opt) =>
        ajax({
          url: '/copywriting/list',
          method: 'GET',
          ...opt,
        }),
      // 更新文案审核状态
      update: (opt) =>
        ajax({
          url: '/copywriting/update-status',
          method: 'PATCH',
          ...opt,
        }),
    },
  }
}

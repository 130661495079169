export default {
  '271453-0': '產品碼',
  '271453-2': '產品名稱',
  '271453-3': '產品狀態',
  '271453-5': '已下架',
  '271453-6': '已上架',
  '271453-7': '目的地城市',
  '271453-9': '請選擇一級品類',
  '271453-10': '跟團遊',
  '271453-11': '自由行',
  '271453-12': '請選擇二級品類',
  '271453-13': '動態打包跟團遊',
  '271453-14': '請輸入數字',
  '271453-15': '動態打包自由行',
  '819196-2': '產品類目',
  '819196-4': '狀態',
  '819196-5': '更新人',
  '819196-6': '更新時間',
  '819196-12': '確定下架該產品？ 下架後該產品類目銷售數據將被統計到全國，但不影響已銷售產品',
  '819196-14': '確定下架',
  '819196-16': '成功',
  '608362-0': '查看產品',
  '608362-2': '產品名稱',
  '608362-3': '到達城市',
  '725421-0': '產品類目',
  '725421-1': '一級品類',
  '725421-2': '二級品類',
  '725421-3': '目的地大類',
  '725421-4': '一級目的地分組',
  '725421-5': '二級目的地分組',
  '725421-6': '目的地名稱',
}

export default function CreditServer(ajax, config) {
  return {
    //获取供应商数据接口
    getVendorInfo: (opt) => {
      return ajax({ url: '/web/vendor/get-vendor-info', method: 'POST', ...opt })
    },
    getLog: (opt) => {
      return ajax({ url: '/web/credit-audit/operation-log/list', method: 'POST', ...opt })
    },
    //新增申请授信单
    saveCredit: (opt) => {
      return ajax({ url: '/web/credit-manage/save-credit', method: 'POST', ...opt })
    },
    listCredits: (opt) => {
      return ajax({ url: '/web/credit-manage/list-credits', method: 'POST', ...opt })
    },
    listCreditChange: (opt) => {
      return ajax({ url: '/web/credit-change/list-credit-change', method: 'POST', ...opt })
    },
    //编辑
    editCredit: (opt) => {
      return ajax({ url: '/web/credit-manage/edit-credit', method: 'POST', ...opt })
    },
    auditApplication: (opt) => {
      return ajax({ url: '/web/credit-audit/audit-application', method: 'POST', ...opt })
    },
    expiryCredit: (opt) => {
      return ajax({ url: '/web/credit-manage/credit-expiry', method: 'POST', ...opt })
    },
    deleteCredit: (opt) => {
      return ajax({ url: '/web/credit-manage/delete-credit', method: 'POST', ...opt })
    },
    getCreditDetail: (opt) => {
      return ajax({ url: '/web/credit-manage/get-credit-detail', method: 'POST', ...opt })
    },
    getCreditList: (opt) => {
      return ajax({ url: '/api/credit-guarantee/list-customer-credit', method: 'POST', ...opt })
    },
    editWarningCredit: (opt) => {
      return ajax({ url: '/web/credit-manage/edit-warning-credit', method: 'POST', ...opt })
    },

    exportCreditChange: (opt) =>
      ajax({
        url: `/web/credit-change/export-credit-change`,
        method: 'post',
        file: true,
        responseType: 'blob',
        ...opt,
      }),

    // 枚举值公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
  }
}

export default function CommissionServer(ajax, config) {
  const { domainName, CommissionServer } = config
  return {
    // 佣金枚举公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    // 查询佣金规则列表
    queryCommissionRulesList: (opt) =>
      ajax({
        url: '/rules',
        method: 'GET',
        ...opt,
      }),
    // 根据佣金规则ID查询日志表数据
    queryCommissionRulesLog: (opt) => {
      const url = `/rules/log/${opt.data}`
      return ajax({
        url,
        method: 'GET',
      })
    },
    // 查询全部类目数据
    queryAllCategories: (opt) =>
      ajax({
        url: '/rules/categorys',
        method: 'GET',
        ...opt,
      }),
    // 获取佣金规则回显数据
    queryCommissionRulesInfo: (opt) => {
      const url = `/rules/${opt.data}`
      return ajax({
        url,
        method: 'GET',
      })
    },
    // 修改佣金规则的状态
    modifyRulesStatus: (opt) =>
      ajax({
        url: '/rules/update-status',
        method: 'PATCH',
        ...opt,
      }),
    // 添加佣金规则
    addRules: (opt) =>
      ajax({
        url: '/rules',
        method: 'POST',
        ...opt,
      }),
    // 修改佣金规则
    modifyRules: (opt) =>
      ajax({
        url: '/rules',
        method: 'PUT',
        ...opt,
      }),

    // 查询佣金账单列表
    queryBillList: (opt) =>
      ajax({
        url: '/bill/list',
        method: 'POST',
        ...opt,
      }),
    // 佣金账单列表-佣金发放
    billTransfer: (opt) =>
      ajax({
        url: '/bill/transfer',
        method: 'POST',
        ...opt,
      }),
    // 佣金账单明细-统计
    billDetailStatistics: (opt) =>
      ajax({
        url: '/bill/detail-statistics',
        method: 'POST',
        ...opt,
      }),
    // 佣金账单明细-明细列表
    billDetailList: (opt) =>
      ajax({
        url: '/bill/detail-list',
        method: 'POST',
        ...opt,
      }),
    // 佣金明细列表查询
    queryWorkFormList: (opt) =>
      ajax({
        url: '/workform/query-list',
        method: 'POST',
        ...opt,
      }),
    // 佣金账单明细导出
    billExportDetail: {
      upload: `${domainName + CommissionServer}/bill/export-detail`,
      download: (opt) =>
        ajax({
          url: `/bill/export-detail`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 订单佣金明细导出
    orderCommissionExport: {
      upload: `${domainName + CommissionServer}/order/commission/export`,
      download: (opt) =>
        ajax({
          url: `/order/commission/export`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 导出人力报税用表
    exportHummusTax: {
      upload: `${domainName + CommissionServer}/bill/export-hummus-tax`,
      download: (opt) =>
        ajax({
          url: `/bill/export-hummus-tax`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 导出付款财务用表
    createPaymentFinance: {
      upload: `${domainName + CommissionServer}/bill/create-payment-finance`,
      download: (opt) =>
        ajax({
          url: `/bill/create-payment-finance`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 导出付款申请用表
    exportPayment: {
      upload: `${domainName + CommissionServer}/bill/export-payment`,
      download: (opt) =>
        ajax({
          url: `/bill/export-payment`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 三段佣金-开始
    // 查询佣金规则列表-按方向查询
    baseCategoryList: (opt) =>
      ajax({
        url: '/base-category/list',
        method: 'GET',
        ...opt,
      }),
    // 查询佣金规则列表-按产品查询
    baseProductList: (opt) =>
      ajax({
        url: '/base-product/list',
        method: 'GET',
        ...opt,
      }),
    // 查询佣金规则列表-按渠道查询
    channelRulesList: (opt) =>
      ajax({
        url: '/channel/rules/list',
        method: 'GET',
        ...opt,
      }),
    // 查询佣金规则列表-佣金发放方式
    grantList: (opt) =>
      ajax({
        url: '/grant/list',
        method: 'GET',
        ...opt,
      }),
    // 添加佣金基数规则信息表数据
    baseCategoryAdd: (opt) =>
      ajax({
        url: '/base-category/add',
        method: 'POST',
        ...opt,
      }),
    // 添加佣金基数规则信息表数据channel/rules/update
    channelAddRules: (opt) =>
      ajax({
        url: '/channel/rules/add',
        method: 'POST',
        ...opt,
      }),
    // 添加佣金基数规则信息表数据channel/rules/update
    updateAddRules: (opt) =>
      ajax({
        url: '/channel/rules/update',
        method: 'POST',
        ...opt,
      }),
    // 根据ID查询佣金基数规则信息表数据
    baeCategory: (opt) => {
      const url = `/base-category/${opt.data}`
      return ajax({
        url,
        method: 'GET',
      })
    },
    // 根据ID查询佣金基数规则信息表数据
    /*channelRules: (opt) => {
      const url = `/channel/rules/${opt.data}`
      return ajax({
        url,
        method: 'GET',
      })
    },*/
    channelRules: (opt) =>
      ajax({
        url: '/channel/rules/find-one',
        method: 'GET',
        ...opt,
      }),
    baseProduct: (opt) => {
      const url = `/base-product/${opt.data}`
      return ajax({
        url,
        method: 'GET',
      })
    },
    grantListByIds: (opt) =>
      ajax({
        url: '/grant/listByIds',
        method: 'POST',
        ...opt,
      }),
    // 方向-修改佣金基数规则信息表数据
    baseCategoryUpdate: (opt) =>
      ajax({
        url: '/base-category/update',
        method: 'POST',
        ...opt,
      }),
    // 方向-修改佣金基数规则信息表数据
    grantUpdate: (opt) =>
      ajax({
        url: '/grant/update',
        method: 'POST',
        ...opt,
      }),
    // 产品-添加佣金基数规则信息表数据
    baseProductAdd: (opt) =>
      ajax({
        url: '/base-product/add',
        method: 'POST',
        ...opt,
      }),
    //修改佣金基数规则信息表数据
    baseProductUpdate: (opt) =>
      ajax({
        url: '/base-product/update',
        method: 'POST',
        ...opt,
      }),
    // 方向-修改佣金基数规则状态
    baseCategoryUpdateStatus: (opt) =>
      ajax({
        url: '/base-category/update-status',
        method: 'POST',
        ...opt,
      }),
    // 方向-修改佣金基数规则状态
    channelRulesUpdateStatus: (opt) =>
      ajax({
        url: '/channel/rules/update-status',
        method: 'POST',
        ...opt,
      }),
    // 产品-修改佣金基数规则状态
    baseProductUpdateStatus: (opt) =>
      ajax({
        url: '/base-product/update-status',
        method: 'POST',
        ...opt,
      }),
    // 根据佣金规则ID查询日志表数据
    operationLogList: (opt) =>
      ajax({
        url: '/operation-log/list',
        method: 'GET',
        ...opt,
      }),
    // 确认工资发放
    confirmSalaryPayment: (opt) =>
      ajax({
        url: '/bill/confirm-salary-payment',
        method: 'POST',
        ...opt,
      }),
    // 三段佣金-结束

    // 单个产品获取预估佣金
    productEstimateCommission: (opt) =>
      ajax({
        url: '/order/commission/product-estimate-commission',
        method: 'POST',
        ...opt,
      }),

    // 单个订单获取预估佣金
    orderEstimateCommission: (opt) =>
      ajax({
        url: '/order/commission/order-estimate-commission',
        method: 'POST',
        ...opt,
      }),

    // 根据用户销售角色id、合作渠道id、终端号生成查询产品佣金条件
    productCommissionConditions: (opt) =>
      ajax({
        url: '/product/commission/conditions',
        method: 'POST',
        ...opt,
      }),
    // 销售账单列表
    salesmanBillList: (opt) =>
      ajax({
        url: '/bill/salesman-bill-list',
        method: 'POST',
        ...opt,
      }),
    // 单个销售账单下佣金明细列表
    salesmanCommissionOrderList: (opt) =>
      ajax({
        url: '/bill/salesman-commission-order-list',
        method: 'POST',
        ...opt,
      }),
    // 订单佣金明细导出
    orderExport: {
      upload: `${domainName + CommissionServer}/bill/salesman-commission-order-export`,
      download: (opt) =>
        ajax({
          url: `/bill/salesman-commission-order-export`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
  }
}

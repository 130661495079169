export default {
  OrderDetail: {
    belongUserName: '客戶歸屬：',
    orderId: '訂單編號',
    copy: '複製',
    channelName: '合作渠道',
    terminalName: '終端',
    orderStatusDesc: '訂單狀態',
    customerType: '客戶類型',
    customerName: '客戶名稱',
    saleCompanyName: '銷售公司',
    saleType: '銷售類型',
    createUserName: '下單人',
    depName: '下單部門',
    dealWithOrderTime: '下單時間',
    followerName: '跟進人',
    followerDepName: '跟進人部門',
    teamNo: '團代號',
    teamName: '團名稱',
    externalCategoryName: '外部合作類目',
    externalOrderId: '外部訂單號',
    shopkeeperRoleName: '店主身份',
    shopkeeperName: '店主姓名',
    firstReleaseTime: '清位時間',
    systemMemo: '系統備忘',
    manuaMemo: '人工備忘',
    add: '添加',
    createMemoUser: '添加人',
    memoContent: '內容',
    remindTime: '下次跟進時間',
    placeholderRemindTime: '請選擇下次跟進時間',
    memoCreateTime: '添加時間',
    operate: '操作',
    save: '保存',
    delete: '删除',
    edit: '編輯',
    confirmDelete: '確定删除備忘？',
    manualAdd: '人工添加',
    placeholderMemoContent: '請填寫備忘內容',
    validRemindTime: '下次跟進時間必須大於當前時間',
    saveMemoTips: '請保存正在編輯的備忘',
    memoOperator: '操作人',
    memoTypeName: '類型',
    memoRemindTime: '提醒時間',
    memoOperateTime: '操作時間',
    beijing: '北京',
    tianjin: '天津',
    hebei: '河北',
    shanxi: '山西',
    neimenggu: '內蒙古',
    liaoning: '遼寧',
    jilin: '吉林',
    heilongjiang: '黑龍江',
    shanghai: '上海',
    jiangsu: '江蘇',
    zhejiang: '浙江',
    anhui: '安徽',
    fujian: '福建',
    jiangxi: '江西',
    shandong: '山東',
    henan: '河南',
    hubei: '湖北',
    hunan: '湖南',
    guangdong: '廣東',
    guangxi: '廣西',
    hainan: '海南',
    chongqing: '重慶',
    sichuan: '四川',
    guizhou: '貴州',
    yunnan: '雲南',
    xizang: '西藏',
    sanxi: '陝西',
    gansu: '甘肅',
    qinghai: '青海',
    ningxia: '寧夏',
    xinjiang: '新疆',
    taiwan: '臺灣',
    hongkong: '香港',
    macao: '澳門',
    abroad: '國外',
    validLength: '校驗長度，類型',
    validProvince: '檢查省份',
    validBirthday: '校驗生日',
    contactor: '連絡人',
    name: '姓名',
    phone: '手機',
    address: '聯繫地址',
    defaultContactor: '默認連絡人',
    yep: '是',
    nope: '否',
    inviteRegister: '邀請注册',
    contactorListTips: '《聯繫人列表》正在監聽訂單的基本信息中訂單狀態',
    validEmail: '請正確填寫郵箱',
    placeholderDistrict: '請選擇省市區',
    placeholderAddress: '請填寫詳細地址',
    sendSuccess: '發送成功',
    requestContactorFailed: '請求連絡人失敗…',
    error: '錯誤',
    saveEditContactorTips: '請先保存正在編輯的連絡人',
    defaultContactorConfirm:
      '默認連絡人同時也是訂單會員，負責接收訂單簡訊和前往中旅旅行小程式進行簽約付款。 確認是否保存。',
    validSelectedRequired: '至少選擇一行數據',
    validSelectedAll: '不可全部選中，至少保留一條數據',
    deleteDefaultContactorTips: '默認連絡人不可删除',
    addOrEditTourists: '新增/編輯出遊人',
    touristsTips: '環影產品出遊人填寫注意事項：',
    touristsAgeTips:
      '1）門票：標準票|年齡12周歲（含）至64周歲（含），兒童票|年齡11周歲（含）以下，老人票|65周歲及以上；',
    touristsHotelTips: '2）酒店：每個房間只需填1個入住人，姓名、手機號碼必須輸入；',
    touristsCount: '出遊人數',
    touristsAdult: '大',
    touristsKid: '小',
    touristsMember: '會員常旅',
    collapse: '收起',
    all: '全部',
    addLately: '最近新增',
    touristValidTips: '此出遊人被綁定到資源，不可删除，姓名/證件號/手機號不可編輯',
    empty: '清空',
    touristType: '類型',
    pleaseChoose: '請選擇',
    certNo: '證件號',
    placeholderCertNo: '請輸入證件號',
    certType: '證件類型',
    issueInfo: '簽發地',
    placeholderIssueInfo: '請輸入簽發地',
    certValidityDate: '有效期',
    placeholderCertValidityDate: '請選擇有效期',
    healthCondition: '健康狀況',
    crowdType: '人員類型',
    pleaseCrowdType: '請選擇人員類型',
    remark: '備註',
    addTourists: '新增出遊人',
    healthy: '健康',
    other: '其他',
    adult: '成人',
    kid: '兒童',
    tourists: '出遊人',
    unknow: '未知',
    validFilled: '均已填寫，不可新增',
    validTwoYears: '不滿2周歲，請修改證件號碼或更換',
    validCerNoUnique: '的證件號碼不能重複，請修改',
    validTouristRequired: '請至少添加一個出遊人',
    validName: '請輸入正確的姓名',
    validCertType: '請選擇正確的類型',
    validPhone: '請輸入正確手機號',
    validCertNo: '請輸入正確的證件號',
    validHealth: '請輸入正確的健康狀況',
    downloadTemplate: '下載範本',
    multiUpload: '批量上傳',
    addOrEdit: '新增/編輯',
    nonMembers: '非會員',
    insurance: '需買保險',
    type: '類型',
    issueName: '簽發地',
    age: '年齡',
    birthday: '出生日期',
    sex: '性別',
    male: '男',
    female: '女',
    inviteMember: '邀請成為會員',
    isMember: '已成為會員',
    fileTypeTips: '只能上傳xls、xlsx文件',
    fileSizeTips: '文件大小超過1M',
    proOrderInfo: '產品方信息',
    saleOrderInfo: '分銷方信息',
    productCompany: '提供該產品的公司',
    saleCompany: '銷售（分銷）該產品的公司',
    memoInfo: '備忘信息',
    touristsInfo: '遊客信息',
    travelDemand: '出遊需求',
    marketingAct: '行銷活動',
    financeInfo: '款項信息',
    contractInfo: '簽約信息',
    contractTips: '簽約完成後，訂單便不會再清位，請儘快完成收款簽約動作。',
    journeyInform: '出遊通知',
    informTips: '如已線下發送或無需發送出遊通知，可直接點擊下方的【完成出遊通知發送】完成本步驟',
    baseInfo: '基本信息',
    order: '下單',
    demand: '需求確認',
    sign: '簽約收款',
    resourceConfirm: '資源確認',
    tour: '出遊',
    result: '結果：',
    resourceFailConfirm: '【確認失敗】狀態的資源，均已跟供應商&客人溝通並處理，確定繼續操作？',
    confirm: '確定',
    cancel: '取消',
    overleapSend: '確定出遊通知已發送給客人，或無需發送出遊通知，跳過此步驟',
    returnTips: '返回需求確認，確定繼續操作？',
    limited: '您無權查看此訂單',
  },
}

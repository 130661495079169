export const rsaOption = { alg: 'SHA256withRSA' }

export const begin = 'begin'
export const end = 'end'
export const split = '-'
export const privateKey = 'private'
export const publicKey = 'public'

export const publicArray = [
  'TUlJQklqQU5CZ2tx',
  'GtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUF4NnVkeU1PbDB4TUszWEZydlQ5TAoxTm83bEI3SStFRHMreDlsNFpYWXBvSTlJR3ZucWxHVW54WVpVTkxTUmtLN2VM',
  'FFyTjAvdXUwM0pxVE0vSkxmCkJDeFhTMXovelgvYUFhTGxJNVZvWTdsQ2N0',
  'zk1RDF',
  'MC9HYk9NTWpnekRJYklyYWZEUks1Z1JYVElqU2NlbTkKNDhnci91SUhFNmJPOXgvZzZtK0tsWm1vdis1SnZJeVkzbGl',
  'K05sT09mYndiQzFYUWhzVGhJcDNYdGNr',
  'TNZegoyMUpEUnd0Z2VHeFA5L1dPMDlYRUdxN0Zx',
  'HU4',
  '1FyUk1Y',
  '1g5bzd1bHhRYTVmeDI3Z0xwRHRSbmxmR2Vz',
  'nVwCjdMN1RjZHcxODVHZStDT3JLTmFPdW5xeUJDRzhlY3JXWG9DT0ZOQWdLcC9NU1kyOWJSZUxVYUl4bXpWRnNTWGUKdFFJREFRQUI=',
]

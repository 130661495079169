export default function (ajax, config) {
  //   const { domainName, FinancialSys } = config
  return {
    getGuideDetail: (opt) =>
      ajax({
        url: '/guide/detail',
        method: 'get',
        ...opt,
      }),
    getGuideList: (opt) =>
      ajax({
        url: '/guide/list',
        method: 'get',
        ...opt,
      }),
  }
}

/**
 * 不需要加解密的后端服务汇总配置表
 * 网关层接口也不加密
 */
export default [
  'cfs',
  'file-preview',
  '/login',
  '/logout',
  '/token/open-api/old-user',
  '/token/refresh/org-company',
]

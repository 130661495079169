import Vue from 'vue'
import Roles from '@/common/const/Roles.js'
const localStore = Vue.observable({
  applicableRole: Roles.applicableRole,
  // 菜单树的类型
  menuType: {
    menu: 1, // 菜单类型
    button: 2, // 按钮类型
  },
})

export { localStore }

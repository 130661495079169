// 接口和页面初始化配置中心
/*原则：
 * 在前置配置之前，需要搞清楚后端微服务前缀路由是什么，然后再配置到该文件下面
 *微服务名称规则为大驼峰命名格式，微服务要保证唯一，多个分支情况下需要进行统一名称进行建立
 * */
// 各种环境情况
export const environment = {
  development: 'development', // 开发
  staging: 'staging', // 预发布测试
  sst: 'sst', // 开发测试
  production: 'production', // 生产
}

export const nodeEnv = process.env.VUE_APP_NODE_ENV
export const isDevelopment = nodeEnv === 'development'

export const gateway = nodeEnv === 'development' ? '/services' : '/nbs'
let service = {
  domainName: '', // 主域名
  wikiRequestUrl: '', // 知识库插件请求地址
  wikeOpenUrl: '', // 知识库跳转地址
  nfsBaseUrl: '', // 文件服务拼接地址
  PreviewH5Url: '', // h5预览地址
  PreviewPC: '', // pc站地址

  gateway, // nbs是流量网关前缀,后面的才是微服务后端代码前缀

  CategoryServer: gateway + '/category-server', // 类目系统
  IntentionServer: gateway + '/intention-server/intention', // 订单意向单
  IntentionTemplateServer: gateway + '/intention-server/intentiontemplate', // 意向单模板
  OrderServer: gateway + '/order-server', // 订单服务
  ProductServer: gateway + '/product-server', // 订单服务
  BaseServer: gateway + '/base-server', // 公共服务
  Wiki: gateway + '/kbr-server', // 知识库
  ConfirmServer: gateway + '/confirm-server', // 确认管理服务
  AuthServer: gateway + '/auth-server', // 修改密码用到
  AdapterServer: gateway + '/adapter-server', // 适配系统
  CommissionServer: gateway + '/commission-server', // 佣金系统
  PoiServer: gateway + '/poi-server', // poi系统
  VendorServer: gateway + '/vendor-server', // 供应商中心
  PricingCenter: gateway + '/pricing-center', // 价格中心
  Cfs: gateway + '/cfs', // 文件服务器
  ResourceServer: gateway + '/resource-server', // 资源系统
  JourneyServer: gateway + '/journey-server',
  ProductCenterServer: gateway + '/product-center', // 产品中心
  OrderCenterServer: gateway + '/order-center', // 订单中心
  PosterServer: gateway + '/poster-server', // 海报系统
  FinancialSys: gateway + '/finance-server', // 财务系统
  FinancialAdapterServer: gateway + '/finance-adapter', // 财务适配系统
  CommunityManageServer: gateway + '/community-manage', // 云店内容管理服务
  ResourceCenterServer: gateway + '/resource-center', // 资源中心
  BaseChannelServer: gateway + '/base-channel', // 资源中心
  FilePreview: gateway + '/file-preview', // 文件预览配置
  PmsServer: gateway + '/pms-server', //促销系统
  CreditServer: gateway + '/credit-server', //授信系统
  BaseFlight: gateway + '/base-flight', // 基础机票
  MobMangeServer: gateway + '/mob-manage', // 小程序运营系统
  GuideServer: gateway + '/guide-server', //导游
  RouterServer: gateway + '/router-server', // nbs转发系统，包含供应商系统的转发
  HomeManage: gateway + '/home-manage',
  CommunityShopKeeperServer: gateway + '/community-shopkeeper', // 云店店主系统
  VisaServer: gateway + '/visa-server', // 签证系统
  HotelManage: gateway + '/hotel-manage', // 酒店中心
  InventoryServer: gateway + '/stock-server', // 库存中心，历史原因名称和服务不对应
  StockServer: gateway + '/stock-server', // 库存
  MemberServer: gateway + '/member-server', // 会员服务
  ProcessServer: gateway + '/process-server', // 流程
  SearchHotelServer: gateway + '/search-hotel', // 搜索酒店
}

switch (nodeEnv) {
  // 开发, 本地开发走vue代理
  case environment.development: {
    service = {
      ...service,
      domainName: '',
      wikiRequestUrl: 'https://sst-private-api.ctshk.com',
      wikeOpenUrl: 'https://sst-nbs.ctshk.com',
      nfsBaseUrl: 'https://sst-nbs.ctshk.com',
      // PreviewH5Url: 'https://sst-wap.ctshk.com',
      PreviewH5Url: 'https://sit-wap.ctshk.com',

      // PreviewH5Url: 'https://10.118.10.238:8080',
      PreviewPC: 'http://sst-ww2.ctshk.com',
    }
    break
  }
  // 测试环境
  case environment.staging: {
    service = {
      ...service,
      domainName: 'https://sit-private-api.ctshk.com',
      wikiRequestUrl: 'https://sit-private-api.ctshk.com',
      wikeOpenUrl: 'https://sit-nbs.ctshk.com',
      nfsBaseUrl: 'https://sit-nbs.ctshk.com',
      PreviewH5Url: 'https://sit-wap.ctshk.com',
      PreviewPC: 'http://sit-ww2.ctshk.com',
    }
    break
  }
  // 开发联调环境
  case environment.sst: {
    service = {
      ...service,
      domainName: 'https://sst-private-api.ctshk.com',
      wikiRequestUrl: 'https://sst-private-api.ctshk.com',
      wikeOpenUrl: 'https://sst-nbs.ctshk.com',
      nfsBaseUrl: 'https://sst-nbs.ctshk.com',
      PreviewH5Url: 'https://sst-wap.ctshk.com',
      PreviewPC: 'http://sst-ww2.ctshk.com',
    }
    break
  }
  // 生产
  case environment.production: {
    service = {
      ...service,
      domainName: 'https://private-api.ctshk.com',
      wikiRequestUrl: 'https://private-api.ctshk.com',
      wikeOpenUrl: 'http://nbs.ctshk.com',
      nfsBaseUrl: 'https://nbs.ctshk.com',
      PreviewH5Url: 'https://wap.ctshk.com',
      PreviewPC: 'https://ww2.ctshk.com',
    }
    break
  }
}
export default service

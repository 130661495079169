<template>
  <dialog-tem-one
    :title="fileName ? fileName : i18n.t('common.文件预览')"
    :width="dialogW"
    top="4vh"
    v-model="show"
    class="preview-file"
    @close="onClose"
    :close-on-click-modal="true"
  >
    <iframe
      v-if="show"
      class="preview-file"
      :src="finFileUrl"
      :width="iframeHW.width"
      :height="iframeHW.height"
    ></iframe>
  </dialog-tem-one>
</template>

<script>
import { isSplicingImgPrefix } from '@/utils'
import DialogTemOne from '@/components/DialogTem/one.vue'
import { encode } from 'js-base64'
import { throttle } from '@/utils/antiShakingAndThrottling.js'
import services from '@/services'
import { i18n } from '@/i18n'
import { blobUrlRegex } from '@/utils/validate'
export default {
  name: 'PreviewFile',
  components: { DialogTemOne },
  data() {
    return {
      fileUrl: '',
      fileName: '',
      show: false,
      dialogW: '500px',
      iframeHW: {
        height: '500px',
        width: '500px',
      },
      i18n,
    }
  },
  computed: {
    finFileUrl() {
      if (blobUrlRegex.test(this.fileUrl)) return this.fileUrl
      const url = isSplicingImgPrefix(this.fileUrl)
      // 临时修改
      // return (
      //   'https://sit-nbs.cits.com.cn/file-preview/onlinePreview?url=' +
      //   encodeURIComponent(encode(url))
      // )
      console.log(
        services.FilePreview.onlinePreview + encodeURIComponent(encode(url)),
        'services.FilePreview.onlinePreview + encodeURIComponent(encode(url))',
      )
      return services.FilePreview.onlinePreview + encodeURIComponent(encode(url))
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getWH()
        }
      },
    },
  },
  created() {
    window.addEventListener('resize', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onScroll)
  },
  methods: {
    onScroll: throttle(function () {
      this.getWH()
    }),
    getWH() {
      const { clientHeight, clientWidth } = document.documentElement
      const dialogW = clientWidth * 0.8
      this.dialogW = dialogW + 'px'
      this.iframeHW = {
        height: clientHeight * 0.85 + 'px',
        width: dialogW - 40 + 'px',
      }
      // console.log(this.dialogW, this.iframeHW)
    },
    onClose() {
      this.show = false
      this.$mount()
    },
  },
}
</script>

<style scoped lang="scss">
.preview-file {
  border: none;
}
</style>

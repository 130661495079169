export default function ProcessServer(ajax, config) {
  return {
    getProcess: (opt) =>
      ajax({
        url: '/preview/process-by-common',
        method: 'get',
        ...opt,
      }),
  }

  // preview/process-by-common
}

export default {
  orderId: '訂單號',
  externalOrderId: '外部訂單號',
  createUserName: '下單人',
  createTime: '下單時間',
  followerName: '跟進人',
  orderStatus: '訂單狀態',
  contacterName: '聯絡人',
  touristName: '出遊人',
  touristTip: '已關聯出遊人數量/需關聯出遊人數量',
  channel: '渠道',
  productPoiInfos: '景點',
  useDate: '使用日期',
  productCode: '產品碼',
  productName: '產品名稱',
  productOwner: '產品負責人',
  vendor: '供應商',
  departureDate: '出遊日期|出發日期|出發/使用日期',
  shopkeeperRole: '店主身份',
  shopkeeperRoleName: '店主姓名',
  customer: '客戶',
  customerNo: '客戶代碼',
  customerName: '客戶名稱',
  customerLevel: '客戶等級',
  selectCustomer: '選擇客戶',
  salesman: '銷售員：',
  saleType: '銷售類型',
  saleCompanyName: '銷售公司',
  teamNo: '團代號',
  teamName: '團名稱',
  name: '姓名',
  nickName: '稱謂',
  chineseName: '中文名',
  familyName: '英文姓',
  firstName: '英文名',
  mobilePhone: '手機',
  telephone: '電話',
  email: '電郵',
  defaultContact: '默認連絡人',
  count: '數量',
  preTotalPrice: '預計總價',
  productClassName: '品類',
  product: '產品',
  bookingInstructions: '預訂須知',
  dateCategory: '日期類別',
  needCustomerName: '需要客名',
  minLimit: '最小限購',
  maxLimit: '最多限購',
  canRefundTicket: '是否可退',
  ticketCollectionMethod: '取票方式',
  ticketCollectionTime: '換票時間',
  ticketCollectionPlace: '換票地點',
  resource: '資源',
  price: '價格',
  admissionTicket: '門票',
  season: '場次',
  unitPrice: '單價',
  totalPrice: '總價 | 資源總額',
  activityName: '活動名稱',
  activityDesc: '活動說明',
  activityTime: '活動時間',
  discount: '優惠',
  discountAmount: '優惠金額',
  discountTotalAmount: '優惠總額',
  receivableTotalAmount: '應收總額',
  pleaseInputEnFirstName: '請輸入英文名',
  pleaseInputEnLastName: '請輸入英文姓',
  pleaseInputEmail: '請輸入電郵',
  pleaseInputRightEmail: '請輸入正確的電郵',
  pleaseInputPhone: '請輸入電話',
  pleaseInputEnName: '請輸入英文姓名',
  choose: '選擇',
  join: '參加',
  remain: '餘',
  rise: '起',
  calendarTips:
    '餘位需二次確認，發起占位後需等待資源負責人確認實时價格和庫存餘位後，再通知客人支付',
  changeProductTips:
    '每次只能選擇一個產品中的資源，更換產品後，原已選中的資源會被清空，確定繼續操作？',
  changeDepartureDateTips:
    '更換出發/使用日期後，已選中的資源價格會更新，其中無報價會被清空，確定繼續操作？',
  need: '需要',
  needNot: '無需',
  refundable: '可退',
  notRefundable: '不退',
  specific: '特定',
  notSpecific: '非特定',
  selectMember: '選擇會員',
  memberId: '會員編號',
  changeSeasonTips: '每次只能選擇一個場次的資源，變更場次後，已選中的資源會被清空，確定繼續操作？',
  pleaseChooseBranch: '請選擇取票點',
  productClass: {
    ticket: '門券',
    hotel: '酒店',
    freeTravel: '自由行',
  },
  freeTravel: {
    dialogTitle: '添加/变更產品資源',
    firstStep: '第1步 | 選擇產品',
    secondStep: '第2步 | 選擇資源',
    total: '共找到{0}個產品',
    back: '返回產品列表',
    resourceTips: '資源只能任選一項',
    changeProduct: '變更產品',
    changeResource: '變更資源',
    addResource: '添加資源',
    selectResourceTips: '資源為任選一項，只能繼續添加已選資源',
  },
  hotel: {
    dialogTitle: '添加/變更酒店房型',
    changeHotel: '變更酒店',
    changeRoom: '變更房型',
    star: '酒店星级',
  },
  changeRoom: '更換房型',
  empty: '空',
  noContactTips: '請添加聯絡人',
  productDesc: '銷售須知',
  maxOccupancy: '可住{0}人',
  checkInOutDate: '入離日期',
  checkIn: '入住',
  checkout: '離店',
  totalNights: '共{0}晚',
  night: '晚',
  room: '間',
  relation: '關聯',
  keyWord: '關鍵詞',
}

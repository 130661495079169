const basePath = '/resource-sys/'
export default [
  {
    path: basePath + 'ticket-inventory',
    name: basePath + 'ticket-inventory',
    component: () => import('@/views/resourceSys/TicketInventoryKanban/index.vue'),
    meta: { title: '机票库存' },
  },
  {
    path: basePath + 'passenger-ticket-resource',
    name: basePath + 'passenger-ticket-resource',
    component: () => import('@/views/resourceSys/PassengerTicketResource/index.vue'),
    meta: { title: '团票资源' },
  },
  {
    path: '/grounding/resource-audit',
    name: '/grounding/resource-audit',
    component: () => import('@/views/resourceSys/ResourceAudit/index.vue'),
    meta: { title: '资源审核' },
  },
  {
    path: '/grounding/group-resource-list',
    name: '/grounding/group-resource-list',
    component: () => import('@/views/resourceSys/GroupResourceList/index.vue'),
    meta: { title: '资源列表' },
  },
  {
    path: '/grounding/group-resource-list/edit',
    name: '/grounding/group-resource-list/edit',
    component: () => import('@/views/resourceSys/GroupResourceListEdit/index.vue'),
    meta: { title: '资源编辑' },
  },
  {
    path: '/grounding/resource-info',
    name: '/grounding/resource-info',
    component: () => import('@/views/resourceSys/ResourceInfo/index.vue'),
    meta: { title: '资源详情' },
  },
]

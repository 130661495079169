// 价格中心
import PriceRule from './PriceRule'
import CommissionSys from './CommissionSys' //佣金管理
import RoleMange from './RoleMange' //角色管理
import Order from './Order' //订单系统
import OrderSys from './OrderSys'
import PublicSys from './PublicSys' //订单系统
import FinancialSys from './FinancialSys.js' // 财务平台
import ProductSys from './ProductSys' // 产品平台
import credit from './credit' // 产品平台
import BaseDataCompany from './BaseDataCompany.json' // 产品平台
import BaseDataStore from './BaseDataStore.json' // 门市
import AppletOperate from './AppletOperate'
import Confirm from './Confirm' //确管系统
import ApprovalRule from './ApprovalRule.js' // 审批流管理-审批规则
import OrgConfig from './OrgConfig.js' // 配置中心-组织基础配置
import ResourceSys from './ResourceSys' // 资源中心
import Wiki from './Wiki' // 知识库存储
import WikiSearch from './WikiSearch' // 知识库搜索
import Login from './login' // 登录
import abnormalStatistical from './abnormalStatistical' // 系统监控
import UserAndOrg from './UserAndOrg' // 用户与组织
import collection from './collection' // 订单收款
import SysMenuSet from './SysMenuSet' // 菜单列表
import stockSys from './stockSys'
import SalesReport from './salesReport'
import HomePageSys from './homePageSys' //首页
import EntrustedTicket from './EntrustedTicket' // 委托订票单

export default {
  PriceRule,
  ...PriceRule,
  CommissionSys,
  ...CommissionSys,
  RoleMange,
  Order: {
    ...Order,
  },
  ...Order,
  OrderSys,
  PublicSys,
  FinancialSys,
  ProductSys,
  credit,
  ...BaseDataCompany,
  ...BaseDataStore,
  ...ProductSys,
  Confirm,
  ...Confirm,
  ...PublicSys,
  ...AppletOperate,
  ApprovalRule,
  OrgConfig,
  ResourceSys,
  Wiki,
  WikiSearch,
  Login,
  abnormalStatistical,
  UserAndOrg,
  ...collection,
  SysMenuSet,
  SalesReport,
  stockSys,
  HomePageSys,
  EntrustedTicket,
}

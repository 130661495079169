export default {
  warehousingOrder: '入庫單',
  outboundOrder: '出庫單',
  orderId: '關聯訂單',
  quantity: '數量',
  roundType: '入庫類型',
  selectGroupPeriod: '可銷售日期',
  receiptNo: '入庫單號',
  outCode: '出庫單號',
  outType: '出庫類型',
  outStatus: '出庫單狀態',
  batchList: '批次列表',
  rules: {
    resourceId: '資源編號只能輸入數字',
    daterange: '請選擇可銷售日期',
  },
}

import Vuex from '@/store'
import services from '@/services'

// 获取服务器数据并且更新缓存数据
export async function userInfoService() {
  try {
    if (!Vuex || !Vuex.state.user.userInfo.id) return Promise.resolve({})
    const info = await services.AuthServer.user.loginInfo({
      data: { id: Vuex.state.user.userInfo.id },
    })
    // console.
    Vuex.commit('set_data', {
      userOrgInfo: info,
    })
  } catch (e) {
    return Promise.reject(e)
  }
}
// 获取用户信息
export async function getUserInfo() {
  try {
    const userOrgInfo = Vuex.state.userOrgInfo
    if (userOrgInfo) return Promise.resolve(userOrgInfo)
    else return await userInfoService()
  } catch (e) {
    return Promise.reject(e)
  }
}

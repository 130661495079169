export default [
  {
    path: '/grounding/inventory-kanban',
    name: '/grounding/inventory-kanban',
    component: () => import('@/views/stockSys/InventoryKanban/index.vue'),
    meta: { title: '库存看板' },
  },
  {
    path: '/grounding/in-out-library-record',
    name: '/grounding/in-out-library-record',
    component: () => import('@/views/stockSys/InOutLibraryRecord/index.vue'),
    meta: { title: '出入库记录' },
  },
  {
    path: '/grounding/godown-entry-detail',
    name: '/grounding/godown-entry-detail',
    component: () => import('@/views/stockSys/InOutLibraryRecord/godownEntryDetail.vue'),
    meta: { title: '入库单详情' },
  },
  {
    path: '/grounding/outbound-order-detail',
    name: '/grounding/outbound-order-detail',
    component: () => import('@/views/stockSys/InOutLibraryRecord/outboundOrderDetail.vue'),
    meta: { title: '出库单详情' },
  },
]

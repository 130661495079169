import assloss from './assloss'
import travelVoucger from './travelVoucger'
import occupy from './occupy'
import confirm from './confirm'
import words from './words'

export default {
  assloss,
  travelVoucger,
  occupy,
  confirm,
  ...words,
}

const basePath = '/public-sys/'
export default [
  {
    path: basePath + 'announcement-list',
    name: basePath + 'announcement-list',
    component: () => import('@/views/publicSys/contentManage/announcementList/index.vue'),
    meta: { title: '公告列表' },
  },
  {
    // 首页查看的公告列表
    path: '/announcements',
    name: '/announcements',
    component: () => import('@/views/homepage/anouncementList.vue'),
    meta: { title: '公告列表' },
    // children:[{

    // }]
  },
  {
    path: '/announcement-view',
    name: '/announcement-view',
    component: () => import('@/views/publicSys/contentManage/announcementList/view.vue'),
    meta: {
      title: '公告列表 / 公告详情',
    },
  },
  {
    path: basePath + 'announcement-add',
    name: basePath + 'announcement-add',
    component: () => import('@/views/publicSys/contentManage/announcementList/edit/index.vue'),
    meta: {
      title: '新增公告',
    },
  },
  {
    path: basePath + 'announcement-edit',
    name: basePath + 'announcement-edit',
    component: () => import('@/views/publicSys/contentManage/announcementList/edit/index.vue'),
    meta: {
      title: '编辑公告',
    },
  },
]

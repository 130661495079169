// 注意为全小写字符
import { toJSon } from '@/common/tool'

// 注意全部转为小写，语言目录和该枚举名称保持一致
export const Lang = {
  zhCn: 'zh-cn', // 简体中文
  zhHk: 'zh-hk', // 繁体中文
  zhTw: 'zh-tw',
  en: 'en', // 美国英语
}

export function LangEnum() {
  const list = [
    { value: Lang.zhCn, label: '简体中文' },
    { value: Lang.zhHk, label: '繁體中文' },
    //{ value: Lang.en, label: 'English' },
  ]
  const keyJson = toJSon(list)
  return {
    list,
    keyJson,
  }
}

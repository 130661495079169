export default {
  direction: {
    ruleId: '規則ID',
    placeholderRuleId: '輸入規則ID',
    productCategory: '產品類目',
    placeholderSaleType: '請選擇銷售模式',
    firstProductClassId: '一級產品目錄不能為空',
  },
  product: {
    placeholderProductId: '請輸入產品編碼',
    productName: '產品名稱',
    placeholderProductName: '請輸入產品名稱',
    lookProductName: '査詢產品名稱',
    departureDates: '適用團期範圍',
    requiredProductId: '產品編碼必須輸入',
    pleaseRequiredProductId: '請先輸入產品編碼',
  },
  paymentMethod: {
    consultType: '合作渠道',
    state: '狀態',
    method: '發放方式',
    salesmanRoleName: 'NBS用户身份/云店店主身份',
    selectMethod: '發放方式選擇',
    validatorRatio: '請輸入0-100的自然數，包括0和100',
    companyNotEmpty: '公司不能為空',
    departmentIdNotEmpty: '部處名稱不能為空',
    paymentMethodNotEmpty: '發放方式不能為空',
    methodNotEmpty: '所有發放方式都不可以為空',
    editMethod: '編輯發放方式',
    selectEditMethod: '請勾選需要編輯的發放方式',
  },
  unit: {},
  common: {
    addRule: '添加規則',
    editRule: '編輯規則',
    abandonContent: '廢棄該規則後，所有涉及到的類目將不能獲得傭金，是否確認廢棄？',
    abandonSuccess: '廢棄成功',
    ruleStatus: '規則狀態',
    productId: '產品編碼',
    calculateType: '計算方式',
    placeholderCalculateType: '按銷售毛利比例',
    proportion: '比例',
    placeholderProportion: '請輸入0到100的數字,最多兩位小數',
    ratio: '金額',
    placeholderRatio: '請輸入自然數，最多兩位小數',
    billCycleTime: '有效期',
    selectProportion: '請輸入傭金計算比例',
    selectCalculateType: '請選擇計算方式',
    selectRatio: '請輸入傭金計算金額',
    selectTime: '請選擇有效期',
    selectDepartureDates: '請選擇適用團期',
    selectCompanyId: '請選擇採購公司',
    searchProductName: '請査詢產品名稱',
    salesmanRoleName: 'NBS用戶身份/雲店店主身份',
  },
  channel: {
    confirmRuleInfo: '廢棄該規則後，所有涉及到的類目將不能獲得傭金，是否確認廢棄？',
    commissionProportion: '傭金比例',
    addRuleInfoOne: '輸入框中輸入0到100的數字，最多兩位小數',
    addRuleInfoTwo: '銷售自己公司產品時，銷售傭金按照之前設定規則作為傭金基數，',
    addRuleInfoThree: '分銷別家公司產品時，銷售傭金按照分銷產品的（銷售價-內部結算價）作為傭金基數',
    oneself: '本人',
    superior: '上級',
    placeholderNumber: '請輸入',
    ruleErrorInfo: '請輸入0至100的數，最多兩位小數',
    errorChannelNoEmpty: '一級渠道不能為空',
    errorCommissionData: '傭金比例最少填寫一條完整數據',
    errorOrderOrDocSums: '下單傭金比例+跟單傭金比例應<=100',
    errorProportionComplete: '請把填寫傭金比例的這行數據填寫完整',
  },
}

// 转为json,label,value格式
export const toJSon = (arr) => {
  const newJSon = {}
  for (let i = 0; i < arr.length; i++) {
    newJSon[arr[i].value] = arr[i].label
  }
  return newJSon
}
// 转为json,code,name格式
export const toJSonCode = (arr) => {
  const newJSon = {}
  for (let i = 0; i < arr.length; i++) {
    newJSon[arr[i].code] = arr[i].name
  }
  return newJSon
}

export const toValLabList = (arr, isCodeNumber = false) => {
  if (!arr) return []

  const newList = []

  for (let i = 0; i < arr.length; i++) {
    newList.push({
      label: arr[i].name,
      value: isCodeNumber ? Number(arr[i].code) : arr[i].code,
    })
  }
  return newList
}

// 服务汇总

import PricingCenter from './PricingCenter' // 价格中心服务
import CategoryServer from './CategoryServer' // 类目服务
import PoiServer from './PoiServer' // poi服务数据
import FinanceServer from './FinanceServer' // 财务服务数据
import OrderServer from './OrderServer' // 订单服务数据
import ConfirmServer from './ConfirmServer' // 确认管理服务数据
import ProductServer from './ProductServer' // 产品服务数据
import CommissionServer from './CommissionServer' // 佣金模块
import ResourceServer from './ResourceServer'
import StockServer from './StockServer'
export default {
  PricingCenter,
  CategoryServer,
  PoiServer,
  FinanceServer,
  OrderServer,
  ConfirmServer,
  ProductServer,
  CommissionServer,
  ResourceServer,
  StockServer,
}

export default {
  left: {
    org: '組織架構',
    closeUser: '已關閉用戶',
    keyword: '請輸入關鍵字查找',
    tips: '删除該組織後，該組織將不能被用來關聯用戶，是否確認删除？',
    confirmText: '確認删除',
    orgCompanyId: '架構公司名稱',
    parentId: '上級組織',
    relatedId: '關聯門市',
    name: '名稱',
    owner: '負責人',
    shopType: '門市類型',
    source: '組織來源',
    relatedWxDepartment: '關聯企微組織',
    cooperationChannelIds: '歸屬渠道',
    placeholder: '請先選擇架構公司',
    organization: '組織',
    rules: {
      parentId: '請選擇上級組織',
      orgCompanyId: '請先選擇架構公司',
      departmentName: '請輸入名稱',
      repeat: '組織名稱重複',
      source: '請選擇組織來源',
      shopType: '請選擇門市類型',
      relatedId: '請選擇關聯公司',
      relatedWxDepartment: '請選擇關聯企微組織',
      cooperationChannelIds: {
        1: '請選擇渠道',
        2: '請選擇二級渠道',
        3: '請選擇三級渠道',
      },
    },
    departmentNo: '碼',
  },
  right: {
    log: '日誌',
    phone: '手機號',
    onlyNoAuthorize: '僅查看未授權用戶',
    addUser: '新增用戶',
    export: '批量導入',
    auth: '批量授權',
    realName: '用戶姓名',
    principal: '登入賬號',
    method: '聯繫方式',
    userType: '用戶類型',
    leaderName: '上級領導',
    roleDesc: '角色',
    rankLevelList: '職級',
    lastLoginTime: '最後登錄時間',
    userError: '請選擇同用戶類型數據',
    userMsg: '請選擇用戶',
    closeUser: '關閉用戶',
    closeType: {
      1: '直接關閉',
      2: '定時關閉',
      name: '關閉類型',
    },
    closeDate: '關閉時間',
    date: '選擇日期',
    rules: {
      closeDate: '請選擇關閉時間',
    },
    tips: '用戶將在{date}零點關閉',
    userNo: '用戶碼',
  },
  modal: {
    role: '關聯角色',
    replace: '勾選即替換用戶原有角色',
    auth: '授權',
    import: {
      downloadOrg: '下載組織數據',
      template: '導入範本',
      tips: '請根據提示信息填寫表格內容',
      downloadTemplate: '下載範本',
      limit: '只能上傳xls、xlsx文件，且不超過2M',
      selectFile: '選擇文件',
      system: '導入系統',
      len: '每次只能上傳一個文件',
      fileType: '只能上傳xls、xlsx文件',
      fileSize: '文件大小超過2M',
      select: {
        org: '請先選擇組織',
        file: '請先選擇文件',
      },
    },
    add: {
      userType: '用戶身份',
      wxUserId: '企微賬號',
      email: '郵箱',
      mainPostList: '主崗組織',
      deputyPostList: '兼崗組織',
      user: '用戶',
      rules: {
        name: '請輸入姓名',
        princal: '請填寫登入賬號',
        char: '請輸入英文、數字和字符',
        userType: '請選擇用戶類型',
        wxUserId: '請填寫企微賬號',
        rankLevelList: '請選擇職級',
        phone: '請填寫手機號',
        checkPhone: '請輸入正確的手機號',
        email: '請輸入正確的郵箱地址',
        mainPostList: '請添加主崗組織',
        roles: '請選擇角色',
        repeat: '主崗組織或兼崗組織不能重複',
        realName: '請填寫用戶姓名',
        userCode: '請填寫用戶碼',
      },
      success: '賬號生成成功。 賬號：{0}，密碼：{1}',
      copy: '複製',
      userSuccess: '用戶生成成功',
      pwdConfirm: '確定重置密碼',
      pwdReset: '重置密碼',
      pwdSuccess: '密碼重置成功。 賬號：{0}，密碼：{1}',
    },
  },
  components: {
    relationCompany: '關聯公司',
    corpId: '公司編號',
    corpName: '公司名稱',
    purchaseCompany: '採購公司',
    productCompany: '產品公司',
    salesCompany: '銷售公司',
    corpError: '請選擇未添加到清單的公司',
    corpConfirm: '確認删除該公司',
    defaultChannel: '默認銷售方式',
    saleChannel: '可選銷售方式',
    select: '請選擇{text}',
    add: '請添加{text}',
    scope: '可查看訂單範圍',
    departmentInfo: '下單部門',
    channel: '渠道',
    channelScope: '訂單可查看渠道範圍',
  },
  nav: {
    org: '虛擬組織',
    qw: '企業微信',
    channel: '可銷售方式',
    departmentName: '中旅集團',
  },
}

export default {
  '134380-0': '成人最低成本（原幣）',
  '134380-1': '兒童最低成本（原幣）',
  '134380-2': '原幣幣種',
  '134380-3': '本幣幣種',
  '134380-4': '匯率',
  '002984-0': '供應商',
  '002984-1': '請選擇供應商',
  '002984-2': '採購公司',
  '002984-3': '請選擇採購公司',
  '002984-4': '選擇日期',
  '002984-5': '客戶',
  '002984-6': '客戶名稱',
  '002984-7': '占位數量',
  '002984-8': '1~99的數量',
  '002984-9': '當前餘位',
  '002984-10': '成人原幣金額',
  '002984-11': '兒童原幣金額',
  '002984-12': '預訂留位時間',
  '002984-13': '小時',
  '002984-14': '分鐘',
  '002984-15': '報名截止時間',
  '002984-16': '天前的',
  '002984-17': '選擇時間',
  '002984-18': '點報名截止',
  '002984-19': '採購類型',
  '002984-20': '庫存',
  '002984-21': '現詢',
  '002984-22': '增加庫存',
  '002984-23': '初始化庫存',
  '002984-24': '1~999的數字',
  '002984-25': '0~99的數字',
  '002984-26': '本幣金額：',
  '023526-0': '請選擇供應商',
  '023526-1': '請選擇採購公司',
  '023526-2': '請選擇可銷售日期',
  '023526-3': '請輸入客戶名稱',
  '023526-4': '請輸入1~99的數量',
  '023526-5': '請輸入',
  '023526-6': '=0的數字',
  '023526-7': '=0的整數',
  '023526-8': '請輸入0~1000的數字',
  '023526-9': '請輸入0~59的數字',
  '023526-10': '請輸入0~99的數字',
  '023526-11': '請選擇時間',
  '023526-12': '請選擇採購規則',
  '023526-13': '請輸入1-999的數字',
  '111883-0': '自由售賣',
  '111883-1': '現詢',
  '172757-0': '資源編號',
  '172757-1': '請輸入資源編號',
  '172757-2': '可銷售日期',
  '172757-3': '至',
  '172757-4': '開始日期',
  '172757-5': '結束日期',
  '172757-6': '供應商',
  '172757-7': '請輸入供應商名稱',
  '172757-8': '出發城市',
  '172757-9': '請輸入出發城市',
  '172757-10': '到達城市',
  '172757-11': '請輸入到達城市',
  '172757-12': '航班號',
  '172757-13': '請輸入航班號',
  '172757-14': '行程天數',
  '172757-15': '請輸入行程天數',
  '172757-16': '査詢',
  '172757-17': '請輸入數字',
  '172757-18': '請輸入99以內的整數',
  '209252-0': '資源編號',
  '209252-1': '出發城市',
  '209252-2': '到達城市',
  '209252-3': '團期',
  '209252-4': '參考航班號',
  '209252-5': '庫存統計',
  '209252-6': '總',
  '209252-7': '占',
  '209252-8': '出',
  '209252-9': '餘',
  '209252-10': '最低成本價',
  '209252-11': '成本原幣金額',
  '209252-12': '成本本幣金額',
  '209252-13': '兒童原幣金額',
  '209252-14': '兒童本幣金額',
  '209252-15': '操作',
  '209252-16': '庫存看板',
  '209252-17': '收起',
  '209252-18': '展開',
  '224092-0': '預訂留位時間',
  '224092-1': '分鐘',
  '224092-2': '報名截止時間',
  '224092-3': '{0}報名截止',
  '224092-4': '供應商名稱',
  '224092-5': '採購公司名稱',
  '224092-6': '採購規則類型',
  '224092-7': '總',
  '224092-8': '占',
  '224092-9': '出',
  '224092-10': '餘',
  '224092-11': '成本原幣金額',
  '224092-12': '成本本幣金額',
  '224092-13': '兒童原幣金額',
  '224092-14': '兒童本幣金額',
  '224092-15': '操作',
  '224092-16': '預收占位',
  '277783-0': '預收占位',
  '277783-1': '航程信息',
  '295887-0': '編輯',
  '295887-1': '新增',
  '295887-2': '團票',
  '295887-3': '航程類型',
  '295887-4': '往返',
  '295887-5': '多程（含缺口程）',
  '295887-6': '資源負責人',
  '295887-7': '請選擇',
  '295887-8': '負責人部門',
  '295887-9': '航程信息',
  '295887-10': '第',
  '295887-11': '天',
  '295887-12': '設為目的地',
  '295887-13': '删除',
  '295887-14': '新增一程',
  '295887-15': '資源負責人必填，不能為空',
  '295887-16': '負責人部門必填，不能為空',
  '295887-17': '請選擇航班號',
  '295887-18': '去',
  '295887-19': '返',
}

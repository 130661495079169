export default function MobManageServer(ajax, config) {
  return {
    // 获取枚举值
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    // 公共日志接口
    operationLog: (opt) =>
      ajax({
        url: `/operation-log/list`,
        method: 'GET',
        ...opt,
      }),
    advertisement: {
      getList: (opt) =>
        ajax({
          url: '/banner/list',
          method: 'GET',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/banner/add',
          method: 'POST',
          ...opt,
        }),
      getDetail: (opt) =>
        ajax({
          url: `/banner/detail/${opt.id}`,
          method: 'GET',
          ...opt,
        }),
      update: (opt) =>
        ajax({
          url: '/banner/update',
          method: 'POST',
          ...opt,
        }),
      // 修改广告上线/下线状态
      updateStatus: (opt) =>
        ajax({
          url: `/banner/update-status/${opt.id}`,
          method: 'POST',
          ...opt,
        }),
      // 更新广告位序值
      updateSerial: (opt) =>
        ajax({
          url: '/banner/update-value',
          method: 'POST',
          ...opt,
        }),
      delete: (opt) =>
        ajax({
          url: `/banner/${opt.id}`,
          method: 'POST',
          ...opt,
        }),
    },
    manageHomeIcon: {
      getList: (opt) =>
        ajax({
          url: '/icon/list',
          method: 'GET',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/icon/add',
          method: 'POST',
          ...opt,
        }),
      getDetail: (opt) =>
        ajax({
          url: `/icon/detail/${opt.id}`,
          method: 'GET',
          ...opt,
        }),
      update: (opt) =>
        ajax({
          url: '/icon/update',
          method: 'POST',
          ...opt,
        }),
      delete: (opt) =>
        ajax({
          url: `/icon/${opt.id}`,
          method: 'POST',
          ...opt,
        }),
      deleteCurrentIcon: (opt) =>
        ajax({
          url: `/icon/delete-detail/${opt.id}`,
          method: 'POST',
          ...opt,
        }),
    },
    // 热门标签
    hotLabel: {
      getList: (opt) =>
        ajax({
          url: '/hot-product/tag/list',
          method: 'GET',
          ...opt,
        }),
      update: (opt) =>
        ajax({
          url: '/hot-product/tag/update',
          method: 'POST',
          ...opt,
        }),
      delete: (opt) =>
        ajax({
          url: `/hot-product/tag/${opt.id}`,
          method: 'POST',
          ...opt,
        }),
      getDetail: (opt) =>
        ajax({
          url: `/hot-product/tag/detail/${opt.id}`,
          method: 'GET',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: `/hot-product/tag/add`,
          method: 'POST',
          ...opt,
        }),
      // 获取终端列表
      getTerminalList: (opt) =>
        ajax({
          url: `/hot-product/tag/terminal-list/${opt.type}`,
          method: 'GET',
          ...opt,
        }),
      // 查询C端或云店标签列表
      getLabelList: (opt) =>
        ajax({
          url: `/hot-product/tag/recommend-tag-list/${opt.type}`,
          method: 'GET',
          ...opt,
        }),
      // 获取tab列表
      getTabList: (opt) =>
        ajax({
          url: `/hot-product/tag/tag-detail/${opt.id}`,
          method: 'GET',
          ...opt,
        }),
      // 删除标签对应的产品
      deleteProduct: (opt) =>
        ajax({
          url: `/hot-product/tag/del-product`,
          method: 'POST',
          ...opt,
        }),
      // 获取楼层标签类型
      getFloorLabelList: (opt) =>
        ajax({
          url: `/hot-product/tag/floor-tag-type`,
          method: 'GET',
          ...opt,
        }),
      // 获取终端频道楼层
      getFloorList: (opt) =>
        ajax({
          url: `/terminal-channel-floor/list`,
          method: 'GET',
          ...opt,
        }),
      // 获取终端频道楼层
      deleteFloorList: (opt) =>
        ajax({
          url: `/terminal-channel-floor/${opt.id}`,
          method: 'POST',
          ...opt,
        }),
    },
    // 维护热门标签
    maintainHotLabel: {
      getList: (opt) =>
        ajax({
          url: '/product-detail/list',
          method: 'GET',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/product-detail/add',
          method: 'POST',
          ...opt,
        }),
      // 更新广告位序值
      updateSerial: (opt) =>
        ajax({
          url: '/product-detail/update',
          method: 'POST',
          ...opt,
        }),
      delete: (opt) =>
        ajax({
          url: `/product-detail/${opt.id}`,
          method: 'POST',
          ...opt,
        }),
      poiAdd: (opt) =>
        ajax({
          url: '/poi-detail/add',
          method: 'POST',
          ...opt,
        }),
      getPoiList: (opt) =>
        ajax({
          url: '/poi-detail/list',
          method: 'GET',
          ...opt,
        }),
      getHotelList: (opt) =>
        ajax({
          url: '/hotel-detail/list',
          method: 'GET',
          ...opt,
        }),
      updatePoiSerial: (opt) =>
        ajax({
          url: '/poi-detail/update-value',
          method: 'POST',
          ...opt,
        }),
      updateHotelSerial: (opt) =>
        ajax({
          url: '/hotel-detail/update-value',
          method: 'POST',
          ...opt,
        }),
      deletePoi: (opt) =>
        ajax({
          url: `/poi-detail/${opt.id}`,
          method: 'POST',
          ...opt,
        }),
      deleteHotel: (opt) =>
        ajax({
          url: `/hotel-detail//${opt.id}`,
          method: 'DELETE',
          ...opt,
        }),
      poiSwitch: (opt) =>
        ajax({
          url: '/poi-detail/switch',
          method: 'POST',
          ...opt,
        }),
      hotelAdd: (opt) =>
        ajax({
          url: '/hotel-detail/add',
          method: 'POST',
          ...opt,
        }),
    },
    // 频道楼层配置
    floorConfig: {
      channelTypeProduct: (opt) =>
        ajax({
          url: '/channel-floor-config/channel-type-product',
          method: 'GET',
          ...opt,
        }),
      // 根据终端号查询频道信息
      getChannelInfo: (opt) =>
        ajax({
          url: '/channel-floor-config/channel-info',
          method: 'GET',
          ...opt,
        }),
      addFloor: (opt) =>
        ajax({
          url: '/channel-floor-config/add',
          method: 'POST',
          ...opt,
        }),
      deleteFloor: (opt) =>
        ajax({
          url: '/channel-floor-config/del',
          method: 'POST',
          ...opt,
        }),
      // 获取楼层详细信息
      getFloorDetail: (opt) =>
        ajax({
          url: '/channel-floor-config/floor-info',
          method: 'GET',
          ...opt,
        }),
      getTerminalList: (opt) =>
        ajax({
          url: '/channel-floor-config/terminal',
          method: 'GET',
          ...opt,
        }),
      // 根据终端类型查询位置
      getChannelTerminalType: (opt) =>
        ajax({
          url: '/banner-config/list',
          method: 'GET',
          ...opt,
        }),
      // 根据终端类型以及位置查询终端列表
      getTerminalInfo: (opt) =>
        ajax({
          url: '/channel-floor-config/terminal-info',
          method: 'GET',
          ...opt,
        }),
    },
    bannerConfig: {
      getList: (opt) =>
        ajax({
          url: '/banner-config/list',
          method: 'GET',
          ...opt,
        }),
    },
    // 外渠道
    channel: {
      add: (opt) => ajax({ url: '/channel-product/add', method: 'post', ...opt }),
      infoPrice: (opt) =>
        ajax({ url: `/channel-product/get-price-detail/${opt.id}`, method: 'get', ...opt }),
      updatePrice: (opt) =>
        ajax({ url: `/channel-product/update-channel-price`, method: 'post', ...opt }),
      list: (opt) => ajax({ url: '/channel-product/list', method: 'get', ...opt }),
      // 下架
      offline: (opt) => ajax({ url: '/channel-product/offline', method: 'post', ...opt }),
      // 上架
      online: (opt) => ajax({ url: '/channel-product/online', method: 'post', ...opt }),
    },
  }
}

export default {
  keyword: '請輸入關鍵字查找',
  drag: '可拖拽調整菜單位置',
  addBtn: '新增按鈕',
  delTips: '該功能表下已設置子功能表，不允許刪除',
  tips: '您正在操作${text}，删除菜單後，被授權的賬號將無法再使用該菜單許可權，請確認操作。',
  menuName: '菜單名稱',
  desc: '描述',
  position: '菜單位置',
  role: '適用角色',
  menuPath: '菜單地址',
  open: '新頁面打開',
  updateUser: '更新人',
  updateTime: '更新時間',
  menu: '菜單',
  btnName: '按鈕名稱',
  btnCode: '按鈕代碼',
  btnPosition: '按鈕位置',
  empty: '名稱為空',
  nameRepeat: '菜單名稱重複',
  codeEmpty: '按鈕代碼不能為空',
  btn: '按鈕',
  codeRepeat: '按鈕代碼重複',
  name: '名稱',
}

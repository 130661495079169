import services from '@/services'
import i18n from '@/i18n'

// 动态获取枚举值
export async function getEnumValues(val, needAll, api = 'AuthServer') {
  // 入参 val：枚举值名称
  // 入参 needAll：如果需要加全部选项，传true，默认没有全部
  try {
    const arr = await services[api].enumValues({ name: val })
    let newArr = []
    arr.map((li) => {
      newArr.push({
        label: li.name,
        value: li.code,
      })
    })
    if (needAll) {
      newArr.unshift({
        label: i18n.t('common.button.all'),
        value: null,
      })
    }
    return newArr
  } catch (e) {
    console.log(e)
  }
}

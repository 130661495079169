import vue from 'vue'
import vuex from './index'
import noShowMenu from '@/layout/noShowMenu'
const config = require('/config/config')

export const menuStore = vue.observable({
  keyMenu: {
    // 如下格式
    // url: {
    //   name: '',
    //   url: '',
    // }
  },
})
export function generateKeyMenu() {
  const apiMenu = vuex.state.userMenus
  apiMenu.forEach((li) => {
    const { url, name } = li
    const sonMenu = noShowMenu[url]
    if (sonMenu) {
      sonMenu.forEach((li) => {
        menuStore.keyMenu[li.path] = {
          url: li.path,
          name: li.meta.title,
        }
      })
    }
    menuStore.keyMenu[url] = {
      url,
      name,
    }
  })
  // console.log(menuStore.keyMenu)
}
// 設置頁面頂部標題
export function setDocumentTitle(path, inName = '') {
  const title = inName ? inName : menuStore.keyMenu[path]?.name
  const conTit = config.title
  if (title) {
    document.title = `${title} - ${conTit}`
  } else {
    document.title = conTit
  }
}

const state = {
  scheduleActive: 1000, //当前进度
  resourceList: [],
  isUniversal: false, //是否是环影
  touristIds: [],
}

const mutations = {
  updateSchedule: (state, { payload }) => {
    console.log(payload)
    state.scheduleActive = payload
  },
  updateResourceList: (state, { resourceList }) => {
    console.log(resourceList)
    state.resourceList = resourceList
  },
  setAsUniversal: (state, data) => {
    if (data !== state.isUniversal) {
      state.isUniversal = data
    }
  },
  resetResourceFlag: (state) => {
    // 重置环影标识
    state.isUniversal = false
  },
  setTouristIds: (state, touristIds) => {
    state.touristIds = touristIds
  },
}

const actions = {
  // async actionB ({ dispatch, commit }) {
  //   await dispatch('actionA') // 等待 actionA 完成
  //   commit('gotOtherData', await getOtherData())
  // }
}

const getters = {
  isUniversal: (state) => state.isUniversal,
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}

import config from './config' // 基础配置
import productList from './productList' // 产品列表
import product from './product.js'
import ticketEdit from './ticketEdit'
import distributionProductList from './distributionProductList'
import dynamicPackaging from './dynamicPackaging'
import productEdit from './productEdit'
import resourceRelative from './resourceRelative'
import salesRecordList from './salesRecordList'

export default {
  //   ...common,
  config,
  productList,
  product,
  ticketEdit,
  distributionProductList,
  dynamicPackaging,
  productEdit,
  resourceRelative,
  salesRecordList,
}

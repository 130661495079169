export default {
  OrderList: {
    '107079-0': '轉單',
    '107079-1': '轉單方式',
    '107079-2': '同部門',
    '107079-3': '跨部門',
    '107079-4': '更換跟進公司',
    '107079-5': '請選擇',
    '107079-6': '更換跟進部門',
    '107079-7': '更換銷售員',
    '107079-8': '轉單後，將由更換後的銷售員負責操作訂單，請謹慎操作',
    '107079-9': '請填寫分配跟進部門',
    '107079-10': '請填寫分配跟進公司',
    '107079-11': '請填寫更換銷售員',
    '107079-12': '當前訂單狀態不支持轉單',
    '098425-0': '分單',
    '098425-1': '分配跟進部門',
    '098425-2': '請選擇',
    '098425-3': '分配銷售員',
    '098425-4': '分單後，將由銷售員負責操作訂單，請謹慎操作',
    '098425-5': '請填寫分配跟進部門',
    '098425-6': '請填寫分配銷售員',
    '116004-0': '時間間隔超過180天',
    '116004-1': '今天',
    '116004-2': '最近7天',
    '116004-3': '最近30天',
    '116004-4': '最近90天',
    '116004-5': '最近180天',
    '116004-6': '請輸入數字',
    '116004-7': '請輸入11位數',
    '116004-8': '請先選擇產品一級品類和二級品類',
    '116004-9': '外部訂單號',
    '116004-10': '產品碼',
    '116004-11': '訂單狀態',
    '116004-12': '產品名稱',
    '116004-13': '出發地',
    '116004-14': '目的地',
    '116004-15': '出發日期',
    '116004-16': '行程天數',
    '116004-17': '聯絡人',
    '116004-18': '數量',
    '116004-19': '應收',
    '116004-20': '已收',
    '116004-21': '清位時間',
    '116004-22': '銷售點',
    '116004-23': '店主身份',
    '116004-24': '店主姓名',
    '116004-25': '最新跟進時間',
    '116004-26': '下次跟進時間',
    '116004-27': '渠道',
    '116004-28': '終端',
    '116004-29': '銷售公司',
    '116004-30': '銷售類型',
    '116004-31': '客戶類型',
    '116004-32': '客戶名稱',
    '116004-33': '團代號',
    '116004-34': '團名稱',
    '116004-35': '產品方訂單號',
    '116004-36': '產品方架構公司',
    '116004-37': '分銷方訂單號',
    '116004-38': '分銷方架構公司',
    '124376-0': '電話',
    '124376-1': '社群分銷',
    '148410-0': '交易號碼',
    '148410-1': '請輸入交易號碼',
    '148410-2': '產品',
    '148410-3': '請輸入關鍵字查找',
    '148410-4': '訂單狀態',
    '148410-5': '請選擇訂單狀態',
    '148410-6': '銷售員',
    '148410-7': '請選擇銷售員',
    '148410-8': '聯絡人',
    '148410-9': '請輸入',
    '148410-10': '聯絡人電話',
    '148410-11': '請輸入',
    '148410-12': '出遊人',
    '148410-13': '請輸入',
    '148410-14': '出遊人電話',
    '148410-15': '請輸入',
    '148410-16': '出發地',
    '148410-17': '請輸入',
    '148410-18': '目的地',
    '148410-19': '請輸入',
    '148410-20': '下單人',
    '148410-21': '請輸入',
    '148410-22': '終端',
    '148410-23': '請選擇',
    '148410-24': '渠道',
    '148410-25': '出發日期',
    '148410-26': '至',
    '148410-27': '開始日期',
    '148410-28': '結束日期',
    '148410-29': '交易時間',
    '148410-30': '開始時間',
    '148410-31': '結束時間',
    '148410-32': '最新跟進',
    '148410-33': '下次跟進時間',
    '148410-34': '店主身份',
    '148410-35': '請選擇店主身份',
    '148410-36': '店主姓名',
    '148410-37': '請輸入店主姓名',
    '148410-38': '客戶類型',
    '148410-39': '請選擇客戶類型',
    '148410-40': '銷售公司',
    '148410-41': '請輸入銷售公司',
    '148410-42': '銷售類型',
    '148410-43': '請選擇銷售類型',
    '148410-44': '外部訂單號',
    '148410-45': '請輸入外部訂單號',
    '148410-46': '產品碼',
    '148410-47': '請輸入產品碼',
    '148410-48': '產品品類',
    '148410-49': '團代號',
    '148410-50': '請輸入團代號',
    '148410-51': '全部査詢',
    '148410-52': '常用査詢',
    '148410-53': '査詢',
    '133031-0': '全部',
    '133031-1': '銷售',
    '133031-2': '導出',
    '133031-3': '出遊人明細',
    '133031-4': '訂單明細',
    '133031-5': '歷史明細',
    '133031-6': '自定義展示列',
    '133031-7': '備註',
    '133031-8': '交易號碼',
    '133031-9': '外部訂單號',
    '133031-10': '訂單狀態',
    '133031-11': '產品碼',
    '133031-12': '產品',
    '133031-13': '出發地->目的地',
    '133031-14': '目的地',
    '133031-15': '出發地',
    '133031-16': '出發日期',
    '133031-17': '行程天數',
    '133031-18': '聯絡人',
    '133031-19': '數量',
    '133031-20': '間',
    '133031-21': '套',
    '133031-22': '大',
    '133031-23': '小',
    '133031-24': '清位時間',
    '133031-25': '銷售員',
    '133031-26': '應收',
    '133031-27': '已收',
    '133031-28': '下單人',
    '133031-29': '銷售點',
    '133031-30': '交易時間',
    '133031-31': '店主身份',
    '133031-32': '店主姓名',
    '133031-33': '最新跟進時間',
    '133031-34': '下次跟進時間',
    '133031-35': '渠道',
    '133031-36': '終端',
    '133031-37': '銷售公司',
    '133031-38': '團代號',
    '133031-39': '團名稱',
    '133031-40': '銷售類型',
    '133031-41': '客戶類型',
    '133031-42': '客戶名稱',
    '133031-43': '產品方訂單號',
    '133031-44': '產品方架構公司',
    '133031-45': '分銷方訂單號',
    '133031-46': '分銷方架構公司',
    '133031-47': '操作',
    '133031-49': '認領',
    '133031-51': '跟單',
    '133031-52': '打開送簽管理',
    '133031-53': '操作項',
    '133031-54': '操作人',
    '133031-55': '操作時間',
    '133031-56': '導出的數量為0，您可以調整查詢準則重新導出',
    '133031-57':
      '導出的數量為{0}，超出單次上限5000條，您可以調整查詢準則重新導出，或繼續將前5000條導出',
    '133031-58': '提示',
    '133031-59': '重新查詢',
    '133031-60': '繼續導出',
    '133031-61':
      '清位時間48小時內，出發日期臨近，請儘快完成簽約收款動作。占位時間不足的情況可聯繫產品重新占位。',
    '133031-62': '出發日期臨近，請儘快聯繫產品採購完成供應商確認。',
    '133031-63': '可認領',
    '133031-64': '可分單',
    '133031-65': '可轉單',
    '133031-66': '可跟進',
    '133031-67': '僅查看',
    '133031-68': '已預約',
    '133031-69': '認領後，將由自己負責操作訂單，確認繼續？',
    '133031-70': '確定',
    '133031-71': '取消',
    '116004-39': '出發地>目的地',
    '148410-54': '辦理進度',
    '133031-72': '聯絡人電郵',
    '133031-73': '請輸入',
    '133031-74': '客戶類別',
    '133031-75': '客戶名稱',
    '133031-76': '會員',
    '133031-77': '銷售點',
    '116004-40': '請輸入正確的郵箱地址',
    '743020-0': '售賣其他公司上架的共享分銷產品，生成的訂單',
    '743020-1': '本公司產品共享到其他公司分銷，聯動生成的訂單',
    '148410-55': '請輸入產品碼/名稱',
    认领状态: '認領狀態',
    无销售员: '无銷售員',
  },
}

import i18n from '@/i18n'
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { isVisit } from '@/Power' // 权限是否可以访问
import { getOneRouter } from '@/layout/tool' // 获取路由默认第一页
import { loadLanguageAsync } from '@/i18n'
import Vuex from '@/store'
import { param2Obj } from '@/utils'
import api from '@/services'
import { setDocumentTitle } from '@/store/allMenu'

const config = require('/config/config')

NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, from, next) => {
  const ua = navigator.userAgent.toLowerCase()
  const isWeixin = ua.indexOf('micromessenger') != -1
  store.commit('settings/changeOnlyShowContent', isWeixin)
  const toCopy = { ...to }

  await loadLanguageAsync(Vuex.state.language)
  NProgress.start()

  setDocumentTitle(to.path)
  const hasToken = store.state.user.token
  // 哈希路由特殊处理
  if (toCopy.fullPath.substring(0, 2) === '/#') {
    toCopy.path = config.publicPath + toCopy.fullPath
  }
  // 记录上一次的页面
  const path = toCopy.path ? toCopy.path : '/'
  const noPage = config.noPage.includes(path.split('?')[0])
  if (!noPage) {
    store.commit('set_data', {
      upperRoute: toCopy.fullPath,
    })
  }
  // 无需登录页面验证跳转,通过配置路由中的auth为false控制无登录访问
  if (toCopy.meta && toCopy.meta.auth === false) {
    next()
    return null
  }

  // 获取后端权限菜单，并且保证能有一个默认访问菜单
  const isTestRedirect = ['/weixin-login/sit', '/weixin-login/sst'].includes(toCopy.path)
  if (hasToken && !isTestRedirect) {
    if (['/login', '/weixin-login'].includes(toCopy.path)) {
      next({ path: getOneRouter() })
    } else {
      if (isWeixin && isVisit(path.split('?')[0])) {
        // const { orgCompanyId, orgCompanyName } = param2Obj(window.location.search)
        const orgCompanyObj = param2Obj(window.location.search)
        const userOrgInfo = store.state.userOrgInfo ?? {}
        const list = userOrgInfo?.orgCompanyList ?? []
        let isExist = false
        list?.forEach((li) => {
          const { orgCompanyId } = li
          if (orgCompanyObj.orgCompanyId == orgCompanyId) {
            isExist = true
            return
          }
        })
        const orgCompanyIdStore = store.state.orgCompany.orgCompanyId
        if (
          isExist &&
          orgCompanyObj.orgCompanyId != orgCompanyIdStore &&
          orgCompanyObj.orgCompanyName != null
        ) {
          await api.Nbs.orgSel({
            data: {
              orgCompanyId: orgCompanyObj.orgCompanyId,
              orgCompanyName: orgCompanyObj.orgCompanyName,
            },
          })
          store.commit('set_data', {
            orgCompany: {
              orgCompanyId: orgCompanyObj.orgCompanyId,
              orgCompanyName: orgCompanyObj.orgCompanyName,
              selected: true,
            },
          })
          window.location.reload(true)
          next()
          return false
        } else {
          next()
        }
      } else if (isVisit(path.split('?')[0])) {
        next()
      } else {
        console.warn(i18n.t('您没有菜单权限'))
        next({ path: getOneRouter() })
      }
    }
  } else {
    if (isWeixin) {
      if (isTestRedirect) {
        if (toCopy.path === '/weixin-login/sit') {
          window.location.href =
            'https://sit-nbs.ctshk.com/nbs-pc/weixin-login' + window.location.search
        }
        if (toCopy.path === '/weixin-login/sst') {
          window.location.href =
            'https://sst-nbs.ctshk.com/nbs-pc/weixin-login' + window.location.search
        }
      } else {
        next(`/weixin-login`)
      }
    } else {
      next(`/login`)
    }
  }
  NProgress.done()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

export default {
  alreadyPutAway: '已上架',
  alreadySoldOut: '已下架',
  underReview: '審批中',
  noDepartureDate: '無貨',
  productCode: '產品碼',
  productName: '產品名稱',
  productYard: '产品码',
  resourceYard: '資源碼',
  resourceCode: '資源編號',
  productCharge: '產品負責人',
  productCategory: '產品品類',
  productClass: '產品類目',
  productCreate: '新增產品',
  daysAndNight: '行程天晚',
  day: '天',
  night: '晚',
  updateTime: '更新時間',
  departureDatePrice: '團期價格',
  poster: '海報',
  nameFront: '名稱前',
  nameEnd: '的資源，由供應商COP系統創建',
  productId: '產品編號',
  product: '產品',
  resourceName: '資源名稱',
  resourceCharge: '資源負責人',
  CascaderDestination: '目的地',
  withdrawAudit: '撤銷審批',
  lookSalesProgress: '銷售進度',
  出发城市: '出發城市',
}

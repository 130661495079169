export default function (ajax, config) {
  return {
    // 销售日结报表
    salesReport: {
      // 获取收退款明细
      getReceiptData: (opt) =>
        ajax({
          url: '/daily-settlement/list-receipt-refund',
          method: 'post',
          ...opt,
        }),
      // 获取销售日结报表列表
      getSalesReportList: (opt) =>
        ajax({
          url: '/daily-settlement/list',
          method: 'post',
          ...opt,
        }),
      // 获取销售日结报表明细
      getSalesReportDetail: (opt) =>
        ajax({
          url: `/daily-settlement/${opt}`,
          method: 'get',
        }),
      // 获取销售日结报表明细(批量)
      getBatchSalesReportDetail: (opt) =>
        ajax({
          url: '/daily-settlement/audit-batch/detail',
          method: 'get',
          ...opt,
        }),
      // 添加
      addReport: (opt) =>
        ajax({
          url: '/daily-settlement/add',
          method: 'post',
          ...opt,
        }),
      //导出明细
      exportDetail: (opt) =>
        ajax({
          url: '/daily-settlement/export',
          method: 'post',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
      // 保存销售日结报表
      saveReport: (opt) =>
        ajax({
          url: '/daily-settlement/save',
          method: 'post',
          data: opt,
        }),
      // 提交销售日结报表
      submitReport: (opt) =>
        ajax({
          url: '/daily-settlement/submit',
          method: 'post',
          data: opt,
        }),
      // 作废销售日结报表
      cancelReport: (opt) =>
        ajax({
          url: '/daily-settlement/cancel',
          method: 'post',
          data: opt,
        }),
      // 撤回销售日结报表
      resaveReport: (opt) =>
        ajax({
          url: '/daily-settlement/resave',
          method: 'post',
          data: opt,
        }),
      // 审核销售日结报表
      approveReport: (opt, batch) =>
        ajax({
          url: batch ? '/daily-settlement/audit-batch' : '/daily-settlement/audit',
          method: 'post',
          data: opt,
        }),
      // 实时生成文件
      getFile: (opt) =>
        ajax({
          url: `/daily-settlement/get-attachment/${opt}`,
          method: 'get',
        }),
      // 退回时刷新
      refresh: (opt) =>
        ajax({
          url: `/daily-settlement/refresh/${opt}`,
          method: 'get',
        }),
    },
    receipt: {
      // 获取预收款信息
      getPreReceiptsList: (opt) =>
        ajax({
          url: '/pre-receipt/list-all',
          method: 'get',
          data: opt,
        }),
    },
    refund: {
      // 可用收款单列表
      refundableList: (opt) =>
        ajax({
          url: '/receipt-by-order/available-receipts',
          method: 'post',
          ...opt,
        }),
    },
    // 收款方式
    getReceiptPayList: (opt) =>
      ajax({
        url: '/receipt-by-order/receipt-pay-type/list',
        method: 'post',
        ...opt,
      }),
  }
}

const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => {
    if (state.user && state.user.name) {
      return state.user.name
    } else return ''
  },
}
export default getters

<template>
  <div id="app">
    <router-view :key="version" />
  </div>
</template>

<script>
import { isPageAsh } from '@/services/tool/globalConfig'

const config = require('/config/config')
export default {
  computed: {
    // 控制页面整体实例刷新
    version() {
      return this.$route.fullPath //this.$store.state.version
    },
  },
  data() {
    return {}
  },
  created() {
    isPageAsh()
  },
}
</script>

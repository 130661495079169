// 组织类型
export const OrgType = {
  group: 1, //集团
  businessGroup: 2, // 事业群
  subsidiary: 3, // 子公司
  department: 4, // 部门（上级非门市类型）
  store: 5, //  门市
  depSuperiorStore: 6, // 部门（上级为门市类型）
  architecture: 7, // 架构公司
}

// 部门关联的公司类型CodeMap
export const DEPARTMENT_CORP_TYPE = {
  PRODUCT_CORP: 1, // 产品公司
  SALES_CORP: 2, // 销售公司
  PURCHASE_CORP: 3, // 采购公司
}
// 角色类型枚举
export const RoleType = {
  systemManagement: 11, // 系统管理,超管
  businessManagement: 12, // 业务管理
  salesDomain: 13, // 销售域
  marketingDomain: 14, // 营销域
  procurementField: 15, // 采购域
  productDomain: 16, // 产品域
  financialDomain: 17, // 财务域
  personnelDomain: 18, // 人事域
}
// 用户类型
export const UserType = {
  businessGroupEmployees: 1, // 事业群员工
  subsidiaryEmployees: 2, // 子公司员工
  employeesOfDirectStores: 3, // 直营店员工
  innovativeStoreClerk: 4, // 创新店店员
  sClosedLoopSgent: 5, // S级闭环代理人
  salesStaff: 6, // 销售岗员工
  nonSalesStaff: 7, // 非销售岗员工
  innovationStoreManager: 8, // 创新店店长
  aUnclosedLoopAgent: 9, // A级非闭环代理人
  GroupEmployees: 10, //集团员工
  employeeOfExpansionPost: 11, // 拓展岗员工
}

import { toJSon } from '@/common/tool'
import { getEnumValues } from '@/views/sysConfig/UserAndOrg/utils/enums'
import { RoleType, UserType } from '@/common/const/AuthServer'

export default {
  // 适用角色
  applicableRole: [
    { label: '系统管理', value: RoleType.systemManagement },
    { label: '业务管理', value: RoleType.businessManagement },
    { label: '销售域', value: RoleType.salesDomain },
    { label: '营销域', value: RoleType.marketingDomain },
    { label: '采购域', value: RoleType.procurementField },
    { label: '产品域', value: RoleType.productDomain },
    { label: '财务域', value: RoleType.financialDomain },
    { label: '人事域', value: RoleType.personnelDomain },
  ],
  applicableUsers: [
    // { value: 1, label: '事业群员工' },
    // { value: 2, label: '子公司员工' },
    { value: UserType.employeesOfDirectStores, label: '直营店员工' },
    { value: UserType.innovativeStoreClerk, label: '创新店店员' },
    { value: UserType.sClosedLoopSgent, label: 'S级闭环代理人' },
    { value: UserType.salesStaff, label: '销售岗员工' },
    { value: UserType.nonSalesStaff, label: '非销售岗员工' },
    { value: UserType.innovationStoreManager, label: '创新店店长' },
    { value: UserType.employeeOfExpansionPost, label: '拓展岗员工' },
  ],
}

async function handleRoleEnum(type) {
  try {
    let res = await getEnumValues(type, false)
    res = res ?? []
    return {
      keyJson: toJSon(res),
      list: res,
    }
  } catch (e) {
    return Promise.reject(e)
  }
}

// 2022-07-13 修改为枚举获取数据
export async function getApplicableUsers() {
  return handleRoleEnum('user-type')
}

export async function getRoleType() {
  return handleRoleEnum('role-type')
}

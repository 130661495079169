export default {
  index: {
    noPermissionTips: '您無權查看此訂單',
    orderCancel: '取消訂單',
    checkLossResult: '查看核損結果',
    signUpForPayment: '發起簽約收款',
    checkBills: '查看單據',
    issueTickets: '去出票',
    issueTicketsTips: '訂單所有資源均占位成功、附加項均審核通過、完成全額收款，是否‘去出票’？',
  },
  travelDemand: {
    status: '狀態',
    useCertificate: '使用憑證',
    occupy: '占位',
    cancelOccupy: '取消占位',
    cancelOccupyTips: '確定要取消占位嗎？',
    tryAgain: '重試',
    releaseTime: '清位時間',
    solution: '解決方案',
    reason: '原因',
    lossEffectiveTime: '方案有效期',
    resourceOwnerName: '資源負責人',
    roundType: '占位方式',
    changeDemand: '變更需求',
    confirmChange: '確認變更',
    notSalesTips: '原產品、出發日期、場次不可售賣，您需重新選擇',
    publicTips1: '1、若涉及變更已占位成功的資源，【確認變更】後，系統將自動取消占位並刪除資源',
    publicTips2: '2、若有新增資源，需重新發起占位',
    resourceOrCountChangeTips:
      '涉及變更已占位成功資源的場次，【確認變更】後，系統將自動取消占位並刪除資源，確定繼續操作？',
    requirementConfirmingTips: '請及時占位，占位成功後，會預留位子',
    occupyingTips: '請聯系{0}處理占位單',
    occupyFailedTips: '請查看失敗原因，重試或與客人溝通變更需求',
    confirmingTips: '請聯系{0}處理確認單',
    confirmFailedTips: '請查看解決方案，與客人溝通',
    cancelOrderLosingTips: '請聯系{0}處理核損單',
    cancelOrderLossFeedBackTips: '請查看核損結果，跟客人溝通取消損失，確認是否最終取消',
    allCancelConfirmingTips: '請聯系{0}處理確認單',
    cancelConfirmingFailTips: '請查看失敗原因，重試',
  },
  additional: {
    title: '附加項',
    additionalTotalPrice: '其他附加項',
    additionalType: '附加項類型',
    explain: '說明',
    amount: '金額',
    additionalAmount: '附加項總額',
    additionalTips: '請聯系{0}審核，如無需調整，可刪除',
  },
  moneyInfo: {
    title: '收退款',
    receiptsType: '收款類型',
    receiptsCode: '收款單號',
    receiptMode: '收款方式',
    receiptStatus: '狀態',
    originalCurrency: '收款幣種',
    originalReceiptsAmount: '收款金額',
    receiptAmount: '換算後金額',
    receivableAmount: '待收金額',
    exchangeRate: '匯率',
    creditAmount: '掛賬金額',
    electronicDocument: '電子單據',
    refundType: '退款類型',
    refundMode: '退款方式',
    refundStatus: '退款狀態',
    refundCurrencyCode: '退款幣種',
    refundAmount: '退款金額',
    refundCode: '退款單號',
    待收总额: '待收總額',
    已收总额: '已收總額',
    待退总额: '待退總額',
    已退总额: '已退總額',
    挂账使用总额: '掛賬總額',
    receiptsTips: '請添加收款',
    refundTips: '請及時發起退款申請，並聯系財務處理退款',
  },
  contact: {
    deleteTips: '確定要删除該聯絡人嗎？',
  },
  tourist: {
    deleteTips: '確定要删除該出遊人嗎？',
  },
  memo: {
    title: '操作記錄',
  },
  lossInfo: {
    lossType: '核損類型',
    allCancel: '整單取消',
    partCancel: '部分取消',
    lossMode: '核損方式',
    systemLoss: '系統自動核損',
    peopleLoss: '人工核損',
    orderTotalAmountAfterChange: '預計訂單總價變化',
    refundableAmount: '預計退款金額',
    lossDetail: '損失明細',
    lossAmount: '損失金額',
    lossUnitPrice: '損失單價',
    lossTotalPrice: '損失總價',
    totalAmountBeforeChange: '預計金額變化',
    totalAmountAfterChange: '預計總價變化',
    lossEffectiveTime: '損失方案有效截止',
    lossEffectiveStatus: '已失效',
    cancelLossTips: '取消核損，當前核損結果失效，如客人仍需取消，請重新發起核損，確定繼續操作？',
    relaunchTips: '重新發起，當前核損結果失效，確定繼續操作？',
    confirmLossTips: '跟客人達成一致，認可當前核損方案，確定取消？',
    cancelLoss: '取消核損',
    relaunch: '重新發起',
    confirmAllCancel: '確認整單取消',
    confirmCancelLoss: '確認部分取消',
  },
  baseInfo: {
    订单状态: '訂單狀態：',
    团: '團',
    交易号码: '交易號碼：',
    复制: '複製',
    交易时间: '交易時間：',
    终端: '終端：',
    选择: '選擇',
    存在占位中的资源您可以联系产品负责人反馈占位结果再进行修改:
      '存在占位中的資源，您可以聯繫產品負責人回饋占位結果，再進行修改',
    修改成功: '修改成功',
    收据: '收據',
    发票: '發票',
    换票证: '換票證',
    团名称: '團名稱：',
    团号: '團號：',
    存在占位中的资源您可以联系产品负责人反馈占位结果再变更需求:
      '存在占位中的資源，您可以聯繫產品負責人#反饋占位結果，再變更需求',
    存在占位成功的资源您先点击取消占位取消占位成功后再进行修改:
      '存在占位成功的資源，您先點擊[取消占位] ，取消占位成功後再進行修改',
    更换渠道客户后已选中的资源价格会更新确定继续操作:
      '更換渠道/客户後已選中的資源價格會更新，確定繼續操作？',
  },
  topNav: {
    基本信息: '基本信息',
    出游需求: '出遊需求',
    附加项: '附加項',
    促销和费用: '促銷和費用',
    收退款: '收退款',
    出游人: '出遊人',
    联络人: '聯絡人',
    操作记录: '操作記錄',
  },
  bill: {
    printNum: '打印次數',
    sendNum: '發送次數',
    entrustedTicketList: '委託訂票單清單',
    billList: '收據清單',
    invoiceList: '發票清單',
    ticketManagementList: '使用憑證清單',
    generated: '已生成',
    voided: '已作廢',
    detail: '明細',
    voucherCode: '使用憑證號',
    voucher: '使用憑證',
    unwritten: '未核銷',
    written: '已核銷',
    printDetail: '列印記錄',
    sendDetail: '發送記錄',
    batchHandleTips: '請至少勾選一條收據/發票/使用憑證',
    printTips:
      '支持格式pdf、jpg、png的文件在線列印，如電子單據非上述格式，請下載後列印，或直接發送給客戶',
  },
  orderCancel: {
    occupyingTips: '存在占位中的資源，訂單無法取消',
    confirmingTips: '訂單中的資源正在跟供應商確認中，暫不可取消訂單，待確認完成後，可正常操作。。',
    cancelingTips: '訂單中正在取消處理中，不可取消訂單。',
    losingTips:
      '訂單中的資源正在跟供應商核損中，暫不可取消訂單，待核損完成且確認核損結果後，可正常操作。',
    cancelReason: '取消原因',
    cancelReasonTips: '請勾選取消原因',
    cancelRemarkTips: '請在輸入框填寫取消原因',
    cancelSuccessTips: '取消訂單申請成功',
  },
}

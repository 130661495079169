export default {
  pleSelectCate: '請選擇外部合作類目',
  pleSelectSaleType: '請選擇銷售類型',
  pleSelectCustomerType: '請選擇客戶類型',
  pleSelectCustomerName: '請輸入客戶名稱',
  pleSelectSaleCompany: '請選擇銷售公司',
  bookPeopleName: '下單人',
  bookPeopleDep: '下單人部處',
  batchHistory: '批量下單歷史記錄',
  externalTemplate: '外部渠道批量下單範本下載',
  companyTemplate: '企業客戶批量下單範本下載',
  customerTemplate: '散客批量下單範本下載',
  uploadLimit: '單文件導入不可超過300條，點擊【批量下單】選擇本地文件，上傳文件同時觸發下單',
  templateDownload: '範本下載',
  batchBook: '批量下單',
  pleaseSelectChannel: '請選擇合作渠道',
  typeLimit: '類型只能是xls、xlsx、csv',
  uploadLoding: '批量下單大概需要幾分鐘的過程，請稍後到歷史記錄查看下單結果',
  uploadFailure: '上傳文件失敗！',
  channelNo: '合作渠道',
  externalCategoryId: '外部合作類目',
  externalOrderId: '外部訂單號',
  saleType: '銷售類型',
  customerType: '客戶類型',
  customerName: '客戶名稱',
  saleCompany: '銷售公司',
}

<template>
  <div class="no-padding-footer">
    <el-button @click="oncancel" v-if="isCancel" :disabled="loading">{{ cancelText }}</el-button>
    <el-button
      type="primary"
      :disabled="confirmDisabled || loading"
      :loading="loading"
      v-if="isConfirm"
      @click="onConfirm"
      >{{ confirmText }}</el-button
    >
  </div>
</template>

<script>
import i18n from '@/i18n'
export default {
  props: {
    loading: Boolean,
    isCancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: i18n.t('common.button.cancel'),
    },
    isConfirm: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: i18n.t('common.button.confirm'),
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    oncancel() {
      this.$emit('cancel')
    },
    onConfirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style scoped lang="scss">
.no-padding-footer {
  padding: 10px 20px 20px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
  border-radius: 0px 0 8px 8px;
}
</style>

export default {
  '825661-0': '大',
  '825661-1': '成人',
  '825661-2': '小',
  '825661-3': '兒童',
  '825661-4': '嬰',
  '825661-5': '嬰兒',
  '825661-6': '間夜',
  '825661-7': '身份證',
  '825661-8': '護照',
  '825661-9': '國際護照',
  '825661-11': 'MU2925/南京祿口機場2020-03-31 02:00->三亞鳳凰國際機場2020-03-31 07:30',
  '825661-13': '占位結果，確認結果',
  '825661-14': '李四',
  '825661-15': '張三',
  '825661-16': '方案說明',
  '825661-17': '備註信息核損',
  '825661-18': '成功',
  '837284-0': '關聯出遊人',
  '837284-2': '小',
  '837284-3': '姓名',
  '837284-4': '類型',
  '837284-5': '證件類型',
  '837284-6': '證件號碼',
  '837284-7': '取消信息',
  '837284-9': '場次：',
  '837284-10': '庫存類型',
  '837284-11': '批次',
  '837284-12': '數量',
  '837284-13': '套',
  '837284-14': '占位結果',
  '837284-15': '供應商已處理',
  '837284-16': '備註',
  '837284-17': '取消',
  '837284-18': '確認',
  '837284-19': '請選擇占位結果',
  '837284-20': '保存成功',
  '837284-21': '男',
  '837284-22': '女',
  '837284-23': '核損',
  '837284-24': '取消確認',
  '837284-25': '中國',
  '894209-0': '關聯出遊人',
  '894209-2': '小',
  '894209-3': '姓名',
  '894209-4': '類型',
  '894209-5': '證件類型',
  '894209-6': '證件號碼',
  库存占位情况: '庫存占位情況',
  '894209-9': '場次：',
  '894209-10': '庫存類型',
  '894209-11': '批次',
  '894209-12': '現詢',
  '894209-13': '成本單價原幣',
  '894209-14': '成人：',
  '894209-15': '兒童：',
  '894209-16': '單房差：',
  '894209-17': '數量',
  '894209-18': '套',
  '894209-19': '份',
  '894209-20': '成本總價原幣',
  '894209-21': '匯率',
  '894209-22': '特殊需求',
  '894209-23': '占位結果',
  '894209-24': '位置滿足',
  '894209-25': '位置不滿足',
  价格: '價格',
  '894209-27': '成人',
  '894209-28': '兒童',
  '894209-29': '單房差',
  '894209-30': '清位時間',
  '894209-31': '選擇日期時間',
  occupyTip: '清位時間最晚不能超過出遊當天23點59分59。',
  '894209-33': '備註',
  '894209-34': '操作記錄',
  '894209-35': '取消',
  '894209-36': '確認',
  '894209-37': '請填寫單價',
  '894209-38': '請輸入正確格式的單價',
  '894209-39': '請輸入正數',
  '894209-40': '請輸入正確的2位小數',
  '894209-41': '輸入值不能超過999999.99',
  '894209-42': '請填寫清位時間',
  '894209-43': '清位時間必須大於當前時間',
  '894209-44': '請選擇占位結果',
  '894209-45': '保存成功',
  '902643-1': '請輸入交易號碼',
  '902643-3': '請選擇資源類型',
  '902643-5': '請輸入資源編號',
  '902643-6': '資源名稱',
  '902643-7': '請輸入資源名稱',
  '902643-9': '至',
  '902643-10': '開始日期',
  '902643-11': '結束日期',
  '902643-12': '供應商',
  '902643-13': '請輸入供應商名稱',
  '902643-14': '負責人',
  '902643-15': '請輸入負責人',
  '902643-16': '占位單號',
  '902643-17': '請輸入占位單號',
  '902643-18': '發起時間',
  '902643-19': '開始時間',
  '902643-20': '結束時間',
  '902643-21': '占位單類型',
  '902643-22': '請選擇占位單類型',
  '902643-23': '資源負責人',
  '902643-24': '占位結果',
  '902643-25': '請選擇占位結果',
  '902643-26': '反饋時間',
  '902643-27': '反饋人',
  '902643-28': '請輸入反饋人',
  '902643-29': '反饋渠道',
  '902643-30': '請選擇占位反馈渠道',
  '902643-32': '請輸入產品碼',
  '902643-34': '請輸入產品名稱',
  '902643-35': '出發日期',
  '902643-36': '採購公司',
  '902643-37': '請輸入採購公司',
  '902643-38': '全部査詢',
  '902643-39': '常用査詢',
  '902643-40': '査詢',
  '902643-41': '今天',
  '902643-42': '請選擇占位發起時間',
  '902643-43': '導遊領隊',
  '922950-2': '占位單號',
  '922950-3': '出發日期',
  '922950-6': '資源名稱',
  '922950-7': '資源負責人',
  '922950-11': '數量',
  '922950-12': '成本單價原幣',
  '922950-13': '成本總價原幣',
  '922950-14': '供應商',
  '922950-15': '負責人',
  '922950-16': '占位單類型',
  '922950-17': '發起時間',
  '922950-18': '處理狀態',
  '922950-19': '占位結果',
  '922950-20': '占位反饋時間',
  '922950-21': '占位反馈渠道',
  '922950-22': '清位時間',
  '922950-23': '反饋人',
  '922950-24': '採購公司',
  '922950-25': '操作',
  '922950-26': '處理',
  '922950-27': '詳情',
  '922950-28': '單價',
  '922950-29': '成本',
  '922950-30': '總價',
  '922950-31': '供應商單號',
  '929699-0': '類型：',
  '929699-1': '取消占位單',
  '929699-2': '狀態：',
  '929699-3': '待處理',
  '929699-4': '發起時間：',
  '929699-5': '資源信息',
  '929699-8': '出發日期',
  '929699-9': '資源名稱',
  '929699-10': '方案',
  '929699-13': '銷售類型',
  '929699-14': '聯絡人',
  '929699-15': '關聯出遊人',
  '929699-16': '姓名',
  '929699-17': '類型',
  '929699-18': '證件類型',
  '929699-19': '證件號碼',
  '929699-20': '反饋信息',
  '929699-21': '占位結果',
  '929699-22': '供應商已處理',
  '929699-23': '供應商已退回',
  '929699-24': '備註',
  '929699-25': '取消',
  '929699-26': '確認',
  '929699-27': '請選擇結果',
  '929699-28': '保存成功',
  '937725-0': '資源信息',
  '937725-3': '出發日期',
  '937725-4': '資源名稱',
  '937725-5': '方案',
  '937725-7': '銷售類型',
  '937725-8': '聯絡人',
  '937725-9': '關聯出遊人',
  '937725-10': '姓名',
  '937725-11': '類型',
  '937725-12': '證件類型',
  '937725-13': '證件號碼',
  '937725-14': '反饋信息',
  '937725-15': '占位結果',
  '937725-16': '位置滿足',
  '937725-17': '位置不滿足',
  '937725-18': '詳情',
  '937725-19': '原需求下單',
  '937725-20': '替代方案',
  '937725-21': '方案說明',
  '937725-22': '價格',
  '937725-23': '散客機票',
  '937725-25': '單價（成本）',
  '937725-26': '數量',
  '937725-27': '小',
  '937725-29': '成本總價原幣',
  '937725-30': '酒店',
  '937725-31': '間',
  '937725-32': '清位時間',
  '937725-33': '選擇日期時間',
  '937725-34':
    '清位時間最晚不能超過出遊當天23點59分59秒，請聯系銷售儘快完成收款簽約動作，超過清位時間無法收款。',
  '937725-35': '備註',
  '937725-36': '取消',
  '937725-37': '確認',
  '937725-38': '請填寫單價',
  '937725-39': '請輸入正確格式的單價',
  '937725-40': '請輸入正數',
  '937725-41': '請輸入正確的2位小數',
  '937725-42': '不能超過999999.99',
  '937725-43': '請填寫清位時間',
  '937725-44': '清位時間必須大於當前時間',
  '937725-45': '請選擇占位結果',
  '937725-46': '請選擇詳情',
  '937725-47': '請填寫方案說明',
  '937725-48': '保存成功',
  '970816-0': '類型：',
  '970816-1': '取消占位單',
  '970816-2': '狀態：',
  '970816-3': '待處理',
  '970816-4': '已處理',
  '970816-5': '發起時間：',
  '970816-6': '資源信息',
  '970816-9': '出發日期',
  '970816-10': '資源名稱',
  '970816-11': '方案',
  '970816-14': '銷售類型',
  '970816-15': '聯絡人',
  '970816-16': '關聯出遊人',
  '970816-17': '姓名',
  '970816-18': '類型',
  '970816-19': '證件類型',
  '970816-20': '證件號碼',
  '970816-21': '反饋信息',
  '970816-22': '占位結果',
  '970816-23': '供應商已處理',
  '970816-24': '供應商已退回',
  '970816-25': '備註',
  '970816-26': '操作記錄',
  '970816-27': '取消',
  '976479-0': '資源信息',
  '976479-3': '出發日期',
  '976479-4': '分銷方交易號碼',
  '976479-5': '產品方交易號碼',
  '976479-6': '分銷方',
  '976479-7': '產品方',
  '976479-8': '資源名稱',
  '976479-9': '方案',
  '976479-11': '銷售類型',
  '976479-12': '聯絡人',
  '976479-13': '關聯出遊人',
  '976479-14': '姓名',
  '976479-15': '類型',
  '976479-16': '證件類型',
  '976479-17': '證件號碼',
  '976479-18': '反饋信息',
  '976479-19': '占位結果',
  '976479-20': '位置滿足',
  '976479-21': '位置不滿足',
  '976479-22': '詳情',
  '976479-23': '原需求下單',
  '976479-24': '替代方案',
  '976479-25': '方案說明',
  '976479-26': '價格',
  '976479-27': '散客機票',
  '976479-29': '單價（成本）',
  '976479-30': '數量',
  '976479-31': '小',
  '976479-33': '成本總價原幣',
  '976479-34': '散客酒店',
  '976479-35': '間',
  '976479-36': '清位時間',
  '976479-37': '選擇日期時間',
  '976479-38': '備註',
  '976479-39': '操作記錄',
  '976479-40': '取消',
  organizerId: '發起人',
}

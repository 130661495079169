const basePath = '/category/'
export default [
  {
    path: basePath + 'category-list',
    name: basePath + 'category-list',
    component: () => import('@/views/category/category-list/list-table'),
    meta: { title: '类目列表' },
  },
  {
    path: basePath + 'category-list/add-category',
    name: basePath + 'category-list/add-category',
    component: () => import('@/views/category/category-list/add-category'),
    meta: { title: '添加类目' },
  },
  {
    path: basePath + 'category-list/edit-category',
    name: basePath + 'category-list/edit-category',
    component: () => import('@/views/category/category-list/add-category'),
    meta: { title: '编辑类目' },
  },
  {
    path: basePath + 'category-list/get-details',
    name: basePath + 'category-list/get-details',
    component: () => import('@/views/category/category-list/get-details'),
    meta: { title: '查看详情' },
  },
  {
    path: basePath + 'product-class',
    name: basePath + 'product-class',
    component: () => import('@/views/category/product-class/index'),
    meta: { title: '品类列表' },
  },
  {
    path: basePath + 'destination-group',
    name: basePath + 'destination-group',
    component: () => import('@/views/category/destination-group/index'),
    meta: { title: '目的地列表' },
  },
  {
    path: basePath + 'departure',
    name: basePath + 'departure',
    component: () => import('@/views/category/departure/index'),
    meta: { title: '出发地列表' },
  },
  {
    path: basePath + 'theme',
    name: basePath + 'theme',
    component: () => import('@/views/category/theme/index'),
    meta: { title: '主题列表' },
  },
  {
    path: basePath + 'all-category',
    name: basePath + 'all-category',
    component: () => import('@/views/category/all-category/index'),
    meta: { title: '全类目树' },
  },
]

export default {
  huoQuErJiMuDeD: '獲取二級目的地組失敗.原因：',
  huoQuYiJiMuDeD: '獲取一級目的地組失敗.原因：',
  huoQuErJiPinLei: '獲取二級品類失敗.原因：',
  huoQuMuDeDiDaL: '獲取目的地大類失敗.原因：',
  huoQuYiJiPinLei: '獲取一級品類失敗.原因：',
  departure: 'Departure',
  erJiMuDeDiZu: '二級目的地組',
  erJiMuDeDiZuId: '二級目的地組ID',
  yiJiMuDeDiZu: '一級目的地組',
  yiJiMuDeDiZuId: '一級目的地組ID',
  viewCommon: {
    AllCategory: {
      placeholder: {
        three: '目的地大類',
      },
    },
  },
  erJiPinLeiId: '二級品類ID',
  yiJiPinLeiId: '一級品類ID',
  data: {
    index: '序號',
  },
  muDeDiLieBiao: '目的地清單',
  erJiMuDeDiFenZ: '二級目的地分組',
  yiJiMuDeDiFenZ: '一級目的地分組',
  muDeDiSouSuo: '目的地蒐索',
  qiYongShiBaiYuan: '啟用失敗.原因：',
  qiYongChengGong: '啟用成功',
  tingYongShiBaiYu: '停用失敗.原因：',
  tingYongChengGong: '停用成功',
  queDingTingYong: '確定停用',
  queDingTingYongG: '確定停用該品類嗎？ 停用後該品類將不能被類目關聯，但不影響已關聯的類目！',
  bianJiPinLei: '編輯品類',
  xinZengPinLei: '新增品類',
  secondProductClassId: '二級品類',
  firstProductClassId: '一級品類',
  creditManage: {
    createTime: '創建時間',
  },
  chuangJianRen: '創建人',
  yiTingYong: '已停用',
  zhengChang: '正常',
  status: '狀態',
  shangJiPinLei: '上級品類',
  lvYouChanPin: '旅遊產品',
  pinLeiBianMa: '品類編號',
  tianJiaPinLei: '新增品類',
  pinLeiLieBiao: '品類清單',
  qingShuRuShangJi: '請輸入上級品類名稱',
  shangJiPinLeiMin: '上級品類名稱',
  qingShuRuPinLei: '請輸入品類名稱',
  pinLeiMingCheng: '品類名稱',
  pinLeiJiBie: '品類級別',
  pinLeiLeiXing: '品類類型',
  chaXunTiaoJian: '查詢條件',
  pinLeiXinZengShi: '品類新增失敗.原因：',
  pinLeiXinZengChe: '品類新增成功',
  pinLeiXiuGaiShi: '品類修改失敗.原因：',
  pinLeiXiuGaiChen: '品類修改成功',
  buNengChaoGuo_30: '不能超過30個字符',
  'qingShuRuPinLei-0': '請輸入品類名稱',
  'qingXuanZePinLei-1': '請選擇品類級別',
  qingXuanZePinLei: '請選擇品類類型',
  pinLeiMa: '品類碼',
  '380687-0': '目的地方向',
  '380687-3': '目的地組級別',
  '380687-6': '系列碼',
  '380687-7': '目的地組名稱',
  '380687-13': '境內',
  '380687-14': '境外',
  '380687-16': '目的地組修改成功',
  '380687-17': '目的地組修改失敗.原因：{0}',
  '380687-18': '目的地組新增成功',
  '380687-19': '目的地組新增失敗.原因：{0}',
  '814461-6': '上級目的地組',
  '814461-12': '新增目的地組',
  '814461-13': '目的地組編號',
  '814461-14': '目的地組碼',
  '814461-17': '一級目的地',
  '814461-18': '二級目的地',
  '814461-24': '停用',
  '814461-25': '啟用',
  '814461-26': '編輯目的地組',
  '814461-27':
    '確定停用該目的地分組嗎？ 停用後該目的地分組將不能被類目關聯，但不影響已關聯的類目！',
  '814461-32': '停用失敗.原因：{0}',
  '814461-34': '啟用失敗.原因：{0}',
  '220052-1': '系列名稱',
  '220052-2': '備註',
  '220052-7': '系列修改成功',
  '220052-8': '系列修改失敗.原因：{0}',
  '220052-9': '系列新增成功',
  '220052-10': '系列新增失敗.原因：{0}',
  '226456-3': '新增系列',
  '226456-4': '編號',
  '226456-14': '編輯系列',
  '226456-15': '確定停用該系列嗎？ 停用後該系列將不能被產品關聯，但不影響已關聯的產品！',
  chuFaChengShiDiQu: '出發城市/地區',
  qingXuanZeXuYaoPiLiangTingYongD: '請選擇需要批量停用的類目',
  piLiangTingYong: '批量停用',
  queDingTingYongGaiLeiMuMaTingYo:
    '確定停用該類目嗎？ 停用後該類目數據將不能被類目關聯，但不影響已關聯的類目！',
  tingYongShiBaiYuanYinResmessage: '停用失敗原因: ',
  queDingTingYongSuoXuanLeiMuMaTi:
    '確定停用所選類目嗎？停用後所選類目資料將不能被類目關聯，但不影響已關聯的類目！',
  tianJiaLeiMu: '新增類目',
  leiMuBianHao: '類目編號',
  'lvYouChanPin-1': '旅遊產品',
  'qingXuanZePinLei-0': '請選擇品類級別',
  form: 'Form',
  button: {
    cancel: '取消',
  },
  save: '保存',
  pleaseChoose: '請選擇',
  'pinLeiLeiXing-1': '品類類型',
}

export default {
  zhiDingChengGong: '置頂成功',
  quXiaoZhiDingChe: '取消置頂成功',
  shanChuChengGong: '删除成功',
  queRenShanChuRow: '確認删除《{0}》嗎',
  shenHeChengGong: '審核成功',
  button: {
    delete: '删除',
    edit: '編輯',
    detail: '詳情',
    deactivate: '停用',
    inquire: '査詢',
  },
  shenHeTongGuo: '審核通過',
  quXiaoZhiDing: '取消置頂',
  zhiDing: '置頂',
  field: {
    operate: '操作',
  },
  chuangZuoShiJian: '創作時間',
  'shenHeBuTongGuo-0': '審核不通過',
  'daiShenHe-0': '待審核',
  'caoGao-0': '草稿',
  status: '狀態',
  'banKuaiLeiXing-0': '版塊類型',
  gongGaoMingCheng: '公告名稱',
  shenHeBuTongGuo: '審核不通過',
  daiShenHe: '待審核',
  caoGao: '草稿',
  pleaseChoose: '請選擇',
  wenZhangLeiXing: '文章類型',
  banKuaiLeiXing: '版塊類型',
  qingShuRuBiaoTi: '標題或內容的關鍵詞',
  guanJianCi: '關鍵字',
  announcementList: {
    list: {
      name: '名稱',
      author: '作者',
      createDate: '創作日期',
      operation: '操作',
    },
    tabs: {
      audit: '待審核',
      published: '已發佈',
      draftBox: '草稿箱',
    },
    btn: {
      add: '新增公告',
    },
  },
  edit: {
    '126230-0': '版塊類型',
    '126230-1': '文章類型',
    '126230-2': '公告名稱',
    '126230-3': '公告正文',
    '126230-4': '關鍵字最多7個，關鍵字之間用逗號隔開。 不區分中英文逗號。',
    '126230-5': '關鍵字',
    '126230-6': '存為草稿',
    '126230-7': '提交審核',
    '126230-8': '取消保存',
    '126230-9': '請選擇版塊類型',
    '126230-10': '請輸入公告名稱',
    '126230-11': '請輸入公告正文',
    '126230-12': '請選擇文章類型',
    '126230-13': '請删除多餘的逗號',
    '126230-14': '關鍵字最多7個',
    '126230-15': '請輸入至少一個關鍵字',
    '126230-16': '保存成功',
  },
}

<template>
  <el-popover placement="left" trigger="click">
    <!--勾选可展示的列表数据-->
    <el-tree
      style="padding-right: 12px"
      node-key="value"
      :default-checked-keys="defaultCheckedKeys"
      :data="showLabels"
      check-on-click-node
      show-checkbox
      @check-change="handleNodeClick"
      check-strictly
    >
      <template #default="{ data }">
        <span>{{ data.label }}</span>
      </template>
    </el-tree>
    <span slot="reference" class="el-icon-s-operation show-filed-opt" />
  </el-popover>
</template>

<script>
import { setCol } from '@/store/localforage/service/twoTableCol'
import { getCacheKey, getFreeLabels, setFiledLabels } from './tool'
export default {
  name: 'TableShowField',
  inject: { getFreeLabels, setFiledLabels, getCacheKey },
  computed: {
    fieldLabels() {
      return this.getFreeLabels().filter((li) => !!li.label)
    },
  },
  data() {
    return {
      showLabels: [],
      defaultCheckedKeys: [],
    }
  },
  watch: {
    fieldLabels: {
      deep: true,
      immediate: true,
      handler(list) {
        const defaultCheckedKeys = []
        this.showLabels = list // JSON.parse(JSON.stringify(list))
        // console.log(list)
        list.forEach((li) => {
          if (li.show) defaultCheckedKeys.push(li.value)
          if (li.children.length) {
            li.children.forEach((tl) => {
              if (tl.show) defaultCheckedKeys.push(tl.value)
            })
          }
        })
        // 默认选中项
        this.defaultCheckedKeys = defaultCheckedKeys
      },
    },
  },
  methods: {
    handleNodeClick(data) {
      data.show = !data.show
      setCol(this.showLabels, this.getCacheKey())
      // this.setFiledLabels(this.showLabels)
    },
  },
}
</script>

<style scoped>
.show-filed-opt {
  margin-left: 12px;
  font-size: 24px;
  color: var(--primary-color);
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import pageDesign from './modules/pageDesign'
import orderDetails from './modules/orderDetails'
import collection from './modules/collection'
import booking from './modules/booking'
import { Lang } from '@/i18n/config'

const config = require('/config/config')

Vue.use(Vuex)

const state = {
  language: Lang.zhHk, // 默认中文
  version: new Date().getTime(), // 版本信息,利用版本信息的不一致，控制页面整体实例刷新
  upperRoute: null, // 上一个页面的路由
  userMenus: [], // 用户当前后台返回菜单地址
  userOrgInfo: null, // 用户组织信息，接口返回信息
  currentCompany: null, // 当前选择公司或门市，当前选择信息
  // 架构公司
  /*
  orgCompanyId: 15
  orgCompanyName: "中国旅游集团旅行服务有限公司"
  selected: true
*/
  orgCompany: null,
  // 页面置灰代码
  pageGreyedOut: false,
}

const mutations = {
  // 数据统一修改，避免多操作函数情况，缺陷：无法精细化操作变量，操作方式类似微信小程序操作
  set_data(state, data) {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        state[key] = data[key]
      } else if (typeof data[key] === 'object') {
        state[key] = { ...state[key], ...data[key] } // 保证差异更新
      } else {
        state[key] = data[key]
      }
    }
  },
  // vuex清除数据
  clear_data(state) {
    state.currentCompany = {}
    state.userOrgInfo = {}
    state.orgCompany = null

    localStorage.clear()
  },
  // 页面置灰参数
  setPageGreyedOut(state, data = false) {
    state.pageGreyedOut = data
  },
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  modules: {
    app,
    settings,
    user,
    pageDesign,
    orderDetails,
    collection,
    booking,
  },
  plugins: [
    createPersistedState({
      key: config.VuexStorageName,
      storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: (key) => localStorage.removeItem(key),
      },
    }),
  ],
})

export default store

import Vue from 'vue'
const mockStore = Vue.observable({
  levelList: [], // 面包屑菜单
  defaultActive: '', // 默认选中路由
  // 所有路由列表数据
  routerList: [],
  // 这是一个临时增加字段，用于便于内部人员展示全部菜单的功能
  allRouter: [
    {
      path: '',
      children: [],
      meta: {
        title: '首页',
        icon: 'el-icon-s-home',
      },
    },
    {
      path: '',
      meta: { title: '类目管理', icon: 'category' },
      children: [
        {
          meta: { title: '类目列表', icon: 'statement-template' },
          path: '/category/category-list',
        },
        {
          meta: { title: '品类列表', icon: 'product-class' },
          path: '/category/product-class',
        },
        {
          meta: { title: '目的地列表', icon: 'product-class' },
          path: '/category/destination-group',
        },
        {
          meta: { title: '出发地列表', icon: 'product-class' },
          path: '/category/departure',
        },
        {
          meta: { title: '主题列表', icon: 'theme' },
          path: '/category/theme',
        },
        {
          meta: { title: '全类目树', icon: 'tree' },
          path: '/category/all-category',
        },
      ],
    },
    {
      path: '',
      meta: {
        title: '确认管理',
        icon: 'confirm',
      },
      children: [
        {
          path: '/confirm/occupy',
          children: [],
          meta: {
            title: '占位单查询',
            icon: 'occupy',
          },
        },
        {
          path: '/confirm/confirm',
          children: [],
          meta: {
            title: '确认单查询',
            icon: 'confirm',
          },
        },
        {
          path: '/confirm/assloss',
          children: [],
          meta: {
            title: '核损单查询',
            icon: 'assloss',
          },
        },
        {
          path: '/confirm/voucher',
          children: [],
          meta: {
            title: '凭证回传查询',
            icon: 'voucher',
          },
        },
      ],
    },
    {
      path: '',
      meta: {
        title: '订单系统',
        icon: 'el-icon-tickets',
      },
      children: [
        {
          path: '/order-sys/order-intention',
          meta: {
            title: '意向单列表',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/order-sys/order-list',
          meta: {
            title: '订单列表',
            icon: 'el-icon-s-order',
          },
        },
      ],
    },
    {
      path: '',
      meta: { title: '产品系统', icon: 'product-class' },
      children: [
        {
          meta: { title: '机酒产品列表', icon: 'product-class' },
          path: '/product-sys/machine-wine-list',
        },
      ],
    },
    {
      path: '',
      meta: { title: '知识库', icon: 'el-icon-document-add' },
      children: [
        {
          meta: { title: '知识库存储', icon: 'el-icon-edit-outline' },
          path: '/wiki-sys/wiki',
        },
        {
          meta: { title: '知识搜索', icon: 'el-icon-search' },
          path: '/wiki-search',
        },
      ],
    },
    {
      path: '',
      meta: { title: '系统设置', icon: 'el-icon-s-tools' },
      children: [
        {
          meta: { title: '系统菜单', icon: 'tree' },
          path: '/sys-menu-set',
        },
      ],
    },
    {
      path: '',
      meta: { title: '基础数据', icon: 'el-icon-coin' },
      children: [
        {
          meta: { title: '公司列表', icon: 'el-icon-office-building' },
          path: '/basedata-company',
        },
        {
          meta: { title: '门市列表', icon: 'el-icon-s-shop' },
          path: '/basedata-store',
        },
      ],
    },
    {
      path: '',
      meta: { title: '用户管理', icon: 'user' },
      children: [
        {
          meta: { title: '用户与组织', icon: 'el-icon-school' },
          path: '/user-org',
        },
        {
          meta: { title: '角色管理', icon: 'el-icon-s-custom' },
          path: '/role-mange',
        },
      ],
    },
    {
      path: '',
      meta: { title: '工具集', icon: 'el-icon-s-tools' },
      children: [
        {
          meta: { title: '携程酒店工具', icon: 'el-icon-s-platform' },
          path: '/tools/tools-room-query',
        },
        {
          meta: { title: '海报查询', icon: 'dashboard' },
          path: '/posterToIn',
        },
        {
          meta: { title: '海报统计', icon: 'statement-line' },
          path: '/posterToIn/statement',
        },
      ],
    },
    {
      path: '/commission-sys',
      meta: {
        title: '佣金管理',
        icon: 'el-icon-tickets',
      },
      children: [
        {
          path: '/commission-sys/commission-rules',
          meta: {
            title: '佣金规则',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/commission-sys/commission-bill',
          meta: {
            title: '佣金账单',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/commission-sys/commission-details',
          meta: {
            title: '佣金明细',
            icon: 'el-icon-tickets',
          },
        },
      ],
    },
    {
      path: '',
      meta: {
        title: '团结算管理',
        icon: 'el-icon-tickets',
      },
      children: [
        {
          path: '/settlement/settlement-query',
          meta: {
            title: '团结算单查询',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/settlement/settlement-check',
          meta: {
            title: '团结算单审核',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/settlement/settlement-carry-over',
          meta: {
            title: '团结算单结转',
            icon: 'el-icon-tickets',
          },
        },
      ],
    },
    {
      path: '',
      meta: {
        title: '收款管理',
        icon: 'el-icon-tickets',
      },
      children: [
        {
          path: '/receive-pay/receive',
          meta: {
            title: '收款查询',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/refund-query/refund',
          meta: {
            title: '退款查询',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/collection-payment',
          meta: {
            title: '代收代付查询',
            icon: 'el-icon-tickets',
          },
        },
      ],
    },
    {
      path: '',
      meta: {
        title: '开票管理',
        icon: 'el-icon-tickets',
      },
      children: [
        {
          path: '/invoice/sale-query',
          meta: {
            title: '销项票查询',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/invoice/invoicing-voiding',
          meta: {
            title: '开票与作废',
            icon: 'el-icon-tickets',
          },
        },
      ],
    },
    {
      path: '',
      meta: {
        title: '预付管理',
        icon: 'el-icon-tickets',
      },
      children: [
        {
          path: '/pre-pay/pre-pay-query',
          meta: {
            title: '预付款查询',
            icon: 'el-icon-tickets',
          },
        },
      ],
    },
    {
      path: '',
      meta: {
        title: '付款管理',
        icon: 'el-icon-tickets',
      },
      children: [
        {
          path: '/payment/apply-list',
          meta: {
            title: '付款申请列表',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/payment/reconciliation-query',
          meta: {
            title: '付款申请',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/payment/payment-query',
          meta: {
            title: '付款查询',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/payment/payment-review',
          meta: {
            title: '应付单审核',
            icon: 'el-icon-tickets',
          },
        },
        {
          path: '/payment/supplier-statement',
          meta: {
            title: '供应商对账列表',
            icon: 'el-icon-tickets',
          },
        },
      ],
    },
    {
      path: '',
      meta: {
        title: '收票管理',
        icon: 'el-icon-tickets',
      },
      children: [
        {
          path: '/receipt/cost-ticket-query',
          meta: {
            title: '成本票查询',
            icon: 'el-icon-tickets',
          },
        },
      ],
    },
  ],

  announcementRouter: [
    {
      id: 1,
      name: '公告列表',
      desc: '',
      icon: 'menu-sales-platform',
      url: '',
      parentId: 0,
      index: 2,
      isOpenPage: false,
      path: '/announcements',
      meta: { title: '公告列表', icon: 'menu-sales-platform', isLink: true },
    },

    {
      id: 2,
      name: '公告详情',
      desc: '',
      icon: 'el-icon-s-promotion',
      url: '/announcement-view',
      parentId: 6,
      index: 0,
      isOpenPage: false,
      path: '/announcement-view',
      meta: { title: '公告详情', icon: 'el-icon-s-promotion' },
      children: [],
      applyTo: [],
      links: '2-0',
    },
  ],
})
export { mockStore }

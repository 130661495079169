export default {
  '648607-0': '資源名稱',
  '648607-1': '資源類型',
  '648607-2': '供應商產品名稱',
  '648607-3': '單位',
  '648607-4': '景區',
  '648607-5': '可用人數',
  '648607-6': '資源負責人',
  '648607-7': '自營',
  '648607-8': '有場次',
  '648607-9': '無場次',
  '648607-10': '負責人部門',
  '648607-11': '資源供應商',
  '648607-12': '供應商負責人',
  '648607-13': '行程天數',
  '648607-14': '天',
  '648607-16': '集合城市',
  '648607-17': '集合時間',
  '648607-18': '散團城市',
  '648607-19': '散團時間',
  '648607-20': '場次',
  '648607-21': '末站目的地城市',
  '648607-22': '是否包含往返交通',
  '648607-23': '往返交通',
  '648607-24': '交通信息說明',
  '648607-25': '是否含門票',
  '648607-26': '門票數量',
  '648607-27': '成人數',
  '648607-28': '兒童數',
  '648607-29': '每單最多預訂',
  '648607-30': '資源銷售須知',
  '095903-0': '費用包含',
  '095903-1': '費用不包含',
  '095903-2': '大交通',
  '095903-3': '住宿',
  '095903-4': '餐食',
  '095903-5': '隨團服務人員',
  '095903-6': '地面交通',
  '095903-7': '門票及地面項目',
  '095903-8': '簽證/簽注',
  '095903-9': '兒童價標準',
  '095903-10': '旅遊意外險',
  '095903-11': '離境稅',
  '095903-12': '簽證費用',
  '095903-13': '兒童附加費',
  '095903-14': '補充',
  '276801-0': '預訂限制',
  '276801-1': '退改規則',
  '276801-2': '預訂須知',
  '276801-3': '簽證須知',
  '276801-4': '安全須知',
  '276801-5': '證件（國籍）限制',
  '276801-6': '年齡限制',
  '276801-7': '人群限制',
  '276801-8': '單房差',
  '276801-9': '預訂說明',
  '276801-10': '產品說明',
  '276801-11': '交通說明',
  '276801-12': '住宿說明',
  '276801-13': '用餐說明',
  '276801-14': '行程安排說明',
  '276801-15': '購物說明',
  '276801-16': '差價說明',
  '276801-17': '出遊通知',
  '276801-18': '成團說明',
  '276801-19': '班機變動申明',
  '276801-20': '意見回饋',
  '276801-21': '溫馨提示',
  '276801-22': '免簽',
  '276801-23': '落地簽',
  '276801-24': '特別提示',
  '276801-25': '出發前準備事項',
  '276801-26': '交通安全',
  '276801-27': '人身安全',
  '276801-28': '財物安全',
  '276801-29': '證件安全',
  '276801-30': '遊覽安全',
  '276801-31': '住宿安全',
  '276801-32': '飲食和藥品安全',
  '276801-33': '自由活動期間安全',
  '276801-34': '突發事件',
  '276801-35': '高危項目安全注意事項',
  '276801-36': '自駕車安全注意事項',
  '276801-37': '其他',
  '437923-0': '首選圖片',
  '437923-1': '資源特色',
  '437923-2': '資源推薦',
  '437923-3': '產品特色',
  '437923-4': '產品賣點',
  '630259-0': '是否可搭配大交通',
  '630259-1': '最晚抵達時間',
  '630259-2': '最早返回時間',
  '630259-3': '否',
  '630259-4': '是',
  '727882-0': '庫存看板',
  '727882-1': '資源詳情',
  '056259-0': '審批通過',
  '056259-1': '審批駁回',
  '056259-2': '您確認審批通過該資源信息？',
  '219632-1': '駁回原因',
  '219632-2': '請輸入駁回原因',
}

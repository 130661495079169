export default {
  salesDate: '銷售日期',
  totalInventory: '總庫存',
  orderSalesInventory: '訂單銷售庫存',
  availableStock: '可用庫存',
  grossProfitMarginOnSales: '銷售毛利率',
  inventorySalesProgress: '庫存銷售進度',
  timeSchedule: '時間進度',
  checkResource: '查看資源',
  owner: '負責人',
  ownerDept: '負責人部門',
}

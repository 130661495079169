export default function BaseFlight(ajax, config) {
  return {
    flight: {
      list: (opt) => ajax({ url: `/flight-number/list`, method: 'get', ...opt }),
      info: (opt) => ajax({ url: `/flight-number/get-flight-detail`, method: 'post', ...opt }),
      carrierPolicy: (opt) =>
        ajax({ url: `/flight-number/find-carrier-policy`, method: 'get', ...opt }),
      // 根据航班起飞日期获取航班详情
      getDateFlightDetail: (opt) =>
        ajax({ url: `/flight-number/get-by-flight-no-and-date`, method: 'post', ...opt }),
    },
  }
}

import { BaseApi } from './couplingAjax'
import config from './config'

import Nbs from './apis/Nbs'
import CategoryServer from './apis/CategoryServer'
import IntentionServer from './apis/IntentionServer'
import IntentionTemplateServer from './apis/IntentionTemplateServer'
import OrderServer from './apis/OrderServer'
import ProductServer from './apis/ProductServer'
import BaseServer from './apis/BaseServer'
import Wiki from './apis/Wiki'
import ConfirmServer from './apis/ConfirmServer'
import AuthServer from './apis/AuthServer'
import AdapterServer from './apis/AdapterServer'
import CommissionServer from './apis/CommissionServer'
import PoiServer from './apis/PoiServer.js'
import InventoryServer from './apis/InventoryServer.js' //库存中心
import PricingCenter from './apis/PricingCenter'
import Cfs from './apis/Cfs'
import ResourceServer from './apis/ResourceServer'
import JourneyServer from './apis/JourneyServer'
import VendorServer from './apis/VendorServer' //供应商
import ProductCenterServer from './apis/ProductCenterServer'
import OrderCenterServer from './apis/OrderCenterServer'
import PosterServer from './apis/PosterServer' // 海报系统
import FinancialSys from './apis/FinancialSys'
import FinancialAdapterServer from './apis/FinancialAdapterServer'
import CommunityManageServer from './apis/CommunityManageServer'
import ResourceCenterServer from './apis/ResourceCenterServer'
import BaseChannelServer from './apis/BaseChannelServer'
import FilePreview from './apis/FilePreview.js'
import PmsServer from './apis/PmsServer' //促销
import CreditServer from './apis/CreditServer' //授信
import BaseFlight from './apis/BaseFlight' // 飞机信息服务
import MobManageServer from './apis/MobManageServer' // 广告位配置
import RouterServer from './apis/RouterServer' // 供应商系统
import HomeManage from './apis/HomeManage' // 内容管理
import CommunityShopKeeperServer from './apis/CommunityShopKeeperServer' // 供应商系统
import VisaServer from './apis/VisaServer' // 签证系统
import ProcessServer from './apis/ProcessServer' // 流程

import GuideServer from './apis/GuideServer' //导游
import HotelManage from './apis/HotelManage'
import FinanceServer from './apis/FinanceServer'
import StockServer from './apis/StockServer'
import MemberServer from './apis/MemberServer'
import SearchHotelServer from './apis/SearchHotelServer'
import i18n from '@/i18n'

// 所有微服务https://sit-private-api.cits.com.cn/nbs/doc.html

// 针对不同服务进行差异化定制
const nbs = (opt) =>
  BaseApi(opt, {
    prefix: config.gateway, // 路径前缀
    dataField: 'data', // 取值字段
    codeField: 'success', // 判断正确错误字段
    codeNum: true, // 返回是否正确取值
    msgField: 'message', // 提示信息获取
    tipsCode: 'errorCode', // 错误号
  })
// 后端服务汇总地址：https://sit-private-api.cits.com.cn/nbs/doc.html

// 类目系统: http://sit-private-api.cits.com.cn/nbs/category-server/doc.html
const categoryServer = (opt) => BaseApi(opt, { prefix: config.CategoryServer })
// 意向单服务: http://sit-private-api.cits.com.cn/nbs/intention-server/doc.html
const intentionServer = (opt) => BaseApi(opt, { prefix: config.IntentionServer })
// 意向单模板: https://sst-private-api.cits.com.cn/nbs/intention-server/doc.html
const intentionTemplateServer = (opt) => BaseApi(opt, { prefix: config.IntentionTemplateServer })
// 订单服务 http://sit-private-api.cits.com.cn/nbs/order-server/doc.html
const orderServer = (opt) => BaseApi(opt, { prefix: config.OrderServer })
// 产品服务: http://sit-private-api.cits.com.cn/nbs/product-server/doc.html
const productServer = (opt) => BaseApi(opt, { prefix: config.ProductServer })
// 公共服务: http://sit-private-api.cits.com.cn/nbs/base-server/doc.html
const baseServer = (opt) => BaseApi(opt, { prefix: config.BaseServer })
// 知识库 http://sit-private-api.cits.com.cn/nbs/kbr-server/doc.html
const wiki = (opt) => BaseApi(opt, { prefix: config.Wiki })
// 确认管理 http://sit-private-api.cits.com.cn/nbs/confirm-server/doc.html
const confirmServer = (opt) => BaseApi(opt, { prefix: config.ConfirmServer })
// 授权服务 http://sit-private-api.cits.com.cn/nbs/auth-server/doc.html
const authServer = (opt, other = {}) => BaseApi(opt, { prefix: config.AuthServer, ...other })
// 适配服务: http://sit-private-api.cits.com.cn/nbs/adapter-server/doc.html
const adapterServer = (opt) => BaseApi(opt, { prefix: config.AdapterServer })
// 佣金管理 https://sit-private-api.cits.com.cn/nbs/commission-server/doc.html
const commissionServer = (opt) => BaseApi(opt, { prefix: config.CommissionServer })
// 海报系统 https://sit-private-api.cits.com.cn/nbs/poster-server/doc.html
const posterServer = (opt) => BaseApi(opt, { prefix: config.PosterServer })

// 计划弃用-业财服务 http://sit-private-api.cits.com.cn/nbs/finance-server/doc.html
const financialSys = (opt) => BaseApi(opt, { prefix: config.FinancialSys })
// 业财适配服务 https://sit-private-api.cits.com.cn/nbs/finance-adapter/doc.html
const financialAdapterServer = (opt) => BaseApi(opt, { prefix: config.FinancialAdapterServer })
// 促销服务 https://sit-private-api.cits.com.cn/nbs/pms-server/doc.html
const pmsServer = (opt) => BaseApi(opt, { prefix: config.PmsServer })
const creditServer = (opt) => BaseApi(opt, { prefix: config.CreditServer })
// 签证系统
const visaServer = (opt) => BaseApi(opt, { prefix: config.VisaServer })
const processServer = (opt) => BaseApi(opt, { prefix: config.ProcessServer })

export default {
  Nbs: Nbs(nbs, config),
  categoryServer: CategoryServer(categoryServer, config),
  IntentionServer: IntentionServer(intentionServer, config),
  IntentionTemplateServer: IntentionTemplateServer(intentionTemplateServer, config),
  OrderServer: OrderServer(orderServer, config),
  ProductServer: ProductServer(productServer, config),
  BaseServer: BaseServer(baseServer, config),
  Wiki: Wiki(wiki, config),
  ConfirmServer: ConfirmServer(confirmServer, config),
  AuthServer: AuthServer(authServer, config),
  AdapterServer: AdapterServer(adapterServer, config),
  CommissionServer: CommissionServer(commissionServer, config),
  FinancialSys: FinancialSys(financialSys, config), // 旧业财
  FinanceServer: FinanceServer(financialSys, config),
  FinancialAdapterServer: FinancialAdapterServer(financialAdapterServer, config),
  CreditServer: CreditServer(creditServer, config),
  PmsServer: PmsServer(pmsServer, config),
  ProcessServer: ProcessServer(processServer, config),
  // poi系统
  PoiServer: PoiServer((opt) => BaseApi(opt, { prefix: config.PoiServer }), config),

  //库存中心
  InventoryServer: InventoryServer(
    (opt) => BaseApi(opt, { prefix: config.InventoryServer }),
    config,
  ),
  // 供应商BaseApi
  VendorServer: VendorServer((opt) => BaseApi(opt, { prefix: config.VendorServer }), config),
  // 价格中心
  PricingCenter: PricingCenter((opt) => BaseApi(opt, { prefix: config.PricingCenter }), config),
  // 文件
  Cfs: Cfs(),
  // 资源系统
  ResourceServer: ResourceServer((opt) => BaseApi(opt, { prefix: config.ResourceServer }), config),
  // 行程编辑器
  JourneyServer: JourneyServer((opt) => BaseApi(opt, { prefix: config.JourneyServer }), config),
  // 产品中心
  ProductCenterServer: ProductCenterServer(
    (opt) => BaseApi(opt, { prefix: config.ProductCenterServer }),
    config,
  ),
  // 订单中心
  OrderCenterServer: OrderCenterServer(
    (opt) => BaseApi(opt, { prefix: config.OrderCenterServer }),
    config,
  ),
  PosterServer: PosterServer(posterServer, config),
  // 云店内容管理服务 https://sit-private-api.cits.com.cn/nbs/community-manage/doc.html
  CommunityManageServer: CommunityManageServer(
    (opt) => BaseApi(opt, { prefix: config.CommunityManageServer }),
    config,
  ),
  // 小程序运营
  MobManageServer: MobManageServer(
    (opt) => BaseApi(opt, { prefix: config.MobMangeServer }),
    config,
  ),
  // 资源中心
  ResourceCenterServer: ResourceCenterServer(
    (opt) => BaseApi(opt, { prefix: config.ResourceCenterServer }),
    config,
  ),
  // 会员渠道服务
  BaseChannelServer: BaseChannelServer(
    (opt) => BaseApi(opt, { prefix: config.BaseChannelServer }),
    config,
  ),
  AdvertiseConfigServer: FilePreview(
    (opt) => BaseApi(opt, { prefix: config.AdvertiseConfigServer }),
    config,
  ),
  ManageHomeIconServer: FilePreview(
    (opt) => BaseApi(opt, { prefix: config.ManageHomeIconServer }),
    config,
  ),
  FilePreview: FilePreview((opt) => BaseApi(opt, { prefix: config.FilePreview }), config),
  // 基础航班信息
  BaseFlight: BaseFlight((opt) => BaseApi(opt, { prefix: config.BaseFlight }), config),
  // 供应商系统
  RouterServer: RouterServer((opt) => BaseApi(opt, { prefix: config.RouterServer }), config),
  GuideServer: GuideServer((opt) => BaseApi(opt, { prefix: config.GuideServer }), config),
  HomeManage: HomeManage((opt) => BaseApi(opt, { prefix: config.HomeManage }), config),
  // 云店店主系统
  CommunityShopKeeperServer: CommunityShopKeeperServer(
    (opt) => BaseApi(opt, { prefix: config.CommunityShopKeeperServer }),
    config,
  ),
  //签证系统
  VisaServer: VisaServer((opt) => BaseApi(opt, { prefix: config.VisaServer }), config),
  HotelManage: HotelManage((opt) => BaseApi(opt, { prefix: config.HotelManage }), config),
  StockServer: StockServer((opt) => BaseApi(opt, { prefix: config.StockServer }), config),
  // 会员系统
  MemberServer: MemberServer((opt) => BaseApi(opt, { prefix: config.MemberServer }), config),
  // 酒店搜索系统
  SearchHotelServer: SearchHotelServer(
    (opt) => BaseApi(opt, { prefix: config.SearchHotelServer }),
    config,
  ),
}

export default {
  '242598-0': '費用包含',
  '242598-1': '大交通',
  '242598-2': '請選擇',
  '242598-3': '選填，可在這裡說明具體的航線信息',
  '242598-4': '往返出發地到出行集合地的聯運航班機票。',
  '242598-5': '如有補充請在這裡說明',
  '242598-6': '住宿',
  '242598-7': '酒店標準',
  '242598-8': '間。',
  '242598-9': '如有補充信息請在這裡注明',
  '242598-10': '餐食',
  '242598-11': '行程內所列餐食，',
  '242598-12': '隨團服務人員',
  '242598-13': '當地中文導遊服務。',
  '242598-14': '中文領隊兼導遊服務。',
  '242598-15': '領隊和當地中文導遊服務。',
  '242598-16': '地面交通',
  '242598-17': '安排當地接送機（站）服務，含',
  '242598-18': '跟團遊期間用車費用',
  '242598-19': '輸入費用',
  '242598-20': '安排當地專屬用車（除部分特殊路段因當地規定及安全考量，則依規定派遣小型車）。',
  '242598-21': '門票及地面項目',
  '242598-22': '行程中所列景點首道大門票',
  '242598-23': '選填，如有補充信息請在這裡說明',
  '242598-24': '簽證/簽注',
  '242598-25': '輸入國家簡稱',
  '242598-26': '兒童價標準',
  '242598-27': '年齡',
  '242598-28': '輸入數字',
  '242598-29': '周歲（含），',
  '242598-30': '選填，請說明兒童服務標準。',
  '242598-31': '身高',
  '242598-32': '米（含），',
  '242598-33': '兒童價特殊說明',
  '242598-34': '可在這裡說明具體的兒童價特殊說明信息',
  '242598-35': '費用中已經包含的其他費用描述',
  '242598-36': '異地出發客人自行前往出行集合地的交通補貼。',
  '242598-37': '旅遊意外險',
  '242598-38': '旅遊人身意外保險。',
  '242598-39': '費用不包含',
  '242598-40': '離境稅',
  '242598-41': '您所支付的費用中不包含',
  '242598-42': '輸入幣種',
  '242598-43': '/人的離境稅價格。 請在出入境時自行準備好機場稅的費用。',
  '242598-44': '簽證費用',
  '242598-45': '本產品不包含',
  '242598-46': '輸入國家-金額等',
  '242598-47': '簽證費用，敬請自理。',
  '242598-48': '/人的簽證費用。 請在出入境時，自備有效簽證。',
  '242598-49': '不含團隊旅遊L簽送關服務費。',
  '242598-50': '兒童附加費',
  '242598-51': '團隊中兒童的價格均為不占床含早餐的價格。',
  '242598-52': '位成人攜帶',
  '242598-53': '位兒童出行,兒童必須占床。',
  '242598-54': '由於',
  '242598-55': '歲以下兒童為含早不占床之報價，如需占床，請聯系客服處理。',
  '242598-56': '稅的名稱',
  '242598-57': '稅。 實際費用以當地實際情況為准，請與當地支付。',
  '242598-58': '全程不含餐，敬請自理。',
  '242598-59': '兒童價不含往返火車票。',
  '242598-60': '您乘坐的',
  '242598-61': '航班號',
  '242598-62': '航班不提供行李免費托運。',
  '242598-63': '目的地',
  '242598-64': '住宿費用敬請自理。',
  '242598-65': '全程只含',
  '242598-66': '具體內容',
  '242598-67': '，其餘餐食敬請自理。',
  '242598-68': '景區名稱',
  '242598-69': '景點門票。',
  '242598-70': '出發地',
  '242598-71': '費用。',
  '242598-72': '本產品不包含如下景區小景點門票',
  '242598-73': '景區名稱+具體小景點',
  '242598-74': '本產品不包含如下景區項目費用',
  '242598-75': '景區名稱+小交通/纜車/講解費用/遊船等項目費用',
  '242598-76': '如有說明信息可在此維護',
  '242598-77': '補充',
  '242598-78': '單房差，如果出現單男單女參團無法拼住或加床時，需補單人房差可入住。',
  '242598-79': '您的行程中不包含',
  '242598-80':
    '，根據當地的風俗習慣和更好的激勵隨團人員的服務，建議您可在行程中支付小費（自願），支付給',
  '242598-81': '相關人員',
  '242598-82': '，共計',
  '242598-83': '費用+貨幣',
  '242598-84': '/人。',
  '242598-85': '出入境個人物品海關徵稅。',
  '242598-86':
    '超重行李的托運費、保管費；因交通延阻、罷工、天氣、機器故障等不可抗力原因所導致的額外費用：酒店內洗衣、理髮、電話、傳真、收費電視、飲品、煙酒等個人消費；自由活動期間的用車服務；提供導遊服務的產品在自由活動期間無陪同服務。',
  '242598-87': '當地參加自費以及“費用包含”中不包含的其他項目。',
  '242598-88': '人力不可抗拒因素（如自然因素，政策性調價等）造成行程調整而新增的費用；',
  '242598-89': '景區名稱+具體小景點',
  '242598-90': '景區名稱+小交通/纜車/講解費用/遊船等項目費用',
}

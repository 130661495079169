export default {
  Add: {
    '759382-0': '發票信息',
    '759382-1': '編輯',
    '759382-2': '是否需要發票',
    '759382-3': '需要',
    '759382-4': '不需要',
    '759382-5': '發票性質',
    '759382-6': '電子發票',
    '759382-7': '紙質發票',
    '759382-8': '抬頭類型',
    '759382-9': '個人',
    '759382-10': '企業',
    '759382-11': '抬頭名稱',
    '759382-12': '稅號',
    '759382-13': '取消',
    '759382-14': '確定',
    '759382-15': '請選擇是否需要發票',
    '759382-16': '請選擇發票性質',
    '759382-17': '請選擇抬頭類型',
    '759382-18': '請輸入發票抬頭',
    '759382-19': '請輸入稅號',
  },
}

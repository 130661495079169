// 常见的公共名称
import common from './common'
// 组件部分，包括业务和非业务，用组件名称命名文件
import components from './components'
// 产品系统页面
import pages from './pages'
// 后台字段，根据服务划分
import backField from './back'
// 公共服务,包含一些工具函数等
import tool from './tool'
// 静态变量文件夹
import constant from './const'
// 逐步替换，开始中文为 key 的词汇
import words from './words'
import cnkey from './cnkey'
export default {
  // 公共字段,表示常用的非业务
  common,
  components,
  ...components,
  tool,
  ...backField,
  ...pages,
  constant,
  ...words,
  ...cnkey,
}

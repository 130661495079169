export default {
  '982961-0': '待提交',
  '982961-1': '審批中',
  '982961-2': '已通過',
  '982961-3': '已駁回',
  '535855-0': '1、待提交狀態：',
  '535855-1': '資源負責人待提交審批的資源草稿信息。',
  '535855-2': '2、審批中狀態：',
  '535855-3': '資源負責人已經提交的資源草稿信息，只有撤銷審批後才可以編輯。',
  '535855-4': '3、已通過狀態：',
  '535855-5':
    '已通過審批的資源，可以被產品打包，或用於訂單的動態打包銷售。 資源草稿信息在審批通過後將更新至已通過資源上。',
  '535855-6': '4、供應商資源：',
  resourceCode: '資源碼',
  resource: '資源',
  rules: {
    resourceCode: '請輸入資源碼',
  },
  '648429-0': '資源編號',
  '648429-1': '請輸入資源編號',
  '648429-2': '資源名稱',
  '648429-3': '請輸入資源名稱',
  '648429-4': '資源類型',
  '648429-5': '請選擇',
  '648429-6': '資源負責人',
  '648429-7': '供應商',
  '648429-8': '請輸入供應商名稱',
  '648429-9': '査詢',
  '648429-10': '請輸入數字',
  '738150-0': '新建資源',
  '738150-1': '從其他組織導入資源',
  '486199-1': '資源編號',
  '486199-2': '請輸入資源編號',
  '486199-3': '査詢',
  '486199-4': '從',
  '486199-5': '導入到',
  '486199-6': '系列（非必填）',
  '486199-7': '填寫目的地+幾天幾晚+產品類型',
  '486199-8': '請輸入資源副標題',
  '486199-9': '資源類型',
  '486199-10': '請選擇',
  '486199-11': '計價單位',
  '486199-12': '計價單位將對客呈現，目前有：',
  '486199-13': '1、人：按人管理成本價和庫存，對客呈現元/人，可按成人、兒童預訂。',
  '486199-14': '2、套：按套管理成本價和庫存，對客呈現元，預訂時不區分成人、兒童，購買套數。',
  '486199-15':
    '3、張：按人群（成人、兒童、老人、學生）管理成本價和庫存，對客呈現元，僅需維護成人價。',
  '486199-16': '導入內容',
  '486199-17': '全部',
  '486199-18': '僅導入資源基本信息',
  '486199-19': '資源負責人',
  '486199-20': '負責人部門',
  '486199-21': '資源供應商',
  '486199-22': '請選擇供應商',
  '486199-23': '跨公司導入資源，需要維護導入後資源的負責人、負責人部門、資源供應商信息。',
  '486199-24': '請先選擇資源',
  '486199-25': '請輸入數字',
  '486199-26': '資源主標題必填，不能為空',
  '486199-27': '產品名稱總長度不能超過70',
  '486199-28': '請選擇資源類型',
  '486199-29': '請選擇計價單位',
  '486199-30': '資源負責人必填，不能為空',
  '486199-31': '負責人部門必填，不能為空',
  '486199-32': '資源供應商必填，不能為空',
  '486199-33': '複製',
  '486199-34': '請先査詢資源信息',
  '486199-35': '資源導入成功，請到待提交中查看',
  '486199-36': '跨公司導入資源，需要維護導入後資源的負責人、',
  '486199-37': '、資源供應商信息。',
  '457408-0': '複製資源',
  '457408-1': '從',
  '457408-2': '複製到',
  '457408-3': '系列（非必填）',
  '457408-4': '填寫目的地+幾天幾晚+產品類型',
  '457408-5': '請輸入資源副標題',
  '457408-6': '資源類型',
  '457408-7': '請選擇',
  '457408-8': '計價單位',
  '457408-9': '計價單位將對客呈現，目前有：',
  '457408-10': '1、人：按人管理成本價和庫存，對客呈現元/人，可按成人、兒童預訂。',
  '457408-11': '2、套：按套管理成本價和庫存，對客呈現元，預訂時不區分成人、兒童，購買套數。',
  '457408-12':
    '3、張：按人群（成人、兒童、老人、學生）管理成本價和庫存，對客呈現元，僅需維護成人價。',
  '457408-13': '複製內容',
  '457408-14': '全部',
  '457408-15': '僅複製資源基本信息',
  '457408-16': '資源主標題必填，不能為空',
  '457408-17': '產品名稱總長度不能超過70',
  '457408-18': '請選擇資源類型',
  '457408-19': '請選擇計價單位',
  '457408-20': '複製',
  '224420-1': '資源編號',
  '224420-2': '資源名稱',
  '224420-3': '來自供應商COP系統的資源',
  '224420-4': '資源類型',
  '224420-5': '行程天數',
  '224420-6': '天',
  '224420-7': '晚',
  '224420-8': '資源負責人',
  '224420-9': '操作',
  '224420-10': '詳情',
  '224420-11': '編輯',
  '224420-12': '退回原因',
  '224420-13': '提交審批',
  '224420-14': '撤銷審批',
  '224420-15': '庫存看板',
  '224420-16': '複製',
  '224420-17': '删除',
  '224420-18': '操作記錄',
  '224420-19': '您確定提交審批該資源嗎',
  '224420-20': '確定提交',
  '224420-21': '關閉',
  '224420-22': '您確定删除該資源草稿嗎？',
  '224420-23': '確定删除',
  '224420-24': '删除成功',
  '224420-25': '您確定撤銷審批該資源嗎？',
  '224420-26': '確定',
  '224420-27': '操作項',
  '224420-0': '操作內容',
  '224420-28': '操作人',
  '224420-30': '操作時間',
  '691593-0': '退回原因',
  '691593-1': '詳細說明',
  '691593-2': '審批人',
  '117058-0':
    '參照資源最新信息生成一條本地的草稿，草稿內容不對客呈現，僅在審批通過後更新至已通過的資源信息。',
  '117058-1': '提示',
  '691593-3': '待審核狀態——資源專員已提交審批的資源信息。',
  '691593-4': '已通過狀態——通過審批後，方能被產品選擇打包。',
  '691593-5': '已駁回狀態——所提交的資源信息沒有通過審批，需要根據審批意見做修改。',
  daiShenPi: '待審核',
  yiTongGuo: '已通過',
  yiBoHui: '已駁回',
  shenPi: '審批',
  chaKan: '查看',
}

export default {
  right: {
    current: '當前知識庫',
    selectTips: '請選擇知識庫',
    tips: '請輸入問題編碼或問題描述或知識主題',
    problem: '問題編碼',
    des: '問題描述',
    theme: '知識主題',
    confirm: '確定删除該知識問題嗎？',
    updateUser: '更新人',
    updateTime: '更新時間',
  },
  rightEdit: {
    answer: '解決方案',
    nameTips: '請輸入問題名稱',
    answerTips: '請輸入解決方案',
    confirm: '解決方案還未保存，確定要關閉嗎',
  },
  left: {
    tips: '請輸入知識庫名稱',
    confirm: '確認要删除該知識庫嗎？',
  },
  leftEdit: {
    type: '知識庫類型',
    typeTips: '請選擇知識庫類型',
    name: '知識庫名稱',
    nameTips: '請輸入知識庫名稱',
    des: '知識庫描述',
    desTips: '請輸入描述',
    len: '名稱不能超過15個字符',
    add: '新增知識庫',
    edit: '修改知識庫',
  },
}

const basePath = '/community-manage/'

export default [
  {
    path: basePath + 'banner-manage',
    name: basePath + 'banner-manage',
    component: () => import('@/views/communityManage/bannerManage'),
    meta: { title: 'banner管理' },
  },
  {
    path: basePath + 'product-recommend',
    name: basePath + 'product-recommend',
    component: () => import('@/views/communityManage/productRecommend'),
    meta: { title: '产品推荐' },
  },
  {
    path: basePath + 'copywriting-review',
    name: basePath + 'copywriting-review',
    component: () => import('@/views/communityManage/copywritingReview'),
    meta: { title: '文案审核' },
  },
  {
    path: basePath + 'manage-shop-keeper',
    name: basePath + 'manage-shop-keeper',
    component: () => import('@/views/communityManage/manageShopKeeper'),
    meta: { title: '店主管理' },
  },
  {
    path: basePath + 'manage-shop-keeper-detail',
    name: basePath + 'manage-shop-keeper-detail',
    component: () => import('@/views/communityManage/manageShopKeeperDetail'),
    meta: { title: '店主信息查看' },
  },
  {
    path: basePath + 'check-manage-shop-keeper',
    name: basePath + 'check-manage-shop-keeper',
    component: () => import('@/views/communityManage/manageShopKeeperDetail'),
    meta: { title: '开店审核' },
  },
  {
    path: basePath + 'edit-manage-shop-keeper',
    name: basePath + 'edit-manage-shop-keeper',
    component: () => import('@/views/communityManage/manageShopKeeperDetail'),
    meta: { title: '编辑店主信息' },
  },
  {
    path: basePath + 'manage-agent',
    name: basePath + 'manage-agent',
    component: () => import('@/views/communityManage/manageAgent'),
    meta: { title: '代理人流程管理' },
  },
]

// b2c使用
const posterPath = '/poster/'
const posterOut = [
  {
    path: posterPath,
    name: posterPath,
    component: () => import('@/views/poster/search/index.vue'),
    meta: {
      auth: false,
      title: '海报查询',
    },
  },
  {
    path: posterPath + 'edit',
    name: posterPath + 'edit',
    component: () => import('@/views/poster/edit/index.vue'),
    meta: {
      auth: false,
      title: '海报制作',
    },
  },
  {
    path: posterPath + 'statement',
    name: posterPath + 'statement',
    component: () => import('@/views/poster/statement'),
    meta: {
      auth: false,
      title: '海报统计',
    },
  },
]

const posterPathIn = '/posterToIn/'
const posterIn = [
  {
    path: posterPathIn,
    name: posterPathIn,
    component: () => import('@/views/poster/search/index.vue'),
    meta: {
      title: '海报查询',
    },
  },
  {
    path: posterPathIn + 'edit',
    name: posterPathIn + 'edit',
    component: () => import('@/views/poster/edit/index.vue'),
    meta: {
      title: '海报制作',
    },
  },
  {
    path: posterPathIn + 'statement',
    name: posterPathIn + 'statement',
    component: () => import('@/views/poster/statement'),
    meta: {
      title: '海报统计',
    },
  },
]

export { posterIn, posterOut }

export default {
  list: {
    意向单类型: '意向单类型',
    意向单状态: '意向單狀態',
    最近跟进人: '最近跟進人',
    不限: '不限',
    状态: '狀態',
    联络人: '聯絡人',
    联络方式电话: '联络方式 电话',
    联络方式邮件: '联络方式 邮件',
    目的城市: '目的城市',
    意向产品: '意向產品',
    意向产品类目: '意向产品类目',
    预计出发日期: '预计出发日期',
    至: '至',
    开始日期: '開始日期',
    结束日期: '結束日期',
    意向单编号: '意向單編號',
    意向单标题: '意向单标题',
    合作渠道: '合作渠道',
    终端: '終端',
    创建人: '創建人',
    创建时间: '創建時間',
    开始时间: '開始時間',
    结束时间: '結束時間',
    全部查询: '全部査詢',
    常用查询: '常用査詢',
    查询: '査詢',
    筛选: '篩選',
    天内跟进: '天內跟進',
    添加意向单: '添加意向單',
    导出明细: '導出明細',
    自定义展示列: '自定義展示列',
    预订数量: '預訂數量',
    成人: '成人',
    儿童: '/兒童',
    下单人: '下單人',
    最近操作人: '最近操作人',
    最近操作时间: '最近操作时间',
    销售公司: '銷售公司',
    操作: '操作',
    跟进: '跟進',
    查看: '查看',
    意向单模板: '意向單範本',
    请输入199之间的整数: '請輸入1-99之間的整數',
    查询条件: '查詢條件',
    更新时间: '更新時間',
    产品: '產品',
    时间间隔超过365天: '時間間隔超過365天',
    请输入数字: '請輸入數字',
    类型: '類型',
    联络人姓名: '聯絡人姓名',
    联络人电话: '聯絡人電話',
    联络人电邮: '聯絡人電郵',
    意向品类: '意向品類',
    出发日期: '出發日期',
    标题: '標題',
    渠道: '渠道',
    下单时间: '下單時間',
    操作人: '操作人',
    操作时间: '操作時間',
    待跟进: '待跟進',
    跟进中: '跟進中',
    已关闭: '已關閉',
    已过期: '已過期',
    未过期: '未過期',
    已下单: '已下單',
    已成交: '已成交',
    国内游: '國內遊',
    出境游: '出境遊',
    机酒打包订: '機酒打包訂',
    电话: '電話',
    社群分销: '社群分銷',
    主动推广: '主動推廣',
    客户咨询: '客戶諮詢',
    在线预订: '線上預訂',
    自营: '自營',
    同业分销: '同業分銷',
    第三方渠道: '第三方渠道',
    'PC（网站）': 'PC（網站）',
    APP应用: 'APP應用',
    微信小程序: '微信小程式',
    M站: 'M站',
    在线客服: '線上客服',
    直营门市: '直營門市',
    创新店: '創新店',
    线下同业: '線下同業',
    蒲公英云店: '蒲公英雲店',
    中旅云店: '中旅雲店',
    '飞猪-中旅旅行': '飛猪-中旅旅行',
    '飞猪-国旅总社': '飛猪-國旅總社',
    美团: '美團',
    携程: '攜程',
    途牛: '途牛',
    其他: '其他',
    '中国+86': '中國+86',
    请输入正确的邮箱地址: '請輸入正確的郵箱地址',
    最小成人数不能大于最大成人数: '最小成人數不能大於最大成人數',
    最大成人数不能小于最小成人数: '最大成人數不能小於最小成人數',
    请先选择产品一级品类和二级品类: '請先選擇產品一級品類和二級品類',
    模板名称: '範本名稱',
    意向单备注: '意向單備註',
    保存: '保存',
    请填写模板名称: '請填寫範本名稱',
    请填写意向标题: '請填寫意向標題',
    请填写意向单备注: '請填寫意向單備註',
    模板修改成功: '範本修改成功',
    模板新增成功: '範本新增成功',
    模板: '範本',
    修改: '修改',
    新增: '新增',
    '失败原因:': '失敗原因：',
    删除: '删除',
    编辑: '編輯',
    确定删除: '確定删除',
    关闭: '關閉',
    删除成功: '删除成功',
    '您确定删除该模板吗？': '您確定刪除該範本嗎？',
    '447003-0': '下單人',
    '447003-1': '下單部門',
    '447003-2': '銷售公司',
    '447003-3':
      '銷售公司與合約簽約、對客收款的公司主體需保持一致（即A公司銷售，需由A公司簽約，使用A公司的銀行帳戶收款），否則無法認款，請謹慎選擇；',
    '447003-4': '請選擇銷售公司',
    '447003-5': '確認渠道、銷售類型',
    '447003-6': '合作渠道',
    '447003-7': '銷售類型',
    '447003-8': '直銷，直接把產品銷售給客人；',
    '447003-9': '分銷，把產品銷售給同行，再由同行銷售給客人',
    '447003-10': '（選擇分銷才能查看產品的同行價格）',
    '447003-11': '已為您帶入上次選擇，可以修改',
    '447003-12': '下單成功後合作渠道和銷售類型會錄入流覽器',
    '447003-13': '選擇產品、團期、人數',
    '447003-14': '特殊需求',
    '447003-15': '請輸入客戶的特殊需求',
    '447003-16': '維護客戶、連絡人信息',
    '447003-17': '客戶類型',
    '447003-18': '客戶名稱',
    '447003-19': '請輸入客戶名稱',
    '447003-20': '聯絡人姓名',
    '447003-21': '請輸入連絡人姓名',
    '447003-22': '聯繫方式',
    '447003-23': '請輸入聯繫方式',
    '447003-24': '外部訂單號',
    '447003-25': '請輸入外部訂單號',
    '447003-26': '外部合作類目',
    '447003-27': '請選擇外部合作類目',
    '447003-28': '歷史訂單',
    '447003-29': '出遊日期：',
    '447003-30': '預估傭金',
    '447003-31': '請務必核實填寫信息，確認無誤後，再提交訂單',
    '447003-32': '取消',
    '447003-33': '提交訂單',
    '447003-34': '請選擇合作渠道',
    '447003-35': '請選擇銷售類型',
    '447003-36': '請選擇客戶類型',
    '447003-37': '請填寫連絡人姓名',
    '447003-38': '手機號不可少於11位',
    '447003-39': '僅支持13-19號段，請修改',
    '447003-40': '請填寫外部訂單號',
    '447003-41': '請先選擇產品',
    '447003-42': '請先選擇酒店',
    '447003-43': '請先維護資源',
    '447003-44': '至少添加一個資源',
    '447003-45': '全國',
    '447003-46': '團隊機票沒有搭配其他資源，是否仍要下單？',
    '447003-47': '提示',
    '447003-48': '確定',
    '447003-49': '請先選擇銷售類型',
    '447003-50': '請先選擇合作渠道',
    '533322-0': '目的地',
    '533322-1': '入離日期',
    '533322-2': '入住日期',
    '533322-3': '退房日期',
    '533322-4': '共',
    '533322-5': '晚',
    '533322-6': '關鍵字',
    '533322-7': '名稱/地址/商圈',
    '533322-8': '搜索',
    '533322-9': '更換酒店/房型',
    '533322-10': '酒店',
    '533322-11': '入住',
    '533322-12': '退房',
    '533322-13': '修改',
    '533322-14': '參考價格',
    '533322-15': '同行價：￥',
    '533322-16': '起',
    '533322-17': '建議銷售價：￥',
    '533322-18': '操作',
    '533322-19': '預覽',
    '533322-20': '分享',
    '533322-21': '對內：',
    '533322-22': '對客：',
    '533322-23': '份早餐',
    '533322-24': '無早餐',
    '533322-25': '間',
    '533322-26': '添加',
    '533322-27': '更換',
    '533322-28':
      "${\r\n        singleCategoryFormData.resourceInfo.hotelResourceList.length === 0 ? '添加' : '更換'\r\n      }酒店",
    '533322-29': '請選擇目的地',
    '533322-30': '請選擇入離日期',
    '533322-31': '酒店房型已售完，請重新選擇',
    '533322-32': '提示',
    '533322-33': '重新選擇',
    '533322-34': '酒店詳情',
    '533322-35': 'MM月DD日',
    '533322-36': '不可取消',
    '533322-37': '前可免費取消',
    '992922-0': '產品碼',
    '992922-1': '請輸入產品碼',
    '992922-2': '査詢/更換',
    '992922-3': '更多產品',
    '992922-4': '更換需求',
    '992922-5': '添加產品',
    '992922-6': '關閉',
    '992922-7': '您査詢的',
    '992922-8': '的',
    '992922-9': '為',
    '992922-10': '單團',
    '992922-11': '預打包',
    '992922-12': '產品，您可以切換到對應的tab頁處理',
    '992922-13': '査詢的【',
    '992922-14': '的產品碼：',
    '992922-15': '不可售賣，需要注意如下事項：',
    '992922-16': '1、可以聯繫【',
    '992922-17': '】，確認產品是否設定共享到事業群',
    '992922-18': '2、核實本公司與【',
    '992922-19': '】是否簽署分銷協定，是否建立客商關係',
    '992922-20': '】的產品碼：',
    '992922-21': '沒有維護團期，您可以進行如下操作：',
    '992922-22': '1、請聯系產品負責人【',
    '992922-23': '】維護團期後再下單',
    '992922-24': '2、重新選擇其他產品',
    '057984-0': '訂單號',
    '057984-1': '產品品類',
    '057984-2': '產品名稱',
    '057984-3': '出遊日期',
    '057984-4': '出遊人數',
    '057984-5': '大',
    '057984-6': '小',
    '057984-7': '訂單狀態',
    '057984-8': '下單人',
    '057984-9': '下單日期',
    '057984-10': '當前客人存在‘需求確認中’訂單，請跟客人確認，切勿重複下單',
    '134386-0': '品類',
    '134386-1': '出發城市',
    '134386-2': '目的城市',
    '134386-3': '出遊日期',
    '134386-4': '選擇日期',
    '134386-5': '返回日期',
    '134386-6': '出遊人數',
    '134386-7': '成人',
    '134386-8': '兒童',
    '134386-9': '1個成人最多可攜帶2個兒童',
    '134386-10': '特殊需求',
    '134386-11': '確認並維護資源',
    '134386-12': '請選擇返回日期',
    '134386-13': '返回日期不可以小於出遊日期',
    '134386-14': '請選擇出遊人數',
    '134386-15': '請選擇品類',
    '134386-16': '請選擇出遊日期',
    '134386-17': '請選擇機票配置',
    '134386-18': '自由行',
    '134386-19': '1個成人最多攜帶2個兒童，請修改',
    '134386-20': '更換需求會清空已選資源，確認繼續操作？',
    '134386-21': '提示',
    '134386-22': '確定',
    '134386-23': '取消',
    '203150-0': '艙等',
    '203150-1': '退改簽',
    '203150-2': '行李額',
    '203150-3': '售價',
    '203150-4': '同行價：',
    '203150-5': '成人：',
    '203150-6': '兒童：',
    '203150-7': '建議銷售價：',
    '203150-8': '數量',
    '203150-9': '大',
    '203150-10': '小',
    '203150-11': '總價',
    '203150-12': '操作',
    '203150-13': '更換艙位',
    '203150-14': '删除',
    '203150-15': '是否删除當前機票？',
    '203150-16': '提示',
    '203150-17': '確定',
    '203150-18': '取消',
    '258696-0': '酒店',
    '258696-1': '房型',
    '258696-2': '床型',
    '258696-3': '早餐',
    '258696-4': '份早餐',
    '258696-5': '無早餐',
    '258696-6': '均價（間/夜）',
    '258696-7': '同行價：',
    '258696-8': '建議銷售價：',
    '258696-9': '間數',
    '258696-10': '使用日期',
    '258696-11': '總價',
    '258696-12': '操作',
    '258696-13': '删除',
    '314749-0': '品類',
    '314749-1': '方案',
    '314749-2': '出發城市',
    '314749-3': '全國',
    '314749-4': '目的城市',
    '314749-5': '出遊日期',
    '314749-6': '返回日期',
    '314749-7': '出遊人數',
    '314749-8': '大',
    '314749-9': '小',
    '314749-10': '特殊需求',
    '314749-11': '更換需求',
    '314749-12': '資源',
    '314749-13': '添加資源',
    '314749-14': '添加',
    '314749-15': '更換',
    '314749-16': '{0}酒店',
    '314749-17': '跟團遊',
    '314749-18': '自由行',
    '314749-19': '單程',
    '314749-20': '往返',
    '314749-21': '更換班機會清空已選機票資源，確認繼續操作？',
    '314749-22': '提示',
    '314749-23': '確定',
    '314749-24': '取消',
    '314749-25': '更換酒店會清空已選酒店資源，確認繼續操作？',
    '368348-0': '詳情',
    '368348-1': '資源編號：',
    '368348-2': '辦理時長',
    '368348-3': '方案',
    '368348-4': '目的城市',
    '368348-5': '出發日期',
    '368348-6': '遊玩日期',
    '368348-7': '單價',
    '368348-8': '同行價：',
    '368348-9': '建議銷售價：',
    '368348-10': '同行價：',
    '368348-11': '成人：',
    '368348-12': '兒童：',
    '368348-13': '單房差：',
    '368348-14': '建議銷售價：',
    '368348-15': '數量',
    '368348-16': '套',
    '368348-17': '人數：',
    '368348-18': '大',
    '368348-19': '小',
    '368348-20': '總價',
    '368348-21': '資源負責人',
    '368348-22': '操作',
    '368348-23': '更換資源',
    '368348-24': '删除',
    '420988-0': '詳情',
    '420988-1': '資源編號：',
    '420988-2': '方案',
    '420988-3': '出發-目的城市',
    '420988-4': '遊玩日期',
    '420988-5': '天',
    '420988-6': '晚',
    '420988-7': '單價',
    '420988-8': '成人：',
    '420988-9': '兒童：',
    '420988-10': '單房差：',
    '420988-11': '數量',
    '420988-12': '人數：',
    '420988-13': '大',
    '420988-14': '小',
    '420988-15': '份',
    '420988-16': '總價',
    '420988-17': '操作',
    '420988-18': '删除',
    '591648-0': '意向單詳情',
    '591648-1': '意向單編號',
    '591648-2': '意向單標題',
    '591648-3': '意向單類型',
    '591648-4': '意向單狀態',
    '591648-5': '終端',
    '591648-6': '合作渠道',
    '591648-7': '創建人',
    '591648-8': '創建時間',
    '591648-9': '預算總額',
    '591648-10': '中文姓名',
    '591648-11': '英文姓名',
    '591648-12': '稱謂',
    '591648-13': '意向產品',
    '591648-14': '',
    '591648-15': '聯絡人電話',
    '591648-16': '聯絡人電郵',
    '591648-17': '出發城市',
    '591648-18': '目的城市',
    '591648-19': '出發日期',
    '591648-20': '預訂數量',
    '591648-21': '成人',
    '591648-22': '/兒童',
    '591648-23': '/嬰兒',
    '591648-24': '/長者',
    '591648-25': '人均預算',
    '591648-26': '未轉化原因',
    '591648-27': '超過預計出發日期未轉化',
    '591648-28': '交易信息',
    '591648-29': '訂單編號',
    '591648-30': '跟進記錄',
    '591648-31': '關閉',
    '591648-32': '繼續下單',
    '591648-33': '保存',
    '643175-0': '操作項',
    '643175-1': '操作人',
    '643175-2': '操作時間',
    '643175-3': '分配部門/分配人',
    '643175-4': '意向單備註',
    '643175-5': '上傳附件',
    '643175-6': '預覽',
    '643175-7': '下載',
    '703357-0': '訂單編號',
    '703357-1': '成交金額',
    '703357-2': '元',
    '703357-3': '新增',
    '703357-4': '删除',
    '703357-5': '請填寫訂單編號',
    '703357-6': '請填寫成交金額',
    '703357-7': '請輸入正確格式，最多可保留兩位小數',
    '703357-8': '一次最多添加9行',
    '864142-0': '意向單跟進',
    '864142-1': '意向單編號',
    '864142-2': '意向單標題',
    '864142-3': '意向單類型',
    '864142-4': '意向單狀態',
    '864142-5': '合作渠道',
    '864142-6': '終端',
    '864142-7': '創建人',
    '864142-8': '創建時間',
    '864142-9': '英文姓名',
    '864142-10': '請輸入英文姓',
    '864142-11': '請輸入英文名',
    '864142-12': '中文姓名',
    '864142-13': '',
    '864142-14': '聯絡人電話',
    '864142-15': '聯絡人電郵',
    '864142-16': '請輸入',
    '864142-17': '稱謂',
    '864142-18': '意向產品',
    '864142-19': '出發城市',
    '864142-20': '目的城市',
    '864142-21': '出發日期',
    '864142-22': '選擇日期',
    '864142-23': '預訂數量',
    '864142-24': '人均預算',
    '864142-25': '預算總額',
    '864142-26': '下步操作',
    '864142-27':
      'B2B、NBS兩套系統並行期間，如意向明確需轉化成訂單，接入NBS系統的業務，可直接點擊【下訂單】跳轉到接單頁下單，尚未接入NBS的業務，可線下到B2B系統下單，在此處選擇【回填成交記錄】',
    '864142-28': '跟進和備註',
    '864142-29': '回填成交記錄',
    '864142-30': '關閉意向單',
    '864142-31': '跟進備註',
    '864142-32': '分配公司',
    '864142-33': '分配部門',
    '864142-34': '分配人',
    '864142-35': '請選擇',
    '864142-36': '上傳附件',
    '864142-37': '上傳文件（不超過5M）',
    '864142-38': '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、',
    '864142-39': '訂單信息',
    '864142-40': '未轉化原因',
    '864142-41': '其他原因說明',
    '864142-42': '跟進記錄',
    '864142-43': '取消',
    '864142-44': '保存並下訂單',
    '864142-45': '保存成交記錄',
    '864142-46': '保存',
    '864142-47': '請輸入數字',
    '864142-48': '出遊日期不能早於當前日期',
    '864142-49': '未填寫聯繫方式',
    '864142-50': '請填寫出發城市',
    '864142-51': '請填寫目的城市',
    '864142-52': '請輸入正確的郵箱',
    '864142-53': '請填寫出遊人數',
    '864142-54': '成人數不可超過1000人',
    '864142-55': '兒童數不可超過2000人',
    '864142-56': '請填寫意向單備註',
    '864142-57': '請選擇未轉化原因',
    '864142-58': '請填寫其他原因說明',
    '864142-59': '上傳成功',
    '864142-60': '上傳附件大小不能超過5MB',
    '864142-61': '提示',
    '864142-62': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、gif、bmp、csv',
    '864142-63': '文件名稱重複，請修改後重新上傳',
    '864142-64': '返回日期',
    '936840-0': '添加意向單',
    '936840-1': '意向單標題',
    '936840-2': '意向單類型',
    '936840-3': '英文姓名',
    '936840-4': '請輸入英文姓',
    '936840-5': '請輸入英文名',
    '936840-6': '中文姓名',
    '936840-7': '稱謂',
    '936840-8': '合作渠道',
    '936840-9': '聯繫方式',
    '936840-10': '手機',
    '936840-11': '請輸入手機號',
    '936840-12': '郵箱',
    '936840-13': '請輸入郵箱',
    '936840-14': '出發城市',
    '936840-15': '目的城市',
    '936840-16': '意向產品',
    '936840-17': '預計出發日期',
    '936840-18': '預訂數量',
    '936840-19': '請輸入',
    '936840-20': '人均預算',
    '936840-21': '預算總額',
    '936840-22': '取消',
    '936840-23': '保存意向',
    '936840-24': '請輸入大於0的整數',
    '936840-25': '提醒時間必須大於當前時間',
    '936840-26': '提醒時間不能晚於出發時間',
    '936840-27': '請輸入正確的郵箱',
    '936840-28': '請輸入聯絡人英文姓名',
    '936840-29': '請選擇意向單類型',
    '936840-30': '請選擇合作渠道',
    '936840-31': '請填寫連絡人姓名',
    '936840-32': '未填寫聯繫方式',
    '936840-33': '請輸入數字',
    '936840-34': '請填寫出發城市',
    '936840-35': '請填寫目的城市',
    '936840-36': '請填寫出遊日期',
  },
}

import Vue from 'vue'
import Router from 'vue-router'

import category from './modules/category'
import confirm from './modules/confirm'
import orderSys from './modules/orderSys'
import { posterOut, posterIn } from './modules/poster'
import productSys from './modules/productSys'
import { wiki, wikiToOut } from './modules/wiki'
import tools from './modules/tools'
import commissionSys from './modules/commissionSys'
import NoMenuPage from './modules/NoMenuPage'
import financialSys from './modules/financialSys'
import communityManage from './modules/communityManage'
import publicSys from './modules/publicSys'
import priceRule from './modules/priceRule'
import resourceSys from './modules/resourceSys'
import appletOperate from './modules/appletOperate'
import supplierSys from './modules/supplierSys'
import sysConfig from './modules/sysConfig'
import baseDataSys from './modules/baseDataSys'
import stockSys from './modules/stockSys'
import salesReport from './modules/salesReport'
import credit from './modules/credit'

const config = require('/config/config')

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/login',
    name: '/login',
    component: () => import('@/views/login/index'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/weixin-login',
    name: '/weixin-login',
    component: () => import('@/views/login/weixin'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/change-password',
    name: '/change-password',
    component: () => import('@/views/login/changePassword'),
  },
  {
    path: '/forget-password',
    name: '/forget-password',
    component: () => import('@/views/login/forgetPassword'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
  },
  {
    path: '/color-card',
    name: '/color-card',
    component: () => import('@/views/ColorCard/index.vue'),
    hidden: true,
    meta: { title: '比色卡', auth: false },
  },
  {
    path: '/flow-chart',
    name: '/flow-chart',
    // src\components\PreviewFlowChart\src\flowChart.vue
    component: () => import('@/components/PreviewFlowChart/src/flowChart.vue'),
  },
  ...posterOut, // 海报系统对外
  ...wikiToOut, // 系统错误码知识库对外查询页，需登录，不含菜单栏
  ...NoMenuPage, // 无左侧菜单的列表页面
  {
    path: '/*',
    component: () => import('@/layout'),
    children: [
      {
        path: '/homepage',
        name: '/homepage',
        component: () => import('@/views/homepage/index.vue'),
        meta: { title: 'NBS首页' },
      },
      ...stockSys,
      ...baseDataSys,
      ...confirm, // 确认管理
      ...category, // 类目管理
      ...orderSys, // 订单系统
      ...productSys, // 产品系统
      ...wiki, // 知识库
      ...posterIn, // 海报对内
      ...tools, // 工具
      ...commissionSys,
      ...financialSys, // 业财
      ...communityManage, // 云店内容管理
      ...publicSys, // 公共服务系统
      ...resourceSys, // 资源系统
      ...priceRule, // 价格中心系统
      ...appletOperate,
      ...supplierSys, // 供应商系统
      ...sysConfig,
      ...salesReport, // 销售日结报表
      ...credit, //挂账
    ],
  },
]

export function useRouter() {
  return router
}

export function useRoute() {
  return router.currentRoute
}
const router = new Router({
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  routes: constantRoutes,
  base: config.publicPath,
})

// 解决Loading chunk (\d)+ failed问题
router.onError((error) => {
  const pattern = /Loading chunk chunk-[A-Za-z0-9]+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    window.location.reload()
    // const targetPath = $router.history.pending.fullPath;
    // $router.replace(targetPath);
  }
})

export default router

// KJUR，hextob64，b64tohex来源：jsrsasign
import CryptoJS from 'crypto-js/crypto-js'
import { privateArray } from '@/utils/config/key'
import {
  privateKey,
  publicKey,
  begin,
  end,
  split,
  publicArray,
  rsaOption,
} from '@/utils/config/codeMap'
import smCrypto from 'sm-crypto'
const SM4 = require('gm-crypt').sm4

const sm2 = smCrypto.sm2

const pubilcKey =
  '04AB193D3FC8EB9C83B7D208D7B280F214C8913571FA93BE2B5190700FA7A3E2B3AADB20FE58E42B35F6EFBD5934525BA22629515015F9F9A85264AD771B19B3B1'
// const privateKey = '288F384C96D17932BD56EAAA4F5D962996A04C8388FD34A6BD627B31EC1364B3'

const CryptoOptions = {
  mode: CryptoJS.mode.ECB,
  padding: CryptoJS.pad.Pkcs7,
}
const keyClient = 'ZGY2NjUyZGJjMGRkNDM1Mw=='
const keyServer = 'ODZlMTljM2JiOTdlYjYxYg=='

const genarateKey = (p, key, char = '/') => {
  const splits = new Array(6).join(split)
  const content = key.join(char)
  const str = `${p.toUpperCase()} KEY${splits}`
  return `${splits}${begin.toUpperCase()} ${str}${content}${splits}${end.toUpperCase()} ${str}`
}

// 加密
export function encryptionAES(params) {
  let keys = '',
    encryptorStr = ''
  keys = md5(keyClient)
  // 开始加密
  const data = CryptoJS.enc.Utf8.parse(JSON.stringify(params))
  const encrypted = CryptoJS.AES.encrypt(data, keys, CryptoOptions)
  encryptorStr = encrypted.toString()
  //返回 加密后的 字符串
  return encryptorStr
}

// 解密
export function decryptAES(params) {
  try {
    let keys = '',
      decryptStr = ''
    keys = md5(keyServer)
    console.log(keys)
    const decrypted = CryptoJS.AES.decrypt(params, keys, CryptoOptions)
    decryptStr = decrypted.toString(CryptoJS.enc.Utf8)
    //返回 解密后的 字符串
    const decryptedData = JSON.parse(decryptStr)
    return JSON.parse(decryptedData.data)
  } catch (e) {
    console.log(e)
    return params
  }
}

// md5加密key
function md5(params) {
  let keys = '',
    md5Str = ''
  keys = CryptoJS.MD5(atob(params))
  // 转16进制
  md5Str = keys.toString(CryptoJS.enc.Hex)
  // 取中间16位
  return CryptoJS.enc.Utf8.parse(md5Str.substr(md5Str.length / 2 - 8, 16))
}

export function utf8ToHex(str) {
  return Array.from(str)
    .map((c) =>
      c.charCodeAt(0) < 128
        ? c.charCodeAt(0).toString(16).length == 1
          ? '0' + c.charCodeAt(0).toString(16)
          : c.charCodeAt(0).toString(16)
        : encodeURIComponent(c).replace(/\%/g, '').toLowerCase(),
    )
    .join('')
}

export function hexToUtf8(hex) {
  return decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'))
}

// 签名
export function sha256WithRSA({ systemId = 2201, uuid, timestamp, versionId = '1.0' }) {
  let signStr = '',
    shaStr = ''
  signStr = systemId + uuid + timestamp + versionId
  // RSA signature generation
  const sig = new window.KJUR.crypto.Signature(rsaOption)
  const client = privateArray.concat([])
  client.splice(client.length - 2, 0, 'K')
  const keyStr = genarateKey(privateKey, client)
  sig.init(keyStr)
  sig.updateString(signStr)
  // signStr进行签名
  shaStr = sig.sign()
  // Base64编码
  return window.hextob64(shaStr)
}

// 验签
export function verify({ signStr, systemId, uuid, timestamp, versionId }) {
  const sig = new window.KJUR.crypto.Signature(rsaOption)
  sig.init(genarateKey(publicKey, publicArray, 'a'))
  const sign = systemId + uuid + timestamp + versionId
  sig.updateString(sign)
  return sig.verify(window.b64tohex(signStr))
}

// 生成uuid
export function uuid() {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  var uuid = s.join('')
  return uuid
}

export const getSecretHash = () => {
  let encryptorStr = ''
  encryptorStr = CryptoJS.MD5(pubilcKey).toString()
  return encryptorStr
}

// 获取长度16的随机key
export const getKeyOrIv = (length) => {
  const randomWord = CryptoJS.lib.WordArray.random(length)
  const hexKey = randomWord.toString(CryptoJS.enc.Hex)
  return hexKey
}

// key加密
export function encryptionSM2() {
  const cipherMode = 1
  const key = getKeyOrIv(8)

  // 配合后端处理，加密后内容拼接04
  const encryptorStr = '04' + sm2.doEncrypt(key, pubilcKey, cipherMode)
  return { key, encryptorStr }
}

// body加密
export function encryptSM4(key, requestBody = '{}') {
  const iv = getKeyOrIv(8)
  let sm4 = new SM4({
    key,
    mode: 'cbc',
    iv,
    cipherType: 'text',
  })
  const encryptBody = sm4.encrypt(requestBody)
  return { iv, encryptBody }
}

// 解密
export function decryptSM4(key, encryptData) {
  const { iv, data } = encryptData
  let sm4 = new SM4({
    key,
    mode: 'cbc',
    iv,
    cipherType: 'text',
  })
  const encryptBody = sm4.decrypt(data)
  return JSON.parse(encryptBody)
}

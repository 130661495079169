export default {
  orderId: '訂單編號',
  inventoryKanban: '庫存看板',
  status: '狀態',
  departureDate: '日期',
  vendorName: '供應商',
  purchaseType: '庫存類型',
  roundId: '批次編號',
  inCount: '總數',
  reserveCount: '占位',
  outCount: '出庫',
  remainingCount: '餘位',
  setAdultCost: '成本價',
  peopleAdultCost: '成人成本價',
  adultCost1: '成人原幣金額',
  adultCost2: '成本原幣金額',
  adultCostStandard1: '成人本幣金額',
  adultCostStandard2: '成本本幣金額',
  childCost1: '兒童成本價',
  childCost: '兒童原幣金額',
  childCostStandard: '兒童本幣金額',
  singleRoomCost1: '單房差',
  singleRoomCost: '單房差原幣金額',
  singleRoomCostStandard: '單房差本幣金額',
  bookingEndTime: '截止銷售時間',
  occupyStayTime: '預訂留位時間',
  roundType: '批次類型',
  updateUserName: '更新人',
  updateTime: '更新時間',
  departureDateDesc: '備註',
  schemeName: '方案名稱',
  session: '場次',
  sessionName: '場次名稱',
  occupyTime: '占位時間',
  clientId: '客戶',
  adultCount: '數量',
  releaseTime: '清位時間',
  outType: '出庫類型',
  label: {
    one: '停售',
    two: '滿位',
    three: '不成團',
    four: '團期已關閉',
  },
  purchaseTypeEnum: {
    one: '庫存可超',
    two: '庫存不可超',
    three: '自由售賣',
    four: '現詢',
  },
  roundTypeEnum: {
    zero: '即時反饋',
    one: '庫存',
    two: '現詢',
    three: '自由售賣',
  },
  procurementStatus: {
    zero: '已出庫',
    one: '占位中',
    two: '已回庫',
  },
  procurementOutType: {
    one: '訂單出庫',
    two: '清位出庫',
    three: '人工出庫',
  },
}

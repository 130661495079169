export default function AuthServer(ajax, config) {
  return {
    // 用户与组织，枚举公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    getUpdatePasswordUrl: (opt) =>
      ajax({
        url: '/open-api/forget-url',
        method: 'get',
        reLogin: false,
        ...opt,
      }),
    redirectWeCom: '/open-api/redirect-we-com',
    // 修改密码
    updatePassWord: (opt) =>
      ajax({
        url: '/updatePassWord',
        method: 'post',
        isResponse: true,
        ...opt,
      }),
    loginType: (opt) =>
      ajax({
        url: '/sys-auth/login-type',
        method: 'get',
        ...opt,
      }),
    // 获取操作记录
    maintainLog: (opt) =>
      ajax({
        url: `/maintain-log/page/${opt.cDype}/${opt.cDataId}`,
        method: 'get',
        ...opt,
      }),
    menu: {
      list: (opt) =>
        ajax({
          url: '/menus/',
          method: 'get',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/menus/',
          method: 'post',
          ...opt,
        }),
      info: (opt) =>
        ajax({
          url: `/menus/${opt.cId}`,
          method: 'get',
          ...opt,
        }),
      edit: (opt) =>
        ajax({
          url: `/menus/${opt.c_id}/`,
          method: 'PUT',
          ...opt,
        }),
      del: (opt) =>
        ajax({
          url: `/menus/${opt.c_id}/`,
          method: 'delete',
          ...opt,
        }),
      currentUserMenus: (opt) =>
        ajax({
          url: `/menus/current-user-menu`,
          method: 'get',
          ...opt,
        }),
      // 修改菜单排序
      sort: (opt) =>
        ajax({
          url: `/menus/index`,
          method: 'PUT',
          ...opt,
        }),
    },
    // 公司操作
    // isCollective: 一体化标识
    corp: {
      list: (opt, isCollective) =>
        ajax({
          url: '/corp/query-list',
          method: 'post',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/corp/add',
          method: 'post',
          ...opt,
        }),
      look: (opt) =>
        ajax({
          url: '/corp/check',
          method: 'get',
          ...opt,
        }),
      edit: (opt) =>
        ajax({
          url: '/corp/update-info',
          method: 'post',
          ...opt,
        }),
      status: (opt) =>
        ajax({
          url: '/corp/update-status',
          method: 'post',
          ...opt,
        }),
    },
    shop: {
      attachmentUpload: (opt) =>
        ajax({
          url: `/employee-attach/upload`,
          method: 'POST',
          ...opt,
        }),
      list: (opt) =>
        ajax({
          url: '/shop/shopInfos',
          method: 'post',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/shop/shopInfo',
          method: 'post',
          ...opt,
        }),
      look: (opt) =>
        ajax({
          url: `/shop/shopInfo-detail`,
          method: 'post',
          ...opt,
        }),
      edit: (opt) =>
        ajax({
          url: '/shop/shopInfo',
          method: 'put',
          ...opt,
        }),
      status: (opt) =>
        ajax({
          url: '/shop/shopInfo-update-Status',
          method: 'PATCH',
          ...opt,
        }),
      // 上传门市附件
      uploadImg: (opt) =>
        ajax({
          url: '/shop/uploadShopAttach',
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          ...opt,
        }),
      removeImg: (opt) =>
        ajax({
          url: `/shop/removeShopAttach/${opt.c_id}`,
          method: 'get',
          ...opt,
        }),
      CategoryCtiyList: (opt) =>
        ajax({
          url: `/shop/query-departure-city`,
          method: 'get',
          ...opt,
        }),
      detailByDepartmentId: (opt) =>
        ajax({
          url: `/shop/shopInfo-detail-by-department-id`,
          method: 'post',
          ...opt,
        }),
      updateOrder: (opt) =>
        ajax({
          url: `/shop/update/order`,
          method: 'post',
          ...opt,
        }),
    },
    // 角色
    role: {
      list: (opt) =>
        ajax({
          url: '/role/query-list',
          method: 'post',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/role/add',
          method: 'post',
          ...opt,
        }),
      del: (opt) =>
        ajax({
          url: `/role/${opt.cId}`,
          method: 'DELETE',
          ...opt,
        }),
      info: (opt) =>
        ajax({
          url: '/role/check',
          method: 'get',
          ...opt,
        }),
      edit: (opt) =>
        ajax({
          url: '/role/update-info',
          method: 'post',
          ...opt,
        }),
      roleMenu: (opt) =>
        ajax({
          url: '/role/query-menu',
          method: 'post',
          ...opt,
        }),
      roleRelation: (opt) =>
        ajax({
          url: '/role/relation-menu',
          method: 'post',
          ...opt,
        }),
    },
    // 部门
    department: {
      tree: (opt) => ajax({ url: '/department/tree', method: 'GET', ...opt }),
      add: (opt) => ajax({ url: '/department/add', method: 'post', ...opt }),
      // 根据组织id，查表头信息
      info: (opt) => ajax({ url: '/department/base', method: 'GET', ...opt }),
      // 根据参数查询组织表数据列表,搜索上级组织时使用
      parentOrg: (opt) => ajax({ url: '/department/list', method: 'GET', ...opt }),
      edit: (opt) => ajax({ url: '/department/update', method: 'post', ...opt }),
      userList: (opt) => ajax({ url: '/department/user-list', method: 'GET', ...opt }),
      closeUserList: (opt) => ajax({ url: '/department/user-list/close', method: 'GET', ...opt }),
      del: (opt) => ajax({ url: `/department/${opt.cId}`, method: 'delete', ...opt }),
      // 关联公司
      relation: {
        list: (opt) =>
          ajax({ url: `/department/department-corp-relation/list`, method: 'get', ...opt }),
        save: (opt) =>
          ajax({ url: `/department/department-corp-relation/save`, method: 'POST', ...opt }),
        del: (opt) =>
          ajax({
            url: `/department/department-corp-relation/delete/${opt.cId}`,
            method: 'delete',
            ...opt,
          }),
      },
      // 根据架构公司，查询该架构公司下面关联的所有业务公司，可根据销售/产品/采购属性进行过滤
      relationCorp: (opt) =>
        ajax({
          url: '/department/corp-info',
          method: 'GET',
          ...opt,
        }),
      // 根据分销公司查询关联产品供应商公司
      relationArchitectureCompany: (opt) =>
        ajax({
          url: '/department/distribution-relation-architecture-company/query',
          method: 'post',
          ...opt,
        }),
      // 根据架构公司查询下面的所有部门
      relationDepartment: (opt) =>
        ajax({
          url: '/department/list-no-cascade',
          method: 'get',
          ...opt,
        }),
    },
    // 缓冲期部处接口
    iteDepartment: {
      list: (opt) => ajax({ url: `/department/user-department`, method: 'get', ...opt }),
    },
    filterPurchase: (opt) => ajax({ url: `/corp/query-list`, method: 'post', ...opt }),
    // 人员
    user: {
      // 查询用户信息-外系统使用
      loginInfo: (opt) => ajax({ url: `/sysuserinfo/get-user-detail`, method: 'get', ...opt }),
      add: (opt) => ajax({ url: `/sysuserinfo/add`, method: 'post', ...opt }),
      update: (opt) => ajax({ url: `/sysuserinfo/update`, method: 'post', ...opt }),
      close: (opt) => ajax({ url: `/sysuserinfo/user-close`, method: 'post', ...opt }),
      open: (opt) => ajax({ url: `/sysuserinfo/${opt.cId}`, method: 'post', ...opt }),
      info: (opt) => ajax({ url: `/sysuserinfo/${opt.cId}`, method: 'get', ...opt }),
      oneAuth: (opt) => ajax({ url: `/sysuserinfo/authorize`, method: 'post', ...opt }),
      batchAuth: (opt) => ajax({ url: `/sysuserinfo/batch-authorize`, method: 'post', ...opt }),
      batchUpdateDep: (opt) =>
        ajax({ url: `/sysuserinfo/batch-change-department`, method: 'post', ...opt }),
      execlDownload: (opt) =>
        ajax({
          url: `/sysuserinfo/download-template`,
          file: true,
          responseType: 'blob',
          method: 'get',
          ...opt,
        }),
      orgDownload: (opt) =>
        ajax({
          url: `/department/exportAll`,
          file: true,
          responseType: 'blob',
          method: 'post',
          ...opt,
        }),
      importExecl: (opt) =>
        ajax({
          url: `/sysuserinfo/import-excel`,
          method: 'post',
          headers: { 'Content-Type': 'multipart/form-data' },
          ...opt,
        }),
      getOneUserRole: (opt) => ajax({ url: `/sysuserinfo/get-role`, method: 'get', ...opt }),
      openGetUser: (opt) =>
        ajax(
          {
            url: `/open-api/sysuserinfo/getUser`,
            method: 'post',
            ...opt,
          },
          { needEncrypt: true },
        ),
      openGetUserInfo: (opt) =>
        ajax(
          {
            url: `/open-api/sysuserinfo/get-user-info`,
            method: 'post',
            ...opt,
          },
          { needEncrypt: true },
        ),
      openSendCode: (opt) =>
        ajax(
          {
            url: `/open-api/sms/verification-code/send`,
            method: 'post',
            ...opt,
          },
          { needEncrypt: true },
        ),
      openCheckCode: (opt) =>
        ajax(
          {
            url: `/open-api/sms/verification-code/check`,
            method: 'post',
            ...opt,
          },
          { needEncrypt: true },
        ),
      openUpdatePassWord: (opt) =>
        ajax(
          {
            url: `/open-api/sysuserinfo/updatePassword`,
            method: 'post',
            ...opt,
          },
          { needEncrypt: true },
        ),
      resetPassWord: (opt) => ajax({ url: `/sysuserinfo/resetPassword`, method: 'post', ...opt }),
      orgCompanyUsers: (opt) =>
        ajax({ url: `/sysuserinfo/get-orgcompany-users`, method: 'post', ...opt }),
      getUserType: (opt) => ajax({ url: `/sysuserinfo/get-user-type`, method: 'get', ...opt }),
    },
    // 微信人员
    wxUser: {
      list: (opt) => ajax({ url: `/wx-user/select-list`, method: 'get', ...opt }),
      wxList: (opt) => ajax({ url: `/wx-user/select-list-wx`, method: 'get', ...opt }),
    },
    // 微信企业部门
    wxDepartment: {
      list: (opt) => ajax({ url: `/wx-department/select-list`, method: 'get', ...opt }),
    },
    // 根据公司id查公司下的部门
    departmentList: (opt) => ajax({ url: `/department/tree-param`, method: 'get', ...opt }),
    // 根据部门查所在的人
    userList: (opt) => ajax({ url: `/department/user-list`, method: 'get', ...opt }),
    // 跨公司时查询到的公司名称
    companyList: (opt) => ajax({ url: `/department/list-no-cascade`, method: 'get', ...opt }),
    // 根据部门Id查销售公司-接单页下单使用
    corpInfo: (opt) => ajax({ url: `/department/${opt.data.departId}/corp-info`, method: 'get' }),
    // 根据公司ID查询供应商信息
    vendorsByCompanyId: (opt) => ajax({ url: '/corp/list-vendors', method: 'get', ...opt }),
    //查询销售渠道，组织销售渠道查询
    saleChannelConfig: (opt) =>
      ajax({ url: '/sale-channel/department-config', method: 'get', ...opt }),
    // 查询登录人销售渠道
    saleChannel: (opt) =>
      ajax({
        url: '/sale-channel',
        method: 'get',
        ...opt,
      }),
    saleChannelTree: (opt) =>
      ajax({
        url: '/sale-channel/info-list',
        method: 'get',
        ...opt,
      }),
    // 查询登录人的默认合作渠道
    defaultChannel: (opt) =>
      ajax({
        url: '/sale-channel/default',
        method: 'get',
        ...opt,
      }),
    financeDepartmentTree: (opt) =>
      ajax({
        url: '/department/get-tree-by-finance',
        method: 'get',
        ...opt,
      }),
    checkPassword: (opt) =>
      ajax({
        url: '/password/check',
        method: 'post',
        ...opt,
      }),
    getCheckCode: (opt) =>
      ajax({
        url: `/open-api/sysuserinfo/verification`,
        method: 'get',
        ...opt,
      }),
    updateNewPassWord: (opt) =>
      ajax({
        url: `/password/update`,
        method: 'post',
        ...opt,
      }),
    departmentLeader: {
      log: (opt) =>
        ajax({
          url: `/department/get-leader-log`,
          method: 'post',
          ...opt,
        }),
      save: (opt) =>
        ajax({
          url: `/department/save-department-leader`,
          method: 'post',
          ...opt,
        }),
      // 查询组织基础配置详情
      getDeptLeader: (opt) =>
        ajax({
          url: `/department/${opt.data.departmentId}/department-learder`,
          method: 'get',
          ...opt,
        }),
    },
  }
}

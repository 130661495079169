export default function FinancialAdapterServer(ajax, config) {
  const { domainName, FinancialAdapterServer } = config
  return {
    // 枚举值公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    //日志接口
    operationLogList: (opt) => {
      return ajax({
        url: '/operation-log/list',
        method: 'get',
        ...opt,
      })
    },
    // 结算单
    settlement: {
      exportTeamEarning: (opt) =>
        ajax({
          url: `/settlement/export-team-earnings-list`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),

      checkTeam: (opt) =>
        ajax({
          url: '/settlement/check',
          method: 'get',
          ...opt,
        }),
      list: (opt) =>
        ajax({
          url: '/settlement/non-settlement-team-list',
          method: 'GET',
          ...opt,
        }),
      // 查询订单关联的公司信息
      companyInfo: (opt) =>
        ajax({
          url: '/settlement/query-order-company-info',
          method: 'GET',
          ...opt,
        }),
      // 申请结算
      apply: (opt) =>
        ajax({
          url: '/settlement/apply',
          method: 'POST',
          ...opt,
        }),
      querySyncLogPage: (opt) =>
        ajax({
          url: '/settlement/query-sync-log-page',
          method: 'GET',
          ...opt,
        }),
      syncByLogId: (id) =>
        ajax({
          url: `/settlement/sync-by-log-id/${id}`,
          method: 'GET',
        }),
      syncTeam: (teamNo) =>
        ajax({
          url: `/settlement/sync-team/${teamNo}`,
          method: 'GET',
        }),
      syncConfirm: (confirmId) =>
        ajax({
          url: `/settlement/sync-confirm/${confirmId}`,
          method: 'GET',
        }),
      syncCommission: (orderId) =>
        ajax({
          url: `/settlement/sync-commission/${orderId}`,
          method: 'GET',
        }),
      syncOrder: (orderId) =>
        ajax({
          url: `/settlement/sync-order/${orderId}`,
          method: 'GET',
        }),
      //  查看订单公司内部结算价明细
      getSettlePriceDetail: (orderId) =>
        ajax({
          url: `/settlement/order-company-inner-settle-price-detail/${orderId}`,
          method: 'GET',
        }),
      // 设置订单公司内部结算价
      setSettlePriceDetail: (opt) =>
        ajax({
          url: `/settlement/change-order-company-inner-settle-price`,
          method: 'POST',
          ...opt,
        }),
    },
    // 对账
    statement: {
      // 未对账列表
      list: (opt) =>
        ajax({
          url: '/statement/list',
          method: 'POST',
          ...opt,
        }),
      // 创建对账单
      add: (opt) =>
        ajax({
          url: '/statement/add',
          method: 'POST',
          ...opt,
        }),
      // 删除对账明细中的确认单
      del: (opt) =>
        ajax({
          url: '/statement/delete-detail',
          method: 'POST',
          ...opt,
        }),
      // 添加对账明细
      addDetail: (opt) =>
        ajax({
          url: '/statement/add-detail',
          method: 'POST',
          ...opt,
        }),
    },
    // 未开票
    unInvoice: {
      // 查询未开票订单数据列表
      list: (opt) =>
        ajax({
          url: '/uninvoice/list',
          method: 'GET',
          ...opt,
        }),
      // 根据订单号查询发票明细数据
      getDetailSaveList: (opt) =>
        ajax({
          url: `/uninvoice`,
          method: 'GET',
          ...opt,
        }),
      // 发票申请
      apply: (opt) =>
        ajax({
          url: '/uninvoice/apply',
          method: 'POST',
          ...opt,
        }),
    },
    // 付款
    payment: {
      applyform: (opt) =>
        ajax({
          url: '/vendor/info',
          method: 'get',
          ...opt,
        }),
    },
    teamEarnings: {
      list: (opt) =>
        ajax({
          url: '/settlement/query-team-earnings-list',
          method: 'GET',
          ...opt,
        }),
      detail: (teamNo) =>
        ajax({
          url: `/settlement/query-team-earnings/${teamNo}`,
          method: 'GET',
        }),
      orderList: (opt) =>
        ajax({
          url: `/settlement/query-order-list`,
          method: 'GET',
          ...opt,
        }),
      confirmList: (opt) =>
        ajax({
          url: `/settlement/query-confirm-list`,
          method: 'GET',
          ...opt,
        }),
      settlementBaseItemList: (opt) =>
        ajax({
          url: `/settlement/query-settlement-base-item-page`,
          method: 'GET',
          ...opt,
        }),
      // 结转团档案
      transferTeamArchive: (opt) =>
        ajax({
          url: '/settlement/transfer-team-archive',
          method: 'POST',
          ...opt,
        }),
      // 批量移除订单
      removeTeamOrder: (opt) =>
        ajax({
          url: '/settlement/remove-team-order',
          method: 'POST',
          ...opt,
        }),
    },
  }
}

export default function ProductServer(ajax, config) {
  return {
    salesRecordList: (opt) =>
      ajax({ url: `/stock/resources-sale-progress`, method: 'POST', ...opt }),
    // 查询是否可以删除场次
    canDeleteSession: (opt) => ajax({ url: `/stock/can-delete-session`, method: 'GET', ...opt }),
    // 共享组
    share: {
      getGroup: (opt) => ajax({ url: '/stock/share-stock-group', method: 'POST', ...opt }),
      updateGroup: (opt) =>
        ajax({ url: '/stock/update-share-stock-group', method: 'POST', ...opt }),
      createGroup: (opt) =>
        ajax({ url: '/stock/create-share-stock-group', method: 'POST', ...opt }),
    },
    // 获取资源场次
    sessionList: (opt) => ajax({ url: '/stock/resources-session', method: 'GET', ...opt }),
    // 預留庫存列表
    obligateList: (opt) =>
      ajax({ url: '/purchase-rule/obligate-statistics', method: 'GET', ...opt }),
  }
}

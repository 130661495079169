import vuex from '@/store'
import axios from 'axios'
// 重要页面置灰处理
export async function isPageAsh() {
  try {
    const config = (await axios.get('https://oss.cits.com.cn/static/gray-global-config.json')).data
    vuex.commit('setPageGreyedOut', config.isGlobalGray ?? false)
  } catch (e) {
    vuex.commit('setPageGreyedOut', false)
  }
}

<template>
  <textarea
    ref="textarea"
    :style="{ height: height + 'px' }"
    class="no-style-textarea"
    disabled
    :value="value"
  ></textarea>
</template>

<script>
export default {
  name: 'NoStyleTextarea',
  props: {
    value: [String, Number],
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (!val) this.height = 0
        else
          this.$nextTick(() => {
            this.Start()
          })
      },
    },
  },
  data() {
    return {
      height: 0,
    }
  },
  mounted() {},
  methods: {
    Start() {
      const textarea = this.$refs.textarea
      this.height = this.calcTextareaHeight(textarea)
    },
    calcTextareaHeight(el) {
      if (typeof el === 'string') {
        el = document.querySelector(el)
      }
      const attrs = ['box-sizing', 'padding-top', 'padding-bottom', 'border-top', 'border-bottom']
      let heightOffset = 0
      const style = window.getComputedStyle(el)
      const [boxSizing, paddingTop, paddingBottom, borderTop, borderBottom] = attrs.map((item) =>
        style.getPropertyValue(item),
      )
      if (boxSizing === 'content-box') {
        heightOffset = -parseFloat(paddingTop) - parseFloat(paddingBottom)
      } else {
        heightOffset = parseFloat(borderTop) + parseFloat(borderBottom)
      }
      return el.scrollHeight + heightOffset
    },
  },
}
</script>

<style scoped lang="scss">
.no-style-textarea {
  background: transparent;
  width: 100%;
  height: auto;
  border: none;
  resize: none;
  outline: none;
  padding: 0;
  margin: 0;
}
</style>

const state = {
  fixedHeader: true,
  sidebarLogo: true,
  onlyShowContent: false,
  // 去掉顶部菜单 和 左侧菜单 仅展示内容区 目前微信打开为true
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  changeOnlyShowContent: (state, onlyShowContent) => {
    state.onlyShowContent = onlyShowContent
  },
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
}
const getters = {
  onlyShowContent: (state) => state.onlyShowContent,
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default {
  firstProductClassId: '一級品類',
  firstProductClassName: '一級品類名稱',
  secondProductClassId: '二級品類',
  secondProductClassName: '二級品類名稱',
  CategoryEnum: {
    one: {
      GroupTour: '跟團遊',
      FreeTravel: '自由行',
      groupTour: '團隊遊',
      AirTickets: '機票',
      hotel: '酒店',
      Tickets: '門券',
    },
    two: {
      regularTour: '常規跟團',
      halfGroup: '半跟團',
      LocalTour: '當地參團',
      privateGroup: '私家團',
      customTour: '定制遊',
      TourAround: '周邊跟團',
      PreFreeTravel: '預打包自由行',
      dynamicFreeTravel: '動態打包自由行',
      WineSet: '酒店套餐',
      DoorCover: '門票套餐',
      RegularTicket: '門券',
      DomesticAirTicket: '國內機票',
      InternationalTicket: '國際機票',
      domesticHotel: '酒店',
      foreignHotel: '國際酒店',
      conferenceTeam: '會務團隊',
      tourismTeam: '旅遊團隊',
    },
  },
}

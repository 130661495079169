/*
 * 项目预加载等处理方式
 * */
import { userInfoService } from '@/Power/userInfo'
import { noCacheMenus } from '@/layout/tool'
import Vuex from '@/store'
import { getHashUrl } from '@/utils'
import { generateKeyMenu } from '@/store/allMenu'

// vue创建app基础逻辑
export default async function () {
  try {
    // 需要排除海报对外地址,并且vuex正常返回结构数据
    if (
      Vuex &&
      Vuex.state.user.token &&
      !['/poster', '/poster/statement', '/poster/edit'].includes(getHashUrl())
    ) {
      // 页面刷新保证数据更新
      await Promise.all([
        userInfoService(), // 用户登录资源数据
        noCacheMenus(), // 左侧菜单资源数据
      ])
    }
    generateKeyMenu()
    return Promise.resolve(true)
  } catch (e) {
    return Promise.reject(e)
  }
}

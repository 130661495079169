const state = {
  sidebar: {
    opened: true,
  },
}

const mutations = {
  setSidebar(state, data) {
    state.sidebar.opened = data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}

const packageData = require('../package.json')

// 单独留出来用于可本地配置
const mockRouter = false

const baseConfig = {
  platform: 'nbs',
  title: '一體化旅行服務運營平臺',
  name: packageData.name,
  publicPath: '/nbs-pc',
  redirectUrl: '/homepage',
  // 不可以再次跳转的页面
  noPage: [
    '/login',
    '/404',
    '/403',
    '/',
    '/weixin-login',
    '/change-password',
    '/forget-password',
    '/flow-chart',
  ],
  mockRouter: process.env.VUE_APP_NODE_ENV === 'production' ? false : mockRouter, // 是否本地json配置路由
  VuexStorageName: 'nbspc',
  gaodeKey: '0e1ebe0f433b9a4e5eb1551d5333c311',
}

module.exports = baseConfig

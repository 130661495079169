export default function IntentionTemplateServer(ajax, config) {
  // const { domainName, IntentionServer } = config
  return {
    // 列表
    page: (opt) =>
      ajax({
        url: `/order-intention-template`,
        method: 'get',
        ...opt,
      }),
    // 新增
    add: (opt) =>
      ajax({
        url: `/order-intention-template`,
        method: 'post',
        ...opt,
      }),
    // 查询
    get: (opt) =>
      ajax({
        url: `/order-intention-template/${opt.id}`,
        method: 'get',
        ...opt,
      }),
    // 修改
    update: (opt) =>
      ajax({
        url: `/order-intention-template/${opt.data.id}`,
        method: 'put',
        ...opt,
      }),
    // 删除
    delete: (opt) =>
      ajax({
        url: `/order-intention-template/${opt.id}`,
        method: 'delete',
        ...opt,
      }),
  }
}

export default function (ajax, config) {
  return {
    auditNotice: (opt) =>
      ajax({
        url: '/notice/audit',
        method: 'POST',
        ...opt,
      }),
    deleteNotice: (id) =>
      ajax({
        url: '/notice/delete/' + id,
        method: 'delete',
      }),
    getNoticeDetail: (id) =>
      ajax({
        url: '/notice/detail/' + id,
        method: 'get',
      }),
    getNoticeList: (opt) =>
      ajax({
        url: '/notice/list',
        method: 'post',
        ...opt,
      }),
    getTodoList: (opt) =>
      ajax({
        url: '/todo/list',
        method: 'post',
        ...opt,
      }),
    saveNotice: (opt) =>
      ajax({
        url: '/notice/save',
        method: 'post',
        ...opt,
      }),
    setTopNotice: (opt) =>
      ajax({
        url: '/notice/top',
        method: 'post',
        ...opt,
      }),
    getTopNotice: (opt) =>
      ajax({
        url: '/notice/top-detail',
        method: 'get',
        ...opt,
      }),
    toggleStatus: (opt) =>
      ajax({
        url: '/notice/update-status',
        method: 'get',
        ...opt,
      }),
    // 板块类型
    boardList: (opt) =>
      ajax({
        url: '/board/list',
        method: 'get',
        ...opt,
      }),
    // 公告类型
    noticeTypeList: (opt) =>
      ajax({
        url: '/notice-type/list',
        method: 'post',
        ...opt,
      }),
    noticeCount: (opt) =>
      ajax({
        url: '/notice/notice-count',
        method: 'post',
        ...opt,
      }),
    clickLog: (opt) =>
      ajax({
        url: '/todo/click-log',
        method: 'post',
        ...opt,
      }),
  }
}

import { toJSon } from '../tool'

// 产品审核状态,
export function AuditStatusEnum() {
  const zero = 0
  const one = 1
  const two = 2
  const three = 3

  const list = [
    { label: '待提交', value: zero },
    { label: '待审核', value: one },
    { label: '已通过', value: two },
    { label: '已退回', value: three },
  ]
  return {
    zero,
    one,
    two,
    three,
    list,
    json: toJSon(list),
  }
}

// 客户端枚举
export const ClientEnum = {
  WBP: 'WBP',
  NBS: 'NBS',
}

// 系统内部使用，知识库维护页面，含菜单
const basePath = '/wiki-sys'
export const wiki = [
  {
    path: basePath + '/wiki',
    name: basePath + '/wiki',
    component: () => import('@/views/wikiSys/wiki/index.vue'),
    meta: { title: '知识库', auth: false },
  },
]

// 对外使用，异常自助检索系统，独立页面
export const wikiToOut = [
  {
    path: '/wiki-search',
    name: '/wiki-search',
    component: () => import('@/views/wikiSys/WikiSearch/index.vue'),
    meta: {
      title: '异常自助检索系统',
    },
  },
]

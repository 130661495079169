export default function PmsServer(ajax, config) {
  return {
    //根据团期和获得id获得产品日历促销信息
    getTips: (opt) => {
      return ajax({ url: '/promotion/list-product-promotions', method: 'POST', ...opt })
    },
    //根据营销活动名称模糊搜索获得活动id
    getPromotionInfo: (opt) => {
      return ajax({ url: '/promotion/get-promotion-info', method: 'POST', ...opt })
    },

    // 获取可以参加的促销活动列表
    canJoinPromotionList: (opt) => {
      return ajax({
        url: '/promotion/list-order-can-join-promotions',
        method: 'POST',
        ...opt,
      })
    },

    // 参加该促销活动
    joinPromotion: (opt) => {
      return ajax({ url: '/promotion/join-promotion', method: 'POST', isResponse: true, ...opt })
    },
  }
}

const basePath = '/commission-sys/'

export default [
  {
    path: basePath + 'commission-rules',
    name: basePath + 'commission-rules',
    component: () => import('@/views/commissionSys/CommissionRules'),
    meta: { title: '佣金规则' },
  },
  {
    path: basePath + 'rule-edit',
    name: basePath + 'rule-edit',
    component: () => import('@/views/commissionSys/CommissionRules/RuleEdit'),
    meta: { title: '编辑佣金规则' },
  },
  {
    path: basePath + 'commission-bill',
    name: basePath + 'commission-bill',
    component: () => import('@/views/commissionSys/CommissionBill'),
    meta: { title: '佣金账单' },
  },
  {
    path: basePath + 'bill-details',
    name: basePath + 'bill-details',
    component: () => import('@/views/commissionSys/CommissionBill/BillDetails/index.vue'),
    meta: { title: '账单明细' },
  },
  {
    path: basePath + 'commission-details',
    name: basePath + 'commission-details',
    component: () => import('@/views/commissionSys/CommissionDetails'),
    meta: { title: '佣金明细' },
  },
  {
    path: basePath + 'direction-setting',
    name: basePath + 'direction-setting',
    component: () => import('@/views/commissionSys/CommissionSettingDirection/index.vue'),
    meta: { title: '按方向设置销售佣金' },
  },
  {
    path: basePath + 'product-setting',
    name: basePath + 'product-setting',
    component: () => import('@/views/commissionSys/CommissionSettingProduct/index.vue'),
    meta: { title: '按产品设置销售佣金' },
  },
  {
    path: basePath + 'channel-setting',
    name: basePath + 'channel-setting',
    component: () => import('@/views/commissionSys/CommissionSettingChannel/index.vue'),
    meta: { title: '按渠道设置分佣比例' },
  },
  {
    path: basePath + 'payment-method',
    name: basePath + 'payment-method',
    component: () => import('@/views/commissionSys/PaymentMethod/index.vue'),
    meta: { title: '佣金发放方式' },
  },
]

// 需要全局注册部分组件这里注册
import i18n from '@/i18n'
// elementUI功能重构部分
import ElInputNumber from './ElInputNumber/index'

import ElementUI from 'element-ui'
import ElementLocale from 'element-ui/lib/locale'
import ElementSrcLocale from 'element-ui/src/locale'

// 自定义组件部分
import RowPopoverForm from './RowPopoverForm' // 表格行气泡校验
import PopoverFormItem from './PopoverFormItem'
import SvgIcon from './SvgIcon'
import NoStyleTextarea from './NoStyleTextarea'
import TwoTable from './TwoTable'
import ZlSelect from './ZlSelect/index'
import PreviewFile from './PreviewFile'
// import elTable from './table' 官方已修复
// import TwoTableColumn from './TwoTableColumn' // 拦截实现业务需求

// 注册到vue实例上面部分
// 批量注册列表
const component = [ElInputNumber]
// 自定义组件列表
const customCom = [
  RowPopoverForm,
  PopoverFormItem,
  SvgIcon,
  NoStyleTextarea,
  TwoTable,
  ZlSelect,
  PreviewFile,
]

function install(Vue) {
  // elementUI组件注册需要比自定义的组件部分晚，否则国际化失效
  Vue.use(ElementUI, { size: 'small' })

  component.forEach((com) => {
    Vue.use(com)
  })
  // Vue.prototype.$msgbox = MessageBox
  // Vue.prototype.$alert = MessageBox.alert
  // Vue.prototype.$confirm = MessageBox.confirm
  // Vue.prototype.$prompt = MessageBox.prompt
  // 国际化加载语言
  ElementSrcLocale.i18n((key, value) => i18n.t(key, value)) // 针对本地化源码国际化处理
  ElementLocale.i18n((key, value) => i18n.t(key, value)) // 非本地化

  customCom.forEach((com) => {
    Vue.use(com)
  })
}
export default install
